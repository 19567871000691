import { useMemo } from 'react';
import { useCookie } from 'react-use';
import { useGetAnalyticsCampaignParametersFromSearch } from './useGetAnalyticsCampaignParametersFromSearch';

export const useGetAnalyticsCampaignParameters = () => {
	const {
		utm_name,
		utm_source,
		utm_content,
		utm_medium,
		utm_campaign,
		utm_term,
		gclid,
	} = useGetAnalyticsCampaignParametersFromSearch();

	const [gclidCookieValue] = useCookie('gclid');

	const parameters = useMemo(() => {
		const parameters: Record<string, string> = {};

		if (utm_name) parameters.name = utm_name;
		if (utm_source) parameters.source = utm_source;
		if (utm_content) parameters.content = utm_content;
		if (utm_medium) parameters.medium = utm_medium;
		if (utm_campaign) parameters.campaign = utm_campaign;
		if (utm_term) parameters.term = utm_term;
		if (gclid || gclidCookieValue) parameters.gclid = gclid;

		return parameters;
	}, [
		utm_name,
		utm_source,
		utm_content,
		utm_medium,
		utm_campaign,
		utm_term,
		gclid,
		gclidCookieValue,
	]);

	return parameters;
};
