import * as z from 'zod';
import { AppLanguage, SupportedCountries } from '../types';

const envVariables = z.object({
	VITE_COUNTRY: z.nativeEnum(SupportedCountries),
	NODE_ENV: z.string(),
	VITE_SERVER_URL: z.string(),
	VITE_ID_CARD_AUTHENTICATION_URL: z.string(),
	VITE_LOCIZE_PROJECT_ID: z.string(),
	VITE_REGION_DEFAULT_LANGUAGE: z.nativeEnum(AppLanguage),
	VITE_OLD_APP_DOMAIN: z.string(),
	VITE_PURCHASE_FLOW_DOMAIN: z.string(),
	VITE_GOOGLE_TAG_MANAGER_ID: z.string().optional(),
	VITE_SMARTLOOK_PROJECT_ID: z.string().optional(),
	VITE_SIFT_BEACON_KEY: z.string().optional(),
	VITE_INVOICES_FEATURE: z.string().optional(),
	VITE_PROFILE_FEATURE: z.string().optional(),
	VITE_AGREEMENTS_FEATURE: z.string().optional(),
	VITE_CREDIT_ACCOUNT_MODIFICATION_FEATURE: z.string().optional(),
	VITE_CREDIT_ACCOUNT_LIMIT_INCREASE_FEATURE: z.string().optional(),
	VITE_CREDIT_ACCOUNT_CONVERSION_FEATURE: z.string().optional(),
	VITE_CREDIT_LINE_PAYMENT_FEATURE: z.string().optional(),
	VITE_RUDDERSTACK_WRITE_KEY: z.string().optional(),
	VITE_RUDDERSTACK_DATA_PLANE_URL: z.string().optional(),
	VITE_ZENDESK_CHAT_KEY_CODE: z.string().optional(),
	VITE_FIREBASE_API_KEY: z.string().optional(),
	VITE_FIREBASE_AUTH_DOMAIN: z.string().optional(),
	VITE_FIREBASE_DATABASE_URL: z.string().optional(),
	VITE_FIREBASE_PROJECT_ID: z.string().optional(),
	VITE_FIREBASE_STORAGE_BUCKET: z.string().optional(),
	VITE_FIREBASE_MESSAGING_SENDER_ID: z.string().optional(),
	VITE_FIREBASE_APP_ID: z.string().optional(),
	VITE_FIREBASE_MEASUREMENT_ID: z.string().optional(),
	VITE_FIREBASE_CLOUD_MESSAGING_VAPID_KEY: z.string().optional(),
});

envVariables.parse(process.env);

declare global {
	namespace NodeJS {
		interface ProcessEnv extends z.infer<typeof envVariables> {}
	}
}

export const SERVER_URL = process.env.VITE_SERVER_URL;
export const GRAPHQL_SERVER_URL = `${SERVER_URL}/graphql`;
export const ID_CARD_AUTHENTICATION_URL =
	process.env.VITE_ID_CARD_AUTHENTICATION_URL;
export const LOCIZE_PROJECT_ID = process.env.VITE_LOCIZE_PROJECT_ID;
export const REGION_DEFAULT_LANGUAGE = process.env.VITE_REGION_DEFAULT_LANGUAGE;
export const ENVIRONMENT = process.env.NODE_ENV;
export const APP_COUNTRY = process.env.VITE_COUNTRY;
export const OLD_APP_DOMAIN = process.env.VITE_OLD_APP_DOMAIN;
export const PURCHASE_FLOW_DOMAIN = process.env.VITE_PURCHASE_FLOW_DOMAIN;
export const GOOGLE_TAG_MANAGER_ID = process.env.VITE_GOOGLE_TAG_MANAGER_ID;
export const SMARTLOOK_PROJECT_ID = process.env.VITE_SMARTLOOK_PROJECT_ID;
export const SENTRY_DSN = process.env.VITE_SENTRY_DSN;
export const SIFT_BEACON_KEY = process.env.VITE_SIFT_BEACON_KEY;
export const INVOICES_FEATURE = process.env.VITE_INVOICES_FEATURE === '1';
export const PROFILE_FEATURE = process.env.VITE_PROFILE_FEATURE === '1';
export const AGREEMENTS_FEATURE = process.env.VITE_AGREEMENTS_FEATURE === '1';
export const CREDIT_ACCOUNT_MODIFICATION_FEATURE =
	process.env.VITE_CREDIT_ACCOUNT_MODIFICATION_FEATURE === '1';
export const CREDIT_ACCOUNT_LIMIT_INCREASE_FEATURE =
	process.env.VITE_CREDIT_ACCOUNT_LIMIT_INCREASE_FEATURE === '1';
export const CREDIT_ACCOUNT_CONVERSION_FEATURE =
	process.env.VITE_CREDIT_ACCOUNT_CONVERSION_FEATURE === '1';
export const CREDIT_LINE_PAYMENT_FEATURE =
	process.env.VITE_CREDIT_LINE_PAYMENT_FEATURE === '1';
export const RUDDERSTACK_WRITE_KEY = process.env.VITE_RUDDERSTACK_WRITE_KEY;
export const RUDDERSTACK_DATA_PLANE_URL =
	process.env.VITE_RUDDERSTACK_DATA_PLANE_URL;
export const VITE_ZENDESK_CHAT_KEY_CODE =
	process.env.VITE_ZENDESK_CHAT_KEY_CODE;
export const FIREBASE_CONFIG = {
	apiKey: process.env.VITE_FIREBASE_API_KEY,
	authDomain: process.env.VITE_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.VITE_FIREBASE_DATABASE_URL,
	projectId: process.env.VITE_FIREBASE_PROJECT_ID,
	storageBucket: process.env.VITE_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.VITE_FIREBASE_APP_ID,
	measurementId: process.env.VITE_FIREBASE_MEASUREMENT_ID,
};
export const VITE_FIREBASE_CLOUD_MESSAGING_VAPID_KEY =
	process.env.VITE_FIREBASE_CLOUD_MESSAGING_VAPID_KEY;

export const isProd = ENVIRONMENT === 'production';
