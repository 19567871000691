/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Upload: { input: number; output: number; }
};

/** Type for AccountScoring invitation */
export type AccountScoringInvitation = {
  /** Id created on side of AccountScoring */
  application_id?: Maybe<Scalars['Int']['output']>;
  /** When invitation was created */
  created_at: Scalars['Int']['output'];
  /** Id created on side of AccountScoring */
  credit_account_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** Language of AccountScoring invitation page */
  language: Scalars['String']['output'];
  /** Result of Invitation Processing */
  process_status: Scalars['String']['output'];
  /** Hash of AccountScoring Invitation */
  response_hash?: Maybe<Scalars['String']['output']>;
  /** Id created on side of AccountScoring */
  response_id?: Maybe<Scalars['Int']['output']>;
  /** URL to AccountScoring invitation */
  response_url?: Maybe<Scalars['String']['output']>;
  /** If AccountScoring should send email or not */
  send_email: Scalars['Boolean']['output'];
  /** Statement Id created on side of AccountScoring */
  statement_id?: Maybe<Scalars['Int']['output']>;
  /** Email of user */
  user_email: Scalars['String']['output'];
  /** Id of user */
  user_id: Scalars['Int']['output'];
  /** Full name of user */
  user_name: Scalars['String']['output'];
  /** SSN of user */
  user_ssn: Scalars['String']['output'];
  /** Valid until */
  valid_until?: Maybe<Scalars['Int']['output']>;
};

/** An uploaded bank account statement for a user */
export type AccountStatement = {
  created_at: Scalars['Int']['output'];
  /** Id of account statement */
  id: Scalars['Int']['output'];
  /** Provider responsible for statement analysis */
  provider: AccountStatementProviderType;
  /** Id created on side of Provider (for ex. AccountScoring response_id) */
  provider_reference?: Maybe<Scalars['Int']['output']>;
  /** Current status of statement */
  status: AccountStatementStatusType;
  /** Id of User, owner of account statement */
  user_id: Scalars['Int']['output'];
};

export enum AccountStatementProviderType {
  ACCOUNTSCORING = 'ACCOUNTSCORING',
  MANUAL = 'MANUAL'
}

export enum AccountStatementStatusType {
  ACCEPTED = 'ACCEPTED',
  ANALYZING = 'ANALYZING',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

/** User actions log */
export type ActionLog = {
  /** The type of action that occurred */
  action: Scalars['String']['output'];
  /** Timestamp, when the log was created */
  created_at: Scalars['Int']['output'];
  /** Data that was used in this action */
  data: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** ID of the user who initiated the log */
  initiator_id?: Maybe<Scalars['Int']['output']>;
  /** IP address of the user who initiated the log */
  ip_addr?: Maybe<Scalars['String']['output']>;
  /** Domain where the action occurred (web, partner, admin etc.) */
  subdomain?: Maybe<Scalars['String']['output']>;
  /** Name of the table where the action occurred */
  table_name: Scalars['String']['output'];
};

export type ActionLogIndex = {
  /** The type of action that occurred */
  action: Scalars['String']['output'];
  /** Timestamp, when the log was created */
  created_at: Scalars['Int']['output'];
  /** Data that was used in this action */
  data: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** ID of the user who initiated the log */
  initiator_id?: Maybe<Scalars['Int']['output']>;
  /** IP address of the user who initiated the log */
  ip_addr?: Maybe<Scalars['String']['output']>;
  /** Domain where the action occurred (web, partner, admin etc.) */
  subdomain?: Maybe<Scalars['String']['output']>;
  /** Name of the table where the action occurred */
  table_name: Scalars['String']['output'];
  /** Name of the user who initiated the log */
  user_name?: Maybe<Scalars['String']['output']>;
};

export enum ActionLogOrderBy {
  ACTION = 'action',
  CREATED_AT = 'created_at',
  SUBDOMAIN = 'subdomain',
  TABLE_NAME = 'table_name',
  USER_NAME = 'user_name'
}

export type AdminApplicationIndex = {
  /** Determine whether to accrue interest and fees for a loan */
  accrue_interest_and_fees: Scalars['Boolean']['output'];
  /** Currently active payment leave for application */
  active_payment_leave?: Maybe<PaymentLeave>;
  /** Whether to add legal person info to the invoice */
  add_legal_person_to_invoice: Scalars['Boolean']['output'];
  /** If application has been modified, the original credit info data is stored here */
  application_modifications?: Maybe<Array<Maybe<ApplicationModification>>>;
  /** Helper table for making purchases via a physical cash register */
  application_reference?: Maybe<ApplicationReference>;
  /** ID of the application_user_info that this application belongs to */
  application_user_info_id?: Maybe<Scalars['Int']['output']>;
  /** Items bought with the purchase */
  basket_items?: Maybe<Array<Maybe<ApplicationBasketItem>>>;
  /** Date, when merchant was paid the application bonuses */
  bonus_paid_at?: Maybe<Scalars['String']['output']>;
  /** Application status based on information provided by the broker. */
  broker_status?: Maybe<Scalars['String']['output']>;
  /** Information about application that has been bought back from merchant */
  buyback_settings?: Maybe<ApplicationBuybackSettings>;
  /** A campaign that applies to the application, if enabled */
  campaign?: Maybe<ApplicationCampaign>;
  /** Determines, whether the application can be modified */
  can_be_modified: Scalars['Boolean']['output'];
  /** Whether an application can convert from a regular schedule to (long-term) CA/HP. */
  can_convert_from_regular: Scalars['Boolean']['output'];
  /** Whether the application can be converted manually to CA. */
  can_manually_convert_to_credit_account: Scalars['Boolean']['output'];
  /** Checks if the application can be refinanced or not */
  can_refinance: Scalars['Boolean']['output'];
  /** Optional URL, where the user will be redirected to after application was rejected */
  cancel_url?: Maybe<Scalars['String']['output']>;
  /** Request made by merchant to cancel this application */
  cancellation_request?: Maybe<ApplicationCancellationRequest>;
  /** Timestamp, when the application was cancelled (purchased item returned to merchant) */
  cancelled_at?: Maybe<Scalars['Int']['output']>;
  /** The user by whom this application was cancelled */
  cancelled_by?: Maybe<User>;
  /** Date, when merchant was paid cashier the application bonuses */
  cashier_bonus_paid_at?: Maybe<Scalars['String']['output']>;
  /** ConvertingSchedule regular campaign schedule */
  converting_schedule_regular?: Maybe<ConvertingScheduleRegular>;
  /** Timestamp, when the application was created */
  created_at: Scalars['Int']['output'];
  /** Additional info of the application about pricing */
  credit_info?: Maybe<ApplicationCreditInfo>;
  /** Fully rejected customers are given an option to get a loan from a third-party loan provider */
  customer_sale?: Maybe<CustomerSale>;
  /** How many days the hire purchase payment is overdue. 0 if HP payment is not overdue. */
  days_past_due?: Maybe<Scalars['Int']['output']>;
  /** Ongoing and finished claim collection processes */
  debt_collections?: Maybe<Array<Maybe<DebtCollection>>>;
  /** Timestamp, when the application was deleted */
  deleted_at?: Maybe<Scalars['Int']['output']>;
  /** Refunds history for specific Direct payment (ESTO Pay) */
  direct_payment_refunds?: Maybe<Array<Maybe<DirectPaymentRefund>>>;
  /** Amount that still has to be paid for the application's down payment */
  down_amount_to_pay?: Maybe<Scalars['Float']['output']>;
  /** Down payment of the application */
  down_installments?: Maybe<Array<Maybe<Installment>>>;
  /** If the application was paid for early it will have additional info */
  early_payment?: Maybe<ApplicationEarlyPayment>;
  /**
   * Disable the eligibility check in order to not make actual queries to the Decision
   *                 Engine. In this case, the eligibility status and state will not change
   */
  eligibility_check_disabled?: Maybe<Scalars['Boolean']['output']>;
  /** 1: Eligible, 0: Pending, -1: Rejected */
  eligibility_state?: Maybe<Scalars['Int']['output']>;
  /** ELIGIBLE or reason why not eligible */
  eligibility_status?: Maybe<Scalars['String']['output']>;
  /** Timestamp of the last time eligibility was checked */
  eligibility_status_last_check?: Maybe<Scalars['Int']['output']>;
  /** Timestamp, when the last payment was made that paid for the application fully */
  ended_at?: Maybe<Scalars['Int']['output']>;
  /** Time when the application will be deleted, if it has not been signed */
  expires_at?: Maybe<Scalars['Int']['output']>;
  /** First monthly installment */
  first_installment?: Maybe<Installment>;
  /** True, if app for private person, false if for legal person and NULL if not fixed at all */
  fixed_for_private_person?: Maybe<Scalars['Boolean']['output']>;
  /** True, if application does NOT represent a legal person */
  for_private_person: Scalars['Boolean']['output'];
  /** Whether to forward rejected application to another broker. */
  forward_rejects: Scalars['Boolean']['output'];
  /** Whether it is a FREE HP application (no credit cost, no scoring) */
  free_hp_enabled: Scalars['Boolean']['output'];
  /** Whether this application was created in a retail (physical) store */
  from_retail: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  /**
   * Whether the user's identity needs verification (depends on requested amount).
   *                     Always include user relation with id_verification_method!
   */
  id_verification_required?: Maybe<Scalars['Boolean']['output']>;
  /** Time when the user (small loan) or merchant (hire purchase) was paid for by us */
  initial_paid_at?: Maybe<Scalars['Int']['output']>;
  /** Required installments for the application */
  installments?: Maybe<Array<Maybe<Installment>>>;
  /** Required installments for the application, ordered by due_at ASC */
  installments_ordered?: Maybe<Array<Maybe<Installment>>>;
  /** The fee for instant payout of small loan */
  instant_payment_fee: Scalars['Float']['output'];
  /** Current status of the Instantor request for this application */
  instantor_eligibility: InstantorEligibility;
  /** Identifier for the application that is used for payments */
  invoice_reference_nr?: Maybe<Scalars['String']['output']>;
  /** Whether the application converts (to CA or longer HP) */
  is_converting_schedule: Scalars['Boolean']['output'];
  /** Determines, if the user can apply for the loan specified by this application */
  is_eligible: Scalars['Boolean']['output'];
  /** Whether the funds will be paid out immediately to the client. Only applies for consumer loans */
  is_instant_payout: Scalars['Boolean']['output'];
  /** Whether the user can make instant payouts. */
  is_instant_payout_available: Scalars['Boolean']['output'];
  /** Determines, if direct payment can be refunded */
  is_refundable: Scalars['Boolean']['output'];
  /** If true, then this application is only used for testing purposes */
  is_test: Scalars['Boolean']['output'];
  /** klix payments connected to this application */
  klix_payments?: Maybe<Array<Maybe<KlixPayment>>>;
  /** Very last monthly installment that will be paid */
  last_installment?: Maybe<Installment>;
  /** Latest customer care decision result for related to application */
  latest_customer_care_decision?: Maybe<CustomerCareDecision>;
  /** Latest modification request made by merchant */
  latest_modification_request?: Maybe<ApplicationModificationRequest>;
  /** Info about the legal person connected to the application */
  legal_person_info?: Maybe<ApplicationLegalPersonInfo>;
  /**
   * Get ESTO's or merchant's or campaign logo, depending on the application.
   *                     NB! Always select merchant and campaign relations
   */
  logo_url: Scalars['String']['output'];
  /** Seller of the purchase */
  merchant?: Maybe<Merchant>;
  /** Data entered by the merchant for the application */
  merchant_data?: Maybe<ApplicationMerchantData>;
  /** ID of the merchant selling something to the user */
  merchant_id?: Maybe<Scalars['Int']['output']>;
  /** Requests made by merchant to modify application values such as net total */
  modification_requests?: Maybe<Array<Maybe<ApplicationModificationRequest>>>;
  /** The next installment that is unpaid (can be in the past as well) */
  next_installment?: Maybe<Installment>;
  /** The next installment date for the given application */
  next_installment_date?: Maybe<Scalars['String']['output']>;
  /**
   * The next payment amount for the application. If overdue then only overdue amount,
   *                     but otherwise get current month
   */
  next_payment_amount?: Maybe<Scalars['Float']['output']>;
  /** Application overdue installments */
  overdue_installments?: Maybe<Array<Maybe<Installment>>>;
  /** The total amount of principal that has been paid */
  paid_principal: Scalars['Float']['output'];
  /** Payment leaves taken for this application */
  payment_leaves?: Maybe<Array<Maybe<PaymentLeave>>>;
  /** Acquirer pivot of the product (application or credit account), if the product has been sold with a portfolio */
  portfolio_sale_product?: Maybe<PortfolioSaleProduct>;
  /** Pre-signing conversion offer for the application */
  pre_signing_conversion_offer?: Maybe<PreSigningConversionOffer>;
  /** Value taken from pricing. Max amount that an admin can waive from a hire purchase. */
  pricing_max_waive_amount: Scalars['Float']['output'];
  /** Timestamp, when the application was processed (for direct payments) */
  processed_at?: Maybe<Scalars['Int']['output']>;
  /** Get the name of merchant if private label or ESTO if not */
  provider_name: Scalars['String']['output'];
  /** URL where the purchase flow starts from */
  purchase_url: Scalars['String']['output'];
  /** A unique string to identify the application */
  reference_key: Scalars['String']['output'];
  /** The application that was refinanced by this application */
  refinanced_application?: Maybe<Application>;
  /** The application that refinanced this application */
  refinancing_parent_application?: Maybe<Application>;
  /** The application's id that refinances this application */
  refinancing_parent_id?: Maybe<Scalars['Int']['output']>;
  /** Get amount that was successfully refunded for Direct payment */
  refunded_amount: Scalars['Float']['output'];
  /** Timestamp, when the application was rejected */
  rejected_at?: Maybe<Scalars['Int']['output']>;
  /** Reminders that have been sent to the user because of overdue installments */
  reminders?: Maybe<Array<Maybe<Reminder>>>;
  /** The amount required for the purchase */
  requested_amount: Scalars['Float']['output'];
  /** Resale applications are potentially eligible applications which are currently unsigned */
  resale?: Maybe<Resale>;
  /** Resale call is created when the user is called during the resale process */
  resale_call?: Maybe<ResaleCall>;
  /** Optional URL, where the user will be redirected to after submitting the application */
  return_url?: Maybe<Scalars['String']['output']>;
  /** Type of the application, like hire-purchase or small loan */
  schedule_type: ApplicationScheduleType;
  /** Determine whether reminders should be sent */
  send_reminders: Scalars['Boolean']['output'];
  /** Whether a separate checkbox for pension query is shown at purchase flow */
  show_separate_pension_query: Scalars['Boolean']['output'];
  /** Timestamp, when the application was signed and accepted */
  signed_at?: Maybe<Scalars['Int']['output']>;
  /** Credit account eligibility status that is one of SimpleEligibilityStatus */
  simple_eligibility_status: Scalars['String']['output'];
  /** Timestamp, when the application was sold to a debt collector */
  sold_at?: Maybe<Scalars['Int']['output']>;
  /** Application status. One of the following: UNSIGNED, REJECTED, ACTIVE, TERMINATED, CANCELLED, DELETED, PROCESSED, ENDED */
  status: ApplicationStatus;
  /** Application sub-statuses */
  sub_statuses?: Maybe<Array<Maybe<LoanSubStatus>>>;
  /** Recurring payments subscription for the application */
  subscription?: Maybe<Subscription>;
  /** Timestamp, when the application was terminated (e.g when customer died or went bankrupt) */
  terminated_at?: Maybe<Scalars['Int']['output']>;
  termination_reason?: Maybe<Scalars['String']['output']>;
  /** The total amount user has paid for this application */
  total_amount_paid?: Maybe<Scalars['Float']['output']>;
  /** The total amount across all installments */
  total_expected?: Maybe<Scalars['Float']['output']>;
  /**
   * Total unpaid amount that should have been paid by now. NB! Only use for single queries
   *                     as it's actually a DB query
   */
  total_overdue: Scalars['Float']['output'];
  /** Get total paid amount for application */
  total_paid?: Maybe<Scalars['Float']['output']>;
  /** Type of the application, like hire-purchase or small loan */
  type: ApplicationType;
  /** Get the total unpaid principal amount for the loan */
  unpaid_principal?: Maybe<Scalars['Float']['output']>;
  /** Owner of the application */
  user?: Maybe<User>;
  /** ID of the user that this application belongs to */
  user_id?: Maybe<Scalars['Int']['output']>;
  /** Additional info of the application about the user */
  user_info?: Maybe<ApplicationUserInfo>;
};


export type AdminApplicationIndexSimpleEligibilityStatusArgs = {
  do_query?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum AdminApplicationsOrderBy {
  CREATED_AT = 'created_at',
  ID = 'id',
  IRR = 'irr',
  MERCHANT_NAME = 'merchant_name',
  MONTHLY_PAYMENT = 'monthly_payment',
  NEXT_INSTALLMENT_DATE = 'next_installment_date',
  PERIOD_MONTHS = 'period_months',
  REQUESTED_AMOUNT = 'requested_amount',
  TOTAL_EXPECTED = 'total_expected',
  USER_NAME = 'user_name'
}

export enum AdminMerchantsOrderBy {
  CREATED_AT = 'created_at',
  NAME = 'name',
  NAME_SEARCH = 'name_search',
  NEED_TO_PAY = 'need_to_pay',
  REGISTRY_CODE = 'registry_code',
  SALES_LAST_30_DAYS = 'sales_last_30_days',
  SALES_LAST_180_DAYS = 'sales_last_180_days',
  SALES_TOTAL = 'sales_total'
}

/** A user with additional information, that can be accessed by admin */
export type AdminUserIndex = {
  /** Whether waiting for client to submit account statement or it is pending etc */
  account_statement_eligibility?: Maybe<Scalars['String']['output']>;
  /** Uploaded account statements of the user */
  account_statements?: Maybe<Array<Maybe<AccountStatement>>>;
  /** Account Scoring */
  accountscoring_invitations?: Maybe<Array<Maybe<AccountScoringInvitation>>>;
  /** User's active applications */
  active_applications?: Maybe<Array<Maybe<Application>>>;
  /** Currently active central payment leave for user */
  active_payment_leave?: Maybe<PaymentLeave>;
  /** Active premium subscription for the user */
  active_premium_subscription?: Maybe<FeatureSubscription>;
  /** Whether the user has allowed ESTO to query Estonian pension centre */
  allow_pension_query: Scalars['Boolean']['output'];
  /** User’s risk profile level for anti-money laundering */
  aml_risk_profile?: Maybe<Scalars['String']['output']>;
  /** Total outstanding principal of all user's applications */
  application_outstanding_principal: Scalars['Float']['output'];
  /** Approximate monthly payment that will be paid during payment leave */
  approximate_monthly_payment?: Maybe<Scalars['Float']['output']>;
  /** Amount of credit the user is allowed to spend (user central limit) */
  available_credit?: Maybe<Scalars['Float']['output']>;
  /** Amount of credit the user is allowed to spend for hire purchase */
  available_hp_credit?: Maybe<Scalars['Float']['output']>;
  /** Amount of credit the user is allowed to spend for Small Loan */
  available_sl_credit?: Maybe<Scalars['Float']['output']>;
  /** Disposable income if the best possible values were used. */
  best_disposable_income?: Maybe<Scalars['Float']['output']>;
  /** Determines whether a user can be onboarded to premium account with opt out approach */
  can_be_opt_out_onboarded_to_premium_account: Scalars['Boolean']['output'];
  /** Whether the user has agreed to the conditions */
  conditions_agreement: Scalars['Boolean']['output'];
  /** Timestamp, when the user was created */
  created_at: Scalars['Int']['output'];
  /** Credit accounts that belong to the user */
  credit_accounts?: Maybe<Array<Maybe<CreditAccount>>>;
  /** User's central credit limit for HP + CA */
  credit_limit?: Maybe<Scalars['Float']['output']>;
  /** Credit scores for a user */
  credit_scores?: Maybe<Array<Maybe<UserCreditScore>>>;
  /** The date of birth the of user in YYYY-MM-DD format */
  date_of_birth?: Maybe<Scalars['String']['output']>;
  /** Maximum monthly payment based on income and liabilities. */
  disposable_income?: Maybe<Scalars['Float']['output']>;
  /**
   * Maximum monthly payment based on income and liabilities WITHOUT the active CA monthly payment.
   *                     NB! Only request this variable when completely necessary as it is a heavy calculation.
   */
  disposable_income_without_ca?: Maybe<Scalars['Float']['output']>;
  /** An identifying document of the user */
  document?: Maybe<UserDocument>;
  /** Debt service coverage ratio (For Estonia). Ratio of user income and lease payments */
  dscr?: Maybe<Scalars['Float']['output']>;
  /** Debt service coverage ratio (For Lithuania). Ratio of lease payments and user income */
  dsti?: Maybe<Scalars['Float']['output']>;
  /** ELIGIBLE or reason why not eligible */
  eligibility_status?: Maybe<Scalars['String']['output']>;
  /** Email of the user */
  email?: Maybe<Scalars['String']['output']>;
  /** A request to RIA for getting access to info from EMTA (Estonian Tax Agency) */
  emta_consent?: Maybe<RiaConsent>;
  /** A saved card used for EveryPay payment */
  everypay_cards?: Maybe<Array<Maybe<EverypayUserCard>>>;
  /** How much credit user can take for Hire Purchase */
  hp_credit_limit?: Maybe<Scalars['Float']['output']>;
  /** ID of the user */
  id: Scalars['Int']['output'];
  /** By which method the user's identity was verified */
  id_verification_method?: Maybe<Scalars['String']['output']>;
  /** Instantor responses with OK status for the user */
  instantor_responses?: Maybe<Array<Maybe<InstantorResponse>>>;
  /** Identifier for the user that is used for payments */
  invoice_reference_nr: Scalars['String']['output'];
  /** Check if the user has any overdue loans or credit accounts */
  is_overdue: Scalars['Boolean']['output'];
  /** Whether password is set for user or not */
  is_password_set: Scalars['Boolean']['output'];
  /** Language that the user is currently using */
  language_abbr: Scalars['String']['output'];
  /** Timestamp of user's last login */
  last_login?: Maybe<Scalars['Int']['output']>;
  /** Date, when the user promised to return the money */
  latest_promised_repayment_date?: Maybe<Scalars['String']['output']>;
  /** The latest actually used credit score */
  latest_user_credit_score?: Maybe<UserCreditScore>;
  /** Legal people (businesses) that belong to the user */
  legal_people?: Maybe<Array<Maybe<LegalPerson>>>;
  /** Get the latest legal people tied to the user */
  legal_people_active?: Maybe<Array<Maybe<LegalPerson>>>;
  /** Marital status: SINGLE, DIVORCED, WIDOWED, MARRIED, NA */
  marital_status: MaritalStatus;
  /** Stores that the user owns */
  merchants?: Maybe<Array<Maybe<UserMerchant>>>;
  /** Emails and SMS sent to the user */
  messages_sent?: Maybe<Array<Maybe<UserMessageSent>>>;
  /** Whether the user has agreed to the newsletter agreement */
  newsletter_agreement: Scalars['Boolean']['output'];
  /**
   * Next monthly payment for the client,
   *                                   sum of HP and CA invoices amounts that client should pay for current month
   */
  next_invoice_amount: Scalars['Float']['output'];
  /**
   * The date of the oldest overdue or upcoming payment for the client's loans (HP or CA).
   *                     Null, if there is nothing to pay for any more.
   */
  next_payment_date?: Maybe<Scalars['String']['output']>;
  /** How many days the user is overdue. 0 if user is not overdue. */
  overdue_days?: Maybe<Scalars['Int']['output']>;
  overflow: Scalars['Float']['output'];
  /** Queries about the defaulted user payments */
  payment_default_queries?: Maybe<Array<Maybe<PaymentDefaultQuery>>>;
  /** All historical and active user-central payment leaves */
  payment_leaves?: Maybe<Array<Maybe<PaymentLeave>>>;
  /** URL, where the client can pay all of their debt */
  payment_url: Scalars['String']['output'];
  /** Currently pending central payment leave */
  pending_payment_leave?: Maybe<PaymentLeave>;
  /** All permission bits that the user has */
  permission_bits: Scalars['Int']['output'];
  /** Phone number of the user */
  phone?: Maybe<Scalars['String']['output']>;
  /** Area code of the phone */
  phone_area_code?: Maybe<Scalars['String']['output']>;
  /** Personal Identificaton Number (SSN) of the user */
  pin?: Maybe<Scalars['String']['output']>;
  /** Whether the user is politically exposed and to which degree */
  political_exposure?: Maybe<PoliticalExposure>;
  /** Current status of user premium subscription */
  premium_subscription_status: Scalars['String']['output'];
  /** History of user's premium subscriptions */
  premium_subscriptions?: Maybe<Array<Maybe<FeatureSubscription>>>;
  /** Profile of the user */
  profile?: Maybe<UserProfile>;
  /** User's latest invoice */
  recent_invoice?: Maybe<Invoice>;
  /** Unique reference (long string) for the user */
  reference_key: Scalars['String']['output'];
  resale?: Maybe<Resale>;
  /** Availability of the user's retirement fund */
  retirement_fund?: Maybe<UserRetirementFund>;
  /** A request of proceeds from the Estonian Retirement Fund */
  retirement_fund_proceeds_requests?: Maybe<Array<Maybe<RetirementFundProceedsRequest>>>;
  /** How the user signed in to the current session */
  sign_in_method?: Maybe<AdminUserIndexsignInMethod>;
  /** Credit accounts that have been signed and belong to the user */
  signed_credit_accounts?: Maybe<Array<Maybe<CreditAccount>>>;
  /** Legal person eligibility status that is either positive, negative or pending */
  simple_eligibility_status?: Maybe<Scalars['String']['output']>;
  /** How much credit user can take for Small Loan */
  sl_credit_limit?: Maybe<Scalars['Float']['output']>;
  /** Total outstanding principal of all user's Small Loan applications */
  small_loan_outstanding_principal: Scalars['Float']['output'];
  /** User's central subscription by which we retrieve money from their credit card for payment */
  subscription?: Maybe<Subscription>;
  /**
   * Total current unpaid amount for the client,
   *                                   sum of all unpaid HP invoices and last unpaid CA invoice amounts that client need to pay right now
   */
  unpaid_invoice_amount: Scalars['Float']['output'];
  /** User manual incomes liabilities group that belong to the user */
  user_manual_incomes_liabilities_groups?: Maybe<Array<Maybe<UserManualIncomesLiabilitiesGroup>>>;
  /** Username for login purposes, usually defaults to PIN */
  username?: Maybe<Scalars['String']['output']>;
};

export enum AdminUserIndexsignInMethod {
  EPARAKSTS = 'eparaksts',
  HASH = 'hash',
  ID_CARD = 'id_card',
  MAGIC_LINK = 'magic_link',
  MOBILE = 'mobile',
  PASSWORD = 'password',
  PAYSERA_BANKLINK = 'paysera_banklink',
  SMART_ID = 'smart_id'
}

/** Get allowed loan investor type for investor. */
export type AllowedLoanInvestorTypeForInvestor = {
  /** Name of type loan investor type. */
  name?: Maybe<Scalars['String']['output']>;
  /** Type of allowed loan investor type. */
  type?: Maybe<Scalars['String']['output']>;
};

/** Get allowed loan investor type for investors. */
export type AllowedLoanInvestorTypeForInvestors = {
  /** Info about loan type. */
  investor?: Maybe<Array<Maybe<AllowedLoanInvestorTypeForInvestor>>>;
};

/** Application form for a purchase */
export type Application = {
  /** Determine whether to accrue interest and fees for a loan */
  accrue_interest_and_fees: Scalars['Boolean']['output'];
  /** Currently active payment leave for application */
  active_payment_leave?: Maybe<PaymentLeave>;
  /** Whether to add legal person info to the invoice */
  add_legal_person_to_invoice: Scalars['Boolean']['output'];
  /** If application has been modified, the original credit info data is stored here */
  application_modifications?: Maybe<Array<Maybe<ApplicationModification>>>;
  /** Helper table for making purchases via a physical cash register */
  application_reference?: Maybe<ApplicationReference>;
  /** ID of the application_user_info that this application belongs to */
  application_user_info_id?: Maybe<Scalars['Int']['output']>;
  /** Items bought with the purchase */
  basket_items?: Maybe<Array<Maybe<ApplicationBasketItem>>>;
  /** Date, when merchant was paid the application bonuses */
  bonus_paid_at?: Maybe<Scalars['String']['output']>;
  /** Application status based on information provided by the broker. */
  broker_status?: Maybe<Scalars['String']['output']>;
  /** Information about application that has been bought back from merchant */
  buyback_settings?: Maybe<ApplicationBuybackSettings>;
  /** A campaign that applies to the application, if enabled */
  campaign?: Maybe<ApplicationCampaign>;
  /** Determines, whether the application can be modified */
  can_be_modified: Scalars['Boolean']['output'];
  /** Whether an application can convert from a regular schedule to (long-term) CA/HP. */
  can_convert_from_regular: Scalars['Boolean']['output'];
  /** Whether the application can be converted manually to CA. */
  can_manually_convert_to_credit_account: Scalars['Boolean']['output'];
  /** Checks if the application can be refinanced or not */
  can_refinance: Scalars['Boolean']['output'];
  /** Optional URL, where the user will be redirected to after application was rejected */
  cancel_url?: Maybe<Scalars['String']['output']>;
  /** Request made by merchant to cancel this application */
  cancellation_request?: Maybe<ApplicationCancellationRequest>;
  /** Timestamp, when the application was cancelled (purchased item returned to merchant) */
  cancelled_at?: Maybe<Scalars['Int']['output']>;
  /** The user by whom this application was cancelled */
  cancelled_by?: Maybe<User>;
  /** Date, when merchant was paid cashier the application bonuses */
  cashier_bonus_paid_at?: Maybe<Scalars['String']['output']>;
  /** ConvertingSchedule regular campaign schedule */
  converting_schedule_regular?: Maybe<ConvertingScheduleRegular>;
  /** Timestamp, when the application was created */
  created_at: Scalars['Int']['output'];
  /** Additional info of the application about pricing */
  credit_info?: Maybe<ApplicationCreditInfo>;
  /** Fully rejected customers are given an option to get a loan from a third-party loan provider */
  customer_sale?: Maybe<CustomerSale>;
  /** How many days the hire purchase payment is overdue. 0 if HP payment is not overdue. */
  days_past_due?: Maybe<Scalars['Int']['output']>;
  /** Ongoing and finished claim collection processes */
  debt_collections?: Maybe<Array<Maybe<DebtCollection>>>;
  /** Timestamp, when the application was deleted */
  deleted_at?: Maybe<Scalars['Int']['output']>;
  /** Refunds history for specific Direct payment (ESTO Pay) */
  direct_payment_refunds?: Maybe<Array<Maybe<DirectPaymentRefund>>>;
  /** Down payment of the application */
  down_installments?: Maybe<Array<Maybe<Installment>>>;
  /** If the application was paid for early it will have additional info */
  early_payment?: Maybe<ApplicationEarlyPayment>;
  /**
   * Disable the eligibility check in order to not make actual queries to the Decision
   *                 Engine. In this case, the eligibility status and state will not change
   */
  eligibility_check_disabled?: Maybe<Scalars['Boolean']['output']>;
  /** 1: Eligible, 0: Pending, -1: Rejected */
  eligibility_state?: Maybe<Scalars['Int']['output']>;
  /** ELIGIBLE or reason why not eligible */
  eligibility_status?: Maybe<Scalars['String']['output']>;
  /** Timestamp of the last time eligibility was checked */
  eligibility_status_last_check?: Maybe<Scalars['Int']['output']>;
  /** Timestamp, when the last payment was made that paid for the application fully */
  ended_at?: Maybe<Scalars['Int']['output']>;
  /** Time when the application will be deleted, if it has not been signed */
  expires_at?: Maybe<Scalars['Int']['output']>;
  /** First monthly installment */
  first_installment?: Maybe<Installment>;
  /** True, if app for private person, false if for legal person and NULL if not fixed at all */
  fixed_for_private_person?: Maybe<Scalars['Boolean']['output']>;
  /** True, if application does NOT represent a legal person */
  for_private_person: Scalars['Boolean']['output'];
  /** Whether to forward rejected application to another broker. */
  forward_rejects: Scalars['Boolean']['output'];
  /** Whether it is a FREE HP application (no credit cost, no scoring) */
  free_hp_enabled: Scalars['Boolean']['output'];
  /** Whether this application was created in a retail (physical) store */
  from_retail: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  /**
   * Whether the user's identity needs verification (depends on requested amount).
   *                     Always include user relation with id_verification_method!
   */
  id_verification_required?: Maybe<Scalars['Boolean']['output']>;
  /** Time when the user (small loan) or merchant (hire purchase) was paid for by us */
  initial_paid_at?: Maybe<Scalars['Int']['output']>;
  /** Required installments for the application */
  installments?: Maybe<Array<Maybe<Installment>>>;
  /** Required installments for the application, ordered by due_at ASC */
  installments_ordered?: Maybe<Array<Maybe<Installment>>>;
  /** The fee for instant payout of small loan */
  instant_payment_fee: Scalars['Float']['output'];
  /** Current status of the Instantor request for this application */
  instantor_eligibility: InstantorEligibility;
  /** Identifier for the application that is used for payments */
  invoice_reference_nr?: Maybe<Scalars['String']['output']>;
  /** Whether the application converts (to CA or longer HP) */
  is_converting_schedule: Scalars['Boolean']['output'];
  /** Determines, if the user can apply for the loan specified by this application */
  is_eligible: Scalars['Boolean']['output'];
  /** Whether the funds will be paid out immediately to the client. Only applies for consumer loans */
  is_instant_payout: Scalars['Boolean']['output'];
  /** Whether the user can make instant payouts. */
  is_instant_payout_available: Scalars['Boolean']['output'];
  /** Determines, if direct payment can be refunded */
  is_refundable: Scalars['Boolean']['output'];
  /** If true, then this application is only used for testing purposes */
  is_test: Scalars['Boolean']['output'];
  /** klix payments connected to this application */
  klix_payments?: Maybe<Array<Maybe<KlixPayment>>>;
  /** Very last monthly installment that will be paid */
  last_installment?: Maybe<Installment>;
  /** Latest customer care decision result for related to application */
  latest_customer_care_decision?: Maybe<CustomerCareDecision>;
  /** Latest modification request made by merchant */
  latest_modification_request?: Maybe<ApplicationModificationRequest>;
  /** Info about the legal person connected to the application */
  legal_person_info?: Maybe<ApplicationLegalPersonInfo>;
  /**
   * Get ESTO's or merchant's or campaign logo, depending on the application.
   *                     NB! Always select merchant and campaign relations
   */
  logo_url: Scalars['String']['output'];
  /** Seller of the purchase */
  merchant?: Maybe<Merchant>;
  /** Data entered by the merchant for the application */
  merchant_data?: Maybe<ApplicationMerchantData>;
  /** ID of the merchant selling something to the user */
  merchant_id?: Maybe<Scalars['Int']['output']>;
  /** Requests made by merchant to modify application values such as net total */
  modification_requests?: Maybe<Array<Maybe<ApplicationModificationRequest>>>;
  /** The next installment that is unpaid (can be in the past as well) */
  next_installment?: Maybe<Installment>;
  /** The next installment date for the given application or oldest installment date, if overdue */
  next_installment_date?: Maybe<Scalars['String']['output']>;
  /**
   * The next payment amount for the application. If overdue then only overdue amount,
   *                     but otherwise get current month
   */
  next_payment_amount?: Maybe<Scalars['Float']['output']>;
  /** Application overdue installments */
  overdue_installments?: Maybe<Array<Maybe<Installment>>>;
  /** The total amount of principal that has been paid */
  paid_principal: Scalars['Float']['output'];
  /** Payment leaves taken for this application */
  payment_leaves?: Maybe<Array<Maybe<PaymentLeave>>>;
  /** Acquirer pivot of the product (application or credit account), if the product has been sold with a portfolio */
  portfolio_sale_product?: Maybe<PortfolioSaleProduct>;
  /** Pre-signing conversion offer for the application */
  pre_signing_conversion_offer?: Maybe<PreSigningConversionOffer>;
  /** Value taken from pricing. Max amount that an admin can waive from a hire purchase. */
  pricing_max_waive_amount: Scalars['Float']['output'];
  /** Timestamp, when the application was processed (for direct payments) */
  processed_at?: Maybe<Scalars['Int']['output']>;
  /** Get the name of merchant if private label or ESTO if not */
  provider_name: Scalars['String']['output'];
  /** URL where the purchase flow starts from */
  purchase_url: Scalars['String']['output'];
  /** A unique string to identify the application */
  reference_key: Scalars['String']['output'];
  /** The application that was refinanced by this application */
  refinanced_application?: Maybe<Application>;
  /** The application that refinanced this application */
  refinancing_parent_application?: Maybe<Application>;
  /** The application's id that refinances this application */
  refinancing_parent_id?: Maybe<Scalars['Int']['output']>;
  /** Get amount that was successfully refunded for Direct payment */
  refunded_amount: Scalars['Float']['output'];
  /** Timestamp, when the application was rejected */
  rejected_at?: Maybe<Scalars['Int']['output']>;
  /** Reminders that have been sent to the user because of overdue installments */
  reminders?: Maybe<Array<Maybe<Reminder>>>;
  /** The amount required for the purchase */
  requested_amount: Scalars['Float']['output'];
  /** Resale applications are potentially eligible applications which are currently unsigned */
  resale?: Maybe<Resale>;
  /** Resale call is created when the user is called during the resale process */
  resale_call?: Maybe<ResaleCall>;
  /** Optional URL, where the user will be redirected to after submitting the application */
  return_url?: Maybe<Scalars['String']['output']>;
  /** Type of the application, like hire-purchase or small loan */
  schedule_type: ApplicationScheduleType;
  /** Determine whether reminders should be sent */
  send_reminders: Scalars['Boolean']['output'];
  /** Whether a separate checkbox for pension query is shown at purchase flow */
  show_separate_pension_query: Scalars['Boolean']['output'];
  /** Timestamp, when the application was signed and accepted */
  signed_at?: Maybe<Scalars['Int']['output']>;
  /** Credit account eligibility status that is one of SimpleEligibilityStatus */
  simple_eligibility_status: Scalars['String']['output'];
  /** Timestamp, when the application was sold to a debt collector */
  sold_at?: Maybe<Scalars['Int']['output']>;
  /** Application status. One of the following: UNSIGNED, REJECTED, ACTIVE, TERMINATED, CANCELLED, DELETED, PROCESSED, ENDED */
  status: ApplicationStatus;
  /** Application sub-statuses */
  sub_statuses?: Maybe<Array<Maybe<LoanSubStatus>>>;
  /** Recurring payments subscription for the application */
  subscription?: Maybe<Subscription>;
  /** Timestamp, when the application was terminated (e.g when customer died or went bankrupt) */
  terminated_at?: Maybe<Scalars['Int']['output']>;
  termination_reason?: Maybe<Scalars['String']['output']>;
  /**
   * Total unpaid amount that should have been paid by now. NB! Only use for single queries
   *                     as it's actually a DB query
   */
  total_overdue: Scalars['Float']['output'];
  /** Get total paid amount for application */
  total_paid?: Maybe<Scalars['Float']['output']>;
  /** Type of the application, like hire-purchase or small loan */
  type: ApplicationType;
  /** Get the total unpaid principal amount for the loan */
  unpaid_principal?: Maybe<Scalars['Float']['output']>;
  /** Owner of the application */
  user?: Maybe<User>;
  /** ID of the user that this application belongs to */
  user_id?: Maybe<Scalars['Int']['output']>;
  /** Additional info of the application about the user */
  user_info?: Maybe<ApplicationUserInfo>;
};


/** Application form for a purchase */
export type ApplicationSimpleEligibilityStatusArgs = {
  do_query?: InputMaybe<Scalars['Boolean']['input']>;
};

/** An item that was purchased with the application */
export type ApplicationBasketItem = {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  quantity: Scalars['Float']['output'];
  seller?: Maybe<Scalars['String']['output']>;
  unit_price: Scalars['Float']['output'];
};

/** Application that is bought back from merchant by ESTO */
export type ApplicationBuybackSettings = {
  application_id: Scalars['Int']['output'];
  /** Date, when the application was bought back from merchant */
  bought_back_at?: Maybe<Scalars['String']['output']>;
  /** Amount the application was bought back for */
  buyback_amount?: Maybe<Scalars['Float']['output']>;
  /** The number of days application has to be overdue, after which it is bought back from the merchant */
  buyback_days: Scalars['Int']['output'];
  /** The discount percentage which is applied to the application that is being bought back */
  buyback_discount_pct: Scalars['Float']['output'];
  /** The merchant financing % at which the application was bought back */
  merchant_financing_pct?: Maybe<Scalars['Float']['output']>;
};

/** A campaign for an application that provides a payment leave or better rates for the customer */
export type ApplicationCampaign = {
  application_id: Scalars['Int']['output'];
  converting_schedule_fixed_contract_fee?: Maybe<Scalars['Float']['output']>;
  /** The length of ConvertingSchedule campaign */
  converting_schedule_months?: Maybe<Scalars['Int']['output']>;
  /** Brand name that is used for ConvertingSchedule campaign */
  converting_schedule_name?: Maybe<Scalars['String']['output']>;
  /** The length of the regular installment period after switching from ConvertingSchedule */
  converting_schedule_regular_months?: Maybe<Scalars['Int']['output']>;
  fixed_annual_pct_rate?: Maybe<Scalars['Float']['output']>;
  fixed_contract_fee?: Maybe<Scalars['Float']['output']>;
  fixed_management_fee?: Maybe<Scalars['Float']['output']>;
  /** No interest for the first selected amount of months */
  interest_free_months: Scalars['Int']['output'];
  payment_leave_enabled: Scalars['Boolean']['output'];
  payment_leave_months: Scalars['Int']['output'];
};

/** A request made by merchant to cancel an application */
export type ApplicationCancellationRequest = {
  /** The application being cancelled */
  application?: Maybe<Application>;
  application_id: Scalars['Int']['output'];
  /** Timestamp, when the cancellation request was made */
  created_at: Scalars['Int']['output'];
  /** Id of the user who requests cancelling this application */
  created_by_id: Scalars['Int']['output'];
  /** The person who requested cancelling this application */
  creator?: Maybe<User>;
  id: Scalars['Int']['output'];
  /** Reason for cancelling this application */
  reason: Scalars['String']['output'];
};

/** Additional credit info about the application */
export type ApplicationCreditInfo = {
  /** First variable for the IRR calculation formula */
  a_irr_var: Scalars['Float']['output'];
  /** Annual interest % */
  annual_pct_rate: Scalars['Float']['output'];
  /** ID of the application */
  application_id: Scalars['Int']['output'];
  /** Second variable for the IRR calculation formula */
  b_irr_var: Scalars['Float']['output'];
  /**
   * Percentage the merchant gets from each application. Can be calculated from either credit income
   *                     or base amount.
   */
  bonus_pct: Scalars['Float']['output'];
  /** The bonus type decides how the bonus is calculated. Either from credit income or base amount */
  bonus_type?: Maybe<CreditInfoBonusType>;
  /** Third variable for the IRR calculation formula */
  c_irr_var: Scalars['Float']['output'];
  /** How much the cashier receives as bonus from issued application */
  cashier_bonus_amount: Scalars['Float']['output'];
  /** Percentage that we pay the cashier for issuing loans based on issued credit (without down payment) */
  cashier_bonus_pct: Scalars['Float']['output'];
  /** The fee for signing the contract */
  contract_fee: Scalars['Float']['output'];
  /** If set, the ESTO X application will always have a fixed contract fee */
  converting_schedule_fixed_contract_fee?: Maybe<Scalars['Float']['output']>;
  /** Total profit from the application */
  credit_income: Scalars['Float']['output'];
  /** The initial payment by the user */
  down_payment: Scalars['Float']['output'];
  /** If set, the application will always have a fixed APR */
  fixed_annual_pct_rate?: Maybe<Scalars['Float']['output']>;
  /** If set, the REGULAR application will always have a fixed contract fee */
  fixed_contract_fee?: Maybe<Scalars['Float']['output']>;
  /** If set, the application will always have a fixed management fee */
  fixed_management_fee?: Maybe<Scalars['Float']['output']>;
  /** If set, the application will always have a fixed period */
  fixed_period_months?: Maybe<Scalars['Int']['output']>;
  /** Internal rate of return % */
  irr?: Maybe<Scalars['Float']['output']>;
  /** The fee we take for managing the contract */
  management_fee: Scalars['Float']['output'];
  /**
   * Max possible monthly payment amount that the loan can have, because the user
   *                     does not have enough income
   */
  max_monthly_payment?: Maybe<Scalars['Float']['output']>;
  /** How much the merchant receives as bonus from this application */
  merchant_bonus_amount: Scalars['Float']['output'];
  /** Total merchant profit from application without the technology cost */
  merchant_credit_income: Scalars['Float']['output'];
  /** Initial base payment amount that ESTO will pay back to the merchant through installment payments */
  merchant_financing_amount: Scalars['Float']['output'];
  /** Percentage of risk/participation that the merchant takes by giving out a lease or hire-purhcase */
  merchant_financing_pct: Scalars['Float']['output'];
  /** The base amount ESTO pays the merchant */
  merchant_initial_amount: Scalars['Float']['output'];
  /** Takes into account technology cost for the merchant */
  merchant_irr: Scalars['Float']['output'];
  /** Amount the merchant gets each month from user's monthly payments */
  merchant_monthly_payment: Scalars['Float']['output'];
  /** Minimum required down payment */
  min_down_payment?: Maybe<Scalars['Float']['output']>;
  /** Amount that the customer is required to pay monthly */
  monthly_payment?: Maybe<Scalars['Float']['output']>;
  /** Credit issued by us */
  net_total: Scalars['Float']['output'];
  /** Term period in months */
  period_months: Scalars['Int']['output'];
  /** How much we receive as kickback from the merchant */
  reverse_kickback_amount: Scalars['Float']['output'];
  /** % of issued credit that the merchant pays us as bonus */
  reverse_kickback_pct: Scalars['Float']['output'];
  /** Amount that ESTO takes from the merchant's profits */
  technology_cost_amount: Scalars['Float']['output'];
  /**
   * Cut that ESTO takes from a self-financing merchant's credit income. For example, if a merchant takes 70%
   *                     from a user's installment payments, we take 5% of that 70%, which makes 3.5% of the credit.
   */
  technology_cost_pct: Scalars['Float']['output'];
  /** Total cost for the user over all installments */
  total_expected?: Maybe<Scalars['Float']['output']>;
};

/** Debt Service Coverage Ratio for an application */
export type ApplicationDscr = {
  /** DSCR for income info entered by the user manually */
  credit_info_dscr?: Maybe<Scalars['Float']['output']>;
  /** DSCR for Instantor, which is salary from account statement */
  instantor_dscr?: Maybe<Scalars['Float']['output']>;
  /** DSCR for retirement fund info, which is basically user's official net salary */
  retirement_fund_dscr?: Maybe<Scalars['Float']['output']>;
};

/** Has some info, if the application was repaid early */
export type ApplicationEarlyPayment = {
  /** Amount that was unpaid, but was not paid for */
  amount_paid_less: Scalars['Float']['output'];
  application_id: Scalars['Int']['output'];
  /** Timestamp, when the last payment was made */
  created_at: Scalars['Int']['output'];
  /** Amount that was paid with the last payment */
  final_amount_paid: Scalars['Float']['output'];
};

/** Additional info about the legal person related to application */
export type ApplicationLegalPersonInfo = {
  /** Address of the legal person's contact */
  address?: Maybe<Scalars['String']['output']>;
  /** ID of the application */
  application_id: Scalars['Int']['output'];
  /** Email of the legal person's contact */
  email?: Maybe<Scalars['String']['output']>;
  legal_person_score?: Maybe<LegalPersonScore>;
  /** ID of the legal person score */
  legal_person_score_id?: Maybe<Scalars['Int']['output']>;
  /** Name of the legal person */
  name?: Maybe<Scalars['String']['output']>;
  /** Phone number of the legal person's contact */
  phone?: Maybe<Scalars['String']['output']>;
  /** Phone area code of the legal person's contact */
  phone_area_code?: Maybe<Scalars['String']['output']>;
};

/** Data of a hire-purchase application, that the merchant has entered */
export type ApplicationMerchantData = {
  application_id: Scalars['Int']['output'];
  /**
   * Optional URL, where the user will be redirected to after cancelling the application
   *                     or being rejected
   */
  cancel_url?: Maybe<Scalars['String']['output']>;
  /** Optional description of the purchase set by merchant */
  message?: Maybe<Scalars['String']['output']>;
  /** Reference set by the merchant for identification */
  reference: Scalars['String']['output'];
  /** Optional URL, where the user will be redirected to after submitting the application */
  return_url?: Maybe<Scalars['String']['output']>;
  /** Retail store, where the purchase was made */
  store?: Maybe<MerchantStore>;
};

/** If application has been modified, the original credit info data is stored here */
export type ApplicationModification = {
  annual_pct_rate: Scalars['Float']['output'];
  application_id: Scalars['Int']['output'];
  bonus_amount: Scalars['Float']['output'];
  bonus_pct: Scalars['Float']['output'];
  bonus_type: Scalars['String']['output'];
  contract_fee: Scalars['Float']['output'];
  contract_fee_amount: Scalars['Float']['output'];
  created_at: Scalars['Int']['output'];
  down_contract_fee_amount: Scalars['Float']['output'];
  down_payment: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  interest_amount: Scalars['Float']['output'];
  irr: Scalars['Float']['output'];
  management_fee: Scalars['Float']['output'];
  management_fee_amount: Scalars['Float']['output'];
  merchant_financing_pct: Scalars['Float']['output'];
  monthly_payment: Scalars['Float']['output'];
  net_total: Scalars['Float']['output'];
  period_months: Scalars['Int']['output'];
  reverse_kickback_amount: Scalars['Float']['output'];
  reverse_kickback_pct: Scalars['Float']['output'];
  technology_cost_pct: Scalars['Float']['output'];
  total_expected: Scalars['Float']['output'];
  type: Scalars['String']['output'];
};

/** A request made by merchant to modify an application */
export type ApplicationModificationRequest = {
  /** The application being cancelled */
  application?: Maybe<Application>;
  application_id: Scalars['Int']['output'];
  /** Timestamp, when the modification request was made */
  created_at: Scalars['Int']['output'];
  /** Id of the user who requests cancelling this application */
  created_by_id: Scalars['Int']['output'];
  /** The person who requested cancelling this application */
  creator?: Maybe<User>;
  /** Timestamp, when the modification request was handled by our customer support */
  handled_at?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** New net total for the application */
  new_requested_amount: Scalars['Float']['output'];
  /** Reason for cancelling this application */
  reason: Scalars['String']['output'];
};

export enum ApplicationOverviewStatus {
  CANCELLED = 'CANCELLED',
  CURRENTLY_ACTIVE = 'CURRENTLY_ACTIVE',
  DELETED = 'DELETED',
  ENDED = 'ENDED',
  INACTIVE = 'INACTIVE',
  NOT_PAID = 'NOT_PAID',
  NO_ACTION = 'NO_ACTION',
  NO_USER = 'NO_USER',
  REJECTED = 'REJECTED',
  SIGNED = 'SIGNED',
  TEST_MODE = 'TEST_MODE'
}

export enum ApplicationOverviewType {
  DIRECT_PAYMENT = 'DIRECT_PAYMENT',
  HIRE_PURCHASE = 'HIRE_PURCHASE',
  MERCHANT_DYNAMIC_LOAN = 'MERCHANT_DYNAMIC_LOAN',
  REFINANCED = 'REFINANCED',
  SMALL_LOAN = 'SMALL_LOAN'
}

/** Attributes of an application that depend on the merchant requiring private label or not */
export type ApplicationPrivateLabel = {
  application_id?: Maybe<Scalars['Int']['output']>;
  /** Random ESTO background if not private label and merchant background, if private label */
  background_url: Scalars['String']['output'];
  is_test: Scalars['Boolean']['output'];
  /** ESTO logo if not private label and merchant logo, if private label */
  logo_url: Scalars['String']['output'];
  /** The maximum possible amount for an application */
  max_amount?: Maybe<Scalars['Float']['output']>;
  /** The minimum possible amount for an application */
  min_amount?: Maybe<Scalars['Float']['output']>;
  /** The name that should be displayed to the client. Merchant name if private label and "ESTO" or similar if not */
  provider_name: Scalars['String']['output'];
  show_down_payment: Scalars['Boolean']['output'];
};

/** Helper for application, when purchased from a physical cash register */
export type ApplicationReference = {
  application_id: Scalars['Int']['output'];
  /** ID of the cashier that created this application */
  created_by_id?: Maybe<Scalars['Int']['output']>;
  /** The cashier that created this application */
  creator?: Maybe<User>;
  phone?: Maybe<Scalars['String']['output']>;
  /** Area code of the phone */
  phone_area_code?: Maybe<Scalars['String']['output']>;
  /** A 6 char long uppercase random string */
  short_reference: Scalars['String']['output'];
  /** URL that redirects to the start of the purchase flow */
  start_url: Scalars['String']['output'];
};

export enum ApplicationScheduleType {
  ESTO_PAY = 'ESTO_PAY',
  ESTO_X = 'ESTO_X',
  FAST_LOAN = 'FAST_LOAN',
  PAY_LATER = 'PAY_LATER',
  REGULAR = 'REGULAR',
  RENOVATION_LOAN = 'RENOVATION_LOAN',
  SMALL_LOAN = 'SMALL_LOAN',
  VEHICLE_LOAN = 'VEHICLE_LOAN'
}

export enum ApplicationStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  DELETED = 'DELETED',
  ENDED = 'ENDED',
  PROCESSED = 'PROCESSED',
  REJECTED = 'REJECTED',
  TERMINATED = 'TERMINATED',
  UNSIGNED = 'UNSIGNED'
}

export enum ApplicationType {
  DIRECT_PAYMENT = 'DIRECT_PAYMENT',
  HIRE_PURCHASE = 'HIRE_PURCHASE',
  MERCHANT_DYNAMIC_LOAN = 'MERCHANT_DYNAMIC_LOAN',
  REFINANCED = 'REFINANCED',
  SMALL_LOAN = 'SMALL_LOAN'
}

/** Additional info about the application about the user */
export type ApplicationUserInfo = {
  /** Address of the user */
  address?: Maybe<Scalars['String']['output']>;
  /** Disposable income if the best possible values were used. */
  best_disposable_income?: Maybe<Scalars['Float']['output']>;
  /** All income (salaries, dividends, etc.) of the user from Decision Engine if the best values were used. */
  best_user_income?: Maybe<Scalars['Float']['output']>;
  /** Known debts (loans, fines, etc.) of the user in all banks from Decision Engine if the best values were used. */
  best_user_liabilities?: Maybe<Scalars['Float']['output']>;
  /** City of the user */
  city?: Maybe<Scalars['String']['output']>;
  /** Credit scores by user. */
  credit_scores?: Maybe<Array<Maybe<UserCreditScore>>>;
  /** Highest possible monthly payment that the user can afford at the time of the application */
  disposable_income?: Maybe<Scalars['Float']['output']>;
  /** Debt service coverage ratio */
  dscr?: Maybe<Scalars['Float']['output']>;
  /** Debt service to income ratio */
  dsti?: Maybe<Scalars['Float']['output']>;
  /** Email of the user */
  email?: Maybe<Scalars['String']['output']>;
  /** Employer of the user. Self-defined by user, so might not be reliable */
  employer?: Maybe<Scalars['String']['output']>;
  /** Date of the employment */
  employment_date?: Maybe<Scalars['String']['output']>;
  /** Client employer's sector (public, private, non-profit) */
  employment_sector?: Maybe<EmploymentSector>;
  /** Monthly liabilities related to consumer loans. Self-defined by user, so might not be reliable */
  expenditure_consumer_loan_monthly?: Maybe<Scalars['Float']['output']>;
  /** All current monthly expenditures for the user. Self-defined by user, so might not be reliable */
  expenditure_monthly?: Maybe<Scalars['Float']['output']>;
  /** Extra income of the customer */
  extra_income?: Maybe<Scalars['Float']['output']>;
  /** First name of the user */
  first_name?: Maybe<Scalars['String']['output']>;
  /** How much reduced earnings the customer has planned in the near future */
  future_reduced_earnings?: Maybe<Scalars['Float']['output']>;
  /** IBAN (bank account nr) of the applicant */
  iban?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** Preferred language */
  language_abbr?: Maybe<Scalars['String']['output']>;
  /** Last name of the user */
  last_name?: Maybe<Scalars['String']['output']>;
  /** Monthly cost of living, entered by the user */
  monthly_living_expenses?: Maybe<Scalars['Float']['output']>;
  /** Monthly net income from all sources for the user. Self-defined by user, so might not be reliable */
  net_income_monthly?: Maybe<Scalars['Float']['output']>;
  /** Number of dependents the user has to take care of, entered by the user */
  number_of_dependents?: Maybe<Scalars['Int']['output']>;
  /** The user's occupation. Self-defined by user, so might not be reliable */
  occupation_category?: Maybe<OccupationCategory>;
  /**
   * How much principal the client currently owes to other institutions that is 30+
   *                                  days overdue, entered by the user
   */
  overdue_debt?: Maybe<Scalars['Float']['output']>;
  /** Phone number of the user */
  phone?: Maybe<Scalars['String']['output']>;
  /** Area code of the phone */
  phone_area_code?: Maybe<Scalars['String']['output']>;
  /** How much debt the customer is planning to acquire in the near future */
  planning_new_debts?: Maybe<Scalars['Float']['output']>;
  /** Post code of the user */
  post_code?: Maybe<Scalars['String']['output']>;
  /** Purpose of the loan. One of NOT_MARRIED_PERSONAL, MARRIED_PERSONAL, MARRIED_HOUSEHOLD, NA */
  purpose_of_loan?: Maybe<PurposeOfLoanType>;
  /** Timestamp when spouse gave consent that their contact info will be used and is correct */
  spouse_consented_at?: Maybe<Scalars['String']['output']>;
  /** Email of the spouse */
  spouse_email?: Maybe<Scalars['String']['output']>;
  /** Date of the employment of the spouse */
  spouse_employment_date?: Maybe<Scalars['String']['output']>;
  /** Self-reported consumer loan monthly liabilities of spouse */
  spouse_expenditure_consumer_loan_monthly?: Maybe<Scalars['Float']['output']>;
  /** Self-reported monthly liabilities of spouse */
  spouse_expenditure_monthly?: Maybe<Scalars['Float']['output']>;
  /** Extra income of the spouse */
  spouse_extra_income?: Maybe<Scalars['Float']['output']>;
  /** First name of the spouse */
  spouse_first_name?: Maybe<Scalars['String']['output']>;
  /** Whether the spouse knows they will have reduced income in the near future */
  spouse_future_reduced_earnings?: Maybe<Scalars['Float']['output']>;
  /** Last name of the spouse */
  spouse_last_name?: Maybe<Scalars['String']['output']>;
  /** Monthly cost of living for spouse, entered by the user */
  spouse_monthly_living_expenses?: Maybe<Scalars['Float']['output']>;
  /** Self-reported net average monthly income of spouse */
  spouse_net_income_monthly?: Maybe<Scalars['Float']['output']>;
  /**
   * How much principal the spouse currently owes to other institutions that is 30+
   *                                  days overdue, entered by the user
   */
  spouse_overdue_debt?: Maybe<Scalars['Float']['output']>;
  /** Phone of the spouse */
  spouse_phone?: Maybe<Scalars['String']['output']>;
  /** Phone area code of the spouse */
  spouse_phone_area_code?: Maybe<Scalars['String']['output']>;
  /** Personal ID number of the spouse */
  spouse_pin?: Maybe<Scalars['String']['output']>;
  /** Whether the spouse plans on entering into new debts in the near future */
  spouse_planning_new_debts?: Maybe<Scalars['Float']['output']>;
  /** Whether the client is the ultimate beneficial owner of the loan, entered by the user */
  ultimate_beneficial_owner?: Maybe<Scalars['Boolean']['output']>;
  /** The latest actually used credit score */
  user_credit_score?: Maybe<UserCreditScore>;
  /**
   * Income that was used in the scoring calculation for this application. It can be official
   *                     or self-reported.
   */
  user_income?: Maybe<Scalars['Float']['output']>;
  /**
   * Liabilities that were used in the scoring calculation for this application. It can be official
   *                     or self-reported or a combination.
   */
  user_liabilities?: Maybe<Scalars['Float']['output']>;
};

/** Overview of all signed applications */
export type ApplicationsOverview = {
  /** Total principal amount not paid back */
  outstanding_principal: Scalars['Float']['output'];
  /** The number of signed contracts */
  signed_contracts_count: Scalars['Int']['output'];
  /** Credit total over all signed applications */
  signed_credit_total: Scalars['Float']['output'];
  /** The total requested amount (with down payment) over all signed applications */
  signed_requested_total: Scalars['Float']['output'];
};

export enum AuthMethod {
  EPARAKSTS = 'eparaksts',
  HASH = 'hash',
  ID_CARD = 'id_card',
  MAGIC_LINK = 'magic_link',
  MOBILE = 'mobile',
  PASSWORD = 'password',
  PAYSERA_BANKLINK = 'paysera_banklink',
  SMART_ID = 'smart_id'
}

/** Current account statement for a bank ESTO has an account with */
export type BankAccount = {
  /** Current balance (for previous day) on the account */
  balance: Scalars['Float']['output'];
  /** Name of the bank. One of: SEB */
  bank_name: BankName;
  updated_at: Scalars['Int']['output'];
};

export enum BankName {
  SEB = 'SEB'
}

/** URL where user should be redirected to login */
export type BanklinkRedirect = {
  /** URL where user should be redirected to */
  redirect_url?: Maybe<Scalars['String']['output']>;
  /** A unique hash that can be used to retrieve the cache set by Banklink login */
  session_id?: Maybe<Scalars['String']['output']>;
};

/** Fees and amounts related to card payment */
export type CardPaymentFee = {
  /** Payment amount without fee */
  amount: Scalars['Float']['output'];
  /** Total card payment amount, including fees. The fee is calculated from the initial amount without fee. */
  amount_with_fee: Scalars['Float']['output'];
  /** Card payment fee % that is used to calculate fee amount */
  card_payment_fee_pct: Scalars['Float']['output'];
  /** Fee that would be added to the payment */
  fee: Scalars['Float']['output'];
};

/** Information about cashier sales made in a physical store. */
export type CashierApplicationSales = {
  /** All bonus that the cashier has earned (paid and unpaid) */
  all_time_bonus_amount: Scalars['Float']['output'];
  /** All bonus that we have already paid the cashier for */
  all_time_bonus_paid: Scalars['Float']['output'];
  /** All bonus that we have not paid to the cashier yet */
  all_time_bonus_unpaid: Scalars['Float']['output'];
  /** Number of sales made this month by cashier */
  current_month_sales_count: Scalars['Int']['output'];
  /** Number of sales made this year by cashier */
  current_year_sales_count: Scalars['Int']['output'];
  /** Number of sales made last month by cashier */
  last_month_sales_count: Scalars['Int']['output'];
  /** Number of sales made last year by cashier */
  last_year_sales_count: Scalars['Int']['output'];
};

/** Comment made by admin about the user and his/her applications */
export type Comment = {
  application_id?: Maybe<Scalars['Int']['output']>;
  comment: Scalars['String']['output'];
  created_at?: Maybe<Scalars['Int']['output']>;
  /** ID of the admin who created the comment */
  created_by_id: Scalars['Int']['output'];
  /** Admin who created the comment */
  creator?: Maybe<User>;
  id: Scalars['Int']['output'];
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** Input for setting contextual fields for CDP events */
export type ContextInput = {
  /** Detailed information about the application */
  app?: InputMaybe<Scalars['String']['input']>;
  /** Detailed information about campaigns */
  campaign?: InputMaybe<Scalars['String']['input']>;
  /** Information about the device */
  device?: InputMaybe<Scalars['String']['input']>;
  /** The IP address of the user */
  ip?: InputMaybe<Scalars['String']['input']>;
  /** Details about the RudderStack SDK */
  library?: InputMaybe<Scalars['String']['input']>;
  /** The language of the device */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** Information about the reachability of the device */
  network?: InputMaybe<Scalars['String']['input']>;
  /** The operating system details of the device */
  os?: InputMaybe<Scalars['String']['input']>;
  /** The screen dimensions of the device */
  screen?: InputMaybe<Scalars['String']['input']>;
  /** The timezone of the user */
  timezone?: InputMaybe<Scalars['String']['input']>;
  /** Any additional relevant information about the user */
  traits?: InputMaybe<Scalars['String']['input']>;
  /** The user agent of the device */
  userAgent?: InputMaybe<Scalars['String']['input']>;
};

/** Contract of user, credit account or application */
export type Contract = {
  /** The application relates to contract */
  application?: Maybe<Application>;
  application_id?: Maybe<Scalars['Int']['output']>;
  /**
   * Method that the client was logged in with while signing the contract.
   *                     One of: eparaksts, hash, id_card, magic_link, mobile, password, paysera_banklink, smart_id
   */
  auth_method: AuthMethod;
  /** The credit account relates to contract */
  credit_account?: Maybe<CreditAccount>;
  credit_account_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** Timestamp, when the contract was signed */
  signed_at: Scalars['Int']['output'];
  signing_ip_addr?: Maybe<Scalars['String']['output']>;
  /**
   * Method that the contract was signed or confirmed with.
   *                     One of: BUTTON_PRESS, EPARAKSTS, ID_CARD, MAGIC_LINK, MANUAL_UPLOAD, MOBILE_ID, PAYSERA_BANKLINK, SMART_ID
   */
  signing_method: ContractSigningMethod;
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
  /** User relates to contract */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** Signature type that is used in ESTO admin when signing contracts. */
export enum ContractSignatureType {
  MOBILE = 'mobile',
  PASSWORD = 'password',
  SMART_ID = 'smart_id'
}

export enum ContractSigningMethod {
  BUTTON_PRESS = 'BUTTON_PRESS',
  EPARAKSTS = 'EPARAKSTS',
  ID_CARD = 'ID_CARD',
  MAGIC_LINK = 'MAGIC_LINK',
  MANUAL_UPLOAD = 'MANUAL_UPLOAD',
  MOBILE_ID = 'MOBILE_ID',
  PAYSERA_BANKLINK = 'PAYSERA_BANKLINK',
  SMART_ID = 'SMART_ID'
}

export enum ContractType {
  APPLICATION_PAYMENT_LEAVE = 'APPLICATION_PAYMENT_LEAVE',
  APPLICATION_SIGNED = 'APPLICATION_SIGNED',
  CREDIT_ACCOUNT_LIMIT_INCREASED = 'CREDIT_ACCOUNT_LIMIT_INCREASED',
  CREDIT_ACCOUNT_MODIFICATION = 'CREDIT_ACCOUNT_MODIFICATION',
  CREDIT_ACCOUNT_PAYMENT_LEAVE = 'CREDIT_ACCOUNT_PAYMENT_LEAVE',
  CREDIT_ACCOUNT_SIGNED = 'CREDIT_ACCOUNT_SIGNED',
  HP_CA_CONVERSION_DECLARATION_OF_INTENT = 'HP_CA_CONVERSION_DECLARATION_OF_INTENT',
  PAYMENT = 'PAYMENT',
  SMALL_LOAN_SIGNED = 'SMALL_LOAN_SIGNED',
  SPOUSE_CONSENT = 'SPOUSE_CONSENT',
  USER_PAYMENT_LEAVE = 'USER_PAYMENT_LEAVE'
}

export enum ConvertingScheduleConversionMethod {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL'
}

export enum ConvertingScheduleConversionType {
  CREDIT_ACCOUNT = 'CREDIT_ACCOUNT',
  HIRE_PURCHASE = 'HIRE_PURCHASE',
  NONE = 'NONE'
}

/** ConvertingSchedule campaign regular schedule */
export type ConvertingScheduleRegular = {
  annual_pct_rate?: Maybe<Scalars['Float']['output']>;
  application_id: Scalars['Int']['output'];
  /** Time, when the customer went overdue and regular schedule was applied */
  applied_at?: Maybe<Scalars['Int']['output']>;
  contract_fee?: Maybe<Scalars['Float']['output']>;
  /** How the HP was converted. One of: AUTOMATIC, MANUAL */
  conversion_method?: Maybe<ConvertingScheduleConversionMethod>;
  /**
   * To which product does the HP convert to after it has gone overdue.
   *                     One of: CREDIT_ACCOUNT, HIRE_PURCHASE, NONE
   */
  conversion_type: ConvertingScheduleConversionType;
  converted_to_credit_account_id?: Maybe<Scalars['Int']['output']>;
  /** How long the ConvertingSchedule campaign lasted for */
  converting_period_months: Scalars['Int']['output'];
  /** Contract fee for ESTO X applications is fixed to this */
  converting_schedule_fixed_contract_fee?: Maybe<Scalars['Float']['output']>;
  irr?: Maybe<Scalars['Float']['output']>;
  management_fee?: Maybe<Scalars['Float']['output']>;
  monthly_payment?: Maybe<Scalars['Float']['output']>;
  period_months?: Maybe<Scalars['Int']['output']>;
  total_expected?: Maybe<Scalars['Float']['output']>;
};

/** Create Stripe Connect account for merchant */
export type CreateMerchantStripe = {
  /** Onboarding link for registration of Connect Account */
  account_link_url?: Maybe<Scalars['String']['output']>;
  /** An error occurs if something went wrong with the Stripe request */
  error?: Maybe<Scalars['String']['output']>;
  /** ID of related merchant */
  merchant_id: Scalars['Int']['output'];
};

/** Credit account of a user */
export type CreditAccount = {
  /** Determine whether to accrue interest and fees for a loan */
  accrue_interest_and_fees: Scalars['Boolean']['output'];
  /** Interest that has accrued, but has not been applied yet */
  accrued_interest: Scalars['Float']['output'];
  /** Management fee that has accrued, but has not been applied yet */
  accrued_management_fee: Scalars['Float']['output'];
  /** Actions that apply to the credit account */
  actions?: Maybe<Array<Maybe<CreditAccountAction>>>;
  /** Currently active payment leave for credit account */
  active_payment_leave?: Maybe<PaymentLeave>;
  annual_pct_rate: Scalars['Float']['output'];
  application_user_info_id?: Maybe<Scalars['Int']['output']>;
  /** The amount that can still be withdrawn from the credit account */
  available_balance: Scalars['Float']['output'];
  /** Campaigns that apply to the credit account */
  campaigns?: Maybe<Array<Maybe<CreditAccountCampaign>>>;
  campaigns_ordered_desc?: Maybe<Array<Maybe<CreditAccountCampaign>>>;
  /** The user can deactivate the credit account. Checks if he has active ESTO_X loan or PAY_LATER */
  can_deactivate: Scalars['Boolean']['output'];
  /** Whether the client can start requesting credit limit increase. */
  can_request_limit_increase: Scalars['Boolean']['output'];
  contract_editing_fee: Scalars['Float']['output'];
  /** All contracts the credit account has */
  contracts?: Maybe<Array<Maybe<Contract>>>;
  created_at: Scalars['String']['output'];
  /** Total credit that can be in use at one time */
  credit_limit: Scalars['Float']['output'];
  credit_limit_recalculation_fee: Scalars['Float']['output'];
  credit_score?: Maybe<UserCreditScore>;
  credit_scoring_fee: Scalars['Float']['output'];
  /** How many days the credit account is overdue. 0 if CA is not overdue. */
  days_past_due?: Maybe<Scalars['Int']['output']>;
  /** Timestamp, when the credit account was deactivated */
  deactivated_at?: Maybe<Scalars['String']['output']>;
  /** Ongoing and finished claim collection processes */
  debt_collections?: Maybe<Array<Maybe<DebtCollection>>>;
  deleted_at?: Maybe<Scalars['String']['output']>;
  /**
   * Disable the eligibility check in order to not make actual queries to the Decision
   *                 Engine. In this case, the eligibility status and state will not change
   */
  eligibility_check_disabled?: Maybe<Scalars['Boolean']['output']>;
  /** 1: Eligible, 0: Pending, -1: Rejected */
  eligibility_state?: Maybe<Scalars['Int']['output']>;
  /** ELIGIBLE or reason why not eligible */
  eligibility_status: Scalars['String']['output'];
  /** Datetime of the last time eligibility was checked */
  eligibility_status_last_check?: Maybe<Scalars['String']['output']>;
  /** Datetime when the CA contract expires. Expires within X months from signing unless re-signed */
  expires_at?: Maybe<Scalars['String']['output']>;
  /**
   * Calculate the first monthly payment for available credit limit.
   *                     NB! Only request this variable when completely necessary as it is a heavy calculation.
   */
  first_monthly_payment: Scalars['Float']['output'];
  /** Whether to forward rejected credit account to another broker. */
  forward_rejects: Scalars['Boolean']['output'];
  /** 1/X of principal that is at minimum added to every invoice */
  fraction_principal: Scalars['Int']['output'];
  /** The accrued interest up to a certain date for a credit account */
  future_accrued_interest?: Maybe<Scalars['Float']['output']>;
  /** The not yet accrued management fee up to a certain date for a credit account */
  future_accrued_management_fee?: Maybe<Scalars['Float']['output']>;
  /** A unique string to identify the credit account */
  hash: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** Fee that is taken for instantly depositing withdrawal amount to customer's bank account */
  instant_payment_fee: Scalars['Float']['output'];
  /** Current status of the Instantor request for this credit account */
  instantor_eligibility: InstantorEligibility;
  invoice_reference_nr: Scalars['String']['output'];
  invoices?: Maybe<Array<Maybe<CreditAccountInvoice>>>;
  irr: Scalars['Float']['output'];
  /** Determines, if the user can apply for the credit account */
  is_eligible: Scalars['Boolean']['output'];
  /** From which channel the customer came to us before signing the credit account */
  landed_from?: Maybe<Scalars['String']['output']>;
  /** Latest customer care decision result for related to credit account */
  latest_customer_care_decision?: Maybe<CustomerCareDecision>;
  /** Most recent credit account invoice */
  latest_invoice?: Maybe<CreditAccountInvoice>;
  /** How much it costs to change credit limit. */
  limit_recalculation_fee: Scalars['Float']['output'];
  /** % of principal amount accrued daily */
  management_fee_daily_pct: Scalars['Float']['output'];
  /** Whether management fee accruing is enabled */
  management_fee_enabled?: Maybe<Scalars['Boolean']['output']>;
  management_fee_monthly: Scalars['Float']['output'];
  /** 1/X of principal that is at minimum added to every invoice. DEPRECATED - use "fraction_principal" variable instead */
  max_period_months: Scalars['Int']['output'];
  /** Amount of the next CA invoice, including currently unpaid amounts */
  next_invoice_amount: Scalars['Float']['output'];
  /** to_date of the next invoice that will be created for this credit account */
  next_invoice_date?: Maybe<Scalars['String']['output']>;
  overflow: Scalars['Float']['output'];
  /** How much card payment fee is paid */
  paid_card_payment_fee: Scalars['Float']['output'];
  /** All payment leaves the credit account has had */
  payment_leaves?: Maybe<Array<Maybe<PaymentLeave>>>;
  /** Acquirer pivot of the product (application or credit account), if the product has been sold with a portfolio */
  portfolio_sale_product?: Maybe<PortfolioSaleProduct>;
  /** The limit up to which the credit limit could be increased right now */
  potential_credit_limit: Scalars['Float']['output'];
  /** Value taken from pricing. Max amount that an admin can waive from a credit account. */
  pricing_max_waive_amount: Scalars['Float']['output'];
  resale?: Maybe<Resale>;
  /** Determine whether reminders should be sent */
  send_reminders: Scalars['Boolean']['output'];
  /**
   * Determines whether credit account should be updated with fresh pricing values
   *                     and whether modification contract should be signed.
   */
  should_be_modified: Scalars['Boolean']['output'];
  /** Indicates whether there has been a limit change that needs to be followed by a contract. */
  should_sign_limit_change: Scalars['Boolean']['output'];
  signed_at?: Maybe<Scalars['String']['output']>;
  /** Credit account eligibility status that is one of SimpleEligibilityStatus */
  simple_eligibility_status: Scalars['String']['output'];
  /** Timestamp, when the credit account was sold to a debt collector */
  sold_at?: Maybe<Scalars['Int']['output']>;
  /** Activated or deactivated state */
  status?: Maybe<CreditAccountStatus>;
  /** Credit account sub-statuses */
  sub_statuses?: Maybe<Array<Maybe<LoanSubStatus>>>;
  /** Recurring payments subscription for the credit account */
  subscription?: Maybe<Subscription>;
  /** Timestamp, when the credit account was terminated */
  terminated_at?: Maybe<Scalars['String']['output']>;
  termination_reason?: Maybe<Scalars['String']['output']>;
  /** Total outstanding amount, including fees */
  total_outstanding: Scalars['Float']['output'];
  unpaid_contract_editing_fee: Scalars['Float']['output'];
  unpaid_credit_limit_recalculation_fee: Scalars['Float']['output'];
  unpaid_credit_scoring_fee: Scalars['Float']['output'];
  /** Total amount of unpaid fixed fees like withdrawal fee */
  unpaid_fees: Scalars['Float']['output'];
  /** How much instant payment fee is unpaid */
  unpaid_instant_payment_fee: Scalars['Float']['output'];
  /** Interest that has been applied and is outstanding */
  unpaid_interest: Scalars['Float']['output'];
  unpaid_management_fee: Scalars['Float']['output'];
  unpaid_payment_leave_fee: Scalars['Float']['output'];
  unpaid_principal: Scalars['Float']['output'];
  unpaid_reminder_fee: Scalars['Float']['output'];
  unpaid_withdrawal_fee: Scalars['Float']['output'];
  user?: Maybe<User>;
  user_id: Scalars['Int']['output'];
  user_info?: Maybe<ApplicationUserInfo>;
  user_profile?: Maybe<UserProfile>;
  /** Fixed amount that is deducted upon withdrawals */
  withdrawal_fee_amount: Scalars['Float']['output'];
  /** Percentage that is deducted from withdrawal principals */
  withdrawal_fee_pct: Scalars['Float']['output'];
  /** All withdrawals the credit account has */
  withdrawals?: Maybe<Array<Maybe<CreditAccountWithdrawal>>>;
};


/** Credit account of a user */
export type CreditAccountFutureAccruedInterestArgs = {
  balance_date?: InputMaybe<Scalars['String']['input']>;
  credit_account_id: Scalars['Int']['input'];
};


/** Credit account of a user */
export type CreditAccountFutureAccruedManagementFeeArgs = {
  balance_date?: InputMaybe<Scalars['String']['input']>;
  credit_account_id: Scalars['Int']['input'];
};


/** Credit account of a user */
export type CreditAccountSimpleEligibilityStatusArgs = {
  do_query?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreditAccountAction = {
  /** Column from credit_account table to log its value */
  column_name: Scalars['String']['output'];
  credit_account_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  /** New value in 'column_name' field of credit_account table */
  new_action?: Maybe<Scalars['String']['output']>;
  /** Previous value in 'column_name' field of credit_account table */
  old_action?: Maybe<Scalars['String']['output']>;
};

export type CreditAccountCampaign = {
  created_at: Scalars['String']['output'];
  credit_account_id: Scalars['Int']['output'];
  /** Timestamp, when campaign was disabled */
  deleted_at?: Maybe<Scalars['String']['output']>;
  /** Timestamp, when campaign was enabled */
  enabled_at?: Maybe<Scalars['String']['output']>;
  end_at?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  start_at?: Maybe<Scalars['String']['output']>;
  /** Campaign status. One of: ACTIVE, INACTIVE, UPCOMING */
  status: CreditAccountCampaignStatus;
  /** Type of campaign (WITHDRAWAL_FEE_AMOUNT, WITHDRAWAL_FEE_PCT, ANNUAL_INTEREST_PCT) */
  type: CreditAccountCampaignType;
  /** Value for given type */
  value: Scalars['Float']['output'];
};

export enum CreditAccountCampaignStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  UPCOMING = 'UPCOMING'
}

export enum CreditAccountCampaignType {
  ANNUAL_INTEREST_PCT = 'ANNUAL_INTEREST_PCT',
  WITHDRAWAL_FEE_AMOUNT = 'WITHDRAWAL_FEE_AMOUNT',
  WITHDRAWAL_FEE_PCT = 'WITHDRAWAL_FEE_PCT'
}

/** An invoice issued for a credit account */
export type CreditAccountInvoice = {
  /** The amount that has been paid of the invoice */
  amount_paid: Scalars['Float']['output'];
  /** The total amount left to pay by the due date */
  amount_to_pay: Scalars['Float']['output'];
  /** Payment due date */
  due_at: Scalars['String']['output'];
  due_card_payment_fee: Scalars['Float']['output'];
  due_contract_editing_fee: Scalars['Float']['output'];
  due_credit_limit_recalculation_fee: Scalars['Float']['output'];
  due_credit_scoring_fee: Scalars['Float']['output'];
  /** The amount that was left unpaid from the previous period */
  due_from_previous_period: Scalars['Float']['output'];
  due_instant_payment_fee: Scalars['Float']['output'];
  due_interest: Scalars['Float']['output'];
  due_management_fee: Scalars['Float']['output'];
  due_payment_leave_fee: Scalars['Float']['output'];
  due_withdrawal_fee: Scalars['Float']['output'];
  /** Start of the invoice period */
  from_date: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  invoice_nr: Scalars['String']['output'];
  /** End of the invoice period */
  to_date: Scalars['String']['output'];
  /** Total amount of unpaid fixed fees that were added during the invoice period */
  unpaid_fees: Scalars['Float']['output'];
};

/** Details about a credit account limit recalculation */
export type CreditAccountLimitRecalculation = {
  application_user_info_id?: Maybe<Scalars['Int']['output']>;
  /** When new limit was created. */
  created_at: Scalars['Int']['output'];
  /** Credit account of the limit recalculation */
  credit_account?: Maybe<CreditAccount>;
  /** Credit Account ID */
  credit_account_id: Scalars['Int']['output'];
  /** Credit limit before the limit change */
  current_credit_limit: Scalars['Float']['output'];
  /** Decision from decision engine */
  decision?: Maybe<Scalars['String']['output']>;
  /** 1: Eligible, 0: Pending, -1: Rejected */
  eligibility_state?: Maybe<Scalars['Int']['output']>;
  /** Unique hash of the credit account limit recalculation. */
  hash?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** Credit limit that will apply if the credit account limit recalculation is accepted. */
  new_credit_limit?: Maybe<Scalars['Float']['output']>;
  /** Maximum possible new credit limit, this value is calculated by DE. */
  potential_credit_limit?: Maybe<Scalars['Float']['output']>;
  /** CA limit recalculation eligibility status that is one of SimpleEligibilityStatus */
  simple_eligibility_status: Scalars['String']['output'];
  /** The status from Decision Engine or limit is accepted by the client. */
  status: Scalars['String']['output'];
  /** The type of the credit account limit recalculation. */
  type: Scalars['String']['output'];
  /** Application user info of the credit account limit recalculation */
  user_info?: Maybe<ApplicationUserInfo>;
};


/** Details about a credit account limit recalculation */
export type CreditAccountLimitRecalculationSimpleEligibilityStatusArgs = {
  do_query?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Calculation of what the next invoice amounts will be */
export type CreditAccountNextInvoice = {
  /** Due contract editing fee on the next invoice */
  contract_editing_fee: Scalars['Float']['output'];
  /** Due credit limit recalculation fee on the next invoice */
  credit_limit_recalculation_fee: Scalars['Float']['output'];
  /** Due credit scoring fee on the next invoice */
  credit_scoring_fee: Scalars['Float']['output'];
  /** Due instant payment fee on the next invoice */
  instant_payment_fee: Scalars['Float']['output'];
  /** Due interest on the next invoice */
  interest: Scalars['Float']['output'];
  /** Date of the next invoice */
  invoice_date: Scalars['String']['output'];
  /** Due management fee on the next invoice */
  management_fee: Scalars['Float']['output'];
  /** Due payment leave fee on the next invoice */
  payment_leave_fee: Scalars['Float']['output'];
  /** Due principal on the next invoice */
  principal: Scalars['Float']['output'];
  /** Due reminder fee on the next invoice */
  reminder_fee: Scalars['Float']['output'];
  /** Total due amount of invoice */
  total: Scalars['Float']['output'];
  /** Due withdrawal fee on the next invoice */
  withdrawal_fee: Scalars['Float']['output'];
};

export enum CreditAccountOnboardingCampaignType {
  INTEREST_FREE = 'INTEREST_FREE'
}

export enum CreditAccountStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  TERMINATED = 'TERMINATED',
  UNSIGNED = 'UNSIGNED'
}

export enum CreditAccountTransactionsOrderBy {
  CREATED_AT = 'created_at',
  TRANSACTION_DATE = 'transaction_date'
}

/** Details about a credit account withdrawal */
export type CreditAccountWithdrawal = {
  /** The principal withdrawal amount */
  amount: Scalars['Float']['output'];
  application_user_info_id?: Maybe<Scalars['Int']['output']>;
  /** Datetime of the withdrawal approval */
  approved_at?: Maybe<Scalars['String']['output']>;
  /** Datetime record was created on */
  created_at: Scalars['String']['output'];
  /** Credit account of the withdrawal */
  credit_account?: Maybe<CreditAccount>;
  /** Credit Account ID */
  credit_account_id: Scalars['Int']['output'];
  /** Fee for scoring */
  credit_scoring_fee: Scalars['Float']['output'];
  /** Decision that comes from scoring */
  decision?: Maybe<Scalars['String']['output']>;
  /** Whether the withdrawal fee should be deducted from the withdrawal */
  deduct_fees: Scalars['Boolean']['output'];
  /** Eligibility state of the withdrawal, can be -1, 0 or 1 */
  eligibility_state: Scalars['Int']['output'];
  /** The hash of the withdrawal */
  hash: Scalars['String']['output'];
  /** Primary key */
  id: Scalars['Int']['output'];
  /** Additional fee, if the type is INSTANT */
  instant_payment_fee: Scalars['Float']['output'];
  /** Datetime of the withdrawal rejection */
  rejected_at?: Maybe<Scalars['String']['output']>;
  resale?: Maybe<Resale>;
  /** Simple eligibility status */
  simple_eligibility_status: Scalars['String']['output'];
  /** Withdrawal status, determinate if the withdrawal completed or not, that's auto calculated */
  status: CreditAccountWithdrawalStatus;
  /** The IBAN to whom the withdrawal will be made */
  to_iban?: Maybe<Scalars['String']['output']>;
  /** The beneficiary name to whom the withdrawal will be made */
  to_name?: Maybe<Scalars['String']['output']>;
  /** When the withdrawal has been made points to the transaction ID */
  transaction_id?: Maybe<Scalars['Int']['output']>;
  /** Type of the withdrawal, REGULAR or INSTANT depending on withdrawal details */
  type: CreditAccountWithdrawalType;
  /** Application user info of the credit account withdrawal */
  user_info?: Maybe<ApplicationUserInfo>;
  /** Withdrawal fee that can be deducted from principal */
  withdrawal_fee: Scalars['Float']['output'];
};

/** Details of a withdrawal, before the withdrawal is started */
export type CreditAccountWithdrawalDetails = {
  /** Credit Account ID */
  credit_account_id: Scalars['Int']['output'];
  /** Fee for scoring */
  credit_scoring_fee: Scalars['Float']['output'];
  /** Whether instant payment option is allowed for this withdrawal */
  instant_payment_enabled: Scalars['Boolean']['output'];
  /** Additional fee, if the type is INSTANT */
  instant_payment_fee: Scalars['Float']['output'];
  /** Returns the latest withdrawal amount if exists */
  latest_withdrawal_amount?: Maybe<Scalars['Float']['output']>;
  /** The minimum amount that a client can withdraw from a credit account */
  min_withdrawal_amount: Scalars['Float']['output'];
  /** Withdrawal fee total amount (fixed + percentage or principal) */
  withdrawal_fee: Scalars['Float']['output'];
};

export enum CreditAccountWithdrawalStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  PROCESSED = 'PROCESSED',
  PROCESSING = 'PROCESSING',
  REJECTED = 'REJECTED'
}

export enum CreditAccountWithdrawalType {
  INSTANT = 'INSTANT',
  REGULAR = 'REGULAR'
}

/** Defaulted payments from credit info */
export type CreditInfoPaymentDefault = {
  /** The start date of the debt */
  begin_date?: Maybe<Scalars['String']['output']>;
  /** Maximum amount of debt the user owes */
  debt_range_end?: Maybe<Scalars['Int']['output']>;
  /** Minimum amount of debt the user owes */
  debt_range_start?: Maybe<Scalars['Int']['output']>;
  /** When the money was paid back */
  end_date?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  max_debt_range_end?: Maybe<Scalars['Int']['output']>;
  max_debt_range_start?: Maybe<Scalars['Int']['output']>;
  /** Field of activity the owner is operating in */
  owner_activity: Scalars['String']['output'];
  /** Who the user owes money to */
  owner_name?: Maybe<Scalars['String']['output']>;
  owner_registry_code?: Maybe<Scalars['String']['output']>;
  query_id: Scalars['Int']['output'];
};

/** Info about pricing values that are dynamic per region */
export type CreditPricing = {
  /** 1st order constant in the IRR quadratic formula */
  a_irr_var: Scalars['Int']['output'];
  /** 2nd order constant in the IRR quadratic formula */
  b_irr_var: Scalars['Int']['output'];
  /** 3rd order constant in the IRR quadratic formula */
  c_irr_var: Scalars['Int']['output'];
};

/** Info about how much the fees and IRR are with the given net total and month combination */
export type CreditSetting = {
  annual_pct_rate: Scalars['Float']['output'];
  contract_fee: Scalars['Float']['output'];
  irr: Scalars['Float']['output'];
  management_fee: Scalars['Float']['output'];
  month: Scalars['Int']['output'];
  monthly_payment: Scalars['Float']['output'];
  net_total: Scalars['Int']['output'];
};

/** Credit setting with some additional information used in credit setting calculator query */
export type CreditSettingCalculator = {
  annual_pct_rate: Scalars['Float']['output'];
  contract_fee: Scalars['Float']['output'];
  down_contract_fee?: Maybe<Scalars['Float']['output']>;
  irr: Scalars['Float']['output'];
  management_fee: Scalars['Float']['output'];
  /** Max possible down payment for this month and net total */
  max_down_payment?: Maybe<Scalars['Float']['output']>;
  /** Min possible down payment for this month and net total */
  min_down_payment?: Maybe<Scalars['Float']['output']>;
  month: Scalars['Int']['output'];
  monthly_payment: Scalars['Float']['output'];
  net_total: Scalars['Int']['output'];
};

/** List of credit settings for specific schedule types. */
export type CreditSettingsForScheduleTypes = {
  /** Credit settings for ESTO_X schedule type. */
  ESTO_X?: Maybe<Array<Maybe<CreditSetting>>>;
  /** Credit settings for FAST_LOAN schedule type. */
  FAST_LOAN?: Maybe<Array<Maybe<CreditSetting>>>;
  /** Credit settings for PAY_LATER schedule type. */
  PAY_LATER?: Maybe<Array<Maybe<CreditSetting>>>;
  /** Credit settings for REGULAR schedule type. */
  REGULAR?: Maybe<Array<Maybe<CreditSetting>>>;
  /** Credit settings for RENOVATION_LOAN schedule type. */
  RENOVATION_LOAN?: Maybe<Array<Maybe<CreditSetting>>>;
  /** Credit settings for SMALL_LOAN schedule type. */
  SMALL_LOAN?: Maybe<Array<Maybe<CreditSetting>>>;
  /** Credit settings for VEHICLE_LOAN schedule type. */
  VEHICLE_LOAN?: Maybe<Array<Maybe<CreditSetting>>>;
};

/** Statistics about products (e.g issued number of applications) */
export type CurrentDayStats = {
  /** Amount of applications issued today */
  issued_applications_count: Scalars['Int']['output'];
  /** Amount of hire purchase principal issued today */
  issued_applications_principal_by_hour?: Maybe<Array<Maybe<IssuedHour>>>;
  /** Amount of credit account principal issued today */
  issued_credit_account_principal_by_hour?: Maybe<Array<Maybe<IssuedHour>>>;
  /** Amount of credit accounts signed today */
  signed_credit_accounts_count: Scalars['Int']['output'];
};

/** Shows the customer care decisions */
export type CustomerCareDecision = {
  application_id?: Maybe<Scalars['Int']['output']>;
  created_at: Scalars['Int']['output'];
  credit_account_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** If Customer Care approved or not */
  is_approved: Scalars['Boolean']['output'];
  /** Type of customer care decision, one of: FRAUD, PEP, CONTACT_INFO, GENERAL */
  type?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** Fully rejected customers are given an option to get a loan from a third-party loan provider */
export type CustomerSale = {
  application_id: Scalars['Int']['output'];
  /** Customer ID in Raha24 system - no more available in our system */
  customer_id?: Maybe<Scalars['Int']['output']>;
  /** This field has a value when loan is successfully sold */
  handled_at?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** The premium that Raha24 will pay ESTO if loan is successfully sold - no more available in our system */
  premium_amount?: Maybe<Scalars['Float']['output']>;
};

/** Timestamp values for filtering by time */
export type DateRange = {
  timestamp_from?: InputMaybe<Scalars['Int']['input']>;
  timestamp_to?: InputMaybe<Scalars['Int']['input']>;
};

/** Debt collection process of an application */
export type DebtCollection = {
  application_id?: Maybe<Scalars['Int']['output']>;
  created_at: Scalars['Int']['output'];
  credit_account_id?: Maybe<Scalars['Int']['output']>;
  /** Debt collection credit account invoices that show invoices in debt collection */
  dc_credit_account_invoices?: Maybe<Array<Maybe<DebtCollectionCreditAccountInvoice>>>;
  /** Debt collection installments that show individual claims of the debt collection process */
  dc_installments?: Maybe<Array<Maybe<DebtCollectionInstallment>>>;
  id: Scalars['Int']['output'];
  /**
   * Timestamp, when the debt collection was successfully resolved (customer paid
   *                     back the debt)
   */
  resolved_at?: Maybe<Scalars['Int']['output']>;
};

/** An individual claim of the debt collection process for credit accounts */
export type DebtCollectionCreditAccountInvoice = {
  amount: Scalars['Float']['output'];
  created_at: Scalars['Int']['output'];
  /** The actual invoice associated with the debt collection claim */
  credit_account_invoice?: Maybe<CreditAccountInvoice>;
  credit_account_invoice_id: Scalars['Int']['output'];
  debt_collection_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  paid: Scalars['Float']['output'];
};

/** An individual claim of the debt collection process */
export type DebtCollectionInstallment = {
  amount: Scalars['Float']['output'];
  created_at: Scalars['Int']['output'];
  debt_collection_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  /** The actual installment associated with the debt collection claim */
  installment?: Maybe<Installment>;
  installment_id: Scalars['Int']['output'];
  paid: Scalars['Float']['output'];
};

/** Result of decision engine */
export type DecisionEngineResultType = {
  /** Instance eligibility status that is one of: CONFIRM_BANK, CONFIRM_BANK_ACCOUNTSCORING, MONTHLY_PAYMENT_TOO_HIGH, NEGATIVE, NEGATIVE_FULL, NEGATIVE_FORWARD, NO_USER, NOT_ENOUGH_CREDIT_FOR_THIS_WITHDRAWAL, OUTSIDE_WORKING_HOURS, SOME_REQUIRED_INFO_IS_MISSING, PENDING, PENDING_CUSTOMER_CARE, POSITIVE, WAITING_SPOUSE_CONSENT, EMTA_CONSENT, CHECK_INCOME, DSTI_DSCR_INSUFFICIENT, MANUAL_SCORING_NEEDED, TOO_MANY_LOW_IRR_LOANS */
  simple_eligibility_status?: Maybe<SimpleEligibilityStatus>;
  /** General result. -1 is negative, 0 is pending, 1 is positive */
  state: Scalars['Int']['output'];
};

/** Returned after preparing an ID card signature */
export type DigidocSignature = {
  /** Required for frontend signature validation */
  info_digest: Scalars['String']['output'];
  /** Session identifier for a signing session */
  signature_id: Scalars['String']['output'];
};

/** A request to refund direct payment */
export type DirectPaymentRefund = {
  /** Amount to refund */
  amount: Scalars['Float']['output'];
  application_id: Scalars['Int']['output'];
  /** Timestamp, when refund was failed on payment provider side */
  cancelled_at?: Maybe<Scalars['Int']['output']>;
  /** Timestamp, when refund was created */
  created_at?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** Timestamp, when refund was processed by payment provider */
  refunded_at?: Maybe<Scalars['Int']['output']>;
  /** The status of request. One of: PENDING, COMPLETED, FAILURE */
  status?: Maybe<Scalars['String']['output']>;
};

export enum Direction {
  ASC = 'asc',
  DESC = 'desc'
}

/** Returned after preparing an ID card signature by Dokobit API */
export type DokobitSignature = {
  /** Signing algorithm. */
  algorithm: Scalars['String']['output'];
  /** Signing data to be signed. */
  dtbs: Scalars['String']['output'];
  /** DTBS hash. */
  dtbs_hash: Scalars['String']['output'];
  /** Access token for signing. */
  token: Scalars['String']['output'];
};

/** Contains eligible amount to selling to investor and time of receiving of this amount. */
export type EligibleAmountForSellingToInvestor = {
  /** Amount of loans eligible for selling to investor */
  amount?: Maybe<Scalars['Float']['output']>;
  /** Time when amount was received from db */
  retrieved_at?: Maybe<Scalars['Int']['output']>;
};

export type Email = {
  id?: Maybe<Scalars['String']['output']>;
};

export enum EmailCategory {
  OVERDUE = 'OVERDUE',
  RESALE = 'RESALE'
}

export enum EmailInstanceType {
  APPLICATION = 'APPLICATION',
  CREDIT_ACCOUNT = 'CREDIT_ACCOUNT',
  CREDIT_ACCOUNT_WITHDRAWAL = 'CREDIT_ACCOUNT_WITHDRAWAL',
  USER = 'USER'
}

export enum EmailTemplate {
  ACCOUNT_STATEMENT = 'ACCOUNT_STATEMENT',
  CUSTOM_EMAIL = 'CUSTOM_EMAIL',
  CUSTOM_SMS = 'CUSTOM_SMS',
  DEBT = 'DEBT',
  INQUIRY = 'INQUIRY',
  LOGIN_PROBLEM = 'LOGIN_PROBLEM',
  OFFER = 'OFFER',
  PAYMENT_LEAVE = 'PAYMENT_LEAVE',
  PRICING = 'PRICING',
  UNSIGNED = 'UNSIGNED',
  WITHDRAWAL = 'WITHDRAWAL'
}

export type EmailTemplateView = {
  content?: Maybe<Scalars['String']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
};

export enum EmploymentSector {
  NON_PROFIT = 'NON_PROFIT',
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

export enum EparakstsAuthorizationMethod {
  MOBILE = 'mobile',
  SMARTCARD = 'smartcard'
}

/** Returned after preparing redirect_url */
export type EparakstsRedirect = {
  /** URL where user should be redirected to */
  redirect_url: Scalars['String']['output'];
};

/** Represents a legal document */
export type EstoLegalDocumentType = {
  document_name: Scalars['String']['output'];
  /** In which language the document is represented */
  language: Scalars['String']['output'];
  /** The last timestamp the document was modified/uploaded */
  last_modified: Scalars['Int']['output'];
  /** An absolute url to download the document */
  url: Scalars['String']['output'];
};

/** Everypay card type, like mastercard */
export enum EverypayCcType {
  MASTER_CARD = 'master_card',
  VISA = 'visa'
}

/** EveryPay fields that will be used in the frontend transaction for customer */
export type EverypayFields = {
  /** A specific code for the transaction, like EUR3D1 that specifies currency etc */
  account_id: Scalars['String']['output'];
  amount: Scalars['Float']['output'];
  /** Our EveryPay API user */
  api_username: Scalars['String']['output'];
  /** Where the payment response will be sent to by EveryPay */
  callback_url: Scalars['String']['output'];
  /** Token for a payment, where the user does not have to enter their details */
  cc_token?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['Int']['output'];
  /** Where the user will be redirected after payment procedure */
  customer_url: Scalars['String']['output'];
  /** User's email, for safety purposes */
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** User's locale, 2-letter combination */
  locale: Scalars['String']['output'];
  /** A unique string for identification */
  nonce: Scalars['String']['output'];
  /** Same as ID */
  order_reference: Scalars['String']['output'];
  /** Link to EveryPay page to enter card details. If not provided, some error with the provider. */
  payment_link?: Maybe<Scalars['String']['output']>;
  /**
   * Boolean, whether to request cc token that will be saved,
   *                     so that the user's card could be reused
   */
  request_cc_token?: Maybe<Scalars['Int']['output']>;
  /** Same as created_at timestamp */
  timestamp: Scalars['Int']['output'];
  /**
   * Which kind of security the user has to pass, when using 1-click payment. It should be
   *                     an ENUM type, but since one of the values starts with a number it breaks the graphql-codegen tool
   */
  token_security?: Maybe<Scalars['String']['output']>;
  transaction_type?: Maybe<EverypayPaymentTransactionType>;
  /** User's IP, for safety purposes */
  user_ip?: Maybe<Scalars['String']['output']>;
};

/** EveryPay payment data stored in our DB */
export type EverypayPayment = {
  /** A specific code for the transaction, like EUR3D1 that specifies currency etc */
  account_id: Scalars['String']['output'];
  amount: Scalars['Float']['output'];
  /** Where the payment response will be sent to by EveryPay */
  callback_url: Scalars['String']['output'];
  /** Token for a payment, where the user does not have to enter their details */
  cc_token?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['Int']['output'];
  /** Where the user will be redirected after payment procedure */
  customer_url: Scalars['String']['output'];
  /** User's email, for safety purposes */
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** User's locale, 2-letter combination */
  locale: Scalars['String']['output'];
  /** A unique string for identification */
  nonce: Scalars['String']['output'];
  /**
   * Boolean, whether to request cc token that will be saved,
   *                     so that the user's card could be reused
   */
  request_cc_token?: Maybe<Scalars['Int']['output']>;
  /**
   * Which kind of security the user has to pass, when using 1-click payment. It should be
   *                     an ENUM type, but since one of the values starts with a number it breaks the graphql-codegen tool
   */
  token_security?: Maybe<Scalars['String']['output']>;
  transaction_type?: Maybe<EverypayPaymentTransactionType>;
  /** User's IP, for safety purposes */
  user_ip?: Maybe<Scalars['String']['output']>;
};

export enum EverypayPaymentTransactionType {
  CIT = 'CIT',
  MIT = 'MIT',
  CHARGE = 'charge',
  CHARGE_AUTOMATIC = 'charge_automatic',
  ONEOFF = 'oneoff',
  TOKENISATION = 'tokenisation'
}

/** A saved card for EveryPay payment */
export type EverypayUserCard = {
  /** The cardholder’s name */
  cc_holder_name: Scalars['String']['output'];
  /** Last four digits of the credit card */
  cc_last_four_digits: Scalars['Int']['output'];
  /** A number representing the expiration month of a card (e.g., 8 for August) */
  cc_month: Scalars['Int']['output'];
  /** A unique token used for creating payments */
  cc_token: Scalars['String']['output'];
  /** Card type, like mastercard */
  cc_type?: Maybe<EverypayCcType>;
  /** A four-digit number representing the expiration year of a card (e.g. 2022) */
  cc_year: Scalars['Int']['output'];
  /** Whether this card should be used */
  enabled: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  user_id: Scalars['Int']['output'];
};

export type FaqCategory = {
  id: Scalars['Int']['output'];
  order_nr: Scalars['Int']['output'];
  /** Sub FAQs of the category */
  subs?: Maybe<Array<Maybe<FaqSub>>>;
  /** Texts in all languages for the category */
  texts?: Maybe<Array<Maybe<FaqCategoryText>>>;
};


export type FaqCategoryTextsArgs = {
  language_abbr?: InputMaybe<Scalars['String']['input']>;
};

/** Text in a certain language for a FAQ category */
export type FaqCategoryText = {
  category_id: Scalars['Int']['output'];
  language_abbr: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type FaqSub = {
  category_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  order_nr: Scalars['Int']['output'];
  /** Answer and question in each language */
  texts?: Maybe<Array<Maybe<FaqSubText>>>;
};


export type FaqSubTextsArgs = {
  language_abbr?: InputMaybe<Scalars['String']['input']>;
};

/** Answer and question for a FAQ sub */
export type FaqSubText = {
  answer_text: Scalars['String']['output'];
  language_abbr: Scalars['String']['output'];
  question_text: Scalars['String']['output'];
  sub_id: Scalars['Int']['output'];
};

/** User subscription information */
export type FeatureSubscription = {
  /** Comment for the subscription */
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['Int']['output']>;
  /** Subscription end date */
  end_at: Scalars['String']['output'];
  /** Subscription cost */
  fee_amount: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  merchant_id?: Maybe<Scalars['Int']['output']>;
  /** Date when the user reimbursed the subscription. Can be null */
  reimbursed_at?: Maybe<Scalars['String']['output']>;
  /** Subscription start date */
  start_at: Scalars['String']['output'];
  /** Subscription type */
  type: FeatureSubscriptionType;
  /** When the user unsubscribed */
  unsubscribed_at?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export enum FeatureSubscriptionType {
  MERCHANT_DEALS_ONE_OFF = 'MERCHANT_DEALS_ONE_OFF',
  MERCHANT_FASTER_SETTLEMENTS = 'MERCHANT_FASTER_SETTLEMENTS',
  MERCHANT_PLATFORM_FEE = 'MERCHANT_PLATFORM_FEE',
  PREMIUM_USER = 'PREMIUM_USER'
}

/** Contains information for creating a payment on Get Income. */
export type GetIncomeAmountToTransfer = {
  /** Total amount must be sent to Get Income. */
  amount_to_transfer?: Maybe<Scalars['Float']['output']>;
  /** Description for adding to bank payment to Get Income. */
  description: Scalars['String']['output'];
};

export type IrrCalculation = {
  /** Internal rate of return */
  irr: Scalars['Float']['output'];
  /** Amount that will be paid back every month */
  monthly_payment: Scalars['Float']['output'];
  /** The total amount that will be paid back by the user */
  total_expected: Scalars['Float']['output'];
};

/** Installment of an application */
export type Installment = {
  amount: Scalars['Float']['output'];
  /** The application this installment belongs to */
  application?: Maybe<Application>;
  /** ID of the application this installment belongs to */
  application_id: Scalars['Int']['output'];
  /** Timestamp, when the installment was deleted */
  deleted_at?: Maybe<Scalars['Int']['output']>;
  /** Date, when the installment is due */
  due_at: Scalars['String']['output'];
  id?: Maybe<Scalars['Int']['output']>;
  /** Amount paid for the installment up to current date */
  paid: Scalars['Float']['output'];
  /** Type of the installment, like MONTHLY_PRINCIPAL or REMINDER */
  type?: Maybe<InstallmentinstallmentDate>;
};

/** The interval length type used when updating installment due_at */
export enum InstallmentIntervalType {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK'
}

export enum InstallmentinstallmentDate {
  CARD_PAYMENT_FEE = 'CARD_PAYMENT_FEE',
  CHARGE = 'CHARGE',
  CONTRACT_EDITING_FEE = 'CONTRACT_EDITING_FEE',
  CREDIT_LIMIT_RECALCULATION_FEE = 'CREDIT_LIMIT_RECALCULATION_FEE',
  CREDIT_SCORING_FEE = 'CREDIT_SCORING_FEE',
  DOWN_CONTRACT_FEE = 'DOWN_CONTRACT_FEE',
  DOWN_PRINCIPAL = 'DOWN_PRINCIPAL',
  INSTANT_PAYMENT_FEE = 'INSTANT_PAYMENT_FEE',
  MONTHLY_CONTRACT_FEE = 'MONTHLY_CONTRACT_FEE',
  MONTHLY_INTEREST = 'MONTHLY_INTEREST',
  MONTHLY_MANAGEMENT_FEE = 'MONTHLY_MANAGEMENT_FEE',
  MONTHLY_PRINCIPAL = 'MONTHLY_PRINCIPAL',
  PAYMENT_LEAVE_FEE = 'PAYMENT_LEAVE_FEE',
  REMINDER = 'REMINDER',
  WITHDRAWAL_FEE = 'WITHDRAWAL_FEE'
}

/** Aggregated data over all Instantor response accounts */
export type InstantorAggregatedData = {
  /** Date of last transaction filtered by description with amount >= 300. Descriptions filtered by keywords, like salaries, etc. */
  last_income_above_300_from_desc_date?: Maybe<Scalars['String']['output']>;
  /** Date of last transaction filtered by employer with amount >= 300. Assumes that payer name equals to name of employer from user profile. */
  last_income_above_300_from_employer_date?: Maybe<Scalars['String']['output']>;
  /** Date of last transaction filtered by employer. Assumes that payer name equals to name of employer from user profile. */
  last_income_from_employer_date?: Maybe<Scalars['String']['output']>;
  /** Max amount of single transaction filtered by description within last 3 months. Descriptions filtered by keywords, like salaries, etc. */
  max_income_from_desc_last_3_months: Scalars['Float']['output'];
  /** Max amount of single transaction filtered by employer within last 3 months. Assumes that payer name equals to name of employer from user profile. */
  max_income_from_employer_last_3_months: Scalars['Float']['output'];
  /** The maximum number of months where there is data from the first day of the month to the last */
  max_whole_months_count: Scalars['Int']['output'];
  response_id: Scalars['String']['output'];
  /** Sum of all transactions filtered by description with amount >= 300 within the last 3 months. Descriptions filtered by keywords, like salaries, etc. */
  sum_income_above_300_from_desc_last_3_months: Scalars['Float']['output'];
  /** Sum of all transactions filtered by description with amount >= 300 within the last 31 days. Descriptions filtered by keywords, like salaries, etc. */
  sum_income_above_300_from_desc_last_31_days: Scalars['Float']['output'];
  /** Sum of all transactions filtered by employer with amount >= 300 withing the last 3 months. Assumes that payer name equals to name of employer from user profile. */
  sum_income_above_300_from_employer_last_3_months: Scalars['Float']['output'];
  /** Sum of all transactions filtered by employer with amount >= 300 withing the last 31 days. Assumes that payer name equals to name of employer from user profile. */
  sum_income_above_300_from_employer_last_31_days: Scalars['Float']['output'];
};

export enum InstantorEligibility {
  ELIGIBLE = 'ELIGIBLE',
  INDETERMINATE_LOAN_AMOUNT_TOO_HIGH = 'INDETERMINATE_LOAN_AMOUNT_TOO_HIGH',
  INDETERMINATE_MIN_BALANCE_TOO_LOW = 'INDETERMINATE_MIN_BALANCE_TOO_LOW',
  INDETERMINATE_TOO_FEW_MONTHS = 'INDETERMINATE_TOO_FEW_MONTHS',
  INDETERMINATE_TOO_MANY_LOANS = 'INDETERMINATE_TOO_MANY_LOANS',
  INELIGIBLE = 'INELIGIBLE',
  INELIGIBLE_DSCR = 'INELIGIBLE_DSCR',
  INELIGIBLE_MALFORMED_ID = 'INELIGIBLE_MALFORMED_ID',
  INELIGIBLE_PROCESS_STATUS = 'INELIGIBLE_PROCESS_STATUS',
  INELIGIBLE_WRONG_PIN = 'INELIGIBLE_WRONG_PIN',
  NONE_AVAILABLE = 'NONE_AVAILABLE',
  PENDING = 'PENDING'
}

/** Response from Instantor about a user's bank accounts */
export type InstantorResponse = {
  /** Bank accounts retrieved with the response */
  accounts?: Maybe<Array<Maybe<InstantorResponseAccount>>>;
  /** Aggregated data over all Instantor response accounts */
  aggregated_data?: Maybe<InstantorAggregatedData>;
  /** Bank, where the user logged into */
  bank_name: Scalars['String']['output'];
  created_at: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  /** Status of the response, like OK or ABANDONED */
  process_status: InstantorResponseInstantorProcessStatus;
  /** Unique reference_key generated by frontend */
  reference_key: Scalars['String']['output'];
  /** Raw JSON of the response */
  response_json: Scalars['String']['output'];
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** Bank account retrieved with an Instantor response */
export type InstantorResponseAccount = {
  /** Average amount incoming each whole month */
  avg_amount_inc_transactions_whole_month: Scalars['Float']['output'];
  /** Average amount outgoing each whole month */
  avg_amount_out_transactions_whole_month: Scalars['Float']['output'];
  /** Average minimum balance for whole months */
  avg_min_balance_whole_month: Scalars['Float']['output'];
  avg_nr_transactions_whole_month: Scalars['Float']['output'];
  /** Account balance at the time the response was retrieved */
  balance: Scalars['Float']['output'];
  /** Aggregated data about each month for the account */
  cash_flows?: Maybe<Array<Maybe<InstantorResponseAccountCashFlow>>>;
  holder_name?: Maybe<Scalars['String']['output']>;
  iban?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** Reference to the Instantor response this account belongs to */
  response_id: Scalars['String']['output'];
  total_transactions_count: Scalars['Int']['output'];
  /** All transactions for the account */
  transactions?: Maybe<Array<Maybe<InstantorResponseAccountTransaction>>>;
  /** Months that have info from the first to last day */
  whole_months_available: Scalars['Int']['output'];
};

/** Aggregated data about a month from an InstantorResponse */
export type InstantorResponseAccountCashFlow = {
  /** Average balance during the month */
  avg_balance: Scalars['Float']['output'];
  /** Start of the month date of the month this cash flow is about */
  calendar_month: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** Total incoming amount during the month */
  incoming: Scalars['Float']['output'];
  /** Whether the month has data from the first day of the month to the last */
  is_whole_month: Scalars['Boolean']['output'];
  /** Highest balance during the month */
  max_balance: Scalars['Float']['output'];
  /** Lowest balance during the month */
  min_balance: Scalars['Float']['output'];
  /** Total outgoing amount during the month */
  outgoing: Scalars['Float']['output'];
  /** Reference to the Instantor response this account belongs to */
  response_account_id: Scalars['Int']['output'];
};

/** A single transaction from an account */
export type InstantorResponseAccountTransaction = {
  /** Amount transferred with the transaction. Can be negative */
  amount: Scalars['Float']['output'];
  /** Account balance at the time the transaction was made */
  balance?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** Date, when the transaction was created */
  on_date: Scalars['String']['output'];
  /** Payer or payee IBAN */
  payer_payee_account?: Maybe<Scalars['String']['output']>;
  /** Payer or payee name */
  payer_payee_name?: Maybe<Scalars['String']['output']>;
  /** Reference to the Instantor response this account belongs to */
  response_account_id: Scalars['Int']['output'];
};

export enum InstantorResponseInstantorProcessStatus {
  ABANDONED = 'abandoned',
  BLOCKED = 'blocked',
  INVALID_LOGIN = 'invalid_login',
  OK = 'ok',
  PROCESS_ERROR = 'process_error'
}

/** Investor of loans that ESTO sells to (e.g. Mintos) */
export type Investor = {
  id: Scalars['Int']['output'];
  /** Interest rate for people who invest in sold loans */
  interest_rate_pct: Scalars['Float']['output'];
  /** Determines the max amount a loan can be dpd that will be invested */
  max_days_past_due: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /** The principal that will be pledged relative to the investment we will receive */
  principal_to_investment_pct: Scalars['Float']['output'];
  /** Link to the latest pledged loans report. */
  report_link?: Maybe<Scalars['String']['output']>;
  /** Percentage of how much ESTO invests in every loan sold to investor */
  skin_in_the_game_pct: Scalars['Float']['output'];
  /** The method of investment the investor uses (Mintos, Pledge etc) */
  type?: Maybe<InvestorType>;
};

/** What type of loans can be pledged to investor */
export enum InvestorLoanType {
  APPLICATION = 'APPLICATION',
  CREDIT_ACCOUNT = 'CREDIT_ACCOUNT'
}

/** Information about loans that have been sold to investor */
export type InvestorOverview = {
  /** Count of loans that have been sent to investor and are currently being sold */
  active_loans_count: Scalars['String']['output'];
  /** Count of loans that have been cancelled (e.g. bought back) */
  cancelled_loans_count: Scalars['String']['output'];
  /** Count of loans that are currently being cancelled (e.g. bought back) */
  cancelling_loans_count: Scalars['String']['output'];
  /** Count of loans that have been fully sold to investor */
  finished_loans_count: Scalars['String']['output'];
  /** Last time pledge was updated in UTC, returns null if pledge was never done */
  last_updated_pledge?: Maybe<Scalars['Int']['output']>;
  /** Total interest that is unpaid to investor */
  outstanding_interest: Scalars['Float']['output'];
  /** Total principal that is unpaid to investor */
  outstanding_principal: Scalars['Float']['output'];
  /** Total active interest that has been paid to investor */
  paid_interest: Scalars['Float']['output'];
  /** Total active principal that has been paid to investor */
  paid_principal: Scalars['Float']['output'];
  /** Count of loans that have been sent to investor and are waiting to be sold */
  pending_loans_count: Scalars['String']['output'];
  /** Total principal that has been bought back from Mintos */
  rebought_principal: Scalars['Float']['output'];
  /** Total principal sent to Mintos (includes bought back, inactive etc. loans) */
  total_pledged_principal: Scalars['Float']['output'];
};

export enum InvestorType {
  GET_INCOME = 'GET_INCOME',
  MINTOS = 'MINTOS',
  PLEDGE = 'PLEDGE'
}

/** Stores joint invoices of the users, also application and CA invoices in the future */
export type Invoice = {
  /** The application for which this invoice is for */
  application_id?: Maybe<Scalars['Int']['output']>;
  /** Date when the grace period is over and by which time the invoice must be paid */
  due_at: Scalars['String']['output'];
  /** Start of invoice period (usually start of previous month or first loan date) */
  from_date: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** A reference number in our system */
  invoice_nr?: Maybe<Scalars['String']['output']>;
  /** A unique invoice reference number for customers */
  invoice_reference_nr: Scalars['String']['output'];
  /** The merchant for which this invoice is for */
  merchant_id?: Maybe<Scalars['Int']['output']>;
  /** End of invoice period (usually end of previous month) */
  to_date: Scalars['String']['output'];
  /** Total amount of all rows including VAT */
  total_amount: Scalars['Float']['output'];
  /** Invoice type (USER, APPLICATION, CREDIT_ACCOUNT, MERCHANT) */
  type: Scalars['String']['output'];
  /** An url where the invoice can be downloaded */
  url?: Maybe<Scalars['String']['output']>;
  /** The user for which this invoice is for */
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type IssuedHour = {
  hour: Scalars['Int']['output'];
  /** Amount of principal issued during the hour */
  principal: Scalars['Float']['output'];
};

export type JsonMac = {
  json: Scalars['String']['output'];
  mac: Scalars['String']['output'];
};

/** Klix Payment data stored in our DB */
export type KlixPayment = {
  /** Payment amount (without fee) */
  amount: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  /** ID of a payment in Klix system */
  klix_request_id: Scalars['String']['output'];
  /** IBAN of the user who made the payment */
  payer_iban?: Maybe<Scalars['String']['output']>;
  /** Payment method key */
  payment_method_key?: Maybe<Scalars['String']['output']>;
  /** URL to which the user should be redirected to make the payment */
  payment_url?: Maybe<Scalars['String']['output']>;
  /** Payment reference */
  reference?: Maybe<Scalars['String']['output']>;
  /** Session ID of the Klix payment */
  session_id: Scalars['String']['output'];
  /** Klix payment status. Comes from Klix API. Values: created, sent, viewed, pending_execute, paid, error, cancelled, overdue, expired, blocked */
  status?: Maybe<KlixPaymentStatusType>;
};

export enum KlixPaymentStatusType {
  BLOCKED = 'blocked',
  CANCELLED = 'cancelled',
  CREATED = 'created',
  ERROR = 'error',
  EXPIRED = 'expired',
  OVERDUE = 'overdue',
  PAID = 'paid',
  PENDING_EXECUTE = 'pending_execute',
  SENT = 'sent',
  VIEWED = 'viewed'
}

export type Language = {
  /** Unique identifier of the language */
  abbreviation: Scalars['String']['output'];
  /** Descriptive name of the language in its own language */
  name: Scalars['String']['output'];
};

export enum LanguageAbbreviationType {
  EN_US = 'en_us',
  ET_EE = 'et_ee',
  LT_LT = 'lt_lt',
  LV_LV = 'lv_lv',
  RU_RU = 'ru_ru'
}

export enum LegalPeopleOrderBy {
  CREATED_AT = 'created_at',
  ID = 'id',
  NAME = 'name',
  REGISTRY_CODE = 'registry_code'
}

/** A legal person (business) */
export type LegalPerson = {
  /** Optional additional information about the legal person */
  comment?: Maybe<Scalars['String']['output']>;
  /** Country code where the legal person is located */
  country_code: Scalars['String']['output'];
  /** Timestamp, when the legal person was created */
  created_at: Scalars['Int']['output'];
  /** ELIGIBLE or reason why not eligible */
  eligibility_status?: Maybe<Scalars['String']['output']>;
  /** ID of the legal person */
  id: Scalars['Int']['output'];
  /** Returns whether legal person is eligible or not for a given amount */
  is_eligible?: Maybe<Scalars['Boolean']['output']>;
  /** The most recent bankruptcy risk score */
  latest_score?: Maybe<LegalPersonScore>;
  /** Name of the legal person */
  name: Scalars['String']['output'];
  /** Date when the legal person was registered */
  registration_date?: Maybe<Scalars['String']['output']>;
  /** Date when the legal person registered period was over */
  registration_end_date?: Maybe<Scalars['String']['output']>;
  /** Registry code (must be unique) of the legal person */
  registry_code: Scalars['String']['output'];
  /** What the current registry status is (registered, bankrupt etc.) */
  registry_status?: Maybe<Scalars['String']['output']>;
  /** The predicted bankruptcy risk score of legal person */
  scores?: Maybe<Array<Maybe<LegalPersonScore>>>;
  /** Legal person eligibility status that is one of: CONFIRM_BANK, CONFIRM_BANK_ACCOUNTSCORING, MONTHLY_PAYMENT_TOO_HIGH, NEGATIVE, NEGATIVE_FULL, NEGATIVE_FORWARD, NO_USER, NOT_ENOUGH_CREDIT_FOR_THIS_WITHDRAWAL, OUTSIDE_WORKING_HOURS, SOME_REQUIRED_INFO_IS_MISSING, PENDING, PENDING_CUSTOMER_CARE, POSITIVE, WAITING_SPOUSE_CONSENT, EMTA_CONSENT, CHECK_INCOME, DSTI_DSCR_INSUFFICIENT, MANUAL_SCORING_NEEDED, TOO_MANY_LOW_IRR_LOANS */
  simple_eligibility_status?: Maybe<SimpleEligibilityStatus>;
  /** The users that this legal person belongs to */
  users?: Maybe<Array<Maybe<User>>>;
};


/** A legal person (business) */
export type LegalPersonIsEligibleArgs = {
  amount?: InputMaybe<Scalars['Float']['input']>;
};

/**
 * The predicted bankruptcy risk score, ranging from 0 to 1, 0 being the lowest, 1 being the
 *             highest risk score
 */
export type LegalPersonScore = {
  /** Date of the predicted risk score. */
  date: Scalars['String']['output'];
  /** The legal person that this score is about */
  legal_person?: Maybe<LegalPerson>;
  /** ID of the legal person */
  legal_person_id: Scalars['Int']['output'];
  /** The predicted risk score on that date. */
  value: Scalars['Float']['output'];
};

/** Loan sub-statuses */
export type LoanSubStatus = {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export enum LoanType {
  APPLICATION = 'application',
  CREDITACCOUNT = 'creditAccount'
}

/** Response from DigiDoc or SK, used for user's verification purposes. */
export type LoginChallenge = {
  /** Code that the user will see on their device (e.g for Mobile-ID, Smart-ID, etc) */
  challenge_id?: Maybe<Scalars['String']['output']>;
  /** Whether the user is already authenticated */
  is_authenticated?: Maybe<Scalars['Boolean']['output']>;
  /** URL where user should be redirected to (for Paysera, eParaksts etc) */
  redirect_url?: Maybe<Scalars['String']['output']>;
  /** A unique has that can be used to retrieve the cache set by authentication */
  session_id?: Maybe<Scalars['String']['output']>;
};

export enum LoginPollStatus {
  EMAIL_REQUEST = 'EMAIL_REQUEST',
  FAILED = 'FAILED',
  MAGIC_LINK_SENT = 'MAGIC_LINK_SENT',
  NO_RESPONSE = 'NO_RESPONSE',
  SUCCESSFUL = 'SUCCESSFUL'
}

export enum MaritalStatus {
  DIVORCED = 'DIVORCED',
  MARRIED = 'MARRIED',
  NA = 'NA',
  SINGLE = 'SINGLE',
  WIDOWED = 'WIDOWED'
}

/** A shop or other commercial entity that we provide loans for */
export type Merchant = {
  address: Scalars['String']['output'];
  /** Full URL of the merchant's background */
  background_path?: Maybe<Scalars['String']['output']>;
  /** Bank account name of the merchant */
  beneficiary_name: Scalars['String']['output'];
  /** Calculator colors etc used by the merchant */
  calculator_skin?: Maybe<MerchantCalculatorSkin>;
  /** Campaign created by the merchant for lower rates, payment leave etc */
  campaign?: Maybe<MerchantCampaign>;
  /** URL where the user is returned after they cancelled a purchase */
  cancel_url?: Maybe<Scalars['String']['output']>;
  /** If loyalty is enabled, cashiers can get rewards for selling products */
  cashier_loyalty_enabled: Scalars['Boolean']['output'];
  /** Relation that the merchant belongs to. Specifies the types of items they sell */
  category?: Maybe<MerchantCategory>;
  /** Category identifies what kind of stuff the merchant sells. */
  category_id?: Maybe<Scalars['Int']['output']>;
  /** Timestamp of the time the merchant was created at */
  created_at: Scalars['Int']['output'];
  /** List of Gateways available to use for Direct Payments (ESTO PAY) and their settings */
  direct_payment_gateways?: Maybe<Array<Maybe<MerchantDirectPaymentGateway>>>;
  /** URL for the merchant that they can put on their website for creating dynamic loans */
  dynamic_loan_url?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  /** URL to the mechant's homepage */
  home_url?: Maybe<Scalars['String']['output']>;
  /** IBAN (bank account) number of the merchant */
  iban: Scalars['String']['output'];
  /** ID of the merchant */
  id: Scalars['Int']['output'];
  invoice_email?: Maybe<Scalars['String']['output']>;
  invoice_reference_nr?: Maybe<Scalars['String']['output']>;
  /** Whether the merchant is a credit broker */
  is_broker?: Maybe<Scalars['Boolean']['output']>;
  /** Full URL of the merchant's logo */
  logo_path?: Maybe<Scalars['String']['output']>;
  /** Send a report about issued and cancelled applications on the 1st day of every month */
  monthly_reports_enabled: Scalars['Boolean']['output'];
  /** Name of the merchant */
  name: Scalars['String']['output'];
  /** URL where the callback is made to after a successful purchase */
  notification_url?: Maybe<Scalars['String']['output']>;
  /** Overflow balance of the merchant */
  overflow: Scalars['Float']['output'];
  phone: Scalars['String']['output'];
  /** Specifies the type of e-commerce platform the merchant uses */
  platform?: Maybe<MerchantPlatform>;
  /** Specifies the type of e-commerce platform the merchant uses */
  platform_id?: Maybe<Scalars['Int']['output']>;
  /** Value taken from pricing. Max amount that an admin crm user can waive from a merchant. */
  pricing_max_waive_amount: Scalars['Float']['output'];
  /** If true, the merchant will be displayed as the lender */
  private_label: Scalars['Boolean']['output'];
  /** Identifying registry code of the merchant's company */
  registry_code: Scalars['String']['output'];
  /** URL where the user is returned after a successful purchase */
  return_url?: Maybe<Scalars['String']['output']>;
  /** A unique string for the merchant that must be kept private */
  secret_key?: Maybe<Scalars['String']['output']>;
  /** Whether an email is sent to the merchant whenever ESTO Pay application is successfully signed */
  send_esto_pay_notification_email: Scalars['Boolean']['output'];
  /** Whether to send notifications to customers when ESTO Pay applications are signed */
  send_esto_pay_notification_to_customer?: Maybe<Scalars['Boolean']['output']>;
  /** Whether an email is sent to the merchant whenever NOT ESTO Pay application is successfully signed */
  send_hp_notification_email: Scalars['Boolean']['output'];
  /** Whether an email is sent to the user, if they created an application but did not finish it */
  send_user_inquiry_email: Scalars['Boolean']['output'];
  /** Settings of the merchant */
  settings?: Maybe<MerchantSettings>;
  /** Unique ID of the shop */
  shop_id: Scalars['String']['output'];
  /** URL for the merchant that they can put on their website for creating small loans */
  small_loan_url?: Maybe<Scalars['String']['output']>;
  /** Retail stores that the merchant owns */
  stores?: Maybe<Array<Maybe<MerchantStore>>>;
  /** Total unpaid amount for the merchant, includes the sum of reverse kickback and mediation fee balances */
  unpaid_amount: Scalars['Float']['output'];
  /** Total unpaid invoice amount for the merchant */
  unpaid_invoice_amount: Scalars['Float']['output'];
  /** Users connected to the merchant */
  users?: Maybe<Array<Maybe<MerchantUser>>>;
  /** VAT number of the merchant */
  vat_number?: Maybe<Scalars['String']['output']>;
};

export type MerchantApplicationIndex = {
  /** Determine whether to accrue interest and fees for a loan */
  accrue_interest_and_fees: Scalars['Boolean']['output'];
  /** Currently active payment leave for application */
  active_payment_leave?: Maybe<PaymentLeave>;
  /** Whether to add legal person info to the invoice */
  add_legal_person_to_invoice: Scalars['Boolean']['output'];
  /** If application has been modified, the original credit info data is stored here */
  application_modifications?: Maybe<Array<Maybe<ApplicationModification>>>;
  /** Helper table for making purchases via a physical cash register */
  application_reference?: Maybe<ApplicationReference>;
  /** ID of the application_user_info that this application belongs to */
  application_user_info_id?: Maybe<Scalars['Int']['output']>;
  /** Items bought with the purchase */
  basket_items?: Maybe<Array<Maybe<ApplicationBasketItem>>>;
  /** Date, when merchant was paid the application bonuses */
  bonus_paid_at?: Maybe<Scalars['String']['output']>;
  /** Application status based on information provided by the broker. */
  broker_status?: Maybe<Scalars['String']['output']>;
  /** Information about application that has been bought back from merchant */
  buyback_settings?: Maybe<ApplicationBuybackSettings>;
  /** A campaign that applies to the application, if enabled */
  campaign?: Maybe<ApplicationCampaign>;
  /** Determines, whether the application can be modified */
  can_be_modified: Scalars['Boolean']['output'];
  /** Whether an application can convert from a regular schedule to (long-term) CA/HP. */
  can_convert_from_regular: Scalars['Boolean']['output'];
  /** Whether the application can be converted manually to CA. */
  can_manually_convert_to_credit_account: Scalars['Boolean']['output'];
  /** Checks if the application can be refinanced or not */
  can_refinance: Scalars['Boolean']['output'];
  /** Optional URL, where the user will be redirected to after application was rejected */
  cancel_url?: Maybe<Scalars['String']['output']>;
  /** Request made by merchant to cancel this application */
  cancellation_request?: Maybe<ApplicationCancellationRequest>;
  /** Timestamp, when the application was cancelled (purchased item returned to merchant) */
  cancelled_at?: Maybe<Scalars['Int']['output']>;
  /** The user by whom this application was cancelled */
  cancelled_by?: Maybe<User>;
  /** Date, when merchant was paid cashier the application bonuses */
  cashier_bonus_paid_at?: Maybe<Scalars['String']['output']>;
  /** ConvertingSchedule regular campaign schedule */
  converting_schedule_regular?: Maybe<ConvertingScheduleRegular>;
  /** Timestamp, when the application was created */
  created_at: Scalars['Int']['output'];
  /** Additional info of the application about pricing */
  credit_info?: Maybe<ApplicationCreditInfo>;
  /** Fully rejected customers are given an option to get a loan from a third-party loan provider */
  customer_sale?: Maybe<CustomerSale>;
  /** How many days the hire purchase payment is overdue. 0 if HP payment is not overdue. */
  days_past_due?: Maybe<Scalars['Int']['output']>;
  /** Ongoing and finished claim collection processes */
  debt_collections?: Maybe<Array<Maybe<DebtCollection>>>;
  /** Timestamp, when the application was deleted */
  deleted_at?: Maybe<Scalars['Int']['output']>;
  /** Refunds history for specific Direct payment (ESTO Pay) */
  direct_payment_refunds?: Maybe<Array<Maybe<DirectPaymentRefund>>>;
  /** Down payment of the application */
  down_installments?: Maybe<Array<Maybe<Installment>>>;
  /** If the application was paid for early it will have additional info */
  early_payment?: Maybe<ApplicationEarlyPayment>;
  /**
   * Disable the eligibility check in order to not make actual queries to the Decision
   *                 Engine. In this case, the eligibility status and state will not change
   */
  eligibility_check_disabled?: Maybe<Scalars['Boolean']['output']>;
  /** 1: Eligible, 0: Pending, -1: Rejected */
  eligibility_state?: Maybe<Scalars['Int']['output']>;
  /** ELIGIBLE or reason why not eligible */
  eligibility_status?: Maybe<Scalars['String']['output']>;
  /** Timestamp of the last time eligibility was checked */
  eligibility_status_last_check?: Maybe<Scalars['Int']['output']>;
  /** Timestamp, when the last payment was made that paid for the application fully */
  ended_at?: Maybe<Scalars['Int']['output']>;
  /** Time when the application will be deleted, if it has not been signed */
  expires_at?: Maybe<Scalars['Int']['output']>;
  /** The final payment that is due to be paid for the application */
  final_payment_date?: Maybe<Scalars['Int']['output']>;
  /** First monthly installment */
  first_installment?: Maybe<Installment>;
  /** True, if app for private person, false if for legal person and NULL if not fixed at all */
  fixed_for_private_person?: Maybe<Scalars['Boolean']['output']>;
  /** True, if application does NOT represent a legal person */
  for_private_person: Scalars['Boolean']['output'];
  /** Whether to forward rejected application to another broker. */
  forward_rejects: Scalars['Boolean']['output'];
  /** Whether it is a FREE HP application (no credit cost, no scoring) */
  free_hp_enabled: Scalars['Boolean']['output'];
  /** Whether this application was created in a retail (physical) store */
  from_retail: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  /**
   * Whether the user's identity needs verification (depends on requested amount).
   *                     Always include user relation with id_verification_method!
   */
  id_verification_required?: Maybe<Scalars['Boolean']['output']>;
  /** Time when the user (small loan) or merchant (hire purchase) was paid for by us */
  initial_paid_at?: Maybe<Scalars['Int']['output']>;
  /** Required installments for the application */
  installments?: Maybe<Array<Maybe<Installment>>>;
  /** Required installments for the application, ordered by due_at ASC */
  installments_ordered?: Maybe<Array<Maybe<Installment>>>;
  /** The fee for instant payout of small loan */
  instant_payment_fee: Scalars['Float']['output'];
  /** Current status of the Instantor request for this application */
  instantor_eligibility: InstantorEligibility;
  /** Identifier for the application that is used for payments */
  invoice_reference_nr?: Maybe<Scalars['String']['output']>;
  /** Whether the application converts (to CA or longer HP) */
  is_converting_schedule: Scalars['Boolean']['output'];
  /** Determines, if the user can apply for the loan specified by this application */
  is_eligible: Scalars['Boolean']['output'];
  /** Whether the funds will be paid out immediately to the client. Only applies for consumer loans */
  is_instant_payout: Scalars['Boolean']['output'];
  /** Whether the user can make instant payouts. */
  is_instant_payout_available: Scalars['Boolean']['output'];
  /** Determines, if direct payment can be refunded */
  is_refundable: Scalars['Boolean']['output'];
  /** If true, then this application is only used for testing purposes */
  is_test: Scalars['Boolean']['output'];
  /** klix payments connected to this application */
  klix_payments?: Maybe<Array<Maybe<KlixPayment>>>;
  /** Very last monthly installment that will be paid */
  last_installment?: Maybe<Installment>;
  /** Latest customer care decision result for related to application */
  latest_customer_care_decision?: Maybe<CustomerCareDecision>;
  /** Latest modification request made by merchant */
  latest_modification_request?: Maybe<ApplicationModificationRequest>;
  /** Info about the legal person connected to the application */
  legal_person_info?: Maybe<ApplicationLegalPersonInfo>;
  /**
   * Get ESTO's or merchant's or campaign logo, depending on the application.
   *                     NB! Always select merchant and campaign relations
   */
  logo_url: Scalars['String']['output'];
  /** Seller of the purchase */
  merchant?: Maybe<Merchant>;
  /** Data entered by the merchant for the application */
  merchant_data?: Maybe<ApplicationMerchantData>;
  /** ID of the merchant selling something to the user */
  merchant_id?: Maybe<Scalars['Int']['output']>;
  /** Requests made by merchant to modify application values such as net total */
  modification_requests?: Maybe<Array<Maybe<ApplicationModificationRequest>>>;
  /** The next installment that is unpaid (can be in the past as well) */
  next_installment?: Maybe<Installment>;
  /** The next installment date for the given application or oldest installment date, if overdue */
  next_installment_date?: Maybe<Scalars['String']['output']>;
  /**
   * The next payment amount for the application. If overdue then only overdue amount,
   *                     but otherwise get current month
   */
  next_payment_amount?: Maybe<Scalars['Float']['output']>;
  /** Application overdue installments */
  overdue_installments?: Maybe<Array<Maybe<Installment>>>;
  /** The total amount of principal that has been paid */
  paid_principal: Scalars['Float']['output'];
  /** Payment leaves taken for this application */
  payment_leaves?: Maybe<Array<Maybe<PaymentLeave>>>;
  /** Acquirer pivot of the product (application or credit account), if the product has been sold with a portfolio */
  portfolio_sale_product?: Maybe<PortfolioSaleProduct>;
  /** Pre-signing conversion offer for the application */
  pre_signing_conversion_offer?: Maybe<PreSigningConversionOffer>;
  /** Value taken from pricing. Max amount that an admin can waive from a hire purchase. */
  pricing_max_waive_amount: Scalars['Float']['output'];
  /** Timestamp, when the application was processed (for direct payments) */
  processed_at?: Maybe<Scalars['Int']['output']>;
  /** Get the name of merchant if private label or ESTO if not */
  provider_name: Scalars['String']['output'];
  /** URL where the purchase flow starts from */
  purchase_url: Scalars['String']['output'];
  /** A unique string to identify the application */
  reference_key: Scalars['String']['output'];
  /** The application that was refinanced by this application */
  refinanced_application?: Maybe<Application>;
  /** The application that refinanced this application */
  refinancing_parent_application?: Maybe<Application>;
  /** The application's id that refinances this application */
  refinancing_parent_id?: Maybe<Scalars['Int']['output']>;
  /** Get amount that was successfully refunded for Direct payment */
  refunded_amount: Scalars['Float']['output'];
  /** Timestamp, when the application was rejected */
  rejected_at?: Maybe<Scalars['Int']['output']>;
  /** Reminders that have been sent to the user because of overdue installments */
  reminders?: Maybe<Array<Maybe<Reminder>>>;
  /** The amount required for the purchase */
  requested_amount: Scalars['Float']['output'];
  /** Resale applications are potentially eligible applications which are currently unsigned */
  resale?: Maybe<Resale>;
  /** Resale call is created when the user is called during the resale process */
  resale_call?: Maybe<ResaleCall>;
  /** Optional URL, where the user will be redirected to after submitting the application */
  return_url?: Maybe<Scalars['String']['output']>;
  /** Type of the application, like hire-purchase or small loan */
  schedule_type: ApplicationScheduleType;
  /** Determine whether reminders should be sent */
  send_reminders: Scalars['Boolean']['output'];
  /** Whether a separate checkbox for pension query is shown at purchase flow */
  show_separate_pension_query: Scalars['Boolean']['output'];
  /** Timestamp, when the application was signed and accepted */
  signed_at?: Maybe<Scalars['Int']['output']>;
  /** Credit account eligibility status that is one of SimpleEligibilityStatus */
  simple_eligibility_status: Scalars['String']['output'];
  /** Timestamp, when the application was sold to a debt collector */
  sold_at?: Maybe<Scalars['Int']['output']>;
  /** Application status. One of the following: UNSIGNED, REJECTED, ACTIVE, TERMINATED, CANCELLED, DELETED, PROCESSED, ENDED */
  status: ApplicationStatus;
  /** Application sub-statuses */
  sub_statuses?: Maybe<Array<Maybe<LoanSubStatus>>>;
  /** Recurring payments subscription for the application */
  subscription?: Maybe<Subscription>;
  /** Timestamp, when the application was terminated (e.g when customer died or went bankrupt) */
  terminated_at?: Maybe<Scalars['Int']['output']>;
  termination_reason?: Maybe<Scalars['String']['output']>;
  /**
   * Total unpaid amount that should have been paid by now. NB! Only use for single queries
   *                     as it's actually a DB query
   */
  total_overdue: Scalars['Float']['output'];
  /** Get total paid amount for application */
  total_paid?: Maybe<Scalars['Float']['output']>;
  /** Type of the application, like hire-purchase or small loan */
  type: ApplicationType;
  /** Get the total unpaid principal amount for the loan */
  unpaid_principal?: Maybe<Scalars['Float']['output']>;
  /** Owner of the application */
  user?: Maybe<User>;
  /** ID of the user that this application belongs to */
  user_id?: Maybe<Scalars['Int']['output']>;
  /** Additional info of the application about the user */
  user_info?: Maybe<ApplicationUserInfo>;
};


export type MerchantApplicationIndexSimpleEligibilityStatusArgs = {
  do_query?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum MerchantApplicationsOrderBy {
  CREATED_AT = 'created_at',
  MERCHANT_REFERENCE = 'merchant_reference',
  REQUESTED_AMOUNT = 'requested_amount',
  SIGNED_AT = 'signed_at'
}

/** Calculator colors etc for the merchant */
export type MerchantCalculatorSkin = {
  active_period: Scalars['String']['output'];
  button: Scalars['String']['output'];
  button_text: Scalars['String']['output'];
  /** Discount multiplier. 1 is the same as actual pricing, the lower the cheaper */
  discount_mltp: Scalars['Float']['output'];
  main: Scalars['String']['output'];
  max_amount: Scalars['Float']['output'];
  merchant_id: Scalars['Int']['output'];
  min_amount: Scalars['Float']['output'];
  monthly_text: Scalars['String']['output'];
  period: Scalars['String']['output'];
  period_text: Scalars['String']['output'];
  secondary: Scalars['String']['output'];
  text: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

/** A campaign for a merchant that provides a payment leave or better rates for the merchant's customers */
export type MerchantCampaign = {
  /** Background image url of ESTO X for merchant */
  converting_schedule_background_url?: Maybe<Scalars['String']['output']>;
  /** Whether Esto X loan will be automatically converted after it has gone overdue */
  converting_schedule_converting_enabled: Scalars['Boolean']['output'];
  /**
   * Enables ESTO X campaign for the merchant, which allows the user to pay within the
   *                     first X months without any additional cost
   */
  converting_schedule_enabled: Scalars['Boolean']['output'];
  /** Contract fee for ESTO X applications is fixed to this */
  converting_schedule_fixed_contract_fee?: Maybe<Scalars['Float']['output']>;
  /** Enables FREE HP (no credit cost, no scoring) for ESTO X */
  converting_schedule_free_hp_enabled: Scalars['Boolean']['output'];
  converting_schedule_logo_url?: Maybe<Scalars['String']['output']>;
  /** The length of ConvertingSchedule campaign */
  converting_schedule_months?: Maybe<Scalars['Int']['output']>;
  /** Brand name that is used for ESTO X campaign */
  converting_schedule_name?: Maybe<Scalars['String']['output']>;
  /** The max allowed purchase amount for ESTO X */
  converting_schedule_net_total_max: Scalars['Float']['output'];
  /** The min allowed purchase amount for ESTO X */
  converting_schedule_net_total_min: Scalars['Float']['output'];
  /** The length of the regular installment period after switching from ConvertingSchedule */
  converting_schedule_regular_months?: Maybe<Scalars['Int']['output']>;
  /** Reverse kickback that overwrites the default reverse kickback pct, if ESTO X is enabled */
  converting_schedule_reverse_kickback_pct: Scalars['Float']['output'];
  /** List of Gateways available to use for Direct Payments (ESTO PAY) and their settings */
  direct_payment_gateways?: Maybe<Array<Maybe<MerchantDirectPaymentGateway>>>;
  enabled: Scalars['Boolean']['output'];
  /** Timestamp, when the campaign won't be available any more. Forever, if null */
  ends_at?: Maybe<Scalars['Int']['output']>;
  /** Background image url of ESTO Pay for merchant */
  esto_pay_background_url?: Maybe<Scalars['String']['output']>;
  /**
   * Enables ESTO Pay campaign for the merchant, which allows the user to pay directly to
   *                     merchant (Direct Payments)
   */
  esto_pay_enabled: Scalars['Boolean']['output'];
  esto_pay_logo_url?: Maybe<Scalars['String']['output']>;
  /** Brand name that is used for ESTO Pay campaign */
  esto_pay_name?: Maybe<Scalars['String']['output']>;
  /** The max allowed purchase amount for ESTO Pay */
  esto_pay_net_total_max: Scalars['Float']['output'];
  /** The min allowed purchase amount for ESTO Pay */
  esto_pay_net_total_min: Scalars['Float']['output'];
  fixed_annual_pct_rate?: Maybe<Scalars['Float']['output']>;
  /** Contract fee for REGULAR applications is fixed to this */
  fixed_contract_fee?: Maybe<Scalars['Float']['output']>;
  fixed_management_fee?: Maybe<Scalars['Float']['output']>;
  /** No interest for the first selected amount of months */
  interest_free_months: Scalars['Int']['output'];
  /** Check if the campaign is currently enabled and has not ended */
  is_active: Scalars['Boolean']['output'];
  merchant?: Maybe<Merchant>;
  merchant_id: Scalars['Int']['output'];
  /** Background image url of Pay Later for merchant */
  pay_later_background_url?: Maybe<Scalars['String']['output']>;
  /** Whether Pay Later loan will be automatically converted after it has gone overdue */
  pay_later_converting_enabled: Scalars['Boolean']['output'];
  /**
   * Enables Pay Later campaign for the merchant, which allows the user to pay within the
   *                     first 30 days without any additional cost
   */
  pay_later_enabled: Scalars['Boolean']['output'];
  /** Enables FREE HP (no credit cost, no scoring) for Pay Later */
  pay_later_free_hp_enabled: Scalars['Boolean']['output'];
  pay_later_logo_url?: Maybe<Scalars['String']['output']>;
  /** Brand name that is used for Pay Later campaign */
  pay_later_name?: Maybe<Scalars['String']['output']>;
  /** The min allowed purchase amount for Pay Later */
  pay_later_net_total_max: Scalars['Float']['output'];
  /** The min allowed purchase amount for Pay Later */
  pay_later_net_total_min: Scalars['Float']['output'];
  /** Reverse kickback that overwrites the default reverse kickback pct, if Pay Later is enabled */
  pay_later_reverse_kickback_pct: Scalars['Float']['output'];
  payment_leave_months: Scalars['Int']['output'];
  /** Background image url of Regular HP for merchant */
  regular_background_url?: Maybe<Scalars['String']['output']>;
  /** Whether Regular HP loan will be automatically converted after it has gone overdue */
  regular_hp_converting_enabled: Scalars['Boolean']['output'];
  /** Enables Regular Hire Purchase campaign for the merchant */
  regular_hp_enabled: Scalars['Boolean']['output'];
  /** Enables FREE HP (no credit cost, no scoring) for Regular HP */
  regular_hp_free_hp_enabled: Scalars['Boolean']['output'];
};

/** Additional information about cashier sales made in a physical store. */
export type MerchantCashierSales = {
  /** All bonus that the cashier has earned (paid and unpaid) */
  all_time_bonus_amount: Scalars['Float']['output'];
  /** All bonus that we have already paid the cashier for */
  all_time_bonus_paid: Scalars['Float']['output'];
  /** All bonus that we have not paid to the cashier yet */
  all_time_bonus_unpaid: Scalars['Float']['output'];
  /** User id of the cashier who made the sales */
  cashier_id: Scalars['String']['output'];
  /** Name of the cashier who made the sales */
  cashier_name: Scalars['String']['output'];
  /** Total price of products sold this month by cashier */
  current_month_sales_amount: Scalars['Float']['output'];
  /** Number of sales made this month by cashier */
  current_month_sales_count: Scalars['Int']['output'];
  /** Total price of products sold this year by cashier */
  current_year_sales_amount: Scalars['Float']['output'];
  /** Number of sales made this year by cashier */
  current_year_sales_count: Scalars['Int']['output'];
  /** Total price of products sold last month by cashier */
  last_month_sales_amount: Scalars['Float']['output'];
  /** Number of sales made last month by cashier */
  last_month_sales_count: Scalars['Int']['output'];
  /** Total price of products sold last year by cashier */
  last_year_sales_amount: Scalars['Float']['output'];
  /** Number of sales made last year by cashier */
  last_year_sales_count: Scalars['Int']['output'];
};

/** Category of which kind of items the merchant sells. */
export type MerchantCategory = {
  id: Scalars['Int']['output'];
  /** Name of the category */
  name: Scalars['String']['output'];
};

/** Payment gateway settings used for processing Direct Payment (ESTO PAY) */
export type MerchantDirectPaymentGateway = {
  /** Whether payment gateway enabled for merchant */
  enabled: Scalars['Boolean']['output'];
  /** A fixed mediation fee added to every Direct Payment */
  fee_fixed: Scalars['Float']['output'];
  /** A % of the Direct Payment taken as mediation fee */
  fee_pct: Scalars['Float']['output'];
  /** MAX Total fee for ESTO Pay taken from the payment amount EUR (subtracted from payment) */
  fee_total_max: Scalars['Float']['output'];
  /** MIN Total fee for ESTO Pay taken from the payment amount EUR (subtracted from payment) */
  fee_total_min: Scalars['Float']['output'];
  /** ID of related merchant */
  merchant_id: Scalars['Int']['output'];
  /** Payment provider used for payment processing */
  provider: Scalars['String']['output'];
};

/** Input for setting payment gateway settings for merchant */
export type MerchantDirectPaymentGatewayInput = {
  /** Whether payment gateway enabled for merchant */
  enabled: Scalars['Boolean']['input'];
  /** A fixed mediation fee added to every Direct Payment */
  fee_fixed?: InputMaybe<Scalars['Float']['input']>;
  /** A % of the Direct Payment taken as mediation fee */
  fee_pct?: InputMaybe<Scalars['Float']['input']>;
  /** MAX Total fee for ESTO Pay taken from the payment amount EUR (subtracted from payment) */
  fee_total_max?: InputMaybe<Scalars['Float']['input']>;
  /** MIN Total fee for ESTO Pay taken from the payment amount EUR (subtracted from payment) */
  fee_total_min?: InputMaybe<Scalars['Float']['input']>;
  /** Payment provider used for payment processing */
  provider: Scalars['String']['input'];
};

/** Represents Merchant Discount code */
export type MerchantDiscount = {
  /** Promo code (e.g CHRISTMASSALE) */
  code: Scalars['String']['output'];
  /** Timestamp, when the MerchantDiscount was created */
  created_at: Scalars['String']['output'];
  /** Timestamp, when discount ends */
  end_at?: Maybe<Scalars['String']['output']>;
  /** The id of the MerchantDiscount */
  id: Scalars['Int']['output'];
  /** ID of a merchant */
  merchant_id: Scalars['Int']['output'];
  /** Timestamp, when discount starts */
  start_at: Scalars['String']['output'];
  /** Status of a discount: INACTIVE, ACTIVE, COMPLETED */
  status: Scalars['String']['output'];
  /** Timestamp, when discount was stopped manually */
  stopped_at?: Maybe<Scalars['String']['output']>;
  /** Timestamp, when the MerchantDiscount was updated */
  updated_at: Scalars['String']['output'];
};

/** Invite sent to user to tie it with merchant */
export type MerchantInvite = {
  email: Scalars['String']['output'];
  hash: Scalars['String']['output'];
  merchant_id: Scalars['Int']['output'];
  permission_bits: Scalars['Int']['output'];
};

export type MerchantPayment = {
  merchant_id: Scalars['Int']['output'];
  merchant_name: Scalars['String']['output'];
  /** Total amount required to pay to the merchant */
  total: Scalars['Float']['output'];
};

/** Platform of the merchant's website */
export type MerchantPlatform = {
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  version?: Maybe<Scalars['String']['output']>;
};

/** Detailed info about a merchant's limits and fees */
export type MerchantSettings = {
  /** First variable for the IRR calculation formula */
  a_irr_var?: Maybe<Scalars['Float']['output']>;
  /** After how many hours loan applications will expire after creation */
  application_expiration_hours: Scalars['Int']['output'];
  /** Second variable for the IRR calculation formula */
  b_irr_var?: Maybe<Scalars['Float']['output']>;
  /**
   * Percentage the merchant gets from each application. Can be calculated from either credit income
   *                     or base amount.
   */
  bonus_pct: Scalars['Float']['output'];
  /** The bonus type decides how the bonus is calculated. Either from credit income or base amount */
  bonus_type?: Maybe<MerchantSettingsBonusType>;
  /** The discount percentage which is applied to the application that is being bought back */
  buyback_discount_pct: Scalars['Float']['output'];
  /** The number of days application has to be overdue, after which it is bought back from the merchant */
  buyback_guarantee_days: Scalars['Int']['output'];
  /** Third variable for the IRR calculation formula */
  c_irr_var?: Maybe<Scalars['Float']['output']>;
  /**
   * The merchant is allowed to create loans that have a dynamic loan amount (user can
   *                     change the amount)
   */
  can_create_dynamic_loan: Scalars['Boolean']['output'];
  /** The merchant is allowed to create a small loan */
  can_create_small_loan: Scalars['Boolean']['output'];
  /**
   * Merchant can enable special settings like 0 contract fee, when creating an application.
   *                     Does not work with campaign
   */
  can_enable_special_settings: Scalars['Boolean']['output'];
  /**
   * Show application eligibility status like POSITIVE, NEGATIVE or PENDING for the merchant,
   *                     if the contract is not signed or rejected yet
   */
  can_see_eligibility_status: Scalars['Boolean']['output'];
  /** Percentage that we pay the cashier for issuing loans based on issued credit (without down payment) */
  cashier_bonus_pct: Scalars['Float']['output'];
  /** Max limit for a user that does not require additional review */
  max_amount_id_verification_not_required: Scalars['Float']['output'];
  /** The maximum period for a loan */
  max_months_period: Scalars['Int']['output'];
  /** Percentage of risk/participation that the merchant takes by giving out a lease */
  merchant_financing_pct: Scalars['Float']['output'];
  /** Owner's ID */
  merchant_id: Scalars['Int']['output'];
  /** Min IRR value for Small Loan, if null then the value from the pricing will be used */
  min_irr_small_loan?: Maybe<Scalars['Float']['output']>;
  /** The minimum period for a loan */
  min_months_period: Scalars['Int']['output'];
  /** The maximum loan that the merchant's customers can request */
  net_total_max: Scalars['Float']['output'];
  /** The minimum loan that the merchant's customers can request */
  net_total_min: Scalars['Float']['output'];
  /** The pct of application net total amount merchant gives ESTO as a bonus */
  reverse_kickback_pct: Scalars['Float']['output'];
  /** Percentage the merchant gets from each issued Small Loan */
  small_loan_bonus_pct: Scalars['Float']['output'];
  /** Percentage that we pay the cashier for issuing Small Loans. */
  small_loan_cashier_bonus_pct: Scalars['Float']['output'];
  /** Indicates whether the small loan should be paid to the merchant or directly to the customer. */
  small_loan_is_paid_to_merchant: Scalars['Boolean']['output'];
  /** VAT pct that is used for example for merchant invoice calculation */
  vat_pct: Scalars['Float']['output'];
};

/** Merchant Shopify account details */
export type MerchantShopify = {
  /** Shopify access token */
  access_token: Scalars['String']['output'];
  /** Shopify API key */
  api_key: Scalars['String']['output'];
  /** Shopify API secret */
  api_secret: Scalars['String']['output'];
  /** The original Shopify subdomain URL for API calls */
  api_url: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  merchant_id: Scalars['Int']['output'];
};

/** A retail (physical) store of the merchant */
export type MerchantStore = {
  /** Cashiers working in this store */
  cashiers?: Maybe<Array<Maybe<User>>>;
  id: Scalars['Int']['output'];
  merchant_id: Scalars['Int']['output'];
  /** Name or location of the store */
  name: Scalars['String']['output'];
};

/** Merchant Stripe connect account information */
export type MerchantStripe = {
  /** Id of Stripe Connect Account */
  account_id: Scalars['String']['output'];
  /** Onboarding link for registration of Connect Account */
  account_link_url?: Maybe<Scalars['String']['output']>;
  /** If all capabilities except payouts and charges are enabled */
  capabilities_enabled: Scalars['Boolean']['output'];
  /** If charges (payments) are enabled */
  charges_enabled: Scalars['Boolean']['output'];
  /**
   * It toggles destination payment schema for merchant via Stripe..
   *                                     true - Destination charge, false - Direct charge.
   */
  destination_charge?: Maybe<Scalars['Boolean']['output']>;
  /** Account hash connection to handle Stripe responses */
  hash: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  merchant_id: Scalars['Int']['output'];
  /** If payouts are enabled */
  payouts_enabled: Scalars['Boolean']['output'];
  /** Url for redirect user after onboarding */
  redirect_url?: Maybe<Scalars['String']['output']>;
  /**
   * Refresh url handles request from stripe to update account link url.
   *                                   The account link url is available for 5 minutes.
   */
  refresh_url?: Maybe<Scalars['String']['output']>;
  /**
   * Return url handles request from stripe to complete onboarding process in our system
   *                                   and redirect to need url.
   */
  return_url?: Maybe<Scalars['String']['output']>;
  /** Status of connect account */
  status: Scalars['String']['output'];
};

/** A user connected to a specified merchant */
export type MerchantUser = {
  /** Whether waiting for client to submit account statement or it is pending etc */
  account_statement_eligibility?: Maybe<Scalars['String']['output']>;
  /** Uploaded account statements of the user */
  account_statements?: Maybe<Array<Maybe<AccountStatement>>>;
  /** Account Scoring */
  accountscoring_invitations?: Maybe<Array<Maybe<AccountScoringInvitation>>>;
  /** User's active applications */
  active_applications?: Maybe<Array<Maybe<Application>>>;
  /** Currently active central payment leave for user */
  active_payment_leave?: Maybe<PaymentLeave>;
  /** Active premium subscription for the user */
  active_premium_subscription?: Maybe<FeatureSubscription>;
  /** Whether the user has allowed ESTO to query Estonian pension centre */
  allow_pension_query: Scalars['Boolean']['output'];
  /** User’s risk profile level for anti-money laundering */
  aml_risk_profile?: Maybe<Scalars['String']['output']>;
  /** Total outstanding principal of all user's applications */
  application_outstanding_principal: Scalars['Float']['output'];
  /** Approximate monthly payment that will be paid during payment leave */
  approximate_monthly_payment?: Maybe<Scalars['Float']['output']>;
  /** Amount of credit the user is allowed to spend (user central limit) */
  available_credit?: Maybe<Scalars['Float']['output']>;
  /** Amount of credit the user is allowed to spend for hire purchase */
  available_hp_credit?: Maybe<Scalars['Float']['output']>;
  /** Amount of credit the user is allowed to spend for Small Loan */
  available_sl_credit?: Maybe<Scalars['Float']['output']>;
  /** Disposable income if the best possible values were used. */
  best_disposable_income?: Maybe<Scalars['Float']['output']>;
  /** Determines whether a user can be onboarded to premium account with opt out approach */
  can_be_opt_out_onboarded_to_premium_account: Scalars['Boolean']['output'];
  /** Whether the user has agreed to the conditions */
  conditions_agreement: Scalars['Boolean']['output'];
  /** Timestamp, when the user was created */
  created_at: Scalars['Int']['output'];
  /** Credit accounts that belong to the user */
  credit_accounts?: Maybe<Array<Maybe<CreditAccount>>>;
  /** User's central credit limit for HP + CA */
  credit_limit?: Maybe<Scalars['Float']['output']>;
  /** Credit scores for a user */
  credit_scores?: Maybe<Array<Maybe<UserCreditScore>>>;
  /** The date of birth the of user in YYYY-MM-DD format */
  date_of_birth?: Maybe<Scalars['String']['output']>;
  /** Maximum monthly payment based on income and liabilities. */
  disposable_income?: Maybe<Scalars['Float']['output']>;
  /**
   * Maximum monthly payment based on income and liabilities WITHOUT the active CA monthly payment.
   *                     NB! Only request this variable when completely necessary as it is a heavy calculation.
   */
  disposable_income_without_ca?: Maybe<Scalars['Float']['output']>;
  /** An identifying document of the user */
  document?: Maybe<UserDocument>;
  /** Debt service coverage ratio (For Estonia). Ratio of user income and lease payments */
  dscr?: Maybe<Scalars['Float']['output']>;
  /** Debt service coverage ratio (For Lithuania). Ratio of lease payments and user income */
  dsti?: Maybe<Scalars['Float']['output']>;
  /** ELIGIBLE or reason why not eligible */
  eligibility_status?: Maybe<Scalars['String']['output']>;
  /** Email of the user */
  email?: Maybe<Scalars['String']['output']>;
  /** A request to RIA for getting access to info from EMTA (Estonian Tax Agency) */
  emta_consent?: Maybe<RiaConsent>;
  /** A saved card used for EveryPay payment */
  everypay_cards?: Maybe<Array<Maybe<EverypayUserCard>>>;
  /** How much credit user can take for Hire Purchase */
  hp_credit_limit?: Maybe<Scalars['Float']['output']>;
  /** ID of the user */
  id: Scalars['Int']['output'];
  /** By which method the user's identity was verified */
  id_verification_method?: Maybe<Scalars['String']['output']>;
  /** Instantor responses with OK status for the user */
  instantor_responses?: Maybe<Array<Maybe<InstantorResponse>>>;
  /** Identifier for the user that is used for payments */
  invoice_reference_nr: Scalars['String']['output'];
  /** Check if the user has any overdue loans or credit accounts */
  is_overdue: Scalars['Boolean']['output'];
  /** Whether password is set for user or not */
  is_password_set: Scalars['Boolean']['output'];
  /** Language that the user is currently using */
  language_abbr: Scalars['String']['output'];
  /** Timestamp of user's last login */
  last_login?: Maybe<Scalars['Int']['output']>;
  /** Date, when the user promised to return the money */
  latest_promised_repayment_date?: Maybe<Scalars['String']['output']>;
  /** The latest actually used credit score */
  latest_user_credit_score?: Maybe<UserCreditScore>;
  /** Legal people (businesses) that belong to the user */
  legal_people?: Maybe<Array<Maybe<LegalPerson>>>;
  /** Get the latest legal people tied to the user */
  legal_people_active?: Maybe<Array<Maybe<LegalPerson>>>;
  /** Marital status: SINGLE, DIVORCED, WIDOWED, MARRIED, NA */
  marital_status: MaritalStatus;
  /** All permission bits that the user has for a specified merchant */
  merchant_permission_bits: Scalars['Int']['output'];
  /** Stores that belong to this merchant, where the user is a cashier */
  merchant_stores?: Maybe<Array<Maybe<MerchantStore>>>;
  /** Stores that the user owns */
  merchants?: Maybe<Array<Maybe<UserMerchant>>>;
  /** Emails and SMS sent to the user */
  messages_sent?: Maybe<Array<Maybe<UserMessageSent>>>;
  /** Whether the user has agreed to the newsletter agreement */
  newsletter_agreement: Scalars['Boolean']['output'];
  /**
   * Next monthly payment for the client,
   *                                   sum of HP and CA invoices amounts that client should pay for current month
   */
  next_invoice_amount: Scalars['Float']['output'];
  /**
   * The date of the oldest overdue or upcoming payment for the client's loans (HP or CA).
   *                     Null, if there is nothing to pay for any more.
   */
  next_payment_date?: Maybe<Scalars['String']['output']>;
  /** How many days the user is overdue. 0 if user is not overdue. */
  overdue_days?: Maybe<Scalars['Int']['output']>;
  overflow: Scalars['Float']['output'];
  /** Queries about the defaulted user payments */
  payment_default_queries?: Maybe<Array<Maybe<PaymentDefaultQuery>>>;
  /** All historical and active user-central payment leaves */
  payment_leaves?: Maybe<Array<Maybe<PaymentLeave>>>;
  /** URL, where the client can pay all of their debt */
  payment_url: Scalars['String']['output'];
  /** Currently pending central payment leave */
  pending_payment_leave?: Maybe<PaymentLeave>;
  /** All permission bits that the user has */
  permission_bits: Scalars['Int']['output'];
  /** Phone number of the user */
  phone?: Maybe<Scalars['String']['output']>;
  /** Area code of the phone */
  phone_area_code?: Maybe<Scalars['String']['output']>;
  /** Personal Identificaton Number (SSN) of the user */
  pin?: Maybe<Scalars['String']['output']>;
  /** Whether the user is politically exposed and to which degree */
  political_exposure?: Maybe<PoliticalExposure>;
  /** Current status of user premium subscription */
  premium_subscription_status: Scalars['String']['output'];
  /** History of user's premium subscriptions */
  premium_subscriptions?: Maybe<Array<Maybe<FeatureSubscription>>>;
  /** Profile of the user */
  profile?: Maybe<UserProfile>;
  /** User's latest invoice */
  recent_invoice?: Maybe<Invoice>;
  /** Unique reference (long string) for the user */
  reference_key: Scalars['String']['output'];
  resale?: Maybe<Resale>;
  /** Availability of the user's retirement fund */
  retirement_fund?: Maybe<UserRetirementFund>;
  /** A request of proceeds from the Estonian Retirement Fund */
  retirement_fund_proceeds_requests?: Maybe<Array<Maybe<RetirementFundProceedsRequest>>>;
  /** Which emails the employee should receive pertinent to the merchant */
  send_emails: Scalars['Int']['output'];
  /** How the user signed in to the current session */
  sign_in_method?: Maybe<MerchantUsersignInMethod>;
  /** Credit accounts that have been signed and belong to the user */
  signed_credit_accounts?: Maybe<Array<Maybe<CreditAccount>>>;
  /** Legal person eligibility status that is either positive, negative or pending */
  simple_eligibility_status?: Maybe<Scalars['String']['output']>;
  /** How much credit user can take for Small Loan */
  sl_credit_limit?: Maybe<Scalars['Float']['output']>;
  /** Total outstanding principal of all user's Small Loan applications */
  small_loan_outstanding_principal: Scalars['Float']['output'];
  /** User's central subscription by which we retrieve money from their credit card for payment */
  subscription?: Maybe<Subscription>;
  /**
   * Total current unpaid amount for the client,
   *                                   sum of all unpaid HP invoices and last unpaid CA invoice amounts that client need to pay right now
   */
  unpaid_invoice_amount: Scalars['Float']['output'];
  /** User manual incomes liabilities group that belong to the user */
  user_manual_incomes_liabilities_groups?: Maybe<Array<Maybe<UserManualIncomesLiabilitiesGroup>>>;
  /** Username for login purposes, usually defaults to PIN */
  username?: Maybe<Scalars['String']['output']>;
};


/** A user connected to a specified merchant */
export type MerchantUserMerchantStoresArgs = {
  merchant_id?: InputMaybe<Scalars['Int']['input']>;
};

export enum MerchantUsersignInMethod {
  EPARAKSTS = 'eparaksts',
  HASH = 'hash',
  ID_CARD = 'id_card',
  MAGIC_LINK = 'magic_link',
  MOBILE = 'mobile',
  PASSWORD = 'password',
  PAYSERA_BANKLINK = 'paysera_banklink',
  SMART_ID = 'smart_id'
}

/** Get information about Mintos Forward Flow maturity limit for month. */
export type MintosForwardFlowMaturityMonthlyLimit = {
  /** Final maturity month for Forward Flow. */
  final_maturity_date?: Maybe<Scalars['String']['output']>;
  /** Pledged amount for Forward Flow. */
  pledged_amount?: Maybe<Scalars['Float']['output']>;
};

/** Get information about Mintos Forward Flow maturity for month limits. */
export type MintosForwardFlowMaturityMonthlyLimits = {
  /** Final maturity monthly for Forward Flow. */
  maturity_monthly_limits?: Maybe<Array<Maybe<MintosForwardFlowMaturityMonthlyLimit>>>;
  /** Time when amount was received from db */
  retrieved_at?: Maybe<Scalars['Int']['output']>;
};

/** Get information about Mintos Forward Flow param. */
export type MintosForwardFlowParam = {
  /** Is Mintos Forward Flow enabled. */
  mintos_forward_flow_enabled?: Maybe<Scalars['Boolean']['output']>;
  /** The maximum possible maturity date is provided by mintos for forward flow loans. */
  mintos_forward_flow_max_allowed_maturity?: Maybe<Scalars['Int']['output']>;
};

export type Mutation = {
  /** Add a cashier to a merchant's (retail) store */
  add_cashier_to_store: Scalars['Boolean']['output'];
  /** Apply for payment leave. This does not yet enable payment leave, it just returns whether payment leave is possible for this product. */
  apply_for_payment_leave?: Maybe<PaymentLeaveDecision>;
  /** Apply for user central payment leave. This has not yet enabled payment leave, it just checks if the customer is eligible for central payment leave. */
  apply_for_user_payment_leave?: Maybe<PaymentLeaveDecision>;
  /** Create a merchant user and update the permission bits */
  attach_user_from_invite: Scalars['Boolean']['output'];
  auth_hash_login?: Maybe<Scalars['Boolean']['output']>;
  cancel_application_subscription?: Maybe<Application>;
  cancel_credit_account_subscription?: Maybe<CreditAccount>;
  cancel_user_subscription?: Maybe<User>;
  /** Create an application as a cashier that the user will be redirected to */
  cashier_application?: Maybe<Application>;
  /** Track events to CDP */
  cdp_track?: Maybe<Scalars['Boolean']['output']>;
  /** Converts an application that has converting schedule enabled to a regular application */
  convert_converting_schedule_to_regular?: Maybe<Application>;
  /** Converts a regular application that was previously converting schedule back to converting schedule */
  convert_regular_to_converting_schedule?: Maybe<Application>;
  /** Create a request to refund the Direct payment */
  create_direct_payment_refund_request?: Maybe<DirectPaymentRefund>;
  create_feature_subscription: Scalars['Boolean']['output'];
  /** Start new Klix payment */
  create_klix_banklink_payment?: Maybe<KlixPayment>;
  /** Create new application with the same initial data */
  create_new_application_from_existing_application?: Maybe<Application>;
  /** Soft delete the cancellation request */
  delete_application_cancellation_request: Scalars['Boolean']['output'];
  /** Soft delete the modification request */
  delete_application_modification_request: Scalars['Boolean']['output'];
  /** Remove the given store from the merchant */
  delete_merchant_store: Scalars['Boolean']['output'];
  /** Delete the specified Everypay card that the customer has */
  destroy_user_card: Scalars['Boolean']['output'];
  /** Enables subscription for customer by token */
  enable_subscription_by_token: Scalars['Boolean']['output'];
  /** Initiate the eParaksts authentication session with the user redirect. */
  eparaksts_login_challenge?: Maybe<EparakstsRedirect>;
  /** Start signing a contract by eParaksts */
  eparaksts_prepare_signature?: Maybe<LoginChallenge>;
  /** Sign the given application's, credit account or user contract with eParaksts */
  eparaksts_sign_contract: Scalars['Boolean']['output'];
  /** Forwards loans (e.g. Small Loan) to another Broker. */
  forward_loan: Scalars['String']['output'];
  generate_secret_key: Scalars['String']['output'];
  /** Attach a user with the given merchant */
  handle_user_merchant: Scalars['Boolean']['output'];
  /** Start preparing the signature for a document with an ID card */
  id_prepare_signature?: Maybe<DokobitSignature>;
  /** Sign a contract (by ID card) that has been prepared */
  id_sign_contract: Scalars['Boolean']['output'];
  logout?: Maybe<Scalars['Boolean']['output']>;
  /** Log in with magic token. Magic token contains signed payload with user pin. Once token signature verified user can log in. */
  magic_login?: Maybe<Scalars['Boolean']['output']>;
  merchant_contact: Scalars['Boolean']['output'];
  /** Update an application that is owned by the merchant */
  merchant_update_application?: Maybe<Application>;
  /**
   * Allows merchant users update their first and last names.
   *                           This mutation extends update_user mutation.
   */
  merchant_update_user?: Maybe<User>;
  /** Get a challenge code from DigiDoc for the specified phone nr */
  mobile_login_challenge?: Maybe<LoginChallenge>;
  /** Start signing a contract by mobile ID */
  mobile_prepare_signature?: Maybe<LoginChallenge>;
  /** Sign the given application's, credit account or user contract with mobile ID */
  mobile_sign_contract: Scalars['Boolean']['output'];
  /** Log in with username and password */
  password_login?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Sign a HP or CA application without using DigiDoc or other auth methods.
   *             NOTE: Currently can only sign one file at a time.
   *             NOTE2: It is only available for real signing, if pricing settings are enabled for the product type
   */
  password_sign_contract?: Maybe<Scalars['Boolean']['output']>;
  /** Update user email. It'll be used for sending magic link and stored in user profile */
  paysera_email_login?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Initiate the Paysera authentication session with the user redirect to bank. After
   *             creating this start polling PayseraLoginQuery
   */
  paysera_login_challenge?: Maybe<BanklinkRedirect>;
  /** Start signing a contract by Paysera */
  paysera_prepare_signature?: Maybe<LoginChallenge>;
  /** Log the current action made in purchase flow */
  purchase_flow_application_action_log?: Maybe<Scalars['Boolean']['output']>;
  /** Log the current action made in purchase flow */
  purchase_flow_credit_account_action_log?: Maybe<Scalars['Boolean']['output']>;
  /** Removes the cashier from merchant's retail store */
  remove_cashier_from_store: Scalars['Boolean']['output'];
  /** Report a custom error from frontend */
  report_error?: Maybe<Scalars['Boolean']['output']>;
  /** Request credit account limit recalculation with MODIFICATION type. */
  request_credit_account_modification?: Maybe<CreditAccountLimitRecalculation>;
  /**
   * Request credit account limit recalculation with LIMIT_INCREASE type. If the limit increase
   *             can be requested, then CreditAccountLimitRecalculation instance will be returned.
   */
  request_credit_limit_increase?: Maybe<CreditAccountLimitRecalculation>;
  /** Reset user password using previously generated token */
  reset_password: Scalars['Boolean']['output'];
  /** Send email or sms or both to spouse with submission link. Also updates spouse info */
  send_consent_link_to_spouse?: Maybe<Scalars['Boolean']['output']>;
  /** Sends a token to verify an email address. */
  send_for_verification: Scalars['Boolean']['output'];
  /** Send email containing merchant user invite */
  send_merchant_invite: Scalars['Boolean']['output'];
  /** Sends password reset link to the provided email */
  send_password_reset_email: Scalars['Boolean']['output'];
  /** Send purchase url to the user */
  send_purchase_url: Scalars['Boolean']['output'];
  /**
   * Initiate the Smart-ID authentication session with the user and get the challenge code
   *             and session ID.
   */
  smart_id_login_challenge?: Maybe<LoginChallenge>;
  /** Start signing a contract by Smart-ID */
  smart_id_prepare_signature?: Maybe<LoginChallenge>;
  /** Sign the given application's, credit account or user contract with Smart-ID */
  smart_id_sign_contract: Scalars['Boolean']['output'];
  /** Start a recurring payment for an application with a given payment method */
  start_application_subscription?: Maybe<Application>;
  /** Start a recurring payment for a credit account */
  start_credit_account_subscription?: Maybe<CreditAccount>;
  /** Get new EveryPay transaction fields for a user */
  start_everypay_payment?: Maybe<EverypayFields>;
  /** Start a recurring payment for User with a given payment method */
  start_user_subscription?: Maybe<User>;
  store_accountscoring_invitation?: Maybe<AccountScoringInvitation>;
  /** Creates a new cancellation request for an application */
  store_application_cancellation_request?: Maybe<ApplicationCancellationRequest>;
  /** Creates a new modification request for an application */
  store_application_modification_request?: Maybe<ApplicationModificationRequest>;
  /** Create an ESTO credit account for the user, if they do not already have one */
  store_credit_account?: Maybe<CreditAccount>;
  /** Record, if FAQ answer was helpful or not */
  store_faq_vote: Scalars['Boolean']['output'];
  /** Store Firebase Cloud Messaging (FCM) token for user device to send push notifications */
  store_fcm_token: Scalars['Boolean']['output'];
  store_merchant_store?: Maybe<MerchantStore>;
  /** Store newsletter consent for user */
  store_newsletter_consent?: Maybe<User>;
  /** Create a small loan application. Currently used only to create a test Small Loan for demo checkout. */
  store_small_loan?: Maybe<Application>;
  store_user_from_merchant_invite: Scalars['Boolean']['output'];
  toggle_subscription: Scalars['Boolean']['output'];
  unsubscribe_from_feature_subscription: Scalars['Boolean']['output'];
  update_application?: Maybe<Application>;
  update_application_campaign?: Maybe<ApplicationCampaign>;
  update_application_credit?: Maybe<Application>;
  update_application_legal_person?: Maybe<Application>;
  /** Update info about spouse for the application */
  update_application_spouse_info?: Maybe<Application>;
  update_application_user?: Maybe<Application>;
  /** Update extra contact info about user that is dynamic per region */
  update_application_user_extra?: Maybe<Application>;
  /** Update the application user info of the product */
  update_application_user_info?: Maybe<ApplicationUserInfo>;
  /** Update the application user info extra contact info of the product. */
  update_application_user_info_extra?: Maybe<ApplicationUserInfo>;
  /** Update the spouse info of application user info of the product */
  update_application_user_info_spouse?: Maybe<ApplicationUserInfo>;
  update_credit_account?: Maybe<CreditAccount>;
  /**
   * Update credit account limit recalculation. The instance can be updated until it has a final status,
   *              i.e. until it is accepted by the user or rejected by the system.
   */
  update_credit_account_limit_recalculation?: Maybe<CreditAccountLimitRecalculation>;
  /** Update user spouse info for the credit account */
  update_credit_account_spouse_info?: Maybe<User>;
  /** Update the attributes required for creating a credit account */
  update_credit_account_user?: Maybe<User>;
  /** Update or create the user document */
  update_credit_account_user_document?: Maybe<UserDocument>;
  /** Update extra contact info about user that is dynamic per region */
  update_credit_account_user_extra?: Maybe<User>;
  /** Update basic attributes on the specified merchant */
  update_merchant?: Maybe<Merchant>;
  /** Update the calculator skin colors that is used by the merchant */
  update_merchant_calculator_skin?: Maybe<MerchantCalculatorSkin>;
  /** It enables or disables payment methods for merchant */
  update_merchant_payment_methods?: Maybe<MerchantCampaign>;
  update_merchant_store?: Maybe<MerchantStore>;
  /** Update the permissions of a user for a given merchant */
  update_merchant_user_permissions: Scalars['Boolean']['output'];
  /** Update whether user is sent email notifications. */
  update_merchant_user_send_emails: Scalars['Boolean']['output'];
  /** Update the password on the given user. Admins can update the password without providing the old password */
  update_password: Scalars['Boolean']['output'];
  /** Update payment leave fallible fields */
  update_payment_leave?: Maybe<PaymentLeave>;
  /** Update most attributes on a user */
  update_user?: Maybe<User>;
  update_user_language: Scalars['Boolean']['output'];
  /** User agrees to the terms and conditions. NB! This should only be used in Purchase Flow */
  update_user_terms: Scalars['Boolean']['output'];
  /** Uploading account statements (e.g. Accountscoring, manual) */
  upload_account_statement?: Maybe<AccountStatement>;
  /** Records customer's attempt to get ESTO Switch and uploads customer bank statement (if available) */
  upload_switch_transaction?: Maybe<Scalars['Boolean']['output']>;
  user_contact: Scalars['Boolean']['output'];
  waive_transaction?: Maybe<Transaction>;
  /** Withdraw cash from credit account to bank account */
  withdraw_from_credit_account?: Maybe<CreditAccountWithdrawal>;
};


export type MutationAddCashierToStoreArgs = {
  store_id: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};


export type MutationApplyForPaymentLeaveArgs = {
  application_id?: InputMaybe<Scalars['Int']['input']>;
  credit_account_id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationApplyForUserPaymentLeaveArgs = {
  user_id: Scalars['Int']['input'];
};


export type MutationAttachUserFromInviteArgs = {
  invite_hash: Scalars['String']['input'];
  user_id: Scalars['Int']['input'];
};


export type MutationAuthHashLoginArgs = {
  hash: Scalars['String']['input'];
};


export type MutationCancelApplicationSubscriptionArgs = {
  application_subscription_id: Scalars['Int']['input'];
};


export type MutationCancelCreditAccountSubscriptionArgs = {
  credit_account_subscription_id: Scalars['Int']['input'];
};


export type MutationCancelUserSubscriptionArgs = {
  subscription_id: Scalars['Int']['input'];
};


export type MutationCashierApplicationArgs = {
  amount: Scalars['Float']['input'];
  document_nr?: InputMaybe<Scalars['String']['input']>;
  document_type?: InputMaybe<UserDocumentType>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  from_retail: Scalars['Boolean']['input'];
  is_test?: InputMaybe<Scalars['Boolean']['input']>;
  language_abbr?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  merchant_down_payment?: InputMaybe<Scalars['Float']['input']>;
  merchant_id: Scalars['Int']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pin?: InputMaybe<Scalars['String']['input']>;
  reference: Scalars['String']['input'];
  schedule_type?: InputMaybe<ApplicationScheduleType>;
  signing_method?: InputMaybe<SigningMethod>;
  special_settings: CashierApplicationSpecialSettings;
  store_id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationCdpTrackArgs = {
  context?: InputMaybe<ContextInput>;
  event_name: Scalars['String']['input'];
  properties: Scalars['String']['input'];
};


export type MutationConvertConvertingScheduleToRegularArgs = {
  application_id: Scalars['Int']['input'];
};


export type MutationConvertRegularToConvertingScheduleArgs = {
  application_id: Scalars['Int']['input'];
};


export type MutationCreateDirectPaymentRefundRequestArgs = {
  amount: Scalars['Float']['input'];
  application_id: Scalars['Int']['input'];
  payment_provider: Scalars['String']['input'];
};


export type MutationCreateFeatureSubscriptionArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  owner_id: Scalars['Int']['input'];
  subscription_fee?: InputMaybe<Scalars['Float']['input']>;
  subscription_type: FeatureSubscriptionType;
};


export type MutationCreateKlixBanklinkPaymentArgs = {
  amount: Scalars['Float']['input'];
  application_id?: InputMaybe<Scalars['Int']['input']>;
  credit_account_id?: InputMaybe<Scalars['Int']['input']>;
  payment_category?: InputMaybe<PaymentCategoryType>;
  payment_method_key?: InputMaybe<Scalars['String']['input']>;
  redirect_url: Scalars['String']['input'];
  user_id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationCreateNewApplicationFromExistingApplicationArgs = {
  application_id: Scalars['Int']['input'];
};


export type MutationDeleteApplicationCancellationRequestArgs = {
  request_id: Scalars['Int']['input'];
};


export type MutationDeleteApplicationModificationRequestArgs = {
  request_id: Scalars['Int']['input'];
};


export type MutationDeleteMerchantStoreArgs = {
  store_id: Scalars['Int']['input'];
};


export type MutationDestroyUserCardArgs = {
  everypay_card_id: Scalars['Int']['input'];
};


export type MutationEnableSubscriptionByTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationEparakstsLoginChallengeArgs = {
  method?: InputMaybe<EparakstsAuthorizationMethod>;
  return_url: Scalars['String']['input'];
};


export type MutationEparakstsPrepareSignatureArgs = {
  application_id?: InputMaybe<Scalars['Int']['input']>;
  contract_type?: InputMaybe<ContractType>;
  credit_account_id?: InputMaybe<Scalars['Int']['input']>;
  return_url: Scalars['String']['input'];
  user_id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationEparakstsSignContractArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
};


export type MutationForwardLoanArgs = {
  application_id?: InputMaybe<Scalars['Int']['input']>;
  credit_account_id?: InputMaybe<Scalars['Int']['input']>;
  merchant_id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationGenerateSecretKeyArgs = {
  merchant_id: Scalars['Int']['input'];
};


export type MutationHandleUserMerchantArgs = {
  attach: Scalars['Boolean']['input'];
  merchant_id: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};


export type MutationIdPrepareSignatureArgs = {
  application_id?: InputMaybe<Scalars['Int']['input']>;
  certificate: Scalars['String']['input'];
  contract_type?: InputMaybe<ContractType>;
  credit_account_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationIdSignContractArgs = {
  signature: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationMagicLoginArgs = {
  token: Scalars['String']['input'];
};


export type MutationMerchantContactArgs = {
  email: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  website: Scalars['String']['input'];
};


export type MutationMerchantUpdateApplicationArgs = {
  application_id: Scalars['Int']['input'];
  reference: Scalars['String']['input'];
};


export type MutationMerchantUpdateUserArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  allow_pension_query?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  conditions_agreement?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employer?: InputMaybe<Scalars['String']['input']>;
  employment_date?: InputMaybe<Scalars['String']['input']>;
  employment_sector?: InputMaybe<EmploymentSector>;
  expenditure_consumer_loan_monthly?: InputMaybe<Scalars['Float']['input']>;
  expenditure_monthly?: InputMaybe<Scalars['Float']['input']>;
  extra_income?: InputMaybe<Scalars['Float']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  future_reduced_earnings?: InputMaybe<Scalars['Float']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  language_abbr?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  monthly_living_expenses?: InputMaybe<Scalars['Float']['input']>;
  net_income_monthly?: InputMaybe<Scalars['Float']['input']>;
  newsletter_agreement?: InputMaybe<Scalars['Boolean']['input']>;
  number_of_dependents?: InputMaybe<Scalars['Int']['input']>;
  occupation_category?: InputMaybe<OccupationCategory>;
  overdue_debt?: InputMaybe<Scalars['Float']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phone_area_code?: InputMaybe<Scalars['String']['input']>;
  planning_new_debts?: InputMaybe<Scalars['Float']['input']>;
  political_exposure?: InputMaybe<PoliticalExposure>;
  post_code?: InputMaybe<Scalars['String']['input']>;
  ultimate_beneficial_owner?: InputMaybe<Scalars['Boolean']['input']>;
  user_id: Scalars['Int']['input'];
};


export type MutationMobileLoginChallengeArgs = {
  invite?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  pin: Scalars['String']['input'];
};


export type MutationMobilePrepareSignatureArgs = {
  application_id?: InputMaybe<Scalars['Int']['input']>;
  contract_type?: InputMaybe<ContractType>;
  credit_account_id?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationMobileSignContractArgs = {
  phone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationPasswordLoginArgs = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationPasswordSignContractArgs = {
  application_id?: InputMaybe<Scalars['Int']['input']>;
  contract_type?: InputMaybe<ContractType>;
  credit_account_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationPayseraEmailLoginArgs = {
  email: Scalars['String']['input'];
  session_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationPayseraLoginChallengeArgs = {
  accept_url?: InputMaybe<Scalars['String']['input']>;
  application_reference?: InputMaybe<Scalars['String']['input']>;
  cancel_url?: InputMaybe<Scalars['String']['input']>;
  invite?: InputMaybe<Scalars['String']['input']>;
  magic_login_url?: InputMaybe<Scalars['String']['input']>;
  payment_method_key: Scalars['String']['input'];
  pin: Scalars['String']['input'];
};


export type MutationPayseraPrepareSignatureArgs = {
  accept_url?: InputMaybe<Scalars['String']['input']>;
  application_id?: InputMaybe<Scalars['Int']['input']>;
  cancel_url?: InputMaybe<Scalars['String']['input']>;
  contract_type?: InputMaybe<ContractType>;
  credit_account_id?: InputMaybe<Scalars['Int']['input']>;
  payment_method_key: Scalars['String']['input'];
  user_id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationPurchaseFlowApplicationActionLogArgs = {
  action: Scalars['String']['input'];
  application_id: Scalars['Int']['input'];
  data?: InputMaybe<Scalars['String']['input']>;
};


export type MutationPurchaseFlowCreditAccountActionLogArgs = {
  action: Scalars['String']['input'];
  credit_account_id: Scalars['Int']['input'];
  data?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRemoveCashierFromStoreArgs = {
  store_id: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};


export type MutationReportErrorArgs = {
  data: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationRequestCreditAccountModificationArgs = {
  credit_account_id: Scalars['Int']['input'];
};


export type MutationRequestCreditLimitIncreaseArgs = {
  credit_account_id: Scalars['Int']['input'];
};


export type MutationResetPasswordArgs = {
  confirm_password: Scalars['String']['input'];
  new_password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationSendConsentLinkToSpouseArgs = {
  application_id?: InputMaybe<Scalars['Int']['input']>;
  credit_account_id?: InputMaybe<Scalars['Int']['input']>;
  spouse_email?: InputMaybe<Scalars['String']['input']>;
  spouse_phone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSendForVerificationArgs = {
  email: Scalars['String']['input'];
};


export type MutationSendMerchantInviteArgs = {
  email: Scalars['String']['input'];
  merchant_id: Scalars['Int']['input'];
  permission_bits: Scalars['Int']['input'];
};


export type MutationSendPasswordResetEmailArgs = {
  email: Scalars['String']['input'];
};


export type MutationSendPurchaseUrlArgs = {
  application_id: Scalars['Int']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<UserMessageSentType>;
};


export type MutationSmartIdLoginChallengeArgs = {
  invite?: InputMaybe<Scalars['String']['input']>;
  pin: Scalars['String']['input'];
};


export type MutationSmartIdPrepareSignatureArgs = {
  application_id?: InputMaybe<Scalars['Int']['input']>;
  contract_type?: InputMaybe<ContractType>;
  credit_account_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationStartApplicationSubscriptionArgs = {
  application_id: Scalars['Int']['input'];
  everypay_card_id: Scalars['Int']['input'];
};


export type MutationStartCreditAccountSubscriptionArgs = {
  credit_account_id: Scalars['Int']['input'];
  everypay_card_id: Scalars['Int']['input'];
};


export type MutationStartEverypayPaymentArgs = {
  amount: Scalars['Float']['input'];
  application_id?: InputMaybe<Scalars['Int']['input']>;
  cc_token?: InputMaybe<Scalars['String']['input']>;
  credit_account_id?: InputMaybe<Scalars['Int']['input']>;
  payment_category?: InputMaybe<PaymentCategoryType>;
  redirect_url: Scalars['String']['input'];
  request_cc_token?: InputMaybe<Scalars['Boolean']['input']>;
  transaction_type?: InputMaybe<EverypayPaymentTransactionType>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationStartUserSubscriptionArgs = {
  everypay_card_id: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};


export type MutationStoreAccountscoringInvitationArgs = {
  application_id?: InputMaybe<Scalars['Int']['input']>;
  credit_account_id?: InputMaybe<Scalars['Int']['input']>;
  language: Scalars['String']['input'];
  redirect_url?: InputMaybe<Scalars['String']['input']>;
  user_email: Scalars['String']['input'];
  user_id: Scalars['Int']['input'];
  user_name: Scalars['String']['input'];
};


export type MutationStoreApplicationCancellationRequestArgs = {
  application_id: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
};


export type MutationStoreApplicationModificationRequestArgs = {
  application_id: Scalars['Int']['input'];
  new_requested_amount: Scalars['Float']['input'];
  reason: Scalars['String']['input'];
};


export type MutationStoreCreditAccountArgs = {
  landed_from?: InputMaybe<Scalars['String']['input']>;
  onboarding_campaign?: InputMaybe<CreditAccountOnboardingCampaignType>;
  user_id: Scalars['Int']['input'];
};


export type MutationStoreFaqVoteArgs = {
  sub_id: Scalars['Int']['input'];
  was_helpful: Scalars['Boolean']['input'];
};


export type MutationStoreFcmTokenArgs = {
  fcm_token: Scalars['String']['input'];
  old_fcm_token?: InputMaybe<Scalars['String']['input']>;
  user_id: Scalars['Int']['input'];
};


export type MutationStoreMerchantStoreArgs = {
  merchant_id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};


export type MutationStoreNewsletterConsentArgs = {
  email: Scalars['String']['input'];
  source: UserCreationSourceType;
};


export type MutationStoreSmallLoanArgs = {
  created_from?: InputMaybe<Scalars['String']['input']>;
  is_test: Scalars['Boolean']['input'];
  period_months?: InputMaybe<Scalars['Int']['input']>;
  requested_amount: Scalars['Float']['input'];
  schedule_type?: InputMaybe<ApplicationScheduleType>;
};


export type MutationStoreUserFromMerchantInviteArgs = {
  confirm_password: Scalars['String']['input'];
  email: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  invite_hash: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  pin: Scalars['String']['input'];
};


export type MutationToggleSubscriptionArgs = {
  subscribe: Scalars['Boolean']['input'];
  user_reference_key: Scalars['String']['input'];
};


export type MutationUnsubscribeFromFeatureSubscriptionArgs = {
  owner_id: Scalars['Int']['input'];
  subscription_type: FeatureSubscriptionType;
};


export type MutationUpdateApplicationArgs = {
  add_legal_person_to_invoice?: InputMaybe<Scalars['Boolean']['input']>;
  application_id: Scalars['Int']['input'];
  for_private_person: Scalars['Boolean']['input'];
  is_instant_payout?: InputMaybe<Scalars['Boolean']['input']>;
  legal_person_id?: InputMaybe<Scalars['Int']['input']>;
  period_months?: InputMaybe<Scalars['Int']['input']>;
  reference_key?: InputMaybe<Scalars['String']['input']>;
  schedule_type: ApplicationScheduleType;
};


export type MutationUpdateApplicationCampaignArgs = {
  application_id: Scalars['Int']['input'];
  payment_leave_enabled: Scalars['Boolean']['input'];
  reference_key?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateApplicationCreditArgs = {
  accept_conversion_offer?: InputMaybe<Scalars['Boolean']['input']>;
  application_id: Scalars['Int']['input'];
  down_payment?: InputMaybe<Scalars['Float']['input']>;
  net_total?: InputMaybe<Scalars['Float']['input']>;
  period_months: Scalars['Int']['input'];
  reference_key?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateApplicationLegalPersonArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  application_id: Scalars['Int']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  legal_person_id: Scalars['Int']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  phone_area_code?: InputMaybe<Scalars['String']['input']>;
  reference_key?: InputMaybe<Scalars['String']['input']>;
  reject_when_necessary?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateApplicationSpouseInfoArgs = {
  application_id: Scalars['Int']['input'];
  reference_key?: InputMaybe<Scalars['String']['input']>;
  reject_when_necessary?: InputMaybe<Scalars['Boolean']['input']>;
  spouse_email?: InputMaybe<Scalars['String']['input']>;
  spouse_employment_date?: InputMaybe<Scalars['String']['input']>;
  spouse_expenditure_consumer_loan_monthly?: InputMaybe<Scalars['Float']['input']>;
  spouse_expenditure_monthly?: InputMaybe<Scalars['Float']['input']>;
  spouse_extra_income?: InputMaybe<Scalars['Float']['input']>;
  spouse_first_name?: InputMaybe<Scalars['String']['input']>;
  spouse_future_reduced_earnings?: InputMaybe<Scalars['Float']['input']>;
  spouse_last_name?: InputMaybe<Scalars['String']['input']>;
  spouse_monthly_living_expenses?: InputMaybe<Scalars['Float']['input']>;
  spouse_net_income_monthly?: InputMaybe<Scalars['Float']['input']>;
  spouse_overdue_debt?: InputMaybe<Scalars['Float']['input']>;
  spouse_phone?: InputMaybe<Scalars['String']['input']>;
  spouse_pin?: InputMaybe<Scalars['String']['input']>;
  spouse_planning_new_debts?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationUpdateApplicationUserArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  application_id: Scalars['Int']['input'];
  city?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  post_code?: InputMaybe<Scalars['String']['input']>;
  purpose_of_loan?: InputMaybe<PurposeOfLoanType>;
  reference_key?: InputMaybe<Scalars['String']['input']>;
  reject_when_necessary?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateApplicationUserExtraArgs = {
  application_id: Scalars['Int']['input'];
  employer?: InputMaybe<Scalars['String']['input']>;
  employment_date?: InputMaybe<Scalars['String']['input']>;
  employment_sector?: InputMaybe<EmploymentSector>;
  expenditure_consumer_loan_monthly?: InputMaybe<Scalars['Float']['input']>;
  expenditure_monthly?: InputMaybe<Scalars['Float']['input']>;
  extra_income?: InputMaybe<Scalars['Float']['input']>;
  future_reduced_earnings?: InputMaybe<Scalars['Float']['input']>;
  monthly_living_expenses?: InputMaybe<Scalars['Float']['input']>;
  net_income_monthly?: InputMaybe<Scalars['Float']['input']>;
  number_of_dependents?: InputMaybe<Scalars['Int']['input']>;
  occupation_category?: InputMaybe<OccupationCategory>;
  planning_new_debts?: InputMaybe<Scalars['Float']['input']>;
  reference_key?: InputMaybe<Scalars['String']['input']>;
  reject_when_necessary?: InputMaybe<Scalars['Boolean']['input']>;
  spouse_phone?: InputMaybe<Scalars['String']['input']>;
  ultimate_beneficial_owner?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateApplicationUserInfoArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  application_user_info_id: Scalars['Int']['input'];
  city?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  post_code?: InputMaybe<Scalars['String']['input']>;
  purpose_of_loan?: InputMaybe<PurposeOfLoanType>;
  reject_when_necessary?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateApplicationUserInfoExtraArgs = {
  application_user_info_id: Scalars['Int']['input'];
  employer?: InputMaybe<Scalars['String']['input']>;
  employment_date?: InputMaybe<Scalars['String']['input']>;
  employment_sector?: InputMaybe<EmploymentSector>;
  expenditure_consumer_loan_monthly?: InputMaybe<Scalars['Float']['input']>;
  expenditure_monthly?: InputMaybe<Scalars['Float']['input']>;
  extra_income?: InputMaybe<Scalars['Float']['input']>;
  future_reduced_earnings?: InputMaybe<Scalars['Float']['input']>;
  monthly_living_expenses?: InputMaybe<Scalars['Float']['input']>;
  net_income_monthly?: InputMaybe<Scalars['Float']['input']>;
  number_of_dependents?: InputMaybe<Scalars['Int']['input']>;
  occupation_category?: InputMaybe<OccupationCategory>;
  overdue_debt?: InputMaybe<Scalars['Float']['input']>;
  planning_new_debts?: InputMaybe<Scalars['Float']['input']>;
  reject_when_necessary?: InputMaybe<Scalars['Boolean']['input']>;
  spouse_phone?: InputMaybe<Scalars['String']['input']>;
  ultimate_beneficial_owner?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateApplicationUserInfoSpouseArgs = {
  application_user_info_id: Scalars['Int']['input'];
  reject_when_necessary?: InputMaybe<Scalars['Boolean']['input']>;
  spouse_email?: InputMaybe<Scalars['String']['input']>;
  spouse_employment_date?: InputMaybe<Scalars['String']['input']>;
  spouse_expenditure_consumer_loan_monthly?: InputMaybe<Scalars['Float']['input']>;
  spouse_expenditure_monthly?: InputMaybe<Scalars['Float']['input']>;
  spouse_extra_income?: InputMaybe<Scalars['Float']['input']>;
  spouse_first_name?: InputMaybe<Scalars['String']['input']>;
  spouse_future_reduced_earnings?: InputMaybe<Scalars['Float']['input']>;
  spouse_last_name?: InputMaybe<Scalars['String']['input']>;
  spouse_monthly_living_expenses?: InputMaybe<Scalars['Float']['input']>;
  spouse_net_income_monthly?: InputMaybe<Scalars['Float']['input']>;
  spouse_overdue_debt?: InputMaybe<Scalars['Float']['input']>;
  spouse_phone?: InputMaybe<Scalars['String']['input']>;
  spouse_pin?: InputMaybe<Scalars['String']['input']>;
  spouse_planning_new_debts?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationUpdateCreditAccountArgs = {
  accrue_interest_and_fees?: InputMaybe<Scalars['Boolean']['input']>;
  credit_account_id: Scalars['Int']['input'];
  credit_limit?: InputMaybe<Scalars['Float']['input']>;
  onboarding_campaign?: InputMaybe<CreditAccountOnboardingCampaignType>;
  send_reminders?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateCreditAccountLimitRecalculationArgs = {
  credit_account_limit_recalculation_id: Scalars['Int']['input'];
  new_credit_limit?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationUpdateCreditAccountSpouseInfoArgs = {
  credit_account_id: Scalars['Int']['input'];
  hash?: InputMaybe<Scalars['String']['input']>;
  spouse_email?: InputMaybe<Scalars['String']['input']>;
  spouse_employment_date?: InputMaybe<Scalars['String']['input']>;
  spouse_expenditure_consumer_loan_monthly?: InputMaybe<Scalars['Float']['input']>;
  spouse_expenditure_monthly?: InputMaybe<Scalars['Float']['input']>;
  spouse_extra_income?: InputMaybe<Scalars['Float']['input']>;
  spouse_first_name?: InputMaybe<Scalars['String']['input']>;
  spouse_future_reduced_earnings?: InputMaybe<Scalars['Float']['input']>;
  spouse_last_name?: InputMaybe<Scalars['String']['input']>;
  spouse_monthly_living_expenses?: InputMaybe<Scalars['Float']['input']>;
  spouse_net_income_monthly?: InputMaybe<Scalars['Float']['input']>;
  spouse_overdue_debt?: InputMaybe<Scalars['Float']['input']>;
  spouse_phone?: InputMaybe<Scalars['String']['input']>;
  spouse_pin?: InputMaybe<Scalars['String']['input']>;
  spouse_planning_new_debts?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationUpdateCreditAccountUserArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  credit_account_id: Scalars['Int']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  post_code?: InputMaybe<Scalars['String']['input']>;
  purpose_of_loan?: InputMaybe<PurposeOfLoanType>;
};


export type MutationUpdateCreditAccountUserDocumentArgs = {
  credit_account_id: Scalars['Int']['input'];
  input?: InputMaybe<UserDocumentInput>;
};


export type MutationUpdateCreditAccountUserExtraArgs = {
  credit_account_id: Scalars['Int']['input'];
  employer?: InputMaybe<Scalars['String']['input']>;
  employment_date?: InputMaybe<Scalars['String']['input']>;
  employment_sector?: InputMaybe<EmploymentSector>;
  expenditure_consumer_loan_monthly?: InputMaybe<Scalars['Float']['input']>;
  expenditure_monthly?: InputMaybe<Scalars['Float']['input']>;
  extra_income?: InputMaybe<Scalars['Float']['input']>;
  future_reduced_earnings?: InputMaybe<Scalars['Float']['input']>;
  monthly_living_expenses?: InputMaybe<Scalars['Float']['input']>;
  net_income_monthly?: InputMaybe<Scalars['Float']['input']>;
  number_of_dependents?: InputMaybe<Scalars['Int']['input']>;
  occupation_category?: InputMaybe<OccupationCategory>;
  planning_new_debts?: InputMaybe<Scalars['Float']['input']>;
  spouse_phone?: InputMaybe<Scalars['String']['input']>;
  ultimate_beneficial_owner?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateMerchantArgs = {
  address: Scalars['String']['input'];
  beneficiary_name: Scalars['String']['input'];
  cancel_url?: InputMaybe<Scalars['String']['input']>;
  category_id?: InputMaybe<Scalars['Int']['input']>;
  email: Scalars['String']['input'];
  home_url?: InputMaybe<Scalars['String']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  invoice_email?: InputMaybe<Scalars['String']['input']>;
  is_broker?: InputMaybe<Scalars['Boolean']['input']>;
  merchant_id: Scalars['Int']['input'];
  monthly_reports_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  notification_url?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  platform_id?: InputMaybe<Scalars['Int']['input']>;
  private_label?: InputMaybe<Scalars['Boolean']['input']>;
  registry_code: Scalars['String']['input'];
  return_url?: InputMaybe<Scalars['String']['input']>;
  send_esto_pay_notification_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_esto_pay_notification_to_customer?: InputMaybe<Scalars['Boolean']['input']>;
  send_hp_notification_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_user_inquiry_email?: InputMaybe<Scalars['Boolean']['input']>;
  vat_number?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateMerchantCalculatorSkinArgs = {
  active_period: Scalars['String']['input'];
  button: Scalars['String']['input'];
  button_text: Scalars['String']['input'];
  discount_mltp?: InputMaybe<Scalars['Float']['input']>;
  main: Scalars['String']['input'];
  max_amount?: InputMaybe<Scalars['Float']['input']>;
  merchant_id: Scalars['Int']['input'];
  min_amount?: InputMaybe<Scalars['Float']['input']>;
  monthly_text: Scalars['String']['input'];
  period: Scalars['String']['input'];
  period_text: Scalars['String']['input'];
  secondary: Scalars['String']['input'];
  text: Scalars['String']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationUpdateMerchantPaymentMethodsArgs = {
  converting_schedule_enabled: Scalars['Boolean']['input'];
  esto_pay_enabled: Scalars['Boolean']['input'];
  merchant_id: Scalars['Int']['input'];
  pay_later_enabled: Scalars['Boolean']['input'];
  regular_hp_enabled: Scalars['Boolean']['input'];
};


export type MutationUpdateMerchantStoreArgs = {
  name: Scalars['String']['input'];
  store_id: Scalars['Int']['input'];
};


export type MutationUpdateMerchantUserPermissionsArgs = {
  merchant_id: Scalars['Int']['input'];
  permission_bits: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};


export type MutationUpdateMerchantUserSendEmailsArgs = {
  merchant_id: Scalars['Int']['input'];
  send_emails: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};


export type MutationUpdatePasswordArgs = {
  new_password: Scalars['String']['input'];
  old_password?: InputMaybe<Scalars['String']['input']>;
  user_id: Scalars['Int']['input'];
};


export type MutationUpdatePaymentLeaveArgs = {
  months: Scalars['Int']['input'];
  payment_leave_id: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};


export type MutationUpdateUserArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  allow_pension_query?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  conditions_agreement?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employer?: InputMaybe<Scalars['String']['input']>;
  employment_date?: InputMaybe<Scalars['String']['input']>;
  employment_sector?: InputMaybe<EmploymentSector>;
  expenditure_consumer_loan_monthly?: InputMaybe<Scalars['Float']['input']>;
  expenditure_monthly?: InputMaybe<Scalars['Float']['input']>;
  extra_income?: InputMaybe<Scalars['Float']['input']>;
  future_reduced_earnings?: InputMaybe<Scalars['Float']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  language_abbr?: InputMaybe<Scalars['String']['input']>;
  monthly_living_expenses?: InputMaybe<Scalars['Float']['input']>;
  net_income_monthly?: InputMaybe<Scalars['Float']['input']>;
  newsletter_agreement?: InputMaybe<Scalars['Boolean']['input']>;
  number_of_dependents?: InputMaybe<Scalars['Int']['input']>;
  occupation_category?: InputMaybe<OccupationCategory>;
  overdue_debt?: InputMaybe<Scalars['Float']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phone_area_code?: InputMaybe<Scalars['String']['input']>;
  planning_new_debts?: InputMaybe<Scalars['Float']['input']>;
  political_exposure?: InputMaybe<PoliticalExposure>;
  post_code?: InputMaybe<Scalars['String']['input']>;
  ultimate_beneficial_owner?: InputMaybe<Scalars['Boolean']['input']>;
  user_id: Scalars['Int']['input'];
};


export type MutationUpdateUserLanguageArgs = {
  language_abbr: Scalars['String']['input'];
  user_id: Scalars['Int']['input'];
};


export type MutationUpdateUserTermsArgs = {
  allow_pension_query: Scalars['Boolean']['input'];
  conditions_agreement: Scalars['Boolean']['input'];
  newsletter_agreement: Scalars['Boolean']['input'];
  political_exposure?: InputMaybe<PoliticalExposure>;
  user_id: Scalars['Int']['input'];
};


export type MutationUploadAccountStatementArgs = {
  application_id?: InputMaybe<Scalars['Int']['input']>;
  credit_account_id?: InputMaybe<Scalars['Int']['input']>;
  provider?: InputMaybe<AccountStatementProviderType>;
  send_email?: InputMaybe<Scalars['Boolean']['input']>;
  statement?: InputMaybe<Scalars['Upload']['input']>;
  user_id: Scalars['Int']['input'];
};


export type MutationUploadSwitchTransactionArgs = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  bank_statement?: InputMaybe<Scalars['Upload']['input']>;
  transaction_reference?: InputMaybe<Scalars['String']['input']>;
  user_id: Scalars['Int']['input'];
};


export type MutationUserContactArgs = {
  description: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationWaiveTransactionArgs = {
  amount: Scalars['Float']['input'];
  application_id?: InputMaybe<Scalars['Int']['input']>;
  credit_account_id?: InputMaybe<Scalars['Int']['input']>;
  merchant_id?: InputMaybe<Scalars['Int']['input']>;
  waiver_reason?: InputMaybe<Scalars['String']['input']>;
};


export type MutationWithdrawFromCreditAccountArgs = {
  amount: Scalars['Float']['input'];
  credit_account_id: Scalars['Int']['input'];
  is_instant_payment: Scalars['Boolean']['input'];
};

export enum OccupationCategory {
  ARTISAN = 'ARTISAN',
  CARETAKER = 'CARETAKER',
  ENTREPRENEUR = 'ENTREPRENEUR',
  GOV_OR_SPECIALIST = 'GOV_OR_SPECIALIST',
  PRIVATE_SECTOR_WORKER = 'PRIVATE_SECTOR_WORKER',
  PUBLIC_SECTOR_WORKER = 'PUBLIC_SECTOR_WORKER',
  RETIRED = 'RETIRED',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  STUDENT = 'STUDENT',
  TOP_MIDDLE_MGMT = 'TOP_MIDDLE_MGMT',
  UNDER_GOVERNMENT_ALLOWANCE = 'UNDER_GOVERNMENT_ALLOWANCE',
  UNEMPLOYED = 'UNEMPLOYED'
}

export enum OrderBy {
  CREATED_AT = 'created_at'
}

/** Get all applications based on the number of days they are overdue */
export type OverdueApplication = {
  /** Determine whether to accrue interest and fees for a loan */
  accrue_interest_and_fees: Scalars['Boolean']['output'];
  /** Currently active payment leave for application */
  active_payment_leave?: Maybe<PaymentLeave>;
  /** Whether to add legal person info to the invoice */
  add_legal_person_to_invoice: Scalars['Boolean']['output'];
  /** If application has been modified, the original credit info data is stored here */
  application_modifications?: Maybe<Array<Maybe<ApplicationModification>>>;
  /** Helper table for making purchases via a physical cash register */
  application_reference?: Maybe<ApplicationReference>;
  /** ID of the application_user_info that this application belongs to */
  application_user_info_id?: Maybe<Scalars['Int']['output']>;
  /** Items bought with the purchase */
  basket_items?: Maybe<Array<Maybe<ApplicationBasketItem>>>;
  /** Date, when merchant was paid the application bonuses */
  bonus_paid_at?: Maybe<Scalars['String']['output']>;
  /** Application status based on information provided by the broker. */
  broker_status?: Maybe<Scalars['String']['output']>;
  /** Information about application that has been bought back from merchant */
  buyback_settings?: Maybe<ApplicationBuybackSettings>;
  /** Timestamp, when the user gets last call for the debt */
  called_at?: Maybe<Scalars['Int']['output']>;
  /** A campaign that applies to the application, if enabled */
  campaign?: Maybe<ApplicationCampaign>;
  /** Determines, whether the application can be modified */
  can_be_modified: Scalars['Boolean']['output'];
  /** Whether an application can convert from a regular schedule to (long-term) CA/HP. */
  can_convert_from_regular: Scalars['Boolean']['output'];
  /** Whether the application can be converted manually to CA. */
  can_manually_convert_to_credit_account: Scalars['Boolean']['output'];
  /** Checks if the application can be refinanced or not */
  can_refinance: Scalars['Boolean']['output'];
  /** Optional URL, where the user will be redirected to after application was rejected */
  cancel_url?: Maybe<Scalars['String']['output']>;
  /** Request made by merchant to cancel this application */
  cancellation_request?: Maybe<ApplicationCancellationRequest>;
  /** Timestamp, when the application was cancelled (purchased item returned to merchant) */
  cancelled_at?: Maybe<Scalars['Int']['output']>;
  /** The user by whom this application was cancelled */
  cancelled_by?: Maybe<User>;
  /** Date, when merchant was paid cashier the application bonuses */
  cashier_bonus_paid_at?: Maybe<Scalars['String']['output']>;
  /** ConvertingSchedule regular campaign schedule */
  converting_schedule_regular?: Maybe<ConvertingScheduleRegular>;
  /** Timestamp, when the application was created */
  created_at: Scalars['Int']['output'];
  /** Additional info of the application about pricing */
  credit_info?: Maybe<ApplicationCreditInfo>;
  /** Fully rejected customers are given an option to get a loan from a third-party loan provider */
  customer_sale?: Maybe<CustomerSale>;
  /** How many days the hire purchase payment is overdue. 0 if HP payment is not overdue. */
  days_past_due?: Maybe<Scalars['Int']['output']>;
  /** Ongoing and finished claim collection processes */
  debt_collections?: Maybe<Array<Maybe<DebtCollection>>>;
  /** Timestamp, when the application was deleted */
  deleted_at?: Maybe<Scalars['Int']['output']>;
  /** Refunds history for specific Direct payment (ESTO Pay) */
  direct_payment_refunds?: Maybe<Array<Maybe<DirectPaymentRefund>>>;
  /** Down payment of the application */
  down_installments?: Maybe<Array<Maybe<Installment>>>;
  /** If the application was paid for early it will have additional info */
  early_payment?: Maybe<ApplicationEarlyPayment>;
  /**
   * Disable the eligibility check in order to not make actual queries to the Decision
   *                 Engine. In this case, the eligibility status and state will not change
   */
  eligibility_check_disabled?: Maybe<Scalars['Boolean']['output']>;
  /** 1: Eligible, 0: Pending, -1: Rejected */
  eligibility_state?: Maybe<Scalars['Int']['output']>;
  /** ELIGIBLE or reason why not eligible */
  eligibility_status?: Maybe<Scalars['String']['output']>;
  /** Timestamp of the last time eligibility was checked */
  eligibility_status_last_check?: Maybe<Scalars['Int']['output']>;
  /** Timestamp, when the last payment was made that paid for the application fully */
  ended_at?: Maybe<Scalars['Int']['output']>;
  /** Time when the application will be deleted, if it has not been signed */
  expires_at?: Maybe<Scalars['Int']['output']>;
  /** First monthly installment */
  first_installment?: Maybe<Installment>;
  /** True, if app for private person, false if for legal person and NULL if not fixed at all */
  fixed_for_private_person?: Maybe<Scalars['Boolean']['output']>;
  /** True, if application does NOT represent a legal person */
  for_private_person: Scalars['Boolean']['output'];
  /** Whether to forward rejected application to another broker. */
  forward_rejects: Scalars['Boolean']['output'];
  /** Whether it is a FREE HP application (no credit cost, no scoring) */
  free_hp_enabled: Scalars['Boolean']['output'];
  /** Whether this application was created in a retail (physical) store */
  from_retail: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  /**
   * Whether the user's identity needs verification (depends on requested amount).
   *                     Always include user relation with id_verification_method!
   */
  id_verification_required?: Maybe<Scalars['Boolean']['output']>;
  /** Time when the user (small loan) or merchant (hire purchase) was paid for by us */
  initial_paid_at?: Maybe<Scalars['Int']['output']>;
  /** Required installments for the application */
  installments?: Maybe<Array<Maybe<Installment>>>;
  /** Required installments for the application, ordered by due_at ASC */
  installments_ordered?: Maybe<Array<Maybe<Installment>>>;
  /** The fee for instant payout of small loan */
  instant_payment_fee: Scalars['Float']['output'];
  /** Current status of the Instantor request for this application */
  instantor_eligibility: InstantorEligibility;
  /** Identifier for the application that is used for payments */
  invoice_reference_nr?: Maybe<Scalars['String']['output']>;
  /** Whether the application converts (to CA or longer HP) */
  is_converting_schedule: Scalars['Boolean']['output'];
  /** Determines, if the user can apply for the loan specified by this application */
  is_eligible: Scalars['Boolean']['output'];
  /** Whether the funds will be paid out immediately to the client. Only applies for consumer loans */
  is_instant_payout: Scalars['Boolean']['output'];
  /** Whether the user can make instant payouts. */
  is_instant_payout_available: Scalars['Boolean']['output'];
  /** Determines, if direct payment can be refunded */
  is_refundable: Scalars['Boolean']['output'];
  /** If true, then this application is only used for testing purposes */
  is_test: Scalars['Boolean']['output'];
  /** klix payments connected to this application */
  klix_payments?: Maybe<Array<Maybe<KlixPayment>>>;
  /** Very last monthly installment that will be paid */
  last_installment?: Maybe<Installment>;
  /** Latest customer care decision result for related to application */
  latest_customer_care_decision?: Maybe<CustomerCareDecision>;
  /** Latest modification request made by merchant */
  latest_modification_request?: Maybe<ApplicationModificationRequest>;
  /** Info about the legal person connected to the application */
  legal_person_info?: Maybe<ApplicationLegalPersonInfo>;
  /**
   * Get ESTO's or merchant's or campaign logo, depending on the application.
   *                     NB! Always select merchant and campaign relations
   */
  logo_url: Scalars['String']['output'];
  /** Seller of the purchase */
  merchant?: Maybe<Merchant>;
  /** Data entered by the merchant for the application */
  merchant_data?: Maybe<ApplicationMerchantData>;
  /** ID of the merchant selling something to the user */
  merchant_id?: Maybe<Scalars['Int']['output']>;
  /** Requests made by merchant to modify application values such as net total */
  modification_requests?: Maybe<Array<Maybe<ApplicationModificationRequest>>>;
  /** True, if application has a call reminder, but user has not been called yet */
  needs_to_be_called: Scalars['Boolean']['output'];
  /** The next installment that is unpaid (can be in the past as well) */
  next_installment?: Maybe<Installment>;
  /** The next installment date for the given application or oldest installment date, if overdue */
  next_installment_date?: Maybe<Scalars['String']['output']>;
  /**
   * The next payment amount for the application. If overdue then only overdue amount,
   *                     but otherwise get current month
   */
  next_payment_amount?: Maybe<Scalars['Float']['output']>;
  /** Application overdue installments */
  overdue_installments?: Maybe<Array<Maybe<Installment>>>;
  /** The total amount of principal that has been paid */
  paid_principal: Scalars['Float']['output'];
  /** Payment leaves taken for this application */
  payment_leaves?: Maybe<Array<Maybe<PaymentLeave>>>;
  /** Acquirer pivot of the product (application or credit account), if the product has been sold with a portfolio */
  portfolio_sale_product?: Maybe<PortfolioSaleProduct>;
  /** Pre-signing conversion offer for the application */
  pre_signing_conversion_offer?: Maybe<PreSigningConversionOffer>;
  /** Value taken from pricing. Max amount that an admin can waive from a hire purchase. */
  pricing_max_waive_amount: Scalars['Float']['output'];
  /** Timestamp, when the application was processed (for direct payments) */
  processed_at?: Maybe<Scalars['Int']['output']>;
  /** Date, when the user promised to return the money */
  promised_repayment_date?: Maybe<Scalars['String']['output']>;
  /** Get the name of merchant if private label or ESTO if not */
  provider_name: Scalars['String']['output'];
  /** URL where the purchase flow starts from */
  purchase_url: Scalars['String']['output'];
  /** A unique string to identify the application */
  reference_key: Scalars['String']['output'];
  /** The application that was refinanced by this application */
  refinanced_application?: Maybe<Application>;
  /** The application that refinanced this application */
  refinancing_parent_application?: Maybe<Application>;
  /** The application's id that refinances this application */
  refinancing_parent_id?: Maybe<Scalars['Int']['output']>;
  /** Get amount that was successfully refunded for Direct payment */
  refunded_amount: Scalars['Float']['output'];
  /** Timestamp, when the application was rejected */
  rejected_at?: Maybe<Scalars['Int']['output']>;
  /** Reminders that have been sent to the user because of overdue installments */
  reminders?: Maybe<Array<Maybe<Reminder>>>;
  /** The amount required for the purchase */
  requested_amount: Scalars['Float']['output'];
  /** Resale applications are potentially eligible applications which are currently unsigned */
  resale?: Maybe<Resale>;
  /** Resale call is created when the user is called during the resale process */
  resale_call?: Maybe<ResaleCall>;
  /** Optional URL, where the user will be redirected to after submitting the application */
  return_url?: Maybe<Scalars['String']['output']>;
  /** Type of the application, like hire-purchase or small loan */
  schedule_type: ApplicationScheduleType;
  /** Determine whether reminders should be sent */
  send_reminders: Scalars['Boolean']['output'];
  /** Whether a separate checkbox for pension query is shown at purchase flow */
  show_separate_pension_query: Scalars['Boolean']['output'];
  /** Timestamp, when the application was signed and accepted */
  signed_at?: Maybe<Scalars['Int']['output']>;
  /** Credit account eligibility status that is one of SimpleEligibilityStatus */
  simple_eligibility_status: Scalars['String']['output'];
  /** Timestamp, when the application was sold to a debt collector */
  sold_at?: Maybe<Scalars['Int']['output']>;
  /** Application status. One of the following: UNSIGNED, REJECTED, ACTIVE, TERMINATED, CANCELLED, DELETED, PROCESSED, ENDED */
  status: ApplicationStatus;
  /** Application sub-statuses */
  sub_statuses?: Maybe<Array<Maybe<LoanSubStatus>>>;
  /** Recurring payments subscription for the application */
  subscription?: Maybe<Subscription>;
  /** Timestamp, when the application was terminated (e.g when customer died or went bankrupt) */
  terminated_at?: Maybe<Scalars['Int']['output']>;
  termination_reason?: Maybe<Scalars['String']['output']>;
  /** Total amount paid over all payments on an overdue application */
  total_amount_paid: Scalars['Float']['output'];
  /** Total debt over all payments on an overdue application */
  total_debt: Scalars['Float']['output'];
  /**
   * Total unpaid amount that should have been paid by now. NB! Only use for single queries
   *                     as it's actually a DB query
   */
  total_overdue: Scalars['Float']['output'];
  /** Get total paid amount for application */
  total_paid?: Maybe<Scalars['Float']['output']>;
  /** Type of the application, like hire-purchase or small loan */
  type: ApplicationType;
  /** Get the total unpaid principal amount for the loan */
  unpaid_principal?: Maybe<Scalars['Float']['output']>;
  /** Owner of the application */
  user?: Maybe<User>;
  /** ID of the user that this application belongs to */
  user_id?: Maybe<Scalars['Int']['output']>;
  /** Additional info of the application about the user */
  user_info?: Maybe<ApplicationUserInfo>;
};


/** Get all applications based on the number of days they are overdue */
export type OverdueApplicationSimpleEligibilityStatusArgs = {
  do_query?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum OverdueApplicationsOrderBy {
  CALLED_AT = 'called_at',
  CREATED_AT = 'created_at',
  TOTAL_DEBT = 'total_debt'
}

/** Overdue credit account with some additional info */
export type OverdueCreditAccount = {
  /** Determine whether to accrue interest and fees for a loan */
  accrue_interest_and_fees: Scalars['Boolean']['output'];
  /** Interest that has accrued, but has not been applied yet */
  accrued_interest: Scalars['Float']['output'];
  /** Management fee that has accrued, but has not been applied yet */
  accrued_management_fee: Scalars['Float']['output'];
  /** Actions that apply to the credit account */
  actions?: Maybe<Array<Maybe<CreditAccountAction>>>;
  /** Currently active payment leave for credit account */
  active_payment_leave?: Maybe<PaymentLeave>;
  annual_pct_rate: Scalars['Float']['output'];
  application_user_info_id?: Maybe<Scalars['Int']['output']>;
  /** The amount that can still be withdrawn from the credit account */
  available_balance: Scalars['Float']['output'];
  /** Timestamp, when the user gets last call for the debt */
  called_at?: Maybe<Scalars['Int']['output']>;
  /** Campaigns that apply to the credit account */
  campaigns?: Maybe<Array<Maybe<CreditAccountCampaign>>>;
  campaigns_ordered_desc?: Maybe<Array<Maybe<CreditAccountCampaign>>>;
  /** The user can deactivate the credit account. Checks if he has active ESTO_X loan or PAY_LATER */
  can_deactivate: Scalars['Boolean']['output'];
  /** Whether the client can start requesting credit limit increase. */
  can_request_limit_increase: Scalars['Boolean']['output'];
  contract_editing_fee: Scalars['Float']['output'];
  /** All contracts the credit account has */
  contracts?: Maybe<Array<Maybe<Contract>>>;
  created_at: Scalars['String']['output'];
  /** Total credit that can be in use at one time */
  credit_limit: Scalars['Float']['output'];
  credit_limit_recalculation_fee: Scalars['Float']['output'];
  credit_score?: Maybe<UserCreditScore>;
  credit_scoring_fee: Scalars['Float']['output'];
  /** How many days the credit account is overdue. 0 if CA is not overdue. */
  days_past_due?: Maybe<Scalars['Int']['output']>;
  /** Timestamp, when the credit account was deactivated */
  deactivated_at?: Maybe<Scalars['String']['output']>;
  /** Ongoing and finished claim collection processes */
  debt_collections?: Maybe<Array<Maybe<DebtCollection>>>;
  deleted_at?: Maybe<Scalars['String']['output']>;
  /**
   * Disable the eligibility check in order to not make actual queries to the Decision
   *                 Engine. In this case, the eligibility status and state will not change
   */
  eligibility_check_disabled?: Maybe<Scalars['Boolean']['output']>;
  /** 1: Eligible, 0: Pending, -1: Rejected */
  eligibility_state?: Maybe<Scalars['Int']['output']>;
  /** ELIGIBLE or reason why not eligible */
  eligibility_status: Scalars['String']['output'];
  /** Datetime of the last time eligibility was checked */
  eligibility_status_last_check?: Maybe<Scalars['String']['output']>;
  /** Datetime when the CA contract expires. Expires within X months from signing unless re-signed */
  expires_at?: Maybe<Scalars['String']['output']>;
  /**
   * Calculate the first monthly payment for available credit limit.
   *                     NB! Only request this variable when completely necessary as it is a heavy calculation.
   */
  first_monthly_payment: Scalars['Float']['output'];
  /** Whether to forward rejected credit account to another broker. */
  forward_rejects: Scalars['Boolean']['output'];
  /** 1/X of principal that is at minimum added to every invoice */
  fraction_principal: Scalars['Int']['output'];
  /** The accrued interest up to a certain date for a credit account */
  future_accrued_interest?: Maybe<Scalars['Float']['output']>;
  /** The not yet accrued management fee up to a certain date for a credit account */
  future_accrued_management_fee?: Maybe<Scalars['Float']['output']>;
  /** A unique string to identify the credit account */
  hash: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** Fee that is taken for instantly depositing withdrawal amount to customer's bank account */
  instant_payment_fee: Scalars['Float']['output'];
  /** Current status of the Instantor request for this credit account */
  instantor_eligibility: InstantorEligibility;
  invoice_reference_nr: Scalars['String']['output'];
  invoices?: Maybe<Array<Maybe<CreditAccountInvoice>>>;
  irr: Scalars['Float']['output'];
  /** Determines, if the user can apply for the credit account */
  is_eligible: Scalars['Boolean']['output'];
  /** From which channel the customer came to us before signing the credit account */
  landed_from?: Maybe<Scalars['String']['output']>;
  /** Latest customer care decision result for related to credit account */
  latest_customer_care_decision?: Maybe<CustomerCareDecision>;
  /** Most recent credit account invoice */
  latest_invoice?: Maybe<CreditAccountInvoice>;
  /** How much it costs to change credit limit. */
  limit_recalculation_fee: Scalars['Float']['output'];
  /** % of principal amount accrued daily */
  management_fee_daily_pct: Scalars['Float']['output'];
  /** Whether management fee accruing is enabled */
  management_fee_enabled?: Maybe<Scalars['Boolean']['output']>;
  management_fee_monthly: Scalars['Float']['output'];
  /** 1/X of principal that is at minimum added to every invoice. DEPRECATED - use "fraction_principal" variable instead */
  max_period_months: Scalars['Int']['output'];
  /** True, if credit account has a call reminder, but user has not been called yet */
  needs_to_be_called: Scalars['Boolean']['output'];
  /** Amount of the next CA invoice, including currently unpaid amounts */
  next_invoice_amount: Scalars['Float']['output'];
  /** to_date of the next invoice that will be created for this credit account */
  next_invoice_date?: Maybe<Scalars['String']['output']>;
  overflow: Scalars['Float']['output'];
  /** How much card payment fee is paid */
  paid_card_payment_fee: Scalars['Float']['output'];
  /** All payment leaves the credit account has had */
  payment_leaves?: Maybe<Array<Maybe<PaymentLeave>>>;
  /** Acquirer pivot of the product (application or credit account), if the product has been sold with a portfolio */
  portfolio_sale_product?: Maybe<PortfolioSaleProduct>;
  /** The limit up to which the credit limit could be increased right now */
  potential_credit_limit: Scalars['Float']['output'];
  /** Value taken from pricing. Max amount that an admin can waive from a credit account. */
  pricing_max_waive_amount: Scalars['Float']['output'];
  /** Date, when the user promised to return the money */
  promised_repayment_date?: Maybe<Scalars['String']['output']>;
  resale?: Maybe<Resale>;
  /** Determine whether reminders should be sent */
  send_reminders: Scalars['Boolean']['output'];
  /**
   * Determines whether credit account should be updated with fresh pricing values
   *                     and whether modification contract should be signed.
   */
  should_be_modified: Scalars['Boolean']['output'];
  /** Indicates whether there has been a limit change that needs to be followed by a contract. */
  should_sign_limit_change: Scalars['Boolean']['output'];
  signed_at?: Maybe<Scalars['String']['output']>;
  /** Credit account eligibility status that is one of SimpleEligibilityStatus */
  simple_eligibility_status: Scalars['String']['output'];
  /** Timestamp, when the credit account was sold to a debt collector */
  sold_at?: Maybe<Scalars['Int']['output']>;
  /** Activated or deactivated state */
  status?: Maybe<CreditAccountStatus>;
  /** Credit account sub-statuses */
  sub_statuses?: Maybe<Array<Maybe<LoanSubStatus>>>;
  /** Recurring payments subscription for the credit account */
  subscription?: Maybe<Subscription>;
  /** Timestamp, when the credit account was terminated */
  terminated_at?: Maybe<Scalars['String']['output']>;
  termination_reason?: Maybe<Scalars['String']['output']>;
  /** Total debt the user owes for this credit account */
  total_debt: Scalars['Float']['output'];
  /** Total outstanding amount, including fees */
  total_outstanding: Scalars['Float']['output'];
  unpaid_contract_editing_fee: Scalars['Float']['output'];
  unpaid_credit_limit_recalculation_fee: Scalars['Float']['output'];
  unpaid_credit_scoring_fee: Scalars['Float']['output'];
  /** Total amount of unpaid fixed fees like withdrawal fee */
  unpaid_fees: Scalars['Float']['output'];
  /** How much instant payment fee is unpaid */
  unpaid_instant_payment_fee: Scalars['Float']['output'];
  /** Interest that has been applied and is outstanding */
  unpaid_interest: Scalars['Float']['output'];
  unpaid_management_fee: Scalars['Float']['output'];
  unpaid_payment_leave_fee: Scalars['Float']['output'];
  unpaid_principal: Scalars['Float']['output'];
  unpaid_reminder_fee: Scalars['Float']['output'];
  unpaid_withdrawal_fee: Scalars['Float']['output'];
  user?: Maybe<User>;
  user_id: Scalars['Int']['output'];
  user_info?: Maybe<ApplicationUserInfo>;
  user_profile?: Maybe<UserProfile>;
  /** Fixed amount that is deducted upon withdrawals */
  withdrawal_fee_amount: Scalars['Float']['output'];
  /** Percentage that is deducted from withdrawal principals */
  withdrawal_fee_pct: Scalars['Float']['output'];
  /** All withdrawals the credit account has */
  withdrawals?: Maybe<Array<Maybe<CreditAccountWithdrawal>>>;
};


/** Overdue credit account with some additional info */
export type OverdueCreditAccountFutureAccruedInterestArgs = {
  balance_date?: InputMaybe<Scalars['String']['input']>;
  credit_account_id: Scalars['Int']['input'];
};


/** Overdue credit account with some additional info */
export type OverdueCreditAccountFutureAccruedManagementFeeArgs = {
  balance_date?: InputMaybe<Scalars['String']['input']>;
  credit_account_id: Scalars['Int']['input'];
};


/** Overdue credit account with some additional info */
export type OverdueCreditAccountSimpleEligibilityStatusArgs = {
  do_query?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum OverdueCreditAccountsOrderBy {
  CALLED_AT = 'called_at',
  CREATED_AT = 'created_at',
  TOTAL_DEBT = 'total_debt'
}

/** Represents overdue days and the number of applications or credit accounts that are overdue that amount of days */
export type OverdueDays = {
  /** The number of days over the payment date */
  num_days_overdue: Scalars['Int']['output'];
  /** The number of applications or credit accounts that are num_days_overdue */
  overdue_count: Scalars['Int']['output'];
  /** The number of applications or credit accounts that need to be called */
  total_to_call: Scalars['Int']['output'];
};

/** Payment from ESTO to a merchant or customer */
export type Payment = {
  amount: Scalars['Float']['output'];
  created_at: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /**
   * Total payment amount filtered by HP, CA or User. This is necessary for payments
   *                     that include multiple transactions and different products - we only want to show the total amount
   *                     based on the filtering.
   */
  filtered_amount?: Maybe<Scalars['Float']['output']>;
  /** Who the payment originated from */
  from_name?: Maybe<Scalars['String']['output']>;
  handled_at?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  saltedge_transaction?: Maybe<SaltedgeTransaction>;
  seb_notification?: Maybe<SebNotification>;
  /** Who the payment was meant for */
  to_name?: Maybe<Scalars['String']['output']>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
  type?: Maybe<PaymentType>;
};


/** Payment from ESTO to a merchant or customer */
export type PaymentTransactionsArgs = {
  merchant_id?: InputMaybe<Scalars['Int']['input']>;
};

export enum PaymentCategoryType {
  PAYMENT_LEAVE = 'PAYMENT_LEAVE',
  REGULAR = 'REGULAR',
  SUBSCRIPTION = 'SUBSCRIPTION'
}

/** Queries about the defaulted user payments */
export type PaymentDefaultQuery = {
  /** Timestamp, when the query was created */
  created_at: Scalars['String']['output'];
  /** Defaulted payments from credit info */
  credit_info_payment_defaults?: Maybe<Array<Maybe<CreditInfoPaymentDefault>>>;
  id: Scalars['Int']['output'];
  /** Claims from taust.ee */
  taust_claims?: Maybe<Array<Maybe<TaustClaims>>>;
  /** Requests about the user from taust.ee */
  taust_requests?: Maybe<Array<Maybe<TaustRequests>>>;
  /** Type of defaulted payment (CREDIT_INFO_CLAIM, TAUST_CLAIM, TAUST_REQUESTS) */
  type: PaymentDefaultType;
  user_id: Scalars['Int']['output'];
};

export enum PaymentDefaultType {
  CREDIT_INFO_CLAIM = 'CREDIT_INFO_CLAIM',
  TAUST_CLAIM = 'TAUST_CLAIM',
  TAUST_REQUESTS = 'TAUST_REQUESTS'
}

/** Payment leave for credit account or application. When active, customer does not have to pay principal from start_date to end_date */
export type PaymentLeave = {
  application_id?: Maybe<Scalars['Int']['output']>;
  /** Timestamp, when the payment leave was created */
  created_at: Scalars['Int']['output'];
  credit_account_id?: Maybe<Scalars['Int']['output']>;
  /** When payment leave is disabled */
  disabled_at?: Maybe<Scalars['Int']['output']>;
  /** When payment leave is enabled */
  enabled_at?: Maybe<Scalars['Int']['output']>;
  /** End date of payment leave */
  end_date: Scalars['String']['output'];
  /** Fee amount for the payment leave */
  fee_amount: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  /** Installment that may have been created during application payment leave */
  installment?: Maybe<Installment>;
  /** Whether payment leave is currently active */
  is_active: Scalars['Boolean']['output'];
  /** Duration of the payment leave */
  months: Scalars['Int']['output'];
  /** Start date of payment leave */
  start_date: Scalars['String']['output'];
  /** Possible values: ACTIVE, DISABLED, PENDING, FINISHED */
  status: PaymentLeaveStatusType;
  /** How the payment leave is applied. Possible values: ONLY_PRINCIPAL, WHOLE_SCHEDULE */
  type?: Maybe<PaymentLeaveType>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** Workflow response specific for payment leave */
export type PaymentLeaveDecision = {
  /** Fee amount that can be taken before payment leave is enabled */
  fee?: Maybe<Scalars['Float']['output']>;
  /** Duration of the payment leave */
  months?: Maybe<Scalars['Int']['output']>;
  /** Possible values: APPROVED, REJECTED, NEED_TO_PAY_FEE, ALREADY_HAS_PAYMENT_LEAVE */
  status?: Maybe<PaymentLeaveDecisionStatusType>;
};

export enum PaymentLeaveDecisionStatusType {
  ALREADY_HAS_PAYMENT_LEAVE = 'ALREADY_HAS_PAYMENT_LEAVE',
  APPROVED = 'APPROVED',
  NEED_TO_PAY_FEE = 'NEED_TO_PAY_FEE',
  REJECTED = 'REJECTED'
}

export enum PaymentLeaveStatusType {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  FINISHED = 'FINISHED',
  PENDING = 'PENDING'
}

export enum PaymentLeaveType {
  ONLY_PRINCIPAL = 'ONLY_PRINCIPAL',
  WHOLE_SCHEDULE = 'WHOLE_SCHEDULE'
}

/** Payment method available to use */
export type PaymentMethod = {
  /** The Beneficiary Name of receiver account */
  beneficiary_name?: Maybe<Scalars['String']['output']>;
  country_code: Scalars['String']['output'];
  /** The IBAN of receiver account */
  iban?: Maybe<Scalars['String']['output']>;
  is_test: Scalars['Boolean']['output'];
  /** String identifier of a payment method */
  key: Scalars['String']['output'];
  logo_url: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** String id of payment provider (e.g. Klix) */
  provider: Scalars['String']['output'];
  /** The SWIFT code of receiver account */
  swift?: Maybe<Scalars['String']['output']>;
  /** Type of a payment method (CARD, BANKLINK) */
  type: Scalars['String']['output'];
};

/** Which entity was used for payment, e.g SEB */
export enum PaymentType {
  DEBT_COLLECTION_JULIANUS = 'DEBT_COLLECTION_JULIANUS',
  DEPOSIT_BRITE = 'DEPOSIT_BRITE',
  DEPOSIT_EVERYPAY = 'DEPOSIT_EVERYPAY',
  DEPOSIT_GETKEVIN = 'DEPOSIT_GETKEVIN',
  DEPOSIT_GETKEVIN_DIRECT_PAYMENT = 'DEPOSIT_GETKEVIN_DIRECT_PAYMENT',
  DEPOSIT_ISO20022XML_IMPORT = 'DEPOSIT_ISO20022XML_IMPORT',
  DEPOSIT_KLIX = 'DEPOSIT_KLIX',
  DEPOSIT_KLIX_DIRECT_PAYMENT = 'DEPOSIT_KLIX_DIRECT_PAYMENT',
  DEPOSIT_MANUAL = 'DEPOSIT_MANUAL',
  DEPOSIT_SALTEDGE = 'DEPOSIT_SALTEDGE',
  DEPOSIT_SEB = 'DEPOSIT_SEB',
  DEPOSIT_STRIPE_DIRECT_PAYMENT = 'DEPOSIT_STRIPE_DIRECT_PAYMENT',
  EVERYPAY_AUTOMATIC = 'EVERYPAY_AUTOMATIC',
  RETURN_TRUSTLY = 'RETURN_TRUSTLY',
  WITHDRAWAL_BRITE = 'WITHDRAWAL_BRITE',
  WITHDRAWAL_MANUAL = 'WITHDRAWAL_MANUAL',
  WITHDRAWAL_SEB = 'WITHDRAWAL_SEB',
  WITHDRAWAL_TRUSTLY = 'WITHDRAWAL_TRUSTLY'
}

/** Fields related to Paysera payment methods, that will be shown to the user in purchase flow */
export type PayseraPaymentMethod = {
  /** Unique key that identifies the payment method */
  key: Scalars['String']['output'];
  /** URL of the payment method logo */
  logo_url: Scalars['String']['output'];
  /** Title of the payment method, that will be shown to the user */
  title: Scalars['String']['output'];
};

export enum PoliticalExposure {
  ESTONIA = 'ESTONIA',
  LATVIA = 'LATVIA',
  LITHUANIA = 'LITHUANIA',
  NONE = 'NONE',
  SECOND = 'SECOND',
  THIRD = 'THIRD'
}

/** Someone that ESTO sells their applications to */
export type PortfolioAcquirer = {
  address?: Maybe<Scalars['String']['output']>;
  beneficiary_name?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  iban?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  registry_code?: Maybe<Scalars['String']['output']>;
};

/** The acquirement of a loan or multiple loans by another party */
export type PortfolioSale = {
  id: Scalars['Int']['output'];
  portfolio_acquirer?: Maybe<PortfolioAcquirer>;
  portfolio_acquirer_id: Scalars['Int']['output'];
};

/** Pivot between application/credit account and portfolio acquirer */
export type PortfolioSaleProduct = {
  application?: Maybe<Application>;
  application_id?: Maybe<Scalars['Int']['output']>;
  /** Timestamp, when the application was sold by us to the acquirer */
  created_at: Scalars['Int']['output'];
  credit_account_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  portfolio_sale?: Maybe<PortfolioSale>;
  portfolio_sale_id: Scalars['Int']['output'];
  /** What state the sale is in currently. e.g sold, being sold */
  status: PortfolioSaleProductStatus;
  /** Timestamp, when the application was sold by us to the acquirer */
  termination_date?: Maybe<Scalars['String']['output']>;
};

export enum PortfolioSaleProductStatus {
  CANCELLED = 'CANCELLED',
  CUSTOMER_NOTIFIED = 'CUSTOMER_NOTIFIED',
  SOLD = 'SOLD',
  STARTED = 'STARTED'
}

/** Pre-signing conversion offer for applications */
export type PreSigningConversionOffer = {
  /** The date when the conversion offer was accepted */
  accepted_at?: Maybe<Scalars['Int']['output']>;
  /** The initial schedule type like ESTO_X */
  initial_schedule_type: Scalars['String']['output'];
  /** The new schedule type like REGULAR */
  new_schedule_type: Scalars['String']['output'];
};

/** Pricing constants for things like maximum loan period or penalty amounts. */
export type Pricing = {
  /** Name of the pricing setting */
  key: Scalars['String']['output'];
  /** The pricing setting value type */
  type: Scalars['String']['output'];
  /** Value of the pricing setting */
  value: Scalars['String']['output'];
};

export enum PurchaseFlowCreditAccountActionType {
  ACCOUNTSCORING_ABANDONED = 'ACCOUNTSCORING_ABANDONED',
  ACCOUNTSCORING_BLOCKED = 'ACCOUNTSCORING_BLOCKED',
  ACCOUNTSCORING_INVALID_LOGIN = 'ACCOUNTSCORING_INVALID_LOGIN',
  ACCOUNTSCORING_PROCESS_ERROR = 'ACCOUNTSCORING_PROCESS_ERROR',
  ACCOUNTSCORING_PROCESS_FINISHED = 'ACCOUNTSCORING_PROCESS_FINISHED',
  ACCOUNTSCORING_REDIRECTED = 'ACCOUNTSCORING_REDIRECTED',
  CHOOSING_ADDITIONAL_SETTINGS = 'CHOOSING_ADDITIONAL_SETTINGS',
  CHOOSING_CONTACT_EXTRA_INFO = 'CHOOSING_CONTACT_EXTRA_INFO',
  CHOOSING_CONTACT_INFO = 'CHOOSING_CONTACT_INFO',
  CHOOSING_CONTACT_SPOUSE_INFO = 'CHOOSING_CONTACT_SPOUSE_INFO',
  CHOOSING_DOCUMENT = 'CHOOSING_DOCUMENT',
  INSTANTOR_ABANDONED = 'INSTANTOR_ABANDONED',
  INSTANTOR_BLOCKED = 'INSTANTOR_BLOCKED',
  INSTANTOR_INVALID_LOGIN = 'INSTANTOR_INVALID_LOGIN',
  INSTANTOR_PROCESS_ERROR = 'INSTANTOR_PROCESS_ERROR',
  INSTANTOR_PROCESS_FINISHED = 'INSTANTOR_PROCESS_FINISHED',
  LANDED = 'LANDED',
  LOGGING_IN_WITH_EPARAKSTS = 'LOGGING_IN_WITH_EPARAKSTS',
  LOGGING_IN_WITH_ID_CARD = 'LOGGING_IN_WITH_ID_CARD',
  LOGGING_IN_WITH_MOBILE = 'LOGGING_IN_WITH_MOBILE',
  LOGGING_IN_WITH_PASSWORD = 'LOGGING_IN_WITH_PASSWORD',
  LOGGING_IN_WITH_PAYSERA = 'LOGGING_IN_WITH_PAYSERA',
  LOGGING_IN_WITH_SMART_ID = 'LOGGING_IN_WITH_SMART_ID',
  REJECTED = 'REJECTED',
  SIGNING_PAGE = 'SIGNING_PAGE',
  SIGNING_SPOUSE_CONSENT = 'SIGNING_SPOUSE_CONSENT',
  STARTING_ACCOUNTSCORING = 'STARTING_ACCOUNTSCORING',
  STARTING_INSTANTOR = 'STARTING_INSTANTOR',
  STARTING_INSTANTOR_WHILE_WITHDRAWING = 'STARTING_INSTANTOR_WHILE_WITHDRAWING',
  SUCCESSFULLY_SIGNED = 'SUCCESSFULLY_SIGNED',
  SUCCESS_PAGE = 'SUCCESS_PAGE',
  UPDATING_TERMS = 'UPDATING_TERMS'
}

/** Shows the actions taken in application purchase flow */
export type PurchaseFlowHistory = {
  /** What action the user performed in purchase flow, e.g. update_credit_info */
  action: Scalars['String']['output'];
  application_id?: Maybe<Scalars['Int']['output']>;
  created_at: Scalars['Int']['output'];
  credit_account_id?: Maybe<Scalars['Int']['output']>;
  /** The data used in this action, represented in json format */
  data?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
};

/** General info about purchase flow, for example fields shown to the customer */
export type PurchaseFlowInfo = {
  /** Whether customer have to confirm that he read, understood and agree to Consumer credit information */
  agree_consumer_credit_information_required: Scalars['Boolean']['output'];
  /** Fields that are asked from the customer in the first step */
  contact_fields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Extra fields that are asked from the customer in the second step */
  extra_fields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Whether customer has legal person option */
  legal_person_enabled: Scalars['Boolean']['output'];
  /** Spouse info fields that are asked from the customer in the third step */
  spouse_fields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export enum PurposeOfLoanType {
  MARRIED_HOUSEHOLD = 'MARRIED_HOUSEHOLD',
  MARRIED_PERSONAL = 'MARRIED_PERSONAL',
  NA = 'NA',
  NOT_MARRIED_PERSONAL = 'NOT_MARRIED_PERSONAL'
}

export type Query = {
  /** Query for getting status AccountScoring invitation for PSD2 process */
  accountscoring_invitation?: Maybe<AccountScoringInvitation>;
  /** Query for getting status account statement, that is analyzed by AccountScoring */
  accountscoring_statement?: Maybe<AccountScoringInvitation>;
  /** Get all active languages */
  active_languages?: Maybe<Array<Maybe<Language>>>;
  /** Get an application by its short or long reference key */
  application_by_reference?: Maybe<Application>;
  /** Get the first installment date for given application */
  application_first_due_at: Scalars['String']['output'];
  /** Receive all installments for the application */
  application_installments?: Maybe<Array<Maybe<Installment>>>;
  /** Retrieve logo and other custom attributes for the application, depending if it is private label */
  application_private_label?: Maybe<ApplicationPrivateLabel>;
  /** Get the remaining amount to pay for the application at current date */
  application_remaining_amount: Scalars['Float']['output'];
  /**
   * Get all possible pricing settings (i.e for every period the possible interest, contract fee,
   *             monthly payment etc)
   */
  calculate_credit_settings?: Maybe<Array<Maybe<CreditSettingCalculator>>>;
  /** Checks whether the given application can be refinanced or not */
  can_refinance_application: Scalars['Boolean']['output'];
  /** Retrieves total card payment amount and fee % for a given payment amount */
  card_payment_fee?: Maybe<CardPaymentFee>;
  /**
   * Retrieve information about cashier sales made in a physical store. Null is returned, if the
   *             user has made no sales during since last year.
   */
  cashier_application_sales?: Maybe<CashierApplicationSales>;
  /** Get all of applications issued by specific cashier */
  cashier_applications?: Maybe<ApplicationPagination>;
  /** Find cashiers that are not already working at the specified store */
  cashiers_for_store?: Maybe<UserPagination>;
  /** Retrieve the credit account of a customer */
  credit_account?: Maybe<CreditAccount>;
  /** Get a credit account by its hash */
  credit_account_by_hash?: Maybe<CreditAccount>;
  /** Receive the CA first monthly payment amount if the client withdraws the specified amount in the input. Can also be used for converting an existing HP to CA */
  credit_account_first_monthly_payment: Scalars['Float']['output'];
  /** A query to get a specific credit account limit recalculation. */
  credit_account_limit_recalculation?: Maybe<CreditAccountLimitRecalculation>;
  /** Get credit limit recalculation eligibility status. */
  credit_account_limit_recalculation_eligibility?: Maybe<DecisionEngineResultType>;
  /** Retrieve the amount to pay for the next credit account invoice */
  credit_account_next_invoice?: Maybe<CreditAccountNextInvoice>;
  credit_account_transactions?: Maybe<TransactionPagination>;
  /** Get the initial withdrawal details that we will show in frontend to the client. */
  credit_account_withdrawal?: Maybe<CreditAccountWithdrawalDetails>;
  /** Get withdrawal eligibility status, if hash is sent as param check by specific CAW */
  credit_account_withdrawal_eligibility?: Maybe<DecisionEngineResultType>;
  /** Retrieve withdrawals for a credit account as a pagination */
  credit_account_withdrawals?: Maybe<CreditAccountWithdrawalPagination>;
  /** Get credit settings by net total and/or a given month */
  credit_settings?: Maybe<Array<Maybe<CreditSetting>>>;
  /** Get credit settings by net total and/or a given month for specific schedule types */
  credit_settings_for_schedule_types?: Maybe<CreditSettingsForScheduleTypes>;
  /** Get the application currently in session or create a new one, if one does not exist */
  current_application?: Maybe<Application>;
  /** A query to get info on CAW based on hash. Requires auth. */
  current_credit_account_withdrawal?: Maybe<CreditAccountWithdrawal>;
  /** Find all employers by search input. Used in purchase flow for auto-complete */
  employers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Retrieve a list of all employment sectors */
  employment_sectors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Query for getting consent for request to EMTA (Estonian Tax Agency) */
  emta_consent?: Maybe<RiaConsent>;
  /** Try to log the user in with eParaksts */
  eparaksts_login?: Maybe<LoginChallenge>;
  /** Get the url to a document by its language and name */
  esto_legal_document?: Maybe<EstoLegalDocumentType>;
  esto_legal_documents?: Maybe<Array<Maybe<EstoLegalDocumentType>>>;
  /** Get FAQ categories and its subs in the specified language */
  faq_categories?: Maybe<Array<Maybe<FaqCategory>>>;
  /** Generates installments based on HP credit settings. Does not save installments to database */
  generate_installments?: Maybe<Array<Maybe<Installment>>>;
  /** Get Klix banklink payment from Database */
  get_klix_payment?: Maybe<KlixPayment>;
  /** Get list of MerchantDiscounts for specified merchant */
  get_merchant_discounts?: Maybe<Array<Maybe<MerchantDiscount>>>;
  /** Get Stripe Payment from Database */
  get_stripe_payment?: Maybe<StripePayment>;
  instantor_response?: Maybe<InstantorResponse>;
  /** Get invoices of users or merchants */
  invoices?: Maybe<InvoicePagination>;
  /** Get the eligibility status for a legal person by its registry code */
  legal_person_eligibility: Scalars['String']['output'];
  /** Get the currently logged in user */
  me?: Maybe<User>;
  /** Retrieve the specified merchant */
  merchant?: Maybe<Merchant>;
  /** Retrieve an application belonging to this merchant */
  merchant_application?: Maybe<Application>;
  merchant_applications?: Maybe<MerchantApplicationIndexPagination>;
  merchant_balance?: Maybe<UnpaidMerchant>;
  /** Returns merchant invite if hash exists in database */
  merchant_invite?: Maybe<MerchantInvite>;
  /** Retrieve payments made by ESTO to the merchant */
  merchant_payments?: Maybe<PaymentPagination>;
  /** Retrieve all transactions related to the merchant */
  merchant_transactions?: Maybe<TransactionRowPagination>;
  /**
   * Get the minimum monthly payment (longest period) for the regular application, even if it
   *             has a ConvertingSchedule or similar campaign
   */
  min_regular_monthly_payment?: Maybe<Scalars['Float']['output']>;
  /** Try to log the user in with Mobile-ID */
  mobile_login?: Maybe<LoginChallenge>;
  /** Retrieve a list of all occupation categories */
  occupation_categories?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Retrieves list of available payment methods across all payment providers */
  payment_methods?: Maybe<Array<Maybe<PaymentMethod>>>;
  /** Try to log the user in with Paysera. It requires PayserLoginMutation to be called first */
  paysera_login?: Maybe<LoginPollStatus>;
  /** Retrieve available payment methods for Paysera */
  paysera_payment_methods?: Maybe<Array<Maybe<PayseraPaymentMethod>>>;
  /** Polls Paysera signing callback status. Possible values: NOT_FOUND, SUCCESS, PENDING, FAILED */
  paysera_payment_status_query?: Maybe<Scalars['String']['output']>;
  /** Polls Paysera contract signing. If we have received callback from Paysera, then it tries signing. */
  paysera_signing_poll?: Maybe<Scalars['Boolean']['output']>;
  /** Retrieve a list of political exposure options */
  political_exposures?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Retrieve the pricing constants for things like maximum loan period or penalty amounts. */
  pricing?: Maybe<Array<Maybe<Pricing>>>;
  /**
   * Get general info about purchase flow, e.g fields that should be asked
   *             in (application or CA) purchase flow from the customer
   */
  purchase_flow_info?: Maybe<PurchaseFlowInfo>;
  /** Retrieve schedule type settings for specific schedule type. */
  schedule_type_settings?: Maybe<ScheduleTypeSettings>;
  /** Retrieve schedule type settings for all schedule types. */
  schedule_types_settings?: Maybe<ScheduleTypesSettings>;
  /**
   * Calculate the credit settings for a small loan. The answers are not exact and are only meant for
   *             displaying to the customer!
   */
  small_loan_calculator?: Maybe<Array<Maybe<CreditSetting>>>;
  /** Try to log the user in with Smart-ID */
  smart_id_login?: Maybe<LoginChallenge>;
  /** Whether user is eligible to apply for ESTO Switch */
  switch_eligibility: Scalars['Boolean']['output'];
  user_applications?: Maybe<UserApplications>;
  /** Get the eligibility status for a user by their ID code */
  user_eligibility: Scalars['String']['output'];
  /** Generate invitation link for user that redirects to profile login */
  user_invitation_link: Scalars['String']['output'];
  /** Retrieve all legal people belonging to user */
  user_legal_people?: Maybe<Array<Maybe<LegalPerson>>>;
  /**
   * Get data for a user to make their payment. It will get loans that should be paid right
   *           now with the current invoice. This can also be used while logged out
   */
  user_payment_data?: Maybe<UserPayment>;
  /** Retrieve current user premium subscription status */
  user_premium_account_subscription_status: Scalars['String']['output'];
  /** Unified transactions list for user. Each transaction is either Transaction or TransactionRow instance depending of conditions */
  user_transactions?: Maybe<UserTransactionPagination>;
};


export type QueryAccountscoringInvitationArgs = {
  application_id?: InputMaybe<Scalars['Int']['input']>;
  credit_account_id?: InputMaybe<Scalars['Int']['input']>;
  response_id?: InputMaybe<Scalars['Int']['input']>;
  user_id: Scalars['Int']['input'];
};


export type QueryAccountscoringStatementArgs = {
  application_id?: InputMaybe<Scalars['Int']['input']>;
  credit_account_id?: InputMaybe<Scalars['Int']['input']>;
  user_id: Scalars['Int']['input'];
};


export type QueryApplicationByReferenceArgs = {
  reference_key?: InputMaybe<Scalars['String']['input']>;
  short_reference?: InputMaybe<Scalars['String']['input']>;
};


export type QueryApplicationFirstDueAtArgs = {
  application_id: Scalars['Int']['input'];
  has_payment_leave: Scalars['Boolean']['input'];
};


export type QueryApplicationInstallmentsArgs = {
  application_id: Scalars['Int']['input'];
};


export type QueryApplicationPrivateLabelArgs = {
  reference_key: Scalars['String']['input'];
};


export type QueryApplicationRemainingAmountArgs = {
  application_id: Scalars['Int']['input'];
};


export type QueryCalculateCreditSettingsArgs = {
  application_id?: InputMaybe<Scalars['Int']['input']>;
  down_payment?: InputMaybe<Scalars['Float']['input']>;
  merchant_id?: InputMaybe<Scalars['Int']['input']>;
  net_total: Scalars['Float']['input'];
  schedule_type?: InputMaybe<ApplicationScheduleType>;
};


export type QueryCanRefinanceApplicationArgs = {
  application_id: Scalars['Int']['input'];
};


export type QueryCardPaymentFeeArgs = {
  amount: Scalars['Float']['input'];
  payment_provider: Scalars['String']['input'];
};


export type QueryCashierApplicationSalesArgs = {
  merchant_id: Scalars['Int']['input'];
};


export type QueryCashierApplicationsArgs = {
  direction?: InputMaybe<Direction>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  merchant_id: Scalars['Int']['input'];
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
  user_id: Scalars['Int']['input'];
};


export type QueryCashiersForStoreArgs = {
  cashier_name?: InputMaybe<Scalars['String']['input']>;
  direction?: InputMaybe<Direction>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
  store_id: Scalars['Int']['input'];
};


export type QueryCreditAccountArgs = {
  credit_account_id?: InputMaybe<Scalars['Int']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  invoice_reference_nr?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCreditAccountByHashArgs = {
  hash: Scalars['String']['input'];
};


export type QueryCreditAccountFirstMonthlyPaymentArgs = {
  credit_account_id?: InputMaybe<Scalars['Int']['input']>;
  withdrawal_amount: Scalars['Float']['input'];
};


export type QueryCreditAccountLimitRecalculationArgs = {
  hash: Scalars['String']['input'];
};


export type QueryCreditAccountLimitRecalculationEligibilityArgs = {
  hash?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCreditAccountNextInvoiceArgs = {
  additional_amount?: InputMaybe<Scalars['Float']['input']>;
  credit_account_id: Scalars['Int']['input'];
};


export type QueryCreditAccountTransactionsArgs = {
  credit_account_id: Scalars['Int']['input'];
  direction?: InputMaybe<Direction>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CreditAccountTransactionsOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCreditAccountWithdrawalArgs = {
  amount: Scalars['Float']['input'];
  credit_account_id: Scalars['Int']['input'];
};


export type QueryCreditAccountWithdrawalEligibilityArgs = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  credit_account_id?: InputMaybe<Scalars['Int']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCreditAccountWithdrawalsArgs = {
  credit_account_id: Scalars['Int']['input'];
  direction?: InputMaybe<Direction>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCreditSettingsArgs = {
  down_payment?: InputMaybe<Scalars['Float']['input']>;
  merchant_id?: InputMaybe<Scalars['Int']['input']>;
  month?: InputMaybe<Scalars['Int']['input']>;
  net_total?: InputMaybe<Scalars['Float']['input']>;
  schedule_type?: InputMaybe<ApplicationScheduleType>;
};


export type QueryCreditSettingsForScheduleTypesArgs = {
  down_payment?: InputMaybe<Scalars['Float']['input']>;
  merchant_id?: InputMaybe<Scalars['Int']['input']>;
  month?: InputMaybe<Scalars['Int']['input']>;
  net_total?: InputMaybe<Scalars['Float']['input']>;
  schedule_types?: InputMaybe<Array<InputMaybe<ApplicationScheduleType>>>;
};


export type QueryCurrentApplicationArgs = {
  reference_key: Scalars['String']['input'];
};


export type QueryCurrentCreditAccountWithdrawalArgs = {
  hash: Scalars['String']['input'];
};


export type QueryEmployersArgs = {
  employer_name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryEmtaConsentArgs = {
  application_id?: InputMaybe<Scalars['Int']['input']>;
  credit_account_id?: InputMaybe<Scalars['Int']['input']>;
  credit_account_limit_recalculation_id?: InputMaybe<Scalars['Int']['input']>;
  credit_account_withdrawal_id?: InputMaybe<Scalars['Int']['input']>;
  return_url: Scalars['String']['input'];
  user_id: Scalars['Int']['input'];
};


export type QueryEparakstsLoginArgs = {
  code: Scalars['String']['input'];
  return_url: Scalars['String']['input'];
};


export type QueryEstoLegalDocumentArgs = {
  document: Scalars['String']['input'];
  language: Scalars['String']['input'];
};


export type QueryEstoLegalDocumentsArgs = {
  language: Scalars['String']['input'];
};


export type QueryFaqCategoriesArgs = {
  language_abbr: Scalars['String']['input'];
};


export type QueryGenerateInstallmentsArgs = {
  reference_key: Scalars['String']['input'];
};


export type QueryGetKlixPaymentArgs = {
  hash: Scalars['String']['input'];
  session_id: Scalars['String']['input'];
};


export type QueryGetMerchantDiscountsArgs = {
  merchant_id: Scalars['Int']['input'];
};


export type QueryGetStripePaymentArgs = {
  hash: Scalars['String']['input'];
  session_id: Scalars['String']['input'];
};


export type QueryInstantorResponseArgs = {
  reference_key: Scalars['String']['input'];
  user_id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInvoicesArgs = {
  direction?: InputMaybe<Direction>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  merchant_id?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLegalPersonEligibilityArgs = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  merchant_id: Scalars['Int']['input'];
  registry_code: Scalars['String']['input'];
};


export type QueryMeArgs = {
  is_me: Scalars['Boolean']['input'];
};


export type QueryMerchantArgs = {
  merchant_id: Scalars['Int']['input'];
};


export type QueryMerchantApplicationArgs = {
  application_id: Scalars['Int']['input'];
};


export type QueryMerchantApplicationsArgs = {
  balance_at_timestamp?: InputMaybe<Scalars['Int']['input']>;
  created_by_user_id?: InputMaybe<Scalars['Int']['input']>;
  date_range?: InputMaybe<DateRange>;
  direction?: InputMaybe<Direction>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  merchant_id: Scalars['Int']['input'];
  merchant_ref_or_user_name?: InputMaybe<Scalars['String']['input']>;
  merchant_reference?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<MerchantApplicationsOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
  schedule_types?: InputMaybe<Array<InputMaybe<ApplicationScheduleType>>>;
  statuses?: InputMaybe<Array<InputMaybe<AdminApplicationStatuses>>>;
  user_name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMerchantBalanceArgs = {
  balance_at?: InputMaybe<Scalars['String']['input']>;
  merchant_id: Scalars['Int']['input'];
};


export type QueryMerchantInviteArgs = {
  invite_hash: Scalars['String']['input'];
};


export type QueryMerchantPaymentsArgs = {
  date_range?: InputMaybe<DateRange>;
  direction?: InputMaybe<Direction>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  merchant_id: Scalars['Int']['input'];
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMerchantTransactionsArgs = {
  book_types?: InputMaybe<Array<InputMaybe<TransactionBookType>>>;
  customer_name?: InputMaybe<Scalars['String']['input']>;
  date_range?: InputMaybe<DateRange>;
  direction?: InputMaybe<Direction>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  merchant_id: Scalars['Int']['input'];
  merchant_ref_or_customer_name?: InputMaybe<Scalars['String']['input']>;
  methods?: InputMaybe<Array<InputMaybe<TransactionMerchantMethod>>>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMinRegularMonthlyPaymentArgs = {
  application_id: Scalars['Int']['input'];
  down_payment: Scalars['Float']['input'];
  net_total: Scalars['Float']['input'];
};


export type QueryMobileLoginArgs = {
  session_id: Scalars['String']['input'];
};


export type QueryPaymentMethodsArgs = {
  country_code?: InputMaybe<Scalars['String']['input']>;
  direct_payment?: InputMaybe<Scalars['Boolean']['input']>;
  test_mode?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPayseraLoginArgs = {
  session_id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPayseraPaymentMethodsArgs = {
  language_abbr: Scalars['String']['input'];
};


export type QueryPayseraSigningPollArgs = {
  session_id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPricingArgs = {
  keys: Array<InputMaybe<Scalars['String']['input']>>;
};


export type QueryScheduleTypeSettingsArgs = {
  schedule_type?: InputMaybe<ApplicationScheduleType>;
};


export type QuerySmallLoanCalculatorArgs = {
  amount: Scalars['Float']['input'];
  schedule_type?: InputMaybe<ApplicationScheduleType>;
};


export type QuerySmartIdLoginArgs = {
  session_id: Scalars['String']['input'];
};


export type QuerySwitchEligibilityArgs = {
  user_id: Scalars['Int']['input'];
};


export type QueryUserApplicationsArgs = {
  direction?: InputMaybe<Direction>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserApplicationsOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<AdminApplicationStatuses>>>;
  user_id: Scalars['Int']['input'];
};


export type QueryUserEligibilityArgs = {
  amount: Scalars['Float']['input'];
  merchant_id: Scalars['Int']['input'];
  pin: Scalars['String']['input'];
};


export type QueryUserInvitationLinkArgs = {
  email: Scalars['String']['input'];
};


export type QueryUserLegalPeopleArgs = {
  user_id: Scalars['Int']['input'];
};


export type QueryUserPaymentDataArgs = {
  reference_key: Scalars['String']['input'];
};


export type QueryUserPremiumAccountSubscriptionStatusArgs = {
  user_id: Scalars['Int']['input'];
};


export type QueryUserTransactionsArgs = {
  direction?: InputMaybe<Direction>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  methods?: InputMaybe<Array<InputMaybe<TransactionMethod>>>;
  orderBy?: InputMaybe<TransactionsOrderBy>;
  page?: InputMaybe<Scalars['Int']['input']>;
  user_id: Scalars['Int']['input'];
};

/** A request to RIA for getting access to info from government organizations (e.g. EMTA) */
export type RiaConsent = {
  business_identifier: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  pin: Scalars['String']['output'];
  reference?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  /** Timestamp, expiration date of consent */
  valid_until?: Maybe<Scalars['Int']['output']>;
};

/** Reminder of an overdue payment */
export type Reminder = {
  /** Total amount the user has to pay at the time of the reminder */
  amount_to_pay: Scalars['Float']['output'];
  application_id: Scalars['Int']['output'];
  created_at?: Maybe<Scalars['Int']['output']>;
  /** Number of days the payment associated with this reminder is overdue */
  days_overdue: Scalars['Int']['output'];
  /** Fee that is added with the reminder */
  fee: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  /** The type of reminder (CALL, VERO_EMAIL, VERO_SMS, EMAIL, MAIL, SMS) represented in bits. */
  method?: Maybe<ReminderMethod>;
  /** Represents the number of reminders sent in a row for an overdue installment */
  number: Scalars['Int']['output'];
};

export enum ReminderMethod {
  CALL = 'CALL',
  EMAIL = 'EMAIL',
  MAIL = 'MAIL',
  SMS = 'SMS',
  VERO_EMAIL = 'VERO_EMAIL',
  VERO_SMS = 'VERO_SMS'
}

/** Resale applications are potentially eligible applications which are currently unsigned */
export type Resale = {
  application_id?: Maybe<Scalars['Int']['output']>;
  /** The customer service rep who is handling this resale */
  claimed_by?: Maybe<User>;
  /** Id of the customer service rep who is handling the resale */
  claimed_by_id?: Maybe<Scalars['Int']['output']>;
  created_at: Scalars['Int']['output'];
  credit_account_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** When the resale process was terminated by admin */
  terminated_at?: Maybe<Scalars['Int']['output']>;
};

/** An application that is in the resale process */
export type ResaleApplication = {
  /** Determine whether to accrue interest and fees for a loan */
  accrue_interest_and_fees: Scalars['Boolean']['output'];
  /** Currently active payment leave for application */
  active_payment_leave?: Maybe<PaymentLeave>;
  /** Whether to add legal person info to the invoice */
  add_legal_person_to_invoice: Scalars['Boolean']['output'];
  /** If application has been modified, the original credit info data is stored here */
  application_modifications?: Maybe<Array<Maybe<ApplicationModification>>>;
  /** Helper table for making purchases via a physical cash register */
  application_reference?: Maybe<ApplicationReference>;
  /** ID of the application_user_info that this application belongs to */
  application_user_info_id?: Maybe<Scalars['Int']['output']>;
  /** Items bought with the purchase */
  basket_items?: Maybe<Array<Maybe<ApplicationBasketItem>>>;
  /** Date, when merchant was paid the application bonuses */
  bonus_paid_at?: Maybe<Scalars['String']['output']>;
  /** Application status based on information provided by the broker. */
  broker_status?: Maybe<Scalars['String']['output']>;
  /** Information about application that has been bought back from merchant */
  buyback_settings?: Maybe<ApplicationBuybackSettings>;
  /** A campaign that applies to the application, if enabled */
  campaign?: Maybe<ApplicationCampaign>;
  /** Determines, whether the application can be modified */
  can_be_modified: Scalars['Boolean']['output'];
  /** Whether an application can convert from a regular schedule to (long-term) CA/HP. */
  can_convert_from_regular: Scalars['Boolean']['output'];
  /** Whether the application can be converted manually to CA. */
  can_manually_convert_to_credit_account: Scalars['Boolean']['output'];
  /** Checks if the application can be refinanced or not */
  can_refinance: Scalars['Boolean']['output'];
  /** Optional URL, where the user will be redirected to after application was rejected */
  cancel_url?: Maybe<Scalars['String']['output']>;
  /** Request made by merchant to cancel this application */
  cancellation_request?: Maybe<ApplicationCancellationRequest>;
  /** Timestamp, when the application was cancelled (purchased item returned to merchant) */
  cancelled_at?: Maybe<Scalars['Int']['output']>;
  /** The user by whom this application was cancelled */
  cancelled_by?: Maybe<User>;
  /** Date, when merchant was paid cashier the application bonuses */
  cashier_bonus_paid_at?: Maybe<Scalars['String']['output']>;
  /** ConvertingSchedule regular campaign schedule */
  converting_schedule_regular?: Maybe<ConvertingScheduleRegular>;
  /** Timestamp, when the application was created */
  created_at: Scalars['Int']['output'];
  /** Additional info of the application about pricing */
  credit_info?: Maybe<ApplicationCreditInfo>;
  /** Fully rejected customers are given an option to get a loan from a third-party loan provider */
  customer_sale?: Maybe<CustomerSale>;
  /** How many days the hire purchase payment is overdue. 0 if HP payment is not overdue. */
  days_past_due?: Maybe<Scalars['Int']['output']>;
  /** Ongoing and finished claim collection processes */
  debt_collections?: Maybe<Array<Maybe<DebtCollection>>>;
  /** Timestamp, when the application was deleted */
  deleted_at?: Maybe<Scalars['Int']['output']>;
  /** Refunds history for specific Direct payment (ESTO Pay) */
  direct_payment_refunds?: Maybe<Array<Maybe<DirectPaymentRefund>>>;
  /** Down payment of the application */
  down_installments?: Maybe<Array<Maybe<Installment>>>;
  /** If the application was paid for early it will have additional info */
  early_payment?: Maybe<ApplicationEarlyPayment>;
  /**
   * Disable the eligibility check in order to not make actual queries to the Decision
   *                 Engine. In this case, the eligibility status and state will not change
   */
  eligibility_check_disabled?: Maybe<Scalars['Boolean']['output']>;
  /** 1: Eligible, 0: Pending, -1: Rejected */
  eligibility_state?: Maybe<Scalars['Int']['output']>;
  /** ELIGIBLE or reason why not eligible */
  eligibility_status?: Maybe<Scalars['String']['output']>;
  /** Timestamp of the last time eligibility was checked */
  eligibility_status_last_check?: Maybe<Scalars['Int']['output']>;
  /** Timestamp, when the last payment was made that paid for the application fully */
  ended_at?: Maybe<Scalars['Int']['output']>;
  /** Time when the application will be deleted, if it has not been signed */
  expires_at?: Maybe<Scalars['Int']['output']>;
  /** First monthly installment */
  first_installment?: Maybe<Installment>;
  /** True, if app for private person, false if for legal person and NULL if not fixed at all */
  fixed_for_private_person?: Maybe<Scalars['Boolean']['output']>;
  /** True, if application does NOT represent a legal person */
  for_private_person: Scalars['Boolean']['output'];
  /** Whether to forward rejected application to another broker. */
  forward_rejects: Scalars['Boolean']['output'];
  /** Whether it is a FREE HP application (no credit cost, no scoring) */
  free_hp_enabled: Scalars['Boolean']['output'];
  /** Whether this application was created in a retail (physical) store */
  from_retail: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  /**
   * Whether the user's identity needs verification (depends on requested amount).
   *                     Always include user relation with id_verification_method!
   */
  id_verification_required?: Maybe<Scalars['Boolean']['output']>;
  /** Time when the user (small loan) or merchant (hire purchase) was paid for by us */
  initial_paid_at?: Maybe<Scalars['Int']['output']>;
  /** Required installments for the application */
  installments?: Maybe<Array<Maybe<Installment>>>;
  /** Required installments for the application, ordered by due_at ASC */
  installments_ordered?: Maybe<Array<Maybe<Installment>>>;
  /** The fee for instant payout of small loan */
  instant_payment_fee: Scalars['Float']['output'];
  /** Current status of the Instantor request for this application */
  instantor_eligibility: InstantorEligibility;
  /** Identifier for the application that is used for payments */
  invoice_reference_nr?: Maybe<Scalars['String']['output']>;
  /** Whether the application converts (to CA or longer HP) */
  is_converting_schedule: Scalars['Boolean']['output'];
  /** Determines, if the user can apply for the loan specified by this application */
  is_eligible: Scalars['Boolean']['output'];
  /** Whether the funds will be paid out immediately to the client. Only applies for consumer loans */
  is_instant_payout: Scalars['Boolean']['output'];
  /** Whether the user can make instant payouts. */
  is_instant_payout_available: Scalars['Boolean']['output'];
  /** Determines, if direct payment can be refunded */
  is_refundable: Scalars['Boolean']['output'];
  /** If true, then this application is only used for testing purposes */
  is_test: Scalars['Boolean']['output'];
  /** klix payments connected to this application */
  klix_payments?: Maybe<Array<Maybe<KlixPayment>>>;
  /** Timestamp, when the last resale action (call, email) was made */
  last_action_timestamp?: Maybe<Scalars['Int']['output']>;
  /** Very last monthly installment that will be paid */
  last_installment?: Maybe<Installment>;
  /** Latest customer care decision result for related to application */
  latest_customer_care_decision?: Maybe<CustomerCareDecision>;
  /** Latest modification request made by merchant */
  latest_modification_request?: Maybe<ApplicationModificationRequest>;
  /** Info about the legal person connected to the application */
  legal_person_info?: Maybe<ApplicationLegalPersonInfo>;
  /**
   * Get ESTO's or merchant's or campaign logo, depending on the application.
   *                     NB! Always select merchant and campaign relations
   */
  logo_url: Scalars['String']['output'];
  /** Seller of the purchase */
  merchant?: Maybe<Merchant>;
  /** Data entered by the merchant for the application */
  merchant_data?: Maybe<ApplicationMerchantData>;
  /** ID of the merchant selling something to the user */
  merchant_id?: Maybe<Scalars['Int']['output']>;
  /** Requests made by merchant to modify application values such as net total */
  modification_requests?: Maybe<Array<Maybe<ApplicationModificationRequest>>>;
  /** The next installment that is unpaid (can be in the past as well) */
  next_installment?: Maybe<Installment>;
  /** The next installment date for the given application or oldest installment date, if overdue */
  next_installment_date?: Maybe<Scalars['String']['output']>;
  /**
   * The next payment amount for the application. If overdue then only overdue amount,
   *                     but otherwise get current month
   */
  next_payment_amount?: Maybe<Scalars['Float']['output']>;
  /** Other resale applications with the same user and merchant */
  other_resale_apps?: Maybe<Array<Maybe<ResaleApplication>>>;
  /** Application overdue installments */
  overdue_installments?: Maybe<Array<Maybe<Installment>>>;
  /** The total amount of principal that has been paid */
  paid_principal: Scalars['Float']['output'];
  /** Payment leaves taken for this application */
  payment_leaves?: Maybe<Array<Maybe<PaymentLeave>>>;
  /** Acquirer pivot of the product (application or credit account), if the product has been sold with a portfolio */
  portfolio_sale_product?: Maybe<PortfolioSaleProduct>;
  /** Pre-signing conversion offer for the application */
  pre_signing_conversion_offer?: Maybe<PreSigningConversionOffer>;
  /** Value taken from pricing. Max amount that an admin can waive from a hire purchase. */
  pricing_max_waive_amount: Scalars['Float']['output'];
  /** Timestamp, when the application was processed (for direct payments) */
  processed_at?: Maybe<Scalars['Int']['output']>;
  /** Get the name of merchant if private label or ESTO if not */
  provider_name: Scalars['String']['output'];
  /** URL where the purchase flow starts from */
  purchase_url: Scalars['String']['output'];
  /** A unique string to identify the application */
  reference_key: Scalars['String']['output'];
  /** The application that was refinanced by this application */
  refinanced_application?: Maybe<Application>;
  /** The application that refinanced this application */
  refinancing_parent_application?: Maybe<Application>;
  /** The application's id that refinances this application */
  refinancing_parent_id?: Maybe<Scalars['Int']['output']>;
  /** Get amount that was successfully refunded for Direct payment */
  refunded_amount: Scalars['Float']['output'];
  /** Timestamp, when the application was rejected */
  rejected_at?: Maybe<Scalars['Int']['output']>;
  /** Reminders that have been sent to the user because of overdue installments */
  reminders?: Maybe<Array<Maybe<Reminder>>>;
  /** The amount required for the purchase */
  requested_amount: Scalars['Float']['output'];
  /** Resale applications are potentially eligible applications which are currently unsigned */
  resale?: Maybe<Resale>;
  /** Resale call is created when the user is called during the resale process */
  resale_call?: Maybe<ResaleCall>;
  /** Optional URL, where the user will be redirected to after submitting the application */
  return_url?: Maybe<Scalars['String']['output']>;
  /** Type of the application, like hire-purchase or small loan */
  schedule_type: ApplicationScheduleType;
  /** Determine whether reminders should be sent */
  send_reminders: Scalars['Boolean']['output'];
  /** Whether a separate checkbox for pension query is shown at purchase flow */
  show_separate_pension_query: Scalars['Boolean']['output'];
  /** Timestamp, when the application was signed and accepted */
  signed_at?: Maybe<Scalars['Int']['output']>;
  /** Credit account eligibility status that is one of SimpleEligibilityStatus */
  simple_eligibility_status: Scalars['String']['output'];
  /** Timestamp, when the application was sold to a debt collector */
  sold_at?: Maybe<Scalars['Int']['output']>;
  /** Application status. One of the following: UNSIGNED, REJECTED, ACTIVE, TERMINATED, CANCELLED, DELETED, PROCESSED, ENDED */
  status: ApplicationStatus;
  /** Application sub-statuses */
  sub_statuses?: Maybe<Array<Maybe<LoanSubStatus>>>;
  /** Recurring payments subscription for the application */
  subscription?: Maybe<Subscription>;
  /** Timestamp, when the application was terminated (e.g when customer died or went bankrupt) */
  terminated_at?: Maybe<Scalars['Int']['output']>;
  termination_reason?: Maybe<Scalars['String']['output']>;
  /**
   * Total unpaid amount that should have been paid by now. NB! Only use for single queries
   *                     as it's actually a DB query
   */
  total_overdue: Scalars['Float']['output'];
  /** Get total paid amount for application */
  total_paid?: Maybe<Scalars['Float']['output']>;
  /** Type of the application, like hire-purchase or small loan */
  type: ApplicationType;
  /** Get the total unpaid principal amount for the loan */
  unpaid_principal?: Maybe<Scalars['Float']['output']>;
  /** Owner of the application */
  user?: Maybe<User>;
  /** ID of the user that this application belongs to */
  user_id?: Maybe<Scalars['Int']['output']>;
  /** Additional info of the application about the user */
  user_info?: Maybe<ApplicationUserInfo>;
};


/** An application that is in the resale process */
export type ResaleApplicationOtherResaleAppsArgs = {
  application_id: Scalars['Int']['input'];
};


/** An application that is in the resale process */
export type ResaleApplicationSimpleEligibilityStatusArgs = {
  do_query?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Resale call is created when the user is called during the resale process */
export type ResaleCall = {
  application_id: Scalars['Int']['output'];
  /** Timestamp, when the user was called */
  called_at: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  /** What was the call response */
  result: ResaleCallResult;
};

export enum ResaleCallResult {
  DID_NOT_ANSWER = 'DID_NOT_ANSWER',
  OTHER = 'OTHER',
  PRICING = 'PRICING',
  TECHNICAL = 'TECHNICAL'
}

/** A credit account that is in the resale process */
export type ResaleCreditAccount = {
  /** Determine whether to accrue interest and fees for a loan */
  accrue_interest_and_fees: Scalars['Boolean']['output'];
  /** Interest that has accrued, but has not been applied yet */
  accrued_interest: Scalars['Float']['output'];
  /** Management fee that has accrued, but has not been applied yet */
  accrued_management_fee: Scalars['Float']['output'];
  /** Actions that apply to the credit account */
  actions?: Maybe<Array<Maybe<CreditAccountAction>>>;
  /** Currently active payment leave for credit account */
  active_payment_leave?: Maybe<PaymentLeave>;
  annual_pct_rate: Scalars['Float']['output'];
  application_user_info_id?: Maybe<Scalars['Int']['output']>;
  /** The amount that can still be withdrawn from the credit account */
  available_balance: Scalars['Float']['output'];
  /** Campaigns that apply to the credit account */
  campaigns?: Maybe<Array<Maybe<CreditAccountCampaign>>>;
  campaigns_ordered_desc?: Maybe<Array<Maybe<CreditAccountCampaign>>>;
  /** The user can deactivate the credit account. Checks if he has active ESTO_X loan or PAY_LATER */
  can_deactivate: Scalars['Boolean']['output'];
  /** Whether the client can start requesting credit limit increase. */
  can_request_limit_increase: Scalars['Boolean']['output'];
  contract_editing_fee: Scalars['Float']['output'];
  /** All contracts the credit account has */
  contracts?: Maybe<Array<Maybe<Contract>>>;
  created_at: Scalars['String']['output'];
  /** Total credit that can be in use at one time */
  credit_limit: Scalars['Float']['output'];
  credit_limit_recalculation_fee: Scalars['Float']['output'];
  credit_score?: Maybe<UserCreditScore>;
  credit_scoring_fee: Scalars['Float']['output'];
  /** How many days the credit account is overdue. 0 if CA is not overdue. */
  days_past_due?: Maybe<Scalars['Int']['output']>;
  /** Timestamp, when the credit account was deactivated */
  deactivated_at?: Maybe<Scalars['String']['output']>;
  /** Ongoing and finished claim collection processes */
  debt_collections?: Maybe<Array<Maybe<DebtCollection>>>;
  deleted_at?: Maybe<Scalars['String']['output']>;
  /**
   * Disable the eligibility check in order to not make actual queries to the Decision
   *                 Engine. In this case, the eligibility status and state will not change
   */
  eligibility_check_disabled?: Maybe<Scalars['Boolean']['output']>;
  /** 1: Eligible, 0: Pending, -1: Rejected */
  eligibility_state?: Maybe<Scalars['Int']['output']>;
  /** ELIGIBLE or reason why not eligible */
  eligibility_status: Scalars['String']['output'];
  /** Datetime of the last time eligibility was checked */
  eligibility_status_last_check?: Maybe<Scalars['String']['output']>;
  /** Datetime when the CA contract expires. Expires within X months from signing unless re-signed */
  expires_at?: Maybe<Scalars['String']['output']>;
  /**
   * Calculate the first monthly payment for available credit limit.
   *                     NB! Only request this variable when completely necessary as it is a heavy calculation.
   */
  first_monthly_payment: Scalars['Float']['output'];
  /** Whether to forward rejected credit account to another broker. */
  forward_rejects: Scalars['Boolean']['output'];
  /** 1/X of principal that is at minimum added to every invoice */
  fraction_principal: Scalars['Int']['output'];
  /** The accrued interest up to a certain date for a credit account */
  future_accrued_interest?: Maybe<Scalars['Float']['output']>;
  /** The not yet accrued management fee up to a certain date for a credit account */
  future_accrued_management_fee?: Maybe<Scalars['Float']['output']>;
  /** A unique string to identify the credit account */
  hash: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** Fee that is taken for instantly depositing withdrawal amount to customer's bank account */
  instant_payment_fee: Scalars['Float']['output'];
  /** Current status of the Instantor request for this credit account */
  instantor_eligibility: InstantorEligibility;
  invoice_reference_nr: Scalars['String']['output'];
  invoices?: Maybe<Array<Maybe<CreditAccountInvoice>>>;
  irr: Scalars['Float']['output'];
  /** Determines, if the user can apply for the credit account */
  is_eligible: Scalars['Boolean']['output'];
  /** From which channel the customer came to us before signing the credit account */
  landed_from?: Maybe<Scalars['String']['output']>;
  /** Timestamp, when the last resale action (call, email) was made */
  last_action_timestamp?: Maybe<Scalars['Int']['output']>;
  /** Latest customer care decision result for related to credit account */
  latest_customer_care_decision?: Maybe<CustomerCareDecision>;
  /** Most recent credit account invoice */
  latest_invoice?: Maybe<CreditAccountInvoice>;
  /** How much it costs to change credit limit. */
  limit_recalculation_fee: Scalars['Float']['output'];
  /** % of principal amount accrued daily */
  management_fee_daily_pct: Scalars['Float']['output'];
  /** Whether management fee accruing is enabled */
  management_fee_enabled?: Maybe<Scalars['Boolean']['output']>;
  management_fee_monthly: Scalars['Float']['output'];
  /** 1/X of principal that is at minimum added to every invoice. DEPRECATED - use "fraction_principal" variable instead */
  max_period_months: Scalars['Int']['output'];
  /** Amount of the next CA invoice, including currently unpaid amounts */
  next_invoice_amount: Scalars['Float']['output'];
  /** to_date of the next invoice that will be created for this credit account */
  next_invoice_date?: Maybe<Scalars['String']['output']>;
  overflow: Scalars['Float']['output'];
  /** How much card payment fee is paid */
  paid_card_payment_fee: Scalars['Float']['output'];
  /** All payment leaves the credit account has had */
  payment_leaves?: Maybe<Array<Maybe<PaymentLeave>>>;
  /** Acquirer pivot of the product (application or credit account), if the product has been sold with a portfolio */
  portfolio_sale_product?: Maybe<PortfolioSaleProduct>;
  /** The limit up to which the credit limit could be increased right now */
  potential_credit_limit: Scalars['Float']['output'];
  /** Value taken from pricing. Max amount that an admin can waive from a credit account. */
  pricing_max_waive_amount: Scalars['Float']['output'];
  resale?: Maybe<Resale>;
  /** Determine whether reminders should be sent */
  send_reminders: Scalars['Boolean']['output'];
  /**
   * Determines whether credit account should be updated with fresh pricing values
   *                     and whether modification contract should be signed.
   */
  should_be_modified: Scalars['Boolean']['output'];
  /** Indicates whether there has been a limit change that needs to be followed by a contract. */
  should_sign_limit_change: Scalars['Boolean']['output'];
  signed_at?: Maybe<Scalars['String']['output']>;
  /** Credit account eligibility status that is one of SimpleEligibilityStatus */
  simple_eligibility_status: Scalars['String']['output'];
  /** Timestamp, when the credit account was sold to a debt collector */
  sold_at?: Maybe<Scalars['Int']['output']>;
  /** Activated or deactivated state */
  status?: Maybe<CreditAccountStatus>;
  /** Credit account sub-statuses */
  sub_statuses?: Maybe<Array<Maybe<LoanSubStatus>>>;
  /** Recurring payments subscription for the credit account */
  subscription?: Maybe<Subscription>;
  /** Timestamp, when the credit account was terminated */
  terminated_at?: Maybe<Scalars['String']['output']>;
  termination_reason?: Maybe<Scalars['String']['output']>;
  /** Total outstanding amount, including fees */
  total_outstanding: Scalars['Float']['output'];
  unpaid_contract_editing_fee: Scalars['Float']['output'];
  unpaid_credit_limit_recalculation_fee: Scalars['Float']['output'];
  unpaid_credit_scoring_fee: Scalars['Float']['output'];
  /** Total amount of unpaid fixed fees like withdrawal fee */
  unpaid_fees: Scalars['Float']['output'];
  /** How much instant payment fee is unpaid */
  unpaid_instant_payment_fee: Scalars['Float']['output'];
  /** Interest that has been applied and is outstanding */
  unpaid_interest: Scalars['Float']['output'];
  unpaid_management_fee: Scalars['Float']['output'];
  unpaid_payment_leave_fee: Scalars['Float']['output'];
  unpaid_principal: Scalars['Float']['output'];
  unpaid_reminder_fee: Scalars['Float']['output'];
  unpaid_withdrawal_fee: Scalars['Float']['output'];
  user?: Maybe<User>;
  user_id: Scalars['Int']['output'];
  user_info?: Maybe<ApplicationUserInfo>;
  user_profile?: Maybe<UserProfile>;
  /** Fixed amount that is deducted upon withdrawals */
  withdrawal_fee_amount: Scalars['Float']['output'];
  /** Percentage that is deducted from withdrawal principals */
  withdrawal_fee_pct: Scalars['Float']['output'];
  /** All withdrawals the credit account has */
  withdrawals?: Maybe<Array<Maybe<CreditAccountWithdrawal>>>;
};


/** A credit account that is in the resale process */
export type ResaleCreditAccountFutureAccruedInterestArgs = {
  balance_date?: InputMaybe<Scalars['String']['input']>;
  credit_account_id: Scalars['Int']['input'];
};


/** A credit account that is in the resale process */
export type ResaleCreditAccountFutureAccruedManagementFeeArgs = {
  balance_date?: InputMaybe<Scalars['String']['input']>;
  credit_account_id: Scalars['Int']['input'];
};


/** A credit account that is in the resale process */
export type ResaleCreditAccountSimpleEligibilityStatusArgs = {
  do_query?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A credit account withdrawal that is in the resale process */
export type ResaleCreditAccountWithdrawal = {
  /** The principal withdrawal amount */
  amount: Scalars['Float']['output'];
  application_user_info_id?: Maybe<Scalars['Int']['output']>;
  /** Datetime of the withdrawal approval */
  approved_at?: Maybe<Scalars['String']['output']>;
  /** Datetime record was created on */
  created_at: Scalars['String']['output'];
  /** Credit account of the withdrawal */
  credit_account?: Maybe<CreditAccount>;
  /** Credit Account ID */
  credit_account_id: Scalars['Int']['output'];
  /** Fee for scoring */
  credit_scoring_fee: Scalars['Float']['output'];
  /** Decision that comes from scoring */
  decision?: Maybe<Scalars['String']['output']>;
  /** Whether the withdrawal fee should be deducted from the withdrawal */
  deduct_fees: Scalars['Boolean']['output'];
  /** Eligibility state of the withdrawal, can be -1, 0 or 1 */
  eligibility_state: Scalars['Int']['output'];
  /** The hash of the withdrawal */
  hash: Scalars['String']['output'];
  /** Primary key */
  id: Scalars['Int']['output'];
  /** Additional fee, if the type is INSTANT */
  instant_payment_fee: Scalars['Float']['output'];
  /** Timestamp, when the last resale action was made */
  last_action_timestamp?: Maybe<Scalars['Int']['output']>;
  /** Datetime of the withdrawal rejection */
  rejected_at?: Maybe<Scalars['String']['output']>;
  resale?: Maybe<Resale>;
  /** Simple eligibility status */
  simple_eligibility_status: Scalars['String']['output'];
  /** Withdrawal status, determinate if the withdrawal completed or not, that's auto calculated */
  status: CreditAccountWithdrawalStatus;
  /** The IBAN to whom the withdrawal will be made */
  to_iban?: Maybe<Scalars['String']['output']>;
  /** The beneficiary name to whom the withdrawal will be made */
  to_name?: Maybe<Scalars['String']['output']>;
  /** When the withdrawal has been made points to the transaction ID */
  transaction_id?: Maybe<Scalars['Int']['output']>;
  /** Type of the withdrawal, REGULAR or INSTANT depending on withdrawal details */
  type: CreditAccountWithdrawalType;
  /** Application user info of the credit account withdrawal */
  user_info?: Maybe<ApplicationUserInfo>;
  /** Withdrawal fee that can be deducted from principal */
  withdrawal_fee: Scalars['Float']['output'];
};

export enum ResaleType {
  APPLICATION = 'APPLICATION',
  CREDIT_ACCOUNT = 'CREDIT_ACCOUNT',
  USER_WITHOUT_CREDIT_ACCOUNT = 'USER_WITHOUT_CREDIT_ACCOUNT'
}

/** Proceeds for a single month for Retirement Fund */
export type RetirementFundProceed = {
  amount: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  /** Date, when the proceed was made. One for each month */
  date: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  net_salary: Scalars['Float']['output'];
  request_id: Scalars['Int']['output'];
};

/** A request from Estonia's Retirement Fund about the user's proceeds */
export type RetirementFundProceedsRequest = {
  created_at: Scalars['Int']['output'];
  first_name: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  last_name: Scalars['String']['output'];
  pin: Scalars['String']['output'];
  /** Proceeds for each month */
  proceeds?: Maybe<Array<Maybe<RetirementFundProceed>>>;
  user_id: Scalars['Int']['output'];
};

/** Notification about incoming/outgoing SEB payments */
export type SebNotification = {
  /** The amount of the SEB transaction. */
  amount: Scalars['Float']['output'];
  /** Exists, if it's a payment from a user for an application */
  application?: Maybe<Application>;
  /** Timestamp, when the notification was created */
  created_at: Scalars['Int']['output'];
  /** Explanation of the payment */
  explanation?: Maybe<Scalars['String']['output']>;
  /** IBAN of the SEB transaction initiator */
  from_iban?: Maybe<Scalars['String']['output']>;
  /** Name of the SEB transaction initiator */
  from_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  payment_id?: Maybe<Scalars['Int']['output']>;
  /** Reference nr on an incoming payment */
  reference_nr?: Maybe<Scalars['String']['output']>;
  /** IBAN of the SEB transaction receiver */
  to_iban?: Maybe<Scalars['String']['output']>;
  /** Name of the SEB transaction receiver */
  to_name?: Maybe<Scalars['String']['output']>;
  /** Type of the notification (BK_TO_CSTMR_NTF, CSTMR_PMNT_RPT, CSTMR_PMNT_STS_RPT) */
  type: SebNotificationType;
  /** Get the XML response in JSON format */
  xml_json: Scalars['String']['output'];
};

export enum SebNotificationType {
  BK_TO_CSTMR_NTF = 'BK_TO_CSTMR_NTF',
  CSTMR_PMNT_RPT = 'CSTMR_PMNT_RPT',
  CSTMR_PMNT_STS_RPT = 'CSTMR_PMNT_STS_RPT'
}

/** Incoming payment fetched through Saltedge */
export type SaltedgeTransaction = {
  /** Transaction's amount */
  amount: Scalars['Float']['output'];
  /** Transaction's category */
  category?: Maybe<Scalars['String']['output']>;
  /** Timestamp, when the Saltedge Transaction was created */
  created_at: Scalars['Int']['output'];
  /** Transaction's currency code */
  currency_code?: Maybe<Scalars['String']['output']>;
  /** Transaction's description */
  description?: Maybe<Scalars['String']['output']>;
  /** Whether the transaction is duplicated or not */
  duplicated?: Maybe<Scalars['String']['output']>;
  /** ID of a transaction in Saltedge system */
  external_id: Scalars['String']['output'];
  /** Extra data associated with the transaction */
  extra: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** The date when the transaction was made */
  made_on?: Maybe<Scalars['String']['output']>;
  /** Possible values are: posted, pending */
  mode?: Maybe<Scalars['String']['output']>;
  payment_id?: Maybe<Scalars['Int']['output']>;
  /** ID of an account in Saltedge system */
  saltedge_account_id?: Maybe<Scalars['String']['output']>;
  /** Possible values are: normal, fee, transfer */
  status?: Maybe<Scalars['String']['output']>;
  /** True means system was unable to recognize payer and payment purpose and requires manual processing */
  unrecognized?: Maybe<Scalars['String']['output']>;
  /** Timestamp, when the Saltedge Transaction was last updated */
  updated_at: Scalars['Int']['output'];
};

/** Settings for specific schedule type. */
export type ScheduleTypeSettings = {
  /** Default loan amount of the schedule type */
  default_loan_amount?: Maybe<Scalars['Float']['output']>;
  /** Default period months of the schedule type */
  default_period?: Maybe<Scalars['Int']['output']>;
  /** Determines if the schedule type is enabled */
  enabled: Scalars['Boolean']['output'];
  /** Determines if the schedule type has fixed annual pct rate */
  fixed_annual_pct_rate?: Maybe<Scalars['Float']['output']>;
  /** Determines if the schedule type has fixed contract fee */
  fixed_contract_fee?: Maybe<Scalars['Float']['output']>;
  /** Determines if the schedule type has fixed management fee */
  fixed_management_fee?: Maybe<Scalars['Float']['output']>;
  /** Number of months for interest free campaign for the schedule type */
  interest_free_months?: Maybe<Scalars['Int']['output']>;
  /** Determines if IRR value should be modified for the schedule type */
  irr_increase?: Maybe<Scalars['Float']['output']>;
  /** Determines if the schedule type can be converted */
  is_converting: Scalars['Boolean']['output'];
  /** Max possible amount of the loan for this schedule type */
  max_loan_amount: Scalars['Float']['output'];
  /** Min possible amount of the loan for this schedule type */
  min_loan_amount: Scalars['Float']['output'];
  /** Determines if the payment leave campaign should be enabled for the schedule type */
  payment_leave_enabled?: Maybe<Scalars['Boolean']['output']>;
  /** Number of months for payment leave campaign for the schedule type */
  payment_leave_months?: Maybe<Scalars['Int']['output']>;
  /** List of the possible months for the schedule type */
  possible_periods: Array<Maybe<Scalars['Int']['output']>>;
};

/** Schedule type settings for all loan schedule types. */
export type ScheduleTypesSettings = {
  /** Settings for ESTO_X schedule type. */
  ESTO_X: ScheduleTypeSettings;
  /** Settings for FAST_LOAN schedule type. */
  FAST_LOAN: ScheduleTypeSettings;
  /** Settings for PAY_LATER schedule type. */
  PAY_LATER: ScheduleTypeSettings;
  /** Settings for REGULAR schedule type. */
  REGULAR: ScheduleTypeSettings;
  /** Settings for RENOVATION_LOAN schedule type. */
  RENOVATION_LOAN: ScheduleTypeSettings;
  /** Settings for SMALL_LOAN schedule type. */
  SMALL_LOAN: ScheduleTypeSettings;
  /** Settings for VEHICLE_LOAN schedule type. */
  VEHICLE_LOAN: ScheduleTypeSettings;
};

/** Information that is displayed on admin sidebar */
export type Sidebar = {
  /** Number of applications or credit accounts that are overdue and need to be called */
  need_to_call_overdue_count: Scalars['Int']['output'];
  /** Number of users that have pending account statements */
  pending_acc_statements_count: Scalars['Int']['output'];
  /** Number of application cancellation requests that are unhandled */
  unhandled_cancellation_requests: Scalars['Int']['output'];
  /** Number of application modification requests that are unhandled */
  unhandled_modification_requests: Scalars['Int']['output'];
  /** Number of payment notifications that are unhandled and have no reference number */
  unhandled_payments_count: Scalars['Int']['output'];
};

export enum SimpleEligibilityStatus {
  CHECK_INCOME = 'CHECK_INCOME',
  CONFIRM_BANK = 'CONFIRM_BANK',
  CONFIRM_BANK_ACCOUNTSCORING = 'CONFIRM_BANK_ACCOUNTSCORING',
  DSTI_DSCR_INSUFFICIENT = 'DSTI_DSCR_INSUFFICIENT',
  EMTA_CONSENT = 'EMTA_CONSENT',
  MANUAL_SCORING_NEEDED = 'MANUAL_SCORING_NEEDED',
  MONTHLY_PAYMENT_TOO_HIGH = 'MONTHLY_PAYMENT_TOO_HIGH',
  NEGATIVE = 'NEGATIVE',
  NEGATIVE_FORWARD = 'NEGATIVE_FORWARD',
  NEGATIVE_FULL = 'NEGATIVE_FULL',
  NOT_ENOUGH_CREDIT_FOR_THIS_WITHDRAWAL = 'NOT_ENOUGH_CREDIT_FOR_THIS_WITHDRAWAL',
  NO_USER = 'NO_USER',
  OUTSIDE_WORKING_HOURS = 'OUTSIDE_WORKING_HOURS',
  PENDING = 'PENDING',
  PENDING_CUSTOMER_CARE = 'PENDING_CUSTOMER_CARE',
  POSITIVE = 'POSITIVE',
  SOME_REQUIRED_INFO_IS_MISSING = 'SOME_REQUIRED_INFO_IS_MISSING',
  TOO_MANY_LOW_IRR_LOANS = 'TOO_MANY_LOW_IRR_LOANS',
  WAITING_SPOUSE_CONSENT = 'WAITING_SPOUSE_CONSENT'
}

/** StripePayment data stored in our DB */
export type StripePayment = {
  id: Scalars['Int']['output'];
  /** Payment url provided by Stripe */
  payment_url: Scalars['String']['output'];
  /** Session ID of a payment */
  session_id: Scalars['String']['output'];
  stripe_status?: Maybe<StripePaymentStatusType>;
};

export enum StripePaymentStatusType {
  CANCELED = 'canceled',
  PROCESSING = 'processing',
  REQUIRES_ACTION = 'requires_action',
  REQUIRES_CONFIRMATION = 'requires_confirmation',
  REQUIRES_PAYMENT_METHOD = 'requires_payment_method',
  SUCCEEDED = 'succeeded'
}

/** Recurring payments subscription for a user or application or credit account */
export type Subscription = {
  application_id: Scalars['Int']['output'];
  /** Fee % that is added to the standing payment amount */
  card_payment_fee_pct: Scalars['Float']['output'];
  credit_account_id: Scalars['Int']['output'];
  everypay_card_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  user_id: Scalars['Int']['output'];
};

/** Details about a ESTO Switch request */
export type SwitchRequest = {
  /** The principal withdrawal amount */
  amount: Scalars['Float']['output'];
  /** Whether ESTO Switch is successful */
  applied_at?: Maybe<Scalars['String']['output']>;
  /** Datetime record was created on */
  created_at: Scalars['String']['output'];
  /** The principal withdrawal amount */
  file_url?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** Status */
  status: Scalars['String']['output'];
  /** Transaction reference to switch */
  transaction_reference?: Maybe<Scalars['String']['output']>;
};

/** Claims from taust.ee */
export type TaustClaims = {
  activity_area?: Maybe<Scalars['String']['output']>;
  /** The amount of money owed */
  amount: Scalars['Float']['output'];
  /** Person who the user owes money to */
  claimant: Scalars['String']['output'];
  /** When the money was paid back to the claimant */
  end_date?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  query_id: Scalars['Int']['output'];
  /** Start date of the debt */
  start_date: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

/** Requests about the user from taust.ee */
export type TaustRequests = {
  /** Amount of companies that have made requests */
  companies: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** Number of days (from now) over which the requests were made */
  period: Scalars['Int']['output'];
  query_id: Scalars['Int']['output'];
  /** Total number of requests */
  total: Scalars['Int']['output'];
};

/** Any movement of funds */
export type Transaction = {
  created_at: Scalars['Int']['output'];
  /** Optional description of the transaction (usually when it was added manually) */
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  method: Scalars['String']['output'];
  /** The type of transaction like ESTO -> Merchant or Customer -> ESTO */
  method_group: Scalars['String']['output'];
  /** Payment made by ESTO to someone or a deposit to ESTO from someone */
  payment?: Maybe<Payment>;
  /** Actual date, when money moved */
  payment_date?: Maybe<Scalars['String']['output']>;
  payment_id?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<TransactionRow>>>;
  /** Transaction value date */
  transaction_date: Scalars['String']['output'];
  transaction_return?: Maybe<TransactionReturn>;
  /** The negative transaction, if a transaction transfer has been made with this transaction */
  transferred_from?: Maybe<Transaction>;
  /** The positive transaction, if a transaction transfer has been made with this transaction */
  transferred_to?: Maybe<Transaction>;
  /** Credit account withdrawal associated with the transaction */
  withdrawal?: Maybe<CreditAccountWithdrawal>;
};


/** Any movement of funds */
export type TransactionRowsArgs = {
  merchant_id?: InputMaybe<Scalars['Int']['input']>;
};

export type TransactionBook = {
  description: Scalars['String']['output'];
  /** Unique reference number for this book */
  reference_nr: Scalars['String']['output'];
  type: TransactionBookType;
};

/** Transaction book with some extra calculated information */
export type TransactionBookIndex = {
  /** Current balance for this transaction book */
  balance: Scalars['Float']['output'];
  description: Scalars['String']['output'];
  /** Unique reference number for this book */
  reference_nr: Scalars['String']['output'];
  type: TransactionBookType;
};

/** Details the single purpose of a given transaction book */
export enum TransactionBookType {
  ACCRUED_CA_CARD_PAYMENT_FEE = 'ACCRUED_CA_CARD_PAYMENT_FEE',
  ACCRUED_CA_CONTRACT_EDITING_FEE = 'ACCRUED_CA_CONTRACT_EDITING_FEE',
  ACCRUED_CA_CREDIT_LIMIT_RECALCULATION_FEE = 'ACCRUED_CA_CREDIT_LIMIT_RECALCULATION_FEE',
  ACCRUED_CA_CREDIT_SCORING_FEE = 'ACCRUED_CA_CREDIT_SCORING_FEE',
  ACCRUED_CA_INSTANT_PAYMENT_FEE = 'ACCRUED_CA_INSTANT_PAYMENT_FEE',
  ACCRUED_CA_INTEREST = 'ACCRUED_CA_INTEREST',
  ACCRUED_CA_MANAGEMENT_FEE = 'ACCRUED_CA_MANAGEMENT_FEE',
  ACCRUED_CA_PAYMENT_LEAVE_FEE = 'ACCRUED_CA_PAYMENT_LEAVE_FEE',
  ACCRUED_CA_REMINDER_FEE = 'ACCRUED_CA_REMINDER_FEE',
  ACCRUED_CA_WITHDRAWAL_FEE = 'ACCRUED_CA_WITHDRAWAL_FEE',
  ACCRUED_HP_BONUS = 'ACCRUED_HP_BONUS',
  ACCRUED_HP_CARD_PAYMENT_FEE = 'ACCRUED_HP_CARD_PAYMENT_FEE',
  ACCRUED_HP_CHARGE = 'ACCRUED_HP_CHARGE',
  ACCRUED_HP_CONTRACT_EDITING_FEE = 'ACCRUED_HP_CONTRACT_EDITING_FEE',
  ACCRUED_HP_CONTRACT_FEE = 'ACCRUED_HP_CONTRACT_FEE',
  ACCRUED_HP_INTEREST = 'ACCRUED_HP_INTEREST',
  ACCRUED_HP_MANAGEMENT_FEE = 'ACCRUED_HP_MANAGEMENT_FEE',
  ACCRUED_HP_PAYMENT_LEAVE_FEE = 'ACCRUED_HP_PAYMENT_LEAVE_FEE',
  ACCRUED_HP_REMINDER_FEE = 'ACCRUED_HP_REMINDER_FEE',
  ACCRUED_HP_REVERSE_KICKBACK = 'ACCRUED_HP_REVERSE_KICKBACK',
  ACCRUED_SL_INSTANT_PAYMENT_FEE = 'ACCRUED_SL_INSTANT_PAYMENT_FEE',
  ACCRUED_USER_CARD_PAYMENT_FEE = 'ACCRUED_USER_CARD_PAYMENT_FEE',
  ACCRUED_USER_PREMIUM_ACCOUNT_FEE = 'ACCRUED_USER_PREMIUM_ACCOUNT_FEE',
  APPLIED_CA_INTEREST = 'APPLIED_CA_INTEREST',
  APPLIED_CA_MANAGEMENT_FEE = 'APPLIED_CA_MANAGEMENT_FEE',
  APPLIED_CA_PRINCIPAL = 'APPLIED_CA_PRINCIPAL',
  APPLIED_MERCHANT_DEALS_FEE = 'APPLIED_MERCHANT_DEALS_FEE',
  APPLIED_MERCHANT_FASTER_SETTLEMENTS_FEE = 'APPLIED_MERCHANT_FASTER_SETTLEMENTS_FEE',
  APPLIED_MERCHANT_MEDIATION_FEE_PAYABLE = 'APPLIED_MERCHANT_MEDIATION_FEE_PAYABLE',
  APPLIED_MERCHANT_PLATFORM_FEE = 'APPLIED_MERCHANT_PLATFORM_FEE',
  APPLIED_MERCHANT_REVERSE_KICKBACK_PAYABLE = 'APPLIED_MERCHANT_REVERSE_KICKBACK_PAYABLE',
  APPLIED_MERCHANT_VAT = 'APPLIED_MERCHANT_VAT',
  APPLIED_USER_PREMIUM_ACCOUNT_FEE = 'APPLIED_USER_PREMIUM_ACCOUNT_FEE',
  CA_CASH = 'CA_CASH',
  CA_CORRECTIONS = 'CA_CORRECTIONS',
  CA_OVERFLOW = 'CA_OVERFLOW',
  CA_PAYABLE = 'CA_PAYABLE',
  CA_REVENUE = 'CA_REVENUE',
  CA_SALE_RECOVERY = 'CA_SALE_RECOVERY',
  CA_WRITE_OFF = 'CA_WRITE_OFF',
  HP_CASH = 'HP_CASH',
  HP_CORRECTIONS = 'HP_CORRECTIONS',
  HP_OVERFLOW = 'HP_OVERFLOW',
  HP_PRINCIPAL_PAYABLE = 'HP_PRINCIPAL_PAYABLE',
  HP_REBALANCE = 'HP_REBALANCE',
  HP_REVENUE = 'HP_REVENUE',
  HP_SALE_RECOVERY = 'HP_SALE_RECOVERY',
  HP_TRANSACTION_RETURN_PAYABLE = 'HP_TRANSACTION_RETURN_PAYABLE',
  HP_WRITE_OFF = 'HP_WRITE_OFF',
  MERCHANT_BONUS_PAYABLE = 'MERCHANT_BONUS_PAYABLE',
  MERCHANT_CASH = 'MERCHANT_CASH',
  MERCHANT_CONTRACT_FEE_PAYABLE = 'MERCHANT_CONTRACT_FEE_PAYABLE',
  MERCHANT_CORRECTIONS = 'MERCHANT_CORRECTIONS',
  MERCHANT_DEALS_FEE = 'MERCHANT_DEALS_FEE',
  MERCHANT_DIRECT_PAYMENTS_PAYABLE = 'MERCHANT_DIRECT_PAYMENTS_PAYABLE',
  MERCHANT_FASTER_SETTLEMENTS_FEE = 'MERCHANT_FASTER_SETTLEMENTS_FEE',
  MERCHANT_INTEREST_PAYABLE = 'MERCHANT_INTEREST_PAYABLE',
  MERCHANT_MANAGEMENT_FEE_PAYABLE = 'MERCHANT_MANAGEMENT_FEE_PAYABLE',
  MERCHANT_MEDIATION_FEE_PAYABLE = 'MERCHANT_MEDIATION_FEE_PAYABLE',
  MERCHANT_OVERFLOW = 'MERCHANT_OVERFLOW',
  MERCHANT_PLATFORM_FEE = 'MERCHANT_PLATFORM_FEE',
  MERCHANT_PRINCIPAL_PAYABLE = 'MERCHANT_PRINCIPAL_PAYABLE',
  MERCHANT_REVENUE = 'MERCHANT_REVENUE',
  MERCHANT_REVERSE_KICKBACK_PAYABLE = 'MERCHANT_REVERSE_KICKBACK_PAYABLE',
  MERCHANT_SF_CONTRACT_FEE_PAYABLE = 'MERCHANT_SF_CONTRACT_FEE_PAYABLE',
  MERCHANT_SF_INTEREST_PAYABLE = 'MERCHANT_SF_INTEREST_PAYABLE',
  MERCHANT_SF_MANAGEMENT_FEE_PAYABLE = 'MERCHANT_SF_MANAGEMENT_FEE_PAYABLE',
  MERCHANT_SF_PRINCIPAL_PAYABLE = 'MERCHANT_SF_PRINCIPAL_PAYABLE',
  MERCHANT_TEST_PAYABLE = 'MERCHANT_TEST_PAYABLE',
  MERCHANT_VAT = 'MERCHANT_VAT',
  MERCHANT_WRITE_OFF = 'MERCHANT_WRITE_OFF',
  OUTSTANDING_CA_PRINCIPAL = 'OUTSTANDING_CA_PRINCIPAL',
  OUTSTANDING_HP_PRINCIPAL = 'OUTSTANDING_HP_PRINCIPAL',
  SL_PRINCIPAL_PAYABLE = 'SL_PRINCIPAL_PAYABLE',
  USER_CASH = 'USER_CASH',
  USER_CORRECTIONS = 'USER_CORRECTIONS',
  USER_OVERFLOW = 'USER_OVERFLOW',
  USER_REVENUE = 'USER_REVENUE'
}

/** Transaction with some extra calculated information */
export type TransactionIndex = {
  /** Sum of transaction row amounts for a transaction */
  amount: Scalars['Float']['output'];
  created_at: Scalars['Int']['output'];
  /** Optional description of the transaction (usually when it was added manually) */
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  method: Scalars['String']['output'];
  /** The type of transaction like ESTO -> Merchant or Customer -> ESTO */
  method_group: Scalars['String']['output'];
  /** Payment made by ESTO to someone or a deposit to ESTO from someone */
  payment?: Maybe<Payment>;
  /** Actual date, when money moved */
  payment_date?: Maybe<Scalars['String']['output']>;
  payment_id?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<TransactionRow>>>;
  /** Transaction value date */
  transaction_date: Scalars['String']['output'];
  transaction_return?: Maybe<TransactionReturn>;
  /** The negative transaction, if a transaction transfer has been made with this transaction */
  transferred_from?: Maybe<Transaction>;
  /** The positive transaction, if a transaction transfer has been made with this transaction */
  transferred_to?: Maybe<Transaction>;
  /** Credit account withdrawal associated with the transaction */
  withdrawal?: Maybe<CreditAccountWithdrawal>;
};


/** Transaction with some extra calculated information */
export type TransactionIndexRowsArgs = {
  merchant_id?: InputMaybe<Scalars['Int']['input']>;
};

/** Transaction method with merchant, either incoming or outgoing */
export enum TransactionMerchantMethod {
  ACCRUE_FEES = 'ACCRUE_FEES',
  ACCRUE_MERCHANT_FEE = 'ACCRUE_MERCHANT_FEE',
  ADD_FEES = 'ADD_FEES',
  APPLY_FEE = 'APPLY_FEE',
  APPLY_INTEREST = 'APPLY_INTEREST',
  APPLY_PRINCIPAL = 'APPLY_PRINCIPAL',
  BALANCE_CORRECTION = 'BALANCE_CORRECTION',
  CANCELLED = 'CANCELLED',
  CLAIM = 'CLAIM',
  CREDIT_ACCOUNT_PAYMENT = 'CREDIT_ACCOUNT_PAYMENT',
  CREDIT_ACCOUNT_PAYOUT = 'CREDIT_ACCOUNT_PAYOUT',
  CREDIT_ACCOUNT_PAYOUT_RETURNED = 'CREDIT_ACCOUNT_PAYOUT_RETURNED',
  CREDIT_ACCOUNT_WITHDRAWAL = 'CREDIT_ACCOUNT_WITHDRAWAL',
  CREDIT_ACCOUNT_WITHDRAWAL_DEDUCTION = 'CREDIT_ACCOUNT_WITHDRAWAL_DEDUCTION',
  CUSTOMER_TO_SELF_TRANSFER = 'CUSTOMER_TO_SELF_TRANSFER',
  DEPOSIT = 'DEPOSIT',
  DIRECT_PAYMENT = 'DIRECT_PAYMENT',
  DIRECT_PAYMENT_RETURNED = 'DIRECT_PAYMENT_RETURNED',
  FEE_PAYMENT_FROM_WITHDRAWAL = 'FEE_PAYMENT_FROM_WITHDRAWAL',
  FEE_PAYMENT_FROM_WITHDRAWAL_CANCELLED = 'FEE_PAYMENT_FROM_WITHDRAWAL_CANCELLED',
  FEE_PAYMENT_FROM_WITHDRAWAL_RETURN = 'FEE_PAYMENT_FROM_WITHDRAWAL_RETURN',
  HP_CA_CONVERSION = 'HP_CA_CONVERSION',
  HP_HP_CONVERSION = 'HP_HP_CONVERSION',
  HP_INVOICE_ACCRUAL = 'HP_INVOICE_ACCRUAL',
  INITIAL_BALANCE_CORRECTION = 'INITIAL_BALANCE_CORRECTION',
  INTEREST_ACCRUAL = 'INTEREST_ACCRUAL',
  LOAN_BUYBACK = 'LOAN_BUYBACK',
  LOAN_CANCELLED_ACTUAL = 'LOAN_CANCELLED_ACTUAL',
  LOAN_ISSUED_ACTUAL = 'LOAN_ISSUED_ACTUAL',
  LOAN_MODIFIED = 'LOAN_MODIFIED',
  LOAN_MODIFIED_ACTUAL = 'LOAN_MODIFIED_ACTUAL',
  LOAN_SALE = 'LOAN_SALE',
  MANAGEMENT_FEE_ACCRUAL = 'MANAGEMENT_FEE_ACCRUAL',
  MERCHANT_BONUS = 'MERCHANT_BONUS',
  MERCHANT_BUYBACK = 'MERCHANT_BUYBACK',
  MERCHANT_DEALS_ONE_OFF_REIMBURSEMENT = 'MERCHANT_DEALS_ONE_OFF_REIMBURSEMENT',
  MERCHANT_DEPOSIT = 'MERCHANT_DEPOSIT',
  MERCHANT_FASTER_SETTLEMENTS_REIMBURSEMENT = 'MERCHANT_FASTER_SETTLEMENTS_REIMBURSEMENT',
  MERCHANT_INITIAL = 'MERCHANT_INITIAL',
  MERCHANT_INSTALLMENT = 'MERCHANT_INSTALLMENT',
  MERCHANT_INVOICE_ACCRUAL = 'MERCHANT_INVOICE_ACCRUAL',
  MERCHANT_PAYOUT = 'MERCHANT_PAYOUT',
  MERCHANT_PLATFORM_FEE_REIMBURSEMENT = 'MERCHANT_PLATFORM_FEE_REIMBURSEMENT',
  MERCHANT_TO_SELF_TRANSFER = 'MERCHANT_TO_SELF_TRANSFER',
  MERCHANT_WAIVED = 'MERCHANT_WAIVED',
  MISTAKEN_TRANSACTION = 'MISTAKEN_TRANSACTION',
  NORMALIZE_MERCHANT_BALANCES = 'NORMALIZE_MERCHANT_BALANCES',
  OVERFLOW_TO_INCOME = 'OVERFLOW_TO_INCOME',
  PARTIAL_EARLY_REPAYMENT = 'PARTIAL_EARLY_REPAYMENT',
  PAYMENT_FROM_CA_FOR_CONVERSION = 'PAYMENT_FROM_CA_FOR_CONVERSION',
  PAYMENT_TO_SLICE_FOR_CONVERSION = 'PAYMENT_TO_SLICE_FOR_CONVERSION',
  PREMIUM_USER_SUBSCRIPTION_REIMBURSEMENT = 'PREMIUM_USER_SUBSCRIPTION_REIMBURSEMENT',
  REFINANCED = 'REFINANCED',
  REMOVE_FEE = 'REMOVE_FEE',
  RETURNED_TO_CUSTOMER = 'RETURNED_TO_CUSTOMER',
  RETURNED_TO_CUSTOMER_PAYOUT = 'RETURNED_TO_CUSTOMER_PAYOUT',
  RETURNED_TO_MERCHANT = 'RETURNED_TO_MERCHANT',
  REVERSE_KICKBACK = 'REVERSE_KICKBACK',
  REVERT_APPLIED_PRINCIPAL = 'REVERT_APPLIED_PRINCIPAL',
  SELF_FINANCING_FEES = 'SELF_FINANCING_FEES',
  SELF_TO_CUSTOMER_TRANSFER = 'SELF_TO_CUSTOMER_TRANSFER',
  SELF_TO_MERCHANT_TRANSFER = 'SELF_TO_MERCHANT_TRANSFER',
  SELF_TO_SELF_TRANSFER = 'SELF_TO_SELF_TRANSFER',
  SMALL_LOAN_PAYOUT = 'SMALL_LOAN_PAYOUT',
  SMALL_LOAN_WITHDRAWAL = 'SMALL_LOAN_WITHDRAWAL',
  SMALL_LOAN_WITHDRAWAL_CANCELLED = 'SMALL_LOAN_WITHDRAWAL_CANCELLED',
  TERMINATED = 'TERMINATED',
  TRANSACTION_ROW_CHANGE = 'TRANSACTION_ROW_CHANGE',
  TRANSACTION_TRANSFER = 'TRANSACTION_TRANSFER',
  TRANSFER_OVERFLOW = 'TRANSFER_OVERFLOW',
  WAIVED = 'WAIVED',
  WAIVED_WRITE_OFF = 'WAIVED_WRITE_OFF',
  WITHDRAWAL_CANCELLED = 'WITHDRAWAL_CANCELLED',
  WITHDRAWAL_RETURNED = 'WITHDRAWAL_RETURNED',
  WITHDRAWAL_RETURNED_DEDUCTION = 'WITHDRAWAL_RETURNED_DEDUCTION'
}

/** Transaction method, either incoming or outgoing */
export enum TransactionMethod {
  ACCRUE_FEES = 'ACCRUE_FEES',
  ACCRUE_MERCHANT_FEE = 'ACCRUE_MERCHANT_FEE',
  ADD_FEES = 'ADD_FEES',
  APPLY_FEE = 'APPLY_FEE',
  APPLY_INTEREST = 'APPLY_INTEREST',
  APPLY_PRINCIPAL = 'APPLY_PRINCIPAL',
  BALANCE_CORRECTION = 'BALANCE_CORRECTION',
  CANCELLED = 'CANCELLED',
  CLAIM = 'CLAIM',
  CREDIT_ACCOUNT_PAYMENT = 'CREDIT_ACCOUNT_PAYMENT',
  CREDIT_ACCOUNT_PAYOUT = 'CREDIT_ACCOUNT_PAYOUT',
  CREDIT_ACCOUNT_PAYOUT_RETURNED = 'CREDIT_ACCOUNT_PAYOUT_RETURNED',
  CREDIT_ACCOUNT_WITHDRAWAL = 'CREDIT_ACCOUNT_WITHDRAWAL',
  CREDIT_ACCOUNT_WITHDRAWAL_DEDUCTION = 'CREDIT_ACCOUNT_WITHDRAWAL_DEDUCTION',
  CUSTOMER_TO_SELF_TRANSFER = 'CUSTOMER_TO_SELF_TRANSFER',
  DEPOSIT = 'DEPOSIT',
  DEPOSIT_FOR_MERCHANT = 'DEPOSIT_FOR_MERCHANT',
  FEE_PAYMENT_FROM_WITHDRAWAL = 'FEE_PAYMENT_FROM_WITHDRAWAL',
  FEE_PAYMENT_FROM_WITHDRAWAL_CANCELLED = 'FEE_PAYMENT_FROM_WITHDRAWAL_CANCELLED',
  FEE_PAYMENT_FROM_WITHDRAWAL_RETURN = 'FEE_PAYMENT_FROM_WITHDRAWAL_RETURN',
  HP_CA_CONVERSION = 'HP_CA_CONVERSION',
  HP_HP_CONVERSION = 'HP_HP_CONVERSION',
  HP_INVOICE_ACCRUAL = 'HP_INVOICE_ACCRUAL',
  INITIAL_BALANCE_CORRECTION = 'INITIAL_BALANCE_CORRECTION',
  INTEREST_ACCRUAL = 'INTEREST_ACCRUAL',
  LOAN_BUYBACK = 'LOAN_BUYBACK',
  LOAN_CANCELLED_ACTUAL = 'LOAN_CANCELLED_ACTUAL',
  LOAN_ISSUED_ACTUAL = 'LOAN_ISSUED_ACTUAL',
  LOAN_MODIFIED = 'LOAN_MODIFIED',
  LOAN_MODIFIED_ACTUAL = 'LOAN_MODIFIED_ACTUAL',
  LOAN_SALE = 'LOAN_SALE',
  MANAGEMENT_FEE_ACCRUAL = 'MANAGEMENT_FEE_ACCRUAL',
  MERCHANT_BONUS = 'MERCHANT_BONUS',
  MERCHANT_BUYBACK = 'MERCHANT_BUYBACK',
  MERCHANT_DEALS_ONE_OFF_REIMBURSEMENT = 'MERCHANT_DEALS_ONE_OFF_REIMBURSEMENT',
  MERCHANT_DEPOSIT = 'MERCHANT_DEPOSIT',
  MERCHANT_FASTER_SETTLEMENTS_REIMBURSEMENT = 'MERCHANT_FASTER_SETTLEMENTS_REIMBURSEMENT',
  MERCHANT_INITIAL = 'MERCHANT_INITIAL',
  MERCHANT_INSTALLMENT = 'MERCHANT_INSTALLMENT',
  MERCHANT_INVOICE_ACCRUAL = 'MERCHANT_INVOICE_ACCRUAL',
  MERCHANT_PAYOUT = 'MERCHANT_PAYOUT',
  MERCHANT_PLATFORM_FEE_REIMBURSEMENT = 'MERCHANT_PLATFORM_FEE_REIMBURSEMENT',
  MERCHANT_TO_SELF_TRANSFER = 'MERCHANT_TO_SELF_TRANSFER',
  MERCHANT_WAIVED = 'MERCHANT_WAIVED',
  MISTAKEN_TRANSACTION = 'MISTAKEN_TRANSACTION',
  NORMALIZE_MERCHANT_BALANCES = 'NORMALIZE_MERCHANT_BALANCES',
  OVERFLOW_TO_INCOME = 'OVERFLOW_TO_INCOME',
  PARTIAL_EARLY_REPAYMENT = 'PARTIAL_EARLY_REPAYMENT',
  PAYMENT_FROM_CA_FOR_CONVERSION = 'PAYMENT_FROM_CA_FOR_CONVERSION',
  PAYMENT_TO_SLICE_FOR_CONVERSION = 'PAYMENT_TO_SLICE_FOR_CONVERSION',
  PREMIUM_USER_SUBSCRIPTION_REIMBURSEMENT = 'PREMIUM_USER_SUBSCRIPTION_REIMBURSEMENT',
  REFINANCED = 'REFINANCED',
  REMOVE_FEE = 'REMOVE_FEE',
  RETURNED_TO_CUSTOMER = 'RETURNED_TO_CUSTOMER',
  RETURNED_TO_CUSTOMER_PAYOUT = 'RETURNED_TO_CUSTOMER_PAYOUT',
  RETURNED_TO_MERCHANT = 'RETURNED_TO_MERCHANT',
  REVERSE_KICKBACK = 'REVERSE_KICKBACK',
  REVERT_APPLIED_PRINCIPAL = 'REVERT_APPLIED_PRINCIPAL',
  SELF_FINANCING_FEES = 'SELF_FINANCING_FEES',
  SELF_TO_CUSTOMER_TRANSFER = 'SELF_TO_CUSTOMER_TRANSFER',
  SELF_TO_MERCHANT_TRANSFER = 'SELF_TO_MERCHANT_TRANSFER',
  SELF_TO_SELF_TRANSFER = 'SELF_TO_SELF_TRANSFER',
  SMALL_LOAN_PAYOUT = 'SMALL_LOAN_PAYOUT',
  SMALL_LOAN_WITHDRAWAL = 'SMALL_LOAN_WITHDRAWAL',
  SMALL_LOAN_WITHDRAWAL_CANCELLED = 'SMALL_LOAN_WITHDRAWAL_CANCELLED',
  TERMINATED = 'TERMINATED',
  TRANSACTION_ROW_CHANGE = 'TRANSACTION_ROW_CHANGE',
  TRANSACTION_TRANSFER = 'TRANSACTION_TRANSFER',
  TRANSFER_OVERFLOW = 'TRANSFER_OVERFLOW',
  WAIVED = 'WAIVED',
  WAIVED_WRITE_OFF = 'WAIVED_WRITE_OFF',
  WITHDRAWAL_CANCELLED = 'WITHDRAWAL_CANCELLED',
  WITHDRAWAL_RETURNED = 'WITHDRAWAL_RETURNED',
  WITHDRAWAL_RETURNED_DEDUCTION = 'WITHDRAWAL_RETURNED_DEDUCTION'
}

/** Used for handling customer return transactions */
export type TransactionReturn = {
  /** IBAN (bank account number) of the beneficiary of the return transaction */
  beneficiary_iban?: Maybe<Scalars['String']['output']>;
  beneficiary_name: Scalars['String']['output'];
  handled_at?: Maybe<Scalars['Int']['output']>;
  transaction_id: Scalars['Int']['output'];
};

export type TransactionRow = {
  amount: Scalars['Float']['output'];
  application?: Maybe<Application>;
  application_id?: Maybe<Scalars['Int']['output']>;
  application_trashed?: Maybe<Application>;
  /** Current balance for related transaction book at the moment of this transaction row */
  balance?: Maybe<Scalars['Float']['output']>;
  book?: Maybe<TransactionBook>;
  credit_account?: Maybe<CreditAccount>;
  credit_account_id?: Maybe<Scalars['Int']['output']>;
  credit_account_trashed?: Maybe<CreditAccount>;
  /** Amount that was subtracted from related transaction book */
  from_amount: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  merchant_id?: Maybe<Scalars['Int']['output']>;
  merchant_trashed?: Maybe<Merchant>;
  /** Amount that was added to related transaction book */
  to_amount: Scalars['Float']['output'];
  transaction?: Maybe<Transaction>;
  transaction_id: Scalars['Int']['output'];
  type?: Maybe<TransactionRowType>;
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['Int']['output']>;
  user_trashed?: Maybe<User>;
};

export type TransactionRowInput = {
  amount: Scalars['Float']['input'];
  type?: InputMaybe<TransactionRowType>;
};

export enum TransactionRowType {
  ACCRUED_INTEREST = 'ACCRUED_INTEREST',
  CARD_PAYMENT_FEE = 'CARD_PAYMENT_FEE',
  CHARGE = 'CHARGE',
  CONTRACT_EDITING_FEE = 'CONTRACT_EDITING_FEE',
  CONTRACT_FEE = 'CONTRACT_FEE',
  CREDIT_LIMIT_RECALCULATION_FEE = 'CREDIT_LIMIT_RECALCULATION_FEE',
  CREDIT_SCORING_FEE = 'CREDIT_SCORING_FEE',
  DOWN_CONTRACT_FEE = 'DOWN_CONTRACT_FEE',
  INSTANT_PAYMENT_FEE = 'INSTANT_PAYMENT_FEE',
  INTEREST = 'INTEREST',
  LOAN_BUYBACK = 'LOAN_BUYBACK',
  LOAN_SALE = 'LOAN_SALE',
  MANAGEMENT_FEE = 'MANAGEMENT_FEE',
  MEDIATION_FEE = 'MEDIATION_FEE',
  MERCHANT_BONUS = 'MERCHANT_BONUS',
  MERCHANT_DEALS_FEE = 'MERCHANT_DEALS_FEE',
  MERCHANT_FASTER_SETTLEMENTS_FEE = 'MERCHANT_FASTER_SETTLEMENTS_FEE',
  MERCHANT_INTEREST = 'MERCHANT_INTEREST',
  MERCHANT_PLATFORM_FEE = 'MERCHANT_PLATFORM_FEE',
  MERCHANT_PRINCIPAL = 'MERCHANT_PRINCIPAL',
  MERCHANT_REVERSE_BONUS = 'MERCHANT_REVERSE_BONUS',
  MERCHANT_VAT = 'MERCHANT_VAT',
  OVERFLOW = 'OVERFLOW',
  PAYMENT_LEAVE_FEE = 'PAYMENT_LEAVE_FEE',
  PRINCIPAL = 'PRINCIPAL',
  REMINDER_FEE = 'REMINDER_FEE',
  REVENUE = 'REVENUE',
  SALE_RECOVERY = 'SALE_RECOVERY',
  USER_PREMIUM_ACCOUNT_FEE = 'USER_PREMIUM_ACCOUNT_FEE',
  WITHDRAWAL_FEE = 'WITHDRAWAL_FEE'
}

/** Transfer transaction between applications and credit accounts */
export type TransactionTransfer = {
  /** Transaction transfer id */
  id: Scalars['Int']['output'];
  /** Incoming transaction id */
  in_id?: Maybe<Scalars['Int']['output']>;
  /** Incoming transaction */
  in_transaction?: Maybe<Transaction>;
  /** Outgoing transaction id */
  out_id?: Maybe<Scalars['Int']['output']>;
  /** Outgoing transaction */
  out_transaction?: Maybe<Transaction>;
};

export enum TransactionTransferType {
  APPLICATION = 'APPLICATION',
  CREDIT_ACCOUNT = 'CREDIT_ACCOUNT'
}

export enum TransactionsOrderBy {
  CREATED_AT = 'created_at',
  TRANSACTION_DATE = 'transaction_date'
}

/** Details of loan or user regarding how much overflow can be transferred there */
export type TransferOverflowOption = {
  amount_to_transfer: Scalars['Float']['output'];
  application_id?: Maybe<Scalars['Int']['output']>;
  credit_account_id?: Maybe<Scalars['Int']['output']>;
  invoice_reference_nr: Scalars['String']['output'];
  is_early_repayment: Scalars['Boolean']['output'];
  user_id?: Maybe<Scalars['Int']['output']>;
};

export enum TriggerArgumentValueType {
  ARRAY = 'array',
  BOOLEAN = 'boolean',
  DOUBLE = 'double',
  INTEGER = 'integer',
  OBJECT = 'object',
  STRING = 'string'
}

/** An application that has not been paid for to the merchant or customer yet */
export type UnpaidApplication = {
  /** Determine whether to accrue interest and fees for a loan */
  accrue_interest_and_fees: Scalars['Boolean']['output'];
  /** Currently active payment leave for application */
  active_payment_leave?: Maybe<PaymentLeave>;
  /** Whether to add legal person info to the invoice */
  add_legal_person_to_invoice: Scalars['Boolean']['output'];
  /** If application has been modified, the original credit info data is stored here */
  application_modifications?: Maybe<Array<Maybe<ApplicationModification>>>;
  /** Helper table for making purchases via a physical cash register */
  application_reference?: Maybe<ApplicationReference>;
  /** ID of the application_user_info that this application belongs to */
  application_user_info_id?: Maybe<Scalars['Int']['output']>;
  /** Items bought with the purchase */
  basket_items?: Maybe<Array<Maybe<ApplicationBasketItem>>>;
  /** Date, when merchant was paid the application bonuses */
  bonus_paid_at?: Maybe<Scalars['String']['output']>;
  /** Application status based on information provided by the broker. */
  broker_status?: Maybe<Scalars['String']['output']>;
  /** Information about application that has been bought back from merchant */
  buyback_settings?: Maybe<ApplicationBuybackSettings>;
  /** A campaign that applies to the application, if enabled */
  campaign?: Maybe<ApplicationCampaign>;
  /** Determines, whether the application can be modified */
  can_be_modified: Scalars['Boolean']['output'];
  /** Whether an application can convert from a regular schedule to (long-term) CA/HP. */
  can_convert_from_regular: Scalars['Boolean']['output'];
  /** Whether the application can be converted manually to CA. */
  can_manually_convert_to_credit_account: Scalars['Boolean']['output'];
  /** Checks if the application can be refinanced or not */
  can_refinance: Scalars['Boolean']['output'];
  /** Optional URL, where the user will be redirected to after application was rejected */
  cancel_url?: Maybe<Scalars['String']['output']>;
  /** Request made by merchant to cancel this application */
  cancellation_request?: Maybe<ApplicationCancellationRequest>;
  /** Timestamp, when the application was cancelled (purchased item returned to merchant) */
  cancelled_at?: Maybe<Scalars['Int']['output']>;
  /** The user by whom this application was cancelled */
  cancelled_by?: Maybe<User>;
  /** Date, when merchant was paid cashier the application bonuses */
  cashier_bonus_paid_at?: Maybe<Scalars['String']['output']>;
  /** ConvertingSchedule regular campaign schedule */
  converting_schedule_regular?: Maybe<ConvertingScheduleRegular>;
  /** Timestamp, when the application was created */
  created_at: Scalars['Int']['output'];
  /** Additional info of the application about pricing */
  credit_info?: Maybe<ApplicationCreditInfo>;
  /** Fully rejected customers are given an option to get a loan from a third-party loan provider */
  customer_sale?: Maybe<CustomerSale>;
  /** How many days the hire purchase payment is overdue. 0 if HP payment is not overdue. */
  days_past_due?: Maybe<Scalars['Int']['output']>;
  /** Ongoing and finished claim collection processes */
  debt_collections?: Maybe<Array<Maybe<DebtCollection>>>;
  /** Timestamp, when the application was deleted */
  deleted_at?: Maybe<Scalars['Int']['output']>;
  /** Refunds history for specific Direct payment (ESTO Pay) */
  direct_payment_refunds?: Maybe<Array<Maybe<DirectPaymentRefund>>>;
  /** Down payment of the application */
  down_installments?: Maybe<Array<Maybe<Installment>>>;
  /** If the application was paid for early it will have additional info */
  early_payment?: Maybe<ApplicationEarlyPayment>;
  /**
   * Disable the eligibility check in order to not make actual queries to the Decision
   *                 Engine. In this case, the eligibility status and state will not change
   */
  eligibility_check_disabled?: Maybe<Scalars['Boolean']['output']>;
  /** 1: Eligible, 0: Pending, -1: Rejected */
  eligibility_state?: Maybe<Scalars['Int']['output']>;
  /** ELIGIBLE or reason why not eligible */
  eligibility_status?: Maybe<Scalars['String']['output']>;
  /** Timestamp of the last time eligibility was checked */
  eligibility_status_last_check?: Maybe<Scalars['Int']['output']>;
  /** Timestamp, when the last payment was made that paid for the application fully */
  ended_at?: Maybe<Scalars['Int']['output']>;
  /** Time when the application will be deleted, if it has not been signed */
  expires_at?: Maybe<Scalars['Int']['output']>;
  /** First monthly installment */
  first_installment?: Maybe<Installment>;
  /** True, if app for private person, false if for legal person and NULL if not fixed at all */
  fixed_for_private_person?: Maybe<Scalars['Boolean']['output']>;
  /** True, if application does NOT represent a legal person */
  for_private_person: Scalars['Boolean']['output'];
  /** Whether to forward rejected application to another broker. */
  forward_rejects: Scalars['Boolean']['output'];
  /** Whether it is a FREE HP application (no credit cost, no scoring) */
  free_hp_enabled: Scalars['Boolean']['output'];
  /** Whether this application was created in a retail (physical) store */
  from_retail: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  /**
   * Whether the user's identity needs verification (depends on requested amount).
   *                     Always include user relation with id_verification_method!
   */
  id_verification_required?: Maybe<Scalars['Boolean']['output']>;
  /** Time when the user (small loan) or merchant (hire purchase) was paid for by us */
  initial_paid_at?: Maybe<Scalars['Int']['output']>;
  /** Required installments for the application */
  installments?: Maybe<Array<Maybe<Installment>>>;
  /** Required installments for the application, ordered by due_at ASC */
  installments_ordered?: Maybe<Array<Maybe<Installment>>>;
  /** The fee for instant payout of small loan */
  instant_payment_fee: Scalars['Float']['output'];
  /** Current status of the Instantor request for this application */
  instantor_eligibility: InstantorEligibility;
  /** Identifier for the application that is used for payments */
  invoice_reference_nr?: Maybe<Scalars['String']['output']>;
  /** Whether the application converts (to CA or longer HP) */
  is_converting_schedule: Scalars['Boolean']['output'];
  /** Determines, if the user can apply for the loan specified by this application */
  is_eligible: Scalars['Boolean']['output'];
  /** Whether the funds will be paid out immediately to the client. Only applies for consumer loans */
  is_instant_payout: Scalars['Boolean']['output'];
  /** Whether the user can make instant payouts. */
  is_instant_payout_available: Scalars['Boolean']['output'];
  /** Determines, if direct payment can be refunded */
  is_refundable: Scalars['Boolean']['output'];
  /** If true, then this application is only used for testing purposes */
  is_test: Scalars['Boolean']['output'];
  /** klix payments connected to this application */
  klix_payments?: Maybe<Array<Maybe<KlixPayment>>>;
  /** Very last monthly installment that will be paid */
  last_installment?: Maybe<Installment>;
  /** Latest customer care decision result for related to application */
  latest_customer_care_decision?: Maybe<CustomerCareDecision>;
  /** Latest modification request made by merchant */
  latest_modification_request?: Maybe<ApplicationModificationRequest>;
  /** Info about the legal person connected to the application */
  legal_person_info?: Maybe<ApplicationLegalPersonInfo>;
  /**
   * Get ESTO's or merchant's or campaign logo, depending on the application.
   *                     NB! Always select merchant and campaign relations
   */
  logo_url: Scalars['String']['output'];
  /** Seller of the purchase */
  merchant?: Maybe<Merchant>;
  /** Data entered by the merchant for the application */
  merchant_data?: Maybe<ApplicationMerchantData>;
  /** ID of the merchant selling something to the user */
  merchant_id?: Maybe<Scalars['Int']['output']>;
  /** Name of the merchant connected to this application */
  merchant_name: Scalars['String']['output'];
  /** Requests made by merchant to modify application values such as net total */
  modification_requests?: Maybe<Array<Maybe<ApplicationModificationRequest>>>;
  /** Total amount required to pay to the merchant */
  need_to_pay_merchant: Scalars['Float']['output'];
  /** The next installment that is unpaid (can be in the past as well) */
  next_installment?: Maybe<Installment>;
  /** The next installment date for the given application or oldest installment date, if overdue */
  next_installment_date?: Maybe<Scalars['String']['output']>;
  /**
   * The next payment amount for the application. If overdue then only overdue amount,
   *                     but otherwise get current month
   */
  next_payment_amount?: Maybe<Scalars['Float']['output']>;
  /** Application overdue installments */
  overdue_installments?: Maybe<Array<Maybe<Installment>>>;
  /** The total amount of principal that has been paid */
  paid_principal: Scalars['Float']['output'];
  /** Timestamp, when the application or installment was signed or created */
  payment_date?: Maybe<Scalars['Int']['output']>;
  /** Payment leaves taken for this application */
  payment_leaves?: Maybe<Array<Maybe<PaymentLeave>>>;
  /** Specifies how the merchant will be paid, like installment or initial */
  payment_method: Scalars['String']['output'];
  /** Acquirer pivot of the product (application or credit account), if the product has been sold with a portfolio */
  portfolio_sale_product?: Maybe<PortfolioSaleProduct>;
  /** Pre-signing conversion offer for the application */
  pre_signing_conversion_offer?: Maybe<PreSigningConversionOffer>;
  /** Value taken from pricing. Max amount that an admin can waive from a hire purchase. */
  pricing_max_waive_amount: Scalars['Float']['output'];
  /** Timestamp, when the application was processed (for direct payments) */
  processed_at?: Maybe<Scalars['Int']['output']>;
  /** Get the name of merchant if private label or ESTO if not */
  provider_name: Scalars['String']['output'];
  /** URL where the purchase flow starts from */
  purchase_url: Scalars['String']['output'];
  /** A unique string to identify the application */
  reference_key: Scalars['String']['output'];
  /** The application that was refinanced by this application */
  refinanced_application?: Maybe<Application>;
  /** The application that refinanced this application */
  refinancing_parent_application?: Maybe<Application>;
  /** The application's id that refinances this application */
  refinancing_parent_id?: Maybe<Scalars['Int']['output']>;
  /** Get amount that was successfully refunded for Direct payment */
  refunded_amount: Scalars['Float']['output'];
  /** Timestamp, when the application was rejected */
  rejected_at?: Maybe<Scalars['Int']['output']>;
  /** Reminders that have been sent to the user because of overdue installments */
  reminders?: Maybe<Array<Maybe<Reminder>>>;
  /** The amount required for the purchase */
  requested_amount: Scalars['Float']['output'];
  /** Resale applications are potentially eligible applications which are currently unsigned */
  resale?: Maybe<Resale>;
  /** Resale call is created when the user is called during the resale process */
  resale_call?: Maybe<ResaleCall>;
  /** Optional URL, where the user will be redirected to after submitting the application */
  return_url?: Maybe<Scalars['String']['output']>;
  /** Amount that will be subtracted from the payment and paid by the merchant to us instead */
  reverse_kickback_amount: Scalars['Float']['output'];
  /** Type of the application, like hire-purchase or small loan */
  schedule_type: ApplicationScheduleType;
  /** Determine whether reminders should be sent */
  send_reminders: Scalars['Boolean']['output'];
  /** Whether a separate checkbox for pension query is shown at purchase flow */
  show_separate_pension_query: Scalars['Boolean']['output'];
  /** Timestamp, when the application was signed and accepted */
  signed_at?: Maybe<Scalars['Int']['output']>;
  /** Credit account eligibility status that is one of SimpleEligibilityStatus */
  simple_eligibility_status: Scalars['String']['output'];
  /** Timestamp, when the application was sold to a debt collector */
  sold_at?: Maybe<Scalars['Int']['output']>;
  /** Application status. One of the following: UNSIGNED, REJECTED, ACTIVE, TERMINATED, CANCELLED, DELETED, PROCESSED, ENDED */
  status: ApplicationStatus;
  /** Application sub-statuses */
  sub_statuses?: Maybe<Array<Maybe<LoanSubStatus>>>;
  /** Recurring payments subscription for the application */
  subscription?: Maybe<Subscription>;
  /** Timestamp, when the application was terminated (e.g when customer died or went bankrupt) */
  terminated_at?: Maybe<Scalars['Int']['output']>;
  termination_reason?: Maybe<Scalars['String']['output']>;
  /**
   * Total unpaid amount that should have been paid by now. NB! Only use for single queries
   *                     as it's actually a DB query
   */
  total_overdue: Scalars['Float']['output'];
  /** Get total paid amount for application */
  total_paid?: Maybe<Scalars['Float']['output']>;
  /** Specifies for what the merchant is paid for, like principal or interest */
  transaction_type: Scalars['String']['output'];
  /** Type of the application, like hire-purchase or small loan */
  type: ApplicationType;
  /** Get the total unpaid principal amount for the loan */
  unpaid_principal?: Maybe<Scalars['Float']['output']>;
  /** Owner of the application */
  user?: Maybe<User>;
  /** ID of the user that this application belongs to */
  user_id?: Maybe<Scalars['Int']['output']>;
  /** Additional info of the application about the user */
  user_info?: Maybe<ApplicationUserInfo>;
};


/** An application that has not been paid for to the merchant or customer yet */
export type UnpaidApplicationSimpleEligibilityStatusArgs = {
  do_query?: InputMaybe<Scalars['Boolean']['input']>;
};

/** List of merchants we need to pay out to with the time the list was received. */
export type UnpaidCachedMerchant = {
  /** List of merchants we need to pay out to */
  merchants?: Maybe<Array<Maybe<UnpaidMerchant>>>;
  /** Time when list of merchants was received from db */
  retrieved_at?: Maybe<Scalars['Int']['output']>;
};

/** Merchant that we need to make a payout to. */
export type UnpaidMerchant = {
  address: Scalars['String']['output'];
  /** Full URL of the merchant's background */
  background_path?: Maybe<Scalars['String']['output']>;
  /** Bank account name of the merchant */
  beneficiary_name: Scalars['String']['output'];
  /** Calculator colors etc used by the merchant */
  calculator_skin?: Maybe<MerchantCalculatorSkin>;
  /** Campaign created by the merchant for lower rates, payment leave etc */
  campaign?: Maybe<MerchantCampaign>;
  /** URL where the user is returned after they cancelled a purchase */
  cancel_url?: Maybe<Scalars['String']['output']>;
  /** If loyalty is enabled, cashiers can get rewards for selling products */
  cashier_loyalty_enabled: Scalars['Boolean']['output'];
  /** Relation that the merchant belongs to. Specifies the types of items they sell */
  category?: Maybe<MerchantCategory>;
  /** Category identifies what kind of stuff the merchant sells. */
  category_id?: Maybe<Scalars['Int']['output']>;
  /** Timestamp of the time the merchant was created at */
  created_at: Scalars['Int']['output'];
  /** List of Gateways available to use for Direct Payments (ESTO PAY) and their settings */
  direct_payment_gateways?: Maybe<Array<Maybe<MerchantDirectPaymentGateway>>>;
  /** URL for the merchant that they can put on their website for creating dynamic loans */
  dynamic_loan_url?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  /** URL to the mechant's homepage */
  home_url?: Maybe<Scalars['String']['output']>;
  /** IBAN (bank account) number of the merchant */
  iban: Scalars['String']['output'];
  /** ID of the merchant */
  id: Scalars['Int']['output'];
  invoice_email?: Maybe<Scalars['String']['output']>;
  invoice_reference_nr?: Maybe<Scalars['String']['output']>;
  /** Whether the merchant is a credit broker */
  is_broker?: Maybe<Scalars['Boolean']['output']>;
  /** Full URL of the merchant's logo */
  logo_path?: Maybe<Scalars['String']['output']>;
  /** Send a report about issued and cancelled applications on the 1st day of every month */
  monthly_reports_enabled: Scalars['Boolean']['output'];
  /** Name of the merchant */
  name: Scalars['String']['output'];
  /** URL where the callback is made to after a successful purchase */
  notification_url?: Maybe<Scalars['String']['output']>;
  /** Overflow balance of the merchant */
  overflow: Scalars['Float']['output'];
  /** Pending bonus balance with the merchant. If negative, we owe the merchant. */
  pending_bonus: Scalars['Float']['output'];
  /** Pending principal balance with the merchant. If negative, we owe the merchant. */
  pending_principal: Scalars['Float']['output'];
  /** Pending reverse kickback balance with the merchant. If negative, the merchant owes us. */
  pending_reverse_kickback?: Maybe<Scalars['Float']['output']>;
  phone: Scalars['String']['output'];
  /** Specifies the type of e-commerce platform the merchant uses */
  platform?: Maybe<MerchantPlatform>;
  /** Specifies the type of e-commerce platform the merchant uses */
  platform_id?: Maybe<Scalars['Int']['output']>;
  /** Value taken from pricing. Max amount that an admin crm user can waive from a merchant. */
  pricing_max_waive_amount: Scalars['Float']['output'];
  /** If true, the merchant will be displayed as the lender */
  private_label: Scalars['Boolean']['output'];
  /** Identifying registry code of the merchant's company */
  registry_code: Scalars['String']['output'];
  /** URL where the user is returned after a successful purchase */
  return_url?: Maybe<Scalars['String']['output']>;
  /** A unique string for the merchant that must be kept private */
  secret_key?: Maybe<Scalars['String']['output']>;
  /** Whether an email is sent to the merchant whenever ESTO Pay application is successfully signed */
  send_esto_pay_notification_email: Scalars['Boolean']['output'];
  /** Whether to send notifications to customers when ESTO Pay applications are signed */
  send_esto_pay_notification_to_customer?: Maybe<Scalars['Boolean']['output']>;
  /** Whether an email is sent to the merchant whenever NOT ESTO Pay application is successfully signed */
  send_hp_notification_email: Scalars['Boolean']['output'];
  /** Whether an email is sent to the user, if they created an application but did not finish it */
  send_user_inquiry_email: Scalars['Boolean']['output'];
  /** Settings of the merchant */
  settings?: Maybe<MerchantSettings>;
  /** Unique ID of the shop */
  shop_id: Scalars['String']['output'];
  /** URL for the merchant that they can put on their website for creating small loans */
  small_loan_url?: Maybe<Scalars['String']['output']>;
  /** Retail stores that the merchant owns */
  stores?: Maybe<Array<Maybe<MerchantStore>>>;
  /** Total unpaid amount for the merchant, includes the sum of reverse kickback and mediation fee balances */
  unpaid_amount: Scalars['Float']['output'];
  /** Total unpaid invoice amount for the merchant */
  unpaid_invoice_amount: Scalars['Float']['output'];
  /** Users connected to the merchant */
  users?: Maybe<Array<Maybe<MerchantUser>>>;
  /** VAT number of the merchant */
  vat_number?: Maybe<Scalars['String']['output']>;
};

/** A user */
export type User = {
  /** Whether waiting for client to submit account statement or it is pending etc */
  account_statement_eligibility?: Maybe<Scalars['String']['output']>;
  /** Uploaded account statements of the user */
  account_statements?: Maybe<Array<Maybe<AccountStatement>>>;
  /** Account Scoring */
  accountscoring_invitations?: Maybe<Array<Maybe<AccountScoringInvitation>>>;
  /** User's active applications */
  active_applications?: Maybe<Array<Maybe<Application>>>;
  /** Currently active central payment leave for user */
  active_payment_leave?: Maybe<PaymentLeave>;
  /** Active premium subscription for the user */
  active_premium_subscription?: Maybe<FeatureSubscription>;
  /** Whether the user has allowed ESTO to query Estonian pension centre */
  allow_pension_query: Scalars['Boolean']['output'];
  /** User’s risk profile level for anti-money laundering */
  aml_risk_profile?: Maybe<Scalars['String']['output']>;
  /** Total outstanding principal of all user's applications */
  application_outstanding_principal: Scalars['Float']['output'];
  /** Approximate monthly payment that will be paid during payment leave */
  approximate_monthly_payment?: Maybe<Scalars['Float']['output']>;
  /** Amount of credit the user is allowed to spend (user central limit) */
  available_credit?: Maybe<Scalars['Float']['output']>;
  /** Amount of credit the user is allowed to spend for hire purchase */
  available_hp_credit?: Maybe<Scalars['Float']['output']>;
  /** Amount of credit the user is allowed to spend for Small Loan */
  available_sl_credit?: Maybe<Scalars['Float']['output']>;
  /** Disposable income if the best possible values were used. */
  best_disposable_income?: Maybe<Scalars['Float']['output']>;
  /** Determines whether a user can be onboarded to premium account with opt out approach */
  can_be_opt_out_onboarded_to_premium_account: Scalars['Boolean']['output'];
  /** Whether the user has agreed to the conditions */
  conditions_agreement: Scalars['Boolean']['output'];
  /** Timestamp, when the user was created */
  created_at: Scalars['Int']['output'];
  /** Credit accounts that belong to the user */
  credit_accounts?: Maybe<Array<Maybe<CreditAccount>>>;
  /** User's central credit limit for HP + CA */
  credit_limit?: Maybe<Scalars['Float']['output']>;
  /** Credit scores for a user */
  credit_scores?: Maybe<Array<Maybe<UserCreditScore>>>;
  /** The date of birth the of user in YYYY-MM-DD format */
  date_of_birth?: Maybe<Scalars['String']['output']>;
  /** Maximum monthly payment based on income and liabilities. */
  disposable_income?: Maybe<Scalars['Float']['output']>;
  /**
   * Maximum monthly payment based on income and liabilities WITHOUT the active CA monthly payment.
   *                     NB! Only request this variable when completely necessary as it is a heavy calculation.
   */
  disposable_income_without_ca?: Maybe<Scalars['Float']['output']>;
  /** An identifying document of the user */
  document?: Maybe<UserDocument>;
  /** Debt service coverage ratio (For Estonia). Ratio of user income and lease payments */
  dscr?: Maybe<Scalars['Float']['output']>;
  /** Debt service coverage ratio (For Lithuania). Ratio of lease payments and user income */
  dsti?: Maybe<Scalars['Float']['output']>;
  /** ELIGIBLE or reason why not eligible */
  eligibility_status?: Maybe<Scalars['String']['output']>;
  /** Email of the user */
  email?: Maybe<Scalars['String']['output']>;
  /** A request to RIA for getting access to info from EMTA (Estonian Tax Agency) */
  emta_consent?: Maybe<RiaConsent>;
  /** A saved card used for EveryPay payment */
  everypay_cards?: Maybe<Array<Maybe<EverypayUserCard>>>;
  /** How much credit user can take for Hire Purchase */
  hp_credit_limit?: Maybe<Scalars['Float']['output']>;
  /** ID of the user */
  id: Scalars['Int']['output'];
  /** By which method the user's identity was verified */
  id_verification_method?: Maybe<Scalars['String']['output']>;
  /** Instantor responses with OK status for the user */
  instantor_responses?: Maybe<Array<Maybe<InstantorResponse>>>;
  /** Identifier for the user that is used for payments */
  invoice_reference_nr: Scalars['String']['output'];
  /** Check if the user has any overdue loans or credit accounts */
  is_overdue: Scalars['Boolean']['output'];
  /** Whether password is set for user or not */
  is_password_set: Scalars['Boolean']['output'];
  /** Language that the user is currently using */
  language_abbr: Scalars['String']['output'];
  /** Timestamp of user's last login */
  last_login?: Maybe<Scalars['Int']['output']>;
  /** Date, when the user promised to return the money */
  latest_promised_repayment_date?: Maybe<Scalars['String']['output']>;
  /** The latest actually used credit score */
  latest_user_credit_score?: Maybe<UserCreditScore>;
  /** Legal people (businesses) that belong to the user */
  legal_people?: Maybe<Array<Maybe<LegalPerson>>>;
  /** Get the latest legal people tied to the user */
  legal_people_active?: Maybe<Array<Maybe<LegalPerson>>>;
  /** Marital status: SINGLE, DIVORCED, WIDOWED, MARRIED, NA */
  marital_status: MaritalStatus;
  /** Stores that the user owns */
  merchants?: Maybe<Array<Maybe<UserMerchant>>>;
  /** Emails and SMS sent to the user */
  messages_sent?: Maybe<Array<Maybe<UserMessageSent>>>;
  /** Whether the user has agreed to the newsletter agreement */
  newsletter_agreement: Scalars['Boolean']['output'];
  /**
   * Next monthly payment for the client,
   *                                   sum of HP and CA invoices amounts that client should pay for current month
   */
  next_invoice_amount: Scalars['Float']['output'];
  /**
   * The date of the oldest overdue or upcoming payment for the client's loans (HP or CA).
   *                     Null, if there is nothing to pay for any more.
   */
  next_payment_date?: Maybe<Scalars['String']['output']>;
  /** How many days the user is overdue. 0 if user is not overdue. */
  overdue_days?: Maybe<Scalars['Int']['output']>;
  overflow: Scalars['Float']['output'];
  /** Queries about the defaulted user payments */
  payment_default_queries?: Maybe<Array<Maybe<PaymentDefaultQuery>>>;
  /** All historical and active user-central payment leaves */
  payment_leaves?: Maybe<Array<Maybe<PaymentLeave>>>;
  /** URL, where the client can pay all of their debt */
  payment_url: Scalars['String']['output'];
  /** Currently pending central payment leave */
  pending_payment_leave?: Maybe<PaymentLeave>;
  /** All permission bits that the user has */
  permission_bits: Scalars['Int']['output'];
  /** Phone number of the user */
  phone?: Maybe<Scalars['String']['output']>;
  /** Area code of the phone */
  phone_area_code?: Maybe<Scalars['String']['output']>;
  /** Personal Identificaton Number (SSN) of the user */
  pin?: Maybe<Scalars['String']['output']>;
  /** Whether the user is politically exposed and to which degree */
  political_exposure?: Maybe<PoliticalExposure>;
  /** Current status of user premium subscription */
  premium_subscription_status: Scalars['String']['output'];
  /** History of user's premium subscriptions */
  premium_subscriptions?: Maybe<Array<Maybe<FeatureSubscription>>>;
  /** Profile of the user */
  profile?: Maybe<UserProfile>;
  /** User's latest invoice */
  recent_invoice?: Maybe<Invoice>;
  /** Unique reference (long string) for the user */
  reference_key: Scalars['String']['output'];
  resale?: Maybe<Resale>;
  /** Availability of the user's retirement fund */
  retirement_fund?: Maybe<UserRetirementFund>;
  /** A request of proceeds from the Estonian Retirement Fund */
  retirement_fund_proceeds_requests?: Maybe<Array<Maybe<RetirementFundProceedsRequest>>>;
  /** How the user signed in to the current session */
  sign_in_method?: Maybe<UsersignInMethod>;
  /** Credit accounts that have been signed and belong to the user */
  signed_credit_accounts?: Maybe<Array<Maybe<CreditAccount>>>;
  /** Legal person eligibility status that is either positive, negative or pending */
  simple_eligibility_status?: Maybe<Scalars['String']['output']>;
  /** How much credit user can take for Small Loan */
  sl_credit_limit?: Maybe<Scalars['Float']['output']>;
  /** Total outstanding principal of all user's Small Loan applications */
  small_loan_outstanding_principal: Scalars['Float']['output'];
  /** User's central subscription by which we retrieve money from their credit card for payment */
  subscription?: Maybe<Subscription>;
  /**
   * Total current unpaid amount for the client,
   *                                   sum of all unpaid HP invoices and last unpaid CA invoice amounts that client need to pay right now
   */
  unpaid_invoice_amount: Scalars['Float']['output'];
  /** User manual incomes liabilities group that belong to the user */
  user_manual_incomes_liabilities_groups?: Maybe<Array<Maybe<UserManualIncomesLiabilitiesGroup>>>;
  /** Username for login purposes, usually defaults to PIN */
  username?: Maybe<Scalars['String']['output']>;
};

/** Application in the user applications list */
export type UserApplicationIndex = {
  /** Determine whether to accrue interest and fees for a loan */
  accrue_interest_and_fees: Scalars['Boolean']['output'];
  /** Currently active payment leave for application */
  active_payment_leave?: Maybe<PaymentLeave>;
  /** Whether to add legal person info to the invoice */
  add_legal_person_to_invoice: Scalars['Boolean']['output'];
  /** If application has been modified, the original credit info data is stored here */
  application_modifications?: Maybe<Array<Maybe<ApplicationModification>>>;
  /** Helper table for making purchases via a physical cash register */
  application_reference?: Maybe<ApplicationReference>;
  /** ID of the application_user_info that this application belongs to */
  application_user_info_id?: Maybe<Scalars['Int']['output']>;
  /** Items bought with the purchase */
  basket_items?: Maybe<Array<Maybe<ApplicationBasketItem>>>;
  /** Date, when merchant was paid the application bonuses */
  bonus_paid_at?: Maybe<Scalars['String']['output']>;
  /** Application status based on information provided by the broker. */
  broker_status?: Maybe<Scalars['String']['output']>;
  /** Information about application that has been bought back from merchant */
  buyback_settings?: Maybe<ApplicationBuybackSettings>;
  /** A campaign that applies to the application, if enabled */
  campaign?: Maybe<ApplicationCampaign>;
  /** Determines, whether the application can be modified */
  can_be_modified: Scalars['Boolean']['output'];
  /** Whether an application can convert from a regular schedule to (long-term) CA/HP. */
  can_convert_from_regular: Scalars['Boolean']['output'];
  /** Whether the application can be converted manually to CA. */
  can_manually_convert_to_credit_account: Scalars['Boolean']['output'];
  /** Checks if the application can be refinanced or not */
  can_refinance: Scalars['Boolean']['output'];
  /** Optional URL, where the user will be redirected to after application was rejected */
  cancel_url?: Maybe<Scalars['String']['output']>;
  /** Request made by merchant to cancel this application */
  cancellation_request?: Maybe<ApplicationCancellationRequest>;
  /** Timestamp, when the application was cancelled (purchased item returned to merchant) */
  cancelled_at?: Maybe<Scalars['Int']['output']>;
  /** The user by whom this application was cancelled */
  cancelled_by?: Maybe<User>;
  /** Date, when merchant was paid cashier the application bonuses */
  cashier_bonus_paid_at?: Maybe<Scalars['String']['output']>;
  /** ConvertingSchedule regular campaign schedule */
  converting_schedule_regular?: Maybe<ConvertingScheduleRegular>;
  /** Timestamp, when the application was created */
  created_at: Scalars['Int']['output'];
  /** Additional info of the application about pricing */
  credit_info?: Maybe<ApplicationCreditInfo>;
  /** Fully rejected customers are given an option to get a loan from a third-party loan provider */
  customer_sale?: Maybe<CustomerSale>;
  /** How many days the hire purchase payment is overdue. 0 if HP payment is not overdue. */
  days_past_due?: Maybe<Scalars['Int']['output']>;
  /** Ongoing and finished claim collection processes */
  debt_collections?: Maybe<Array<Maybe<DebtCollection>>>;
  /** Timestamp, when the application was deleted */
  deleted_at?: Maybe<Scalars['Int']['output']>;
  /** Refunds history for specific Direct payment (ESTO Pay) */
  direct_payment_refunds?: Maybe<Array<Maybe<DirectPaymentRefund>>>;
  /** Down payment of the application */
  down_installments?: Maybe<Array<Maybe<Installment>>>;
  /** If the application was paid for early it will have additional info */
  early_payment?: Maybe<ApplicationEarlyPayment>;
  /**
   * Disable the eligibility check in order to not make actual queries to the Decision
   *                 Engine. In this case, the eligibility status and state will not change
   */
  eligibility_check_disabled?: Maybe<Scalars['Boolean']['output']>;
  /** 1: Eligible, 0: Pending, -1: Rejected */
  eligibility_state?: Maybe<Scalars['Int']['output']>;
  /** ELIGIBLE or reason why not eligible */
  eligibility_status?: Maybe<Scalars['String']['output']>;
  /** Timestamp of the last time eligibility was checked */
  eligibility_status_last_check?: Maybe<Scalars['Int']['output']>;
  /** Timestamp, when the last payment was made that paid for the application fully */
  ended_at?: Maybe<Scalars['Int']['output']>;
  /** Time when the application will be deleted, if it has not been signed */
  expires_at?: Maybe<Scalars['Int']['output']>;
  /** First monthly installment */
  first_installment?: Maybe<Installment>;
  /** True, if app for private person, false if for legal person and NULL if not fixed at all */
  fixed_for_private_person?: Maybe<Scalars['Boolean']['output']>;
  /** True, if application does NOT represent a legal person */
  for_private_person: Scalars['Boolean']['output'];
  /** Whether to forward rejected application to another broker. */
  forward_rejects: Scalars['Boolean']['output'];
  /** Whether it is a FREE HP application (no credit cost, no scoring) */
  free_hp_enabled: Scalars['Boolean']['output'];
  /** Whether this application was created in a retail (physical) store */
  from_retail: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  /**
   * Whether the user's identity needs verification (depends on requested amount).
   *                     Always include user relation with id_verification_method!
   */
  id_verification_required?: Maybe<Scalars['Boolean']['output']>;
  /** Time when the user (small loan) or merchant (hire purchase) was paid for by us */
  initial_paid_at?: Maybe<Scalars['Int']['output']>;
  /** Required installments for the application */
  installments?: Maybe<Array<Maybe<Installment>>>;
  /** Required installments for the application, ordered by due_at ASC */
  installments_ordered?: Maybe<Array<Maybe<Installment>>>;
  /** The fee for instant payout of small loan */
  instant_payment_fee: Scalars['Float']['output'];
  /** Current status of the Instantor request for this application */
  instantor_eligibility: InstantorEligibility;
  /** Identifier for the application that is used for payments */
  invoice_reference_nr?: Maybe<Scalars['String']['output']>;
  /** Whether the application converts (to CA or longer HP) */
  is_converting_schedule: Scalars['Boolean']['output'];
  /** Determines, if the user can apply for the loan specified by this application */
  is_eligible: Scalars['Boolean']['output'];
  /** Whether the funds will be paid out immediately to the client. Only applies for consumer loans */
  is_instant_payout: Scalars['Boolean']['output'];
  /** Whether the user can make instant payouts. */
  is_instant_payout_available: Scalars['Boolean']['output'];
  /** Determines, if direct payment can be refunded */
  is_refundable: Scalars['Boolean']['output'];
  /** If true, then this application is only used for testing purposes */
  is_test: Scalars['Boolean']['output'];
  /** klix payments connected to this application */
  klix_payments?: Maybe<Array<Maybe<KlixPayment>>>;
  /** Very last monthly installment that will be paid */
  last_installment?: Maybe<Installment>;
  /** The last (final) installment date for the given application. Maturity date */
  last_installment_date?: Maybe<Scalars['String']['output']>;
  /** Latest customer care decision result for related to application */
  latest_customer_care_decision?: Maybe<CustomerCareDecision>;
  /** Latest modification request made by merchant */
  latest_modification_request?: Maybe<ApplicationModificationRequest>;
  /** Info about the legal person connected to the application */
  legal_person_info?: Maybe<ApplicationLegalPersonInfo>;
  /**
   * Get ESTO's or merchant's or campaign logo, depending on the application.
   *                     NB! Always select merchant and campaign relations
   */
  logo_url: Scalars['String']['output'];
  /** Seller of the purchase */
  merchant?: Maybe<Merchant>;
  /** Data entered by the merchant for the application */
  merchant_data?: Maybe<ApplicationMerchantData>;
  /** ID of the merchant selling something to the user */
  merchant_id?: Maybe<Scalars['Int']['output']>;
  /** Requests made by merchant to modify application values such as net total */
  modification_requests?: Maybe<Array<Maybe<ApplicationModificationRequest>>>;
  /** The next installment that is unpaid (can be in the past as well) */
  next_installment?: Maybe<Installment>;
  /** The next installment date for the given application or oldest installment date, if overdue */
  next_installment_date?: Maybe<Scalars['String']['output']>;
  /**
   * The next payment amount for the application. If overdue then only overdue amount,
   *                     but otherwise get current month
   */
  next_payment_amount?: Maybe<Scalars['Float']['output']>;
  /** Amount that should have been paid by now, but has not been paid */
  overdue_amount: Scalars['Float']['output'];
  /** Application overdue installments */
  overdue_installments?: Maybe<Array<Maybe<Installment>>>;
  /** The total amount of principal that has been paid */
  paid_principal: Scalars['Float']['output'];
  /** Payment leaves taken for this application */
  payment_leaves?: Maybe<Array<Maybe<PaymentLeave>>>;
  /** Acquirer pivot of the product (application or credit account), if the product has been sold with a portfolio */
  portfolio_sale_product?: Maybe<PortfolioSaleProduct>;
  /** Pre-signing conversion offer for the application */
  pre_signing_conversion_offer?: Maybe<PreSigningConversionOffer>;
  /** Value taken from pricing. Max amount that an admin can waive from a hire purchase. */
  pricing_max_waive_amount: Scalars['Float']['output'];
  /** Timestamp, when the application was processed (for direct payments) */
  processed_at?: Maybe<Scalars['Int']['output']>;
  /** Get the name of merchant if private label or ESTO if not */
  provider_name: Scalars['String']['output'];
  /** URL where the purchase flow starts from */
  purchase_url: Scalars['String']['output'];
  /** A unique string to identify the application */
  reference_key: Scalars['String']['output'];
  /** The application that was refinanced by this application */
  refinanced_application?: Maybe<Application>;
  /** The application that refinanced this application */
  refinancing_parent_application?: Maybe<Application>;
  /** The application's id that refinances this application */
  refinancing_parent_id?: Maybe<Scalars['Int']['output']>;
  /** Get amount that was successfully refunded for Direct payment */
  refunded_amount: Scalars['Float']['output'];
  /** Timestamp, when the application was rejected */
  rejected_at?: Maybe<Scalars['Int']['output']>;
  /** Reminders that have been sent to the user because of overdue installments */
  reminders?: Maybe<Array<Maybe<Reminder>>>;
  /** The amount required for the purchase */
  requested_amount: Scalars['Float']['output'];
  /** Resale applications are potentially eligible applications which are currently unsigned */
  resale?: Maybe<Resale>;
  /** Resale call is created when the user is called during the resale process */
  resale_call?: Maybe<ResaleCall>;
  /** Optional URL, where the user will be redirected to after submitting the application */
  return_url?: Maybe<Scalars['String']['output']>;
  /** Type of the application, like hire-purchase or small loan */
  schedule_type: ApplicationScheduleType;
  /** Determine whether reminders should be sent */
  send_reminders: Scalars['Boolean']['output'];
  /** Whether a separate checkbox for pension query is shown at purchase flow */
  show_separate_pension_query: Scalars['Boolean']['output'];
  /** Timestamp, when the application was signed and accepted */
  signed_at?: Maybe<Scalars['Int']['output']>;
  /** Credit account eligibility status that is one of SimpleEligibilityStatus */
  simple_eligibility_status: Scalars['String']['output'];
  /** Timestamp, when the application was sold to a debt collector */
  sold_at?: Maybe<Scalars['Int']['output']>;
  /** Application status. One of the following: UNSIGNED, REJECTED, ACTIVE, TERMINATED, CANCELLED, DELETED, PROCESSED, ENDED */
  status: ApplicationStatus;
  /** Application sub-statuses */
  sub_statuses?: Maybe<Array<Maybe<LoanSubStatus>>>;
  /** Recurring payments subscription for the application */
  subscription?: Maybe<Subscription>;
  /** Timestamp, when the application was terminated (e.g when customer died or went bankrupt) */
  terminated_at?: Maybe<Scalars['Int']['output']>;
  termination_reason?: Maybe<Scalars['String']['output']>;
  /**
   * Total amount needed to pay for the application in total by the end of the application,
   *                     including what has already been paid
   */
  total_amount: Scalars['Float']['output'];
  /** Total amount that should be paid right now. Includes both overdue (from previous months) and upcoming payments this month */
  total_due: Scalars['Float']['output'];
  /**
   * Total unpaid amount that should have been paid by now. NB! Only use for single queries
   *                     as it's actually a DB query
   */
  total_overdue: Scalars['Float']['output'];
  /** Total amount already paid for the application */
  total_paid: Scalars['Float']['output'];
  /** Type of the application, like hire-purchase or small loan */
  type: ApplicationType;
  /** Get the total unpaid principal amount for the loan */
  unpaid_principal?: Maybe<Scalars['Float']['output']>;
  /** Owner of the application */
  user?: Maybe<User>;
  /** ID of the user that this application belongs to */
  user_id?: Maybe<Scalars['Int']['output']>;
  /** Additional info of the application about the user */
  user_info?: Maybe<ApplicationUserInfo>;
};


/** Application in the user applications list */
export type UserApplicationIndexSimpleEligibilityStatusArgs = {
  do_query?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum UserApplicationsOrderBy {
  CREATED_AT = 'created_at',
  NEXT_INSTALLMENT_DATE = 'next_installment_date',
  SIGNED_AT = 'signed_at'
}

/** An internal blacklist for unwanted customers */
export type UserBlacklist = {
  /** Comment for blacklist entry */
  comment?: Maybe<Scalars['String']['output']>;
  /** Date time, when the blacklist entry was created */
  created_at: Scalars['String']['output'];
  /** Date time, when the blacklist entry was deleted */
  deleted_at?: Maybe<Scalars['String']['output']>;
  /** Entity primary key */
  id: Scalars['Int']['output'];
  /** User PIN. Indicates a person who is not able to use ESTO's services */
  pin: Scalars['String']['output'];
  /** Date time, when the blacklist entry was last time updated */
  updated_at: Scalars['String']['output'];
};

export enum UserBlacklistOrderBy {
  CREATED_AT = 'created_at',
  ID = 'id',
  PIN = 'pin',
  UPDATED_AT = 'updated_at'
}

export enum UserCallResponseType {
  DID_NOT_ANSWER = 'DID_NOT_ANSWER',
  OTHER = 'OTHER',
  PROMISED_REPAYMENT = 'PROMISED_REPAYMENT',
  UNABLE_TO_PAY = 'UNABLE_TO_PAY',
  WRONG_NUMBER_OR_PERSON = 'WRONG_NUMBER_OR_PERSON'
}

/** List of user creation sources */
export enum UserCreationSourceType {
  ADMIN_CRM = 'ADMIN_CRM',
  APPLICATION_SPOUSE_CONSENT = 'APPLICATION_SPOUSE_CONSENT',
  CREDIT_ACCOUNT = 'CREDIT_ACCOUNT',
  CREDIT_ACCOUNT_SPOUSE_CONSENT = 'CREDIT_ACCOUNT_SPOUSE_CONSENT',
  CUSTOMER_PROFILE = 'CUSTOMER_PROFILE',
  DIRECT_PAYMENT = 'DIRECT_PAYMENT',
  HIRE_PURCHASE = 'HIRE_PURCHASE',
  MERCHANT_DYNAMIC_LOAN = 'MERCHANT_DYNAMIC_LOAN',
  MERCHANT_INVITATION = 'MERCHANT_INVITATION',
  NEWSLETTER_SIGNUP_ESTO_PAY = 'NEWSLETTER_SIGNUP_ESTO_PAY',
  NEWSLETTER_SIGNUP_HOMEPAGE_DEALS = 'NEWSLETTER_SIGNUP_HOMEPAGE_DEALS',
  NEWSLETTER_SIGNUP_HOMEPAGE_LANDING = 'NEWSLETTER_SIGNUP_HOMEPAGE_LANDING',
  REFINANCED = 'REFINANCED',
  SMALL_LOAN = 'SMALL_LOAN',
  USER_ELIGIBILITY = 'USER_ELIGIBILITY',
  USER_VERIFICATION = 'USER_VERIFICATION'
}

export type UserCreditScore = {
  created_at: Scalars['Int']['output'];
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  provider?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Document like passport of the user */
export type UserDocument = {
  application_id?: Maybe<Scalars['Int']['output']>;
  /** Unique identifier of the document */
  document_nr?: Maybe<Scalars['String']['output']>;
  /** Date, when the document will expire */
  expiry_date?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** Date, when the document was issued */
  issuing_date?: Maybe<Scalars['String']['output']>;
  /** Type like passport or ID card */
  type: UserDocumentType;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** A document (passport or similar) associated with the user */
export type UserDocumentInput = {
  document_nr: Scalars['String']['input'];
  document_type: Scalars['String']['input'];
  expiry_date: Scalars['String']['input'];
  issuing_date: Scalars['String']['input'];
};

export enum UserDocumentType {
  DRIVING_LICENSE = 'DRIVING_LICENSE',
  ID_CARD = 'ID_CARD',
  PASSPORT = 'PASSPORT',
  VISA = 'VISA'
}

/** User information about income are entered into the system manually. */
export type UserIncome = {
  /** Amount of income entered manually */
  amount?: Maybe<Scalars['Float']['output']>;
  created_at: Scalars['Int']['output'];
  /** Manually entered income description */
  description?: Maybe<Scalars['String']['output']>;
  /** ID of user income. */
  id: Scalars['Int']['output'];
  /** Income type check from list manually for a specific region */
  type?: Maybe<UserIncomeType>;
  updated_at: Scalars['Int']['output'];
  /** ID of user manual incomes/liabilities group. */
  user_manual_incomes_liabilities_group_id?: Maybe<Scalars['Int']['output']>;
};

/** Input for setting user income for user manual group */
export type UserIncomeInput = {
  /** Amount of income entered manually. */
  amount: Scalars['Float']['input'];
  /** Manually entered income description. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ID of user income. */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Income type check from list manually for a specific region. */
  type: Scalars['String']['input'];
  /** ID of user manual incomes/liabilities group. */
  user_manual_incomes_liabilities_group_id: Scalars['Int']['input'];
};

/** Document confirmation the approval of the entered information on income and liability. */
export type UserIncomeLiabilityDocumentType = {
  created_at: Scalars['Int']['output'];
  /** Description of the uploaded approval document */
  description?: Maybe<Scalars['String']['output']>;
  /** The name of the document that was uploaded for approval */
  file_name?: Maybe<Scalars['String']['output']>;
  /** ID of user income/liability document. */
  id: Scalars['Int']['output'];
  updated_at: Scalars['Int']['output'];
  /** An url for download the approval document of income and liability from the Admin CRM if the accesses are available. */
  url?: Maybe<Scalars['String']['output']>;
  /** ID of user manual incomes/liabilities group. */
  user_manual_incomes_liabilities_group_id?: Maybe<Scalars['Int']['output']>;
};

/** List of user income types */
export enum UserIncomeType {
  AUTHOR_OR_ATHLETE = 'AUTHOR_OR_ATHLETE',
  CHILDCARE_ALLOWANCE = 'CHILDCARE_ALLOWANCE',
  CLERIC = 'CLERIC',
  DAILY_ALLOWANCE = 'DAILY_ALLOWANCE',
  DISABILITY_PENSION = 'DISABILITY_PENSION',
  EMPLOYEE = 'EMPLOYEE',
  FARMER = 'FARMER',
  INDIVIDUAL_ACTIVITY_ACCORDING_TO_THE_CERTIFICATE = 'INDIVIDUAL_ACTIVITY_ACCORDING_TO_THE_CERTIFICATE',
  INDIVIDUAL_ACTIVITY_UNDER_BUSINESS_LICENSE = 'INDIVIDUAL_ACTIVITY_UNDER_BUSINESS_LICENSE',
  LAWYER = 'LAWYER',
  OFFICIAL_STATUTORY_SERVANT = 'OFFICIAL_STATUTORY_SERVANT',
  OTHER = 'OTHER',
  OWNER_OF_SOLE_BUSINESS = 'OWNER_OF_SOLE_BUSINESS',
  PENSION_PAID_BY_FOREIGN_STATE = 'PENSION_PAID_BY_FOREIGN_STATE',
  RENT = 'RENT',
  RETIREE = 'RETIREE',
  SAILOR = 'SAILOR',
  SALARY = 'SALARY',
  WORK_ABROAD = 'WORK_ABROAD'
}

/** User information about liability are entered into the system manually. */
export type UserLiability = {
  /** Amount of liability entered manually */
  amount?: Maybe<Scalars['Float']['output']>;
  created_at: Scalars['Int']['output'];
  /** Manually entered liability description */
  description?: Maybe<Scalars['String']['output']>;
  /** ID of user liability. */
  id: Scalars['Int']['output'];
  /** Liability type check from list manually for a specific region */
  type?: Maybe<UserLiabilityType>;
  updated_at: Scalars['Int']['output'];
  /** ID of user manual incomes/liabilities group. */
  user_manual_incomes_liabilities_group_id?: Maybe<Scalars['Int']['output']>;
};

/** Input for setting user liability for user manual group */
export type UserLiabilityInput = {
  /** Amount of income entered manually */
  amount: Scalars['Float']['input'];
  /** Manually entered income description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ID of user liability. */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Income type check from list manually for a specific region */
  type: Scalars['String']['input'];
  /** ID of user manual incomes/liabilities group. */
  user_manual_incomes_liabilities_group_id: Scalars['Int']['input'];
};

/** List of user liability types */
export enum UserLiabilityType {
  OTHER = 'OTHER'
}

/** User manual incomes and liabilities group */
export type UserManualIncomesLiabilitiesGroup = {
  application_id?: Maybe<Scalars['Int']['output']>;
  created_at: Scalars['Int']['output'];
  credit_account_id?: Maybe<Scalars['Int']['output']>;
  documents?: Maybe<Array<Maybe<UserIncomeLiabilityDocumentType>>>;
  id: Scalars['Int']['output'];
  incomes?: Maybe<Array<Maybe<UserIncome>>>;
  /** Sum of incomes for user by group */
  incomes_sum?: Maybe<Scalars['Float']['output']>;
  liabilities?: Maybe<Array<Maybe<UserLiability>>>;
  /** Sum of liabilities for user by group */
  liabilities_sum?: Maybe<Scalars['Float']['output']>;
  updated_at: Scalars['Int']['output'];
  user?: Maybe<User>;
  user_id: Scalars['Int']['output'];
};

/** Merchant that is tied to a user */
export type UserMerchant = {
  address: Scalars['String']['output'];
  /** Full URL of the merchant's background */
  background_path?: Maybe<Scalars['String']['output']>;
  /** Bank account name of the merchant */
  beneficiary_name: Scalars['String']['output'];
  /** Calculator colors etc used by the merchant */
  calculator_skin?: Maybe<MerchantCalculatorSkin>;
  /** Campaign created by the merchant for lower rates, payment leave etc */
  campaign?: Maybe<MerchantCampaign>;
  /** URL where the user is returned after they cancelled a purchase */
  cancel_url?: Maybe<Scalars['String']['output']>;
  /** If loyalty is enabled, cashiers can get rewards for selling products */
  cashier_loyalty_enabled: Scalars['Boolean']['output'];
  /** Relation that the merchant belongs to. Specifies the types of items they sell */
  category?: Maybe<MerchantCategory>;
  /** Category identifies what kind of stuff the merchant sells. */
  category_id?: Maybe<Scalars['Int']['output']>;
  /** Timestamp of the time the merchant was created at */
  created_at: Scalars['Int']['output'];
  /** List of Gateways available to use for Direct Payments (ESTO PAY) and their settings */
  direct_payment_gateways?: Maybe<Array<Maybe<MerchantDirectPaymentGateway>>>;
  /** URL for the merchant that they can put on their website for creating dynamic loans */
  dynamic_loan_url?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  /** URL to the mechant's homepage */
  home_url?: Maybe<Scalars['String']['output']>;
  /** IBAN (bank account) number of the merchant */
  iban: Scalars['String']['output'];
  /** ID of the merchant */
  id: Scalars['Int']['output'];
  invoice_email?: Maybe<Scalars['String']['output']>;
  invoice_reference_nr?: Maybe<Scalars['String']['output']>;
  /** Whether the merchant is a credit broker */
  is_broker?: Maybe<Scalars['Boolean']['output']>;
  /** Full URL of the merchant's logo */
  logo_path?: Maybe<Scalars['String']['output']>;
  /** All permission bits that the user has for a specified merchant */
  merchant_permission_bits: Scalars['Int']['output'];
  /** Send a report about issued and cancelled applications on the 1st day of every month */
  monthly_reports_enabled: Scalars['Boolean']['output'];
  /** Name of the merchant */
  name: Scalars['String']['output'];
  /** URL where the callback is made to after a successful purchase */
  notification_url?: Maybe<Scalars['String']['output']>;
  /** Overflow balance of the merchant */
  overflow: Scalars['Float']['output'];
  phone: Scalars['String']['output'];
  /** Specifies the type of e-commerce platform the merchant uses */
  platform?: Maybe<MerchantPlatform>;
  /** Specifies the type of e-commerce platform the merchant uses */
  platform_id?: Maybe<Scalars['Int']['output']>;
  /** Value taken from pricing. Max amount that an admin crm user can waive from a merchant. */
  pricing_max_waive_amount: Scalars['Float']['output'];
  /** If true, the merchant will be displayed as the lender */
  private_label: Scalars['Boolean']['output'];
  /** Identifying registry code of the merchant's company */
  registry_code: Scalars['String']['output'];
  /** URL where the user is returned after a successful purchase */
  return_url?: Maybe<Scalars['String']['output']>;
  /** A unique string for the merchant that must be kept private */
  secret_key?: Maybe<Scalars['String']['output']>;
  /** Which emails the employee should receive pertinent to the merchant */
  send_emails: Scalars['Int']['output'];
  /** Whether an email is sent to the merchant whenever ESTO Pay application is successfully signed */
  send_esto_pay_notification_email: Scalars['Boolean']['output'];
  /** Whether to send notifications to customers when ESTO Pay applications are signed */
  send_esto_pay_notification_to_customer?: Maybe<Scalars['Boolean']['output']>;
  /** Whether an email is sent to the merchant whenever NOT ESTO Pay application is successfully signed */
  send_hp_notification_email: Scalars['Boolean']['output'];
  /** Whether an email is sent to the user, if they created an application but did not finish it */
  send_user_inquiry_email: Scalars['Boolean']['output'];
  /** Settings of the merchant */
  settings?: Maybe<MerchantSettings>;
  /** Unique ID of the shop */
  shop_id: Scalars['String']['output'];
  /** URL for the merchant that they can put on their website for creating small loans */
  small_loan_url?: Maybe<Scalars['String']['output']>;
  /** Stores of the merchant, where the user is a cashier */
  stores?: Maybe<Array<Maybe<MerchantStore>>>;
  /** Total unpaid amount for the merchant, includes the sum of reverse kickback and mediation fee balances */
  unpaid_amount: Scalars['Float']['output'];
  /** Total unpaid invoice amount for the merchant */
  unpaid_invoice_amount: Scalars['Float']['output'];
  /** Users connected to the merchant */
  users?: Maybe<Array<Maybe<MerchantUser>>>;
  /** VAT number of the merchant */
  vat_number?: Maybe<Scalars['String']['output']>;
};

/** An email or SMS sent to a user */
export type UserMessageSent = {
  /** The application relates to message sent */
  application?: Maybe<Application>;
  /** Text of the sent message */
  body: Scalars['String']['output'];
  /** First 50 letters of the body */
  body_short: Scalars['String']['output'];
  created_at: Scalars['Int']['output'];
  /** The credit account relates to message sent */
  credit_account?: Maybe<CreditAccount>;
  id: Scalars['Int']['output'];
  /** The email or SMS class name that was sent */
  method: Scalars['String']['output'];
  /** Link to the message's actual view */
  permalink?: Maybe<Scalars['String']['output']>;
  /** Through which provider the message was sent. One of - VERO,ESTO,ZENDESK,CUSTOMER_IO */
  provider?: Maybe<UserMessageSentProvider>;
  /** Reminder relates to message sent */
  reminder?: Maybe<Reminder>;
  /** Current status of the message (SENT,DELIVERED,OPENED,CLICKED,FAILED,UNSUBSCRIBED) */
  status?: Maybe<UserMessageSentStatus>;
  /** Subject line of the email */
  subject?: Maybe<Scalars['String']['output']>;
  /** Type one of - EMAIL,SMS,CALL */
  type?: Maybe<UserMessageSentType>;
  /** User relates to message sent */
  user?: Maybe<User>;
  user_id: Scalars['Int']['output'];
};

export enum UserMessageSentProvider {
  CUSTOMER_IO = 'CUSTOMER_IO',
  ESTO = 'ESTO',
  VERO = 'VERO',
  ZENDESK = 'ZENDESK'
}

export enum UserMessageSentStatus {
  CLICKED = 'CLICKED',
  DELIVERED = 'DELIVERED',
  FAILED = 'FAILED',
  OPENED = 'OPENED',
  SENT = 'SENT',
  UNSUBSCRIBED = 'UNSUBSCRIBED'
}

export enum UserMessageSentType {
  CALL = 'CALL',
  EMAIL = 'EMAIL',
  SMS = 'SMS'
}

/** Information about payment (userId, amount, etc.) for central payment page */
export type UserPayment = {
  /** Total current unpaid amount for the client, sum of all unpaid HP invoices and last unpaid CA invoice amounts that client need to pay right now */
  amount: Scalars['Float']['output'];
  /** List of applications that the user should currently pay for */
  applications?: Maybe<Array<Maybe<Application>>>;
  /** Active credit account of the user. Null, if nothing to pay currently */
  credit_account?: Maybe<CreditAccount>;
  /** A unique invoice reference number for the user that is used for payments */
  invoice_reference_nr: Scalars['String']['output'];
  /** User's latest central invoice */
  recent_invoice?: Maybe<Invoice>;
  /** ID of the user */
  user_id: Scalars['Int']['output'];
};

/** Profile of a user */
export type UserProfile = {
  /** Address of the user */
  address?: Maybe<Scalars['String']['output']>;
  /** All income (salaries, dividends, etc.) of the user from Decision Engine if the best values were used. */
  best_user_income?: Maybe<Scalars['Float']['output']>;
  /** Known debts (loans, fines, etc.) of the user in all banks from Decision Engine if the best values were used. */
  best_user_liabilities?: Maybe<Scalars['Float']['output']>;
  /** City of the user */
  city?: Maybe<Scalars['String']['output']>;
  /** Employer of the user. Self-defined by user, so might not be reliable */
  employer?: Maybe<Scalars['String']['output']>;
  /** Date of the employment */
  employment_date?: Maybe<Scalars['String']['output']>;
  /** Client employer's sector (public, private, non-profit) */
  employment_sector?: Maybe<EmploymentSector>;
  /** Monthly liabilities related to consumer loans. Self-defined by user, so might not be reliable */
  expenditure_consumer_loan_monthly?: Maybe<Scalars['Float']['output']>;
  /** All current monthly liabilities for the user. Self-defined by user, so might not be reliable */
  expenditure_monthly?: Maybe<Scalars['Float']['output']>;
  /** Extra income of the customer */
  extra_income?: Maybe<Scalars['Float']['output']>;
  /** First name of the user */
  first_name?: Maybe<Scalars['String']['output']>;
  /** How much reduced earnings the customer has planned in the near future */
  future_reduced_earnings?: Maybe<Scalars['Float']['output']>;
  /** IBAN (bank account number) of the user */
  iban?: Maybe<Scalars['String']['output']>;
  /** Last name of the user */
  last_name?: Maybe<Scalars['String']['output']>;
  /** Monthly cost of living, entered by the user */
  monthly_living_expenses?: Maybe<Scalars['Float']['output']>;
  /** Monthly net income from all sources for the user. Self-defined by user, so might not be reliable */
  net_income_monthly?: Maybe<Scalars['Float']['output']>;
  /** Number of dependents the user has to take care of, entered by the user */
  number_of_dependents?: Maybe<Scalars['Int']['output']>;
  /** The user's occupation. Self-defined by user, so might not be reliable */
  occupation_category?: Maybe<OccupationCategory>;
  /**
   * How much principal the client currently owes to other institutions that is 30+
   *                                  days overdue, entered by the user
   */
  overdue_debt?: Maybe<Scalars['Float']['output']>;
  /** How much debt the customer is planning to acquire in the near future */
  planning_new_debts?: Maybe<Scalars['Float']['output']>;
  /** Post code of the user */
  post_code?: Maybe<Scalars['String']['output']>;
  /** Email of the spouse */
  spouse_email?: Maybe<Scalars['String']['output']>;
  /** Date of the employment of the spouse */
  spouse_employment_date?: Maybe<Scalars['String']['output']>;
  /** Self-reported consumer loan monthly liabilities of spouse */
  spouse_expenditure_consumer_loan_monthly?: Maybe<Scalars['Float']['output']>;
  /** Self-reported monthly liabilities of spouse */
  spouse_expenditure_monthly?: Maybe<Scalars['Float']['output']>;
  /** Extra income of the spouse */
  spouse_extra_income?: Maybe<Scalars['Float']['output']>;
  /** First name of the spouse */
  spouse_first_name?: Maybe<Scalars['String']['output']>;
  /** Last name of the spouse */
  spouse_last_name?: Maybe<Scalars['String']['output']>;
  /** Monthly cost of living for spouse, entered by the user */
  spouse_monthly_living_expenses?: Maybe<Scalars['Float']['output']>;
  /** Self-reported net average monthly income of spouse */
  spouse_net_income_monthly?: Maybe<Scalars['Float']['output']>;
  /**
   * How much principal the spouse currently owes to other institutions that is 30+
   *                                  days overdue, entered by the user
   */
  spouse_overdue_debt?: Maybe<Scalars['Float']['output']>;
  /** Phone of the spouse */
  spouse_phone?: Maybe<Scalars['String']['output']>;
  /** Phone area code of the spouse */
  spouse_phone_area_code?: Maybe<Scalars['String']['output']>;
  /** Personal ID number of the spouse */
  spouse_pin?: Maybe<Scalars['String']['output']>;
  /** Whether the client is the ultimate beneficial owner of the loan, entered by the user */
  ultimate_beneficial_owner?: Maybe<Scalars['Boolean']['output']>;
  user_id: Scalars['Int']['output'];
  /**
   * Income that was used in the scoring calculation for this application. It can be official
   *                     or self-reported.
   */
  user_income?: Maybe<Scalars['Float']['output']>;
  /**
   * Liabilities that were used in the scoring calculation for this application. It can be official
   *                     or self-reported or a combination.
   */
  user_liabilities?: Maybe<Scalars['Float']['output']>;
};

/** Statistics on resale activity of a user. */
export type UserResaleStats = {
  /** How many calls have been made by user. */
  calls_made: Scalars['Int']['output'];
  /** How many customers have been claimed by user. */
  claimed: Scalars['Int']['output'];
  /** How many credit accounts have been signed. */
  credit_accounts_signed: Scalars['Int']['output'];
  /** How many credit accounts have been started. */
  credit_accounts_started: Scalars['Int']['output'];
  /** How many emails have been sent by user. */
  emails_sent: Scalars['Int']['output'];
  /** How many customers have been claimed by user and then was expired during 5 days of inactivity (no calls/emails) */
  expired: Scalars['Int']['output'];
  /** How many funds were withdrawn from CA, after customer support called the client to withdraw. */
  withdrawals_amount: Scalars['Float']['output'];
};

/** Retirement fund accessibility status for a user */
export type UserRetirementFund = {
  /** Determines, if the user currently has an active retirement fund */
  availability_status?: Maybe<RetFundAvailabilityStatus>;
  /** Percentage of gross salary that is added to the user's retirement fund */
  fund_pct?: Maybe<Scalars['Float']['output']>;
  user_id: Scalars['Int']['output'];
};

/** List of user liability types */
export enum UserRiskType {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM'
}

/** Any movement of funds for user */
export type UserTransaction = {
  /** Sum of transaction row amounts for a transaction */
  amount: Scalars['Float']['output'];
  created_at: Scalars['Int']['output'];
  /** Optional description of the transaction (usually when it was added manually) */
  description?: Maybe<Scalars['String']['output']>;
  /** FROM where the funds move */
  from: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** Invoice reference number of product related to transaction */
  invoice_reference_nr?: Maybe<Scalars['String']['output']>;
  method: Scalars['String']['output'];
  /** The type of transaction like ESTO -> Merchant or Customer -> ESTO */
  method_group: Scalars['String']['output'];
  /** Payment made by ESTO to someone or a deposit to ESTO from someone */
  payment?: Maybe<Payment>;
  /** Actual date, when money moved */
  payment_date?: Maybe<Scalars['String']['output']>;
  payment_id?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<TransactionRow>>>;
  /** TO where the funds move */
  to: Scalars['String']['output'];
  /** Transaction value date */
  transaction_date: Scalars['String']['output'];
  transaction_return?: Maybe<TransactionReturn>;
  /** The negative transaction, if a transaction transfer has been made with this transaction */
  transferred_from?: Maybe<Transaction>;
  /** The positive transaction, if a transaction transfer has been made with this transaction */
  transferred_to?: Maybe<Transaction>;
  /** Type of transaction. transaction.method for grouped rows, transaction.method + transaction_row.type for singled rows */
  type: Scalars['String']['output'];
  /** Credit account withdrawal associated with the transaction */
  withdrawal?: Maybe<CreditAccountWithdrawal>;
};


/** Any movement of funds for user */
export type UserTransactionRowsArgs = {
  merchant_id?: InputMaybe<Scalars['Int']['input']>;
};

/** Shows how much overflow can be transfered to other loans or user central */
export type UserTransferOverflow = {
  /** How much overflow is available to transfer */
  available_overflow_amount?: Maybe<Scalars['Float']['output']>;
  /** ID of the user */
  id: Scalars['Int']['output'];
  /** The list of options to transfer overflow */
  options?: Maybe<Array<Maybe<TransferOverflowOption>>>;
};

export enum UsersOrderBy {
  ADDRESS = 'address',
  CREATED_AT = 'created_at',
  EMAIL = 'email',
  ID = 'id',
  NAME = 'name',
  PHONE = 'phone'
}

export enum UsersignInMethod {
  EPARAKSTS = 'eparaksts',
  HASH = 'hash',
  ID_CARD = 'id_card',
  MAGIC_LINK = 'magic_link',
  MOBILE = 'mobile',
  PASSWORD = 'password',
  PAYSERA_BANKLINK = 'paysera_banklink',
  SMART_ID = 'smart_id'
}

/** The response a workflow returns after a trigger has been executed */
export type WorkflowDecision = {
  /** Decision of the trigger that was executed */
  decision: Scalars['String']['output'];
  /** Rating of the trigger that was executed */
  rating: Scalars['String']['output'];
};

/** The argument given when manually triggering workflow trigger */
export type WorkflowTriggerArgument = {
  /** Argument key */
  key: Scalars['String']['input'];
  /** Argument value */
  value: Scalars['String']['input'];
  /** The data type of given value */
  value_type: TriggerArgumentValueType;
};

/** Search for signed or rejected or no action applications. Possible: NO_ACTION, SIGNED, REJECTED, CANCELLED, NOT_PAID, CURRENTLY_ACTIVE, INACTIVE, NO_USER, TEST_MODE, DELETED, ENDED */
export enum AdminApplicationStatuses {
  CANCELLED = 'CANCELLED',
  CURRENTLY_ACTIVE = 'CURRENTLY_ACTIVE',
  DELETED = 'DELETED',
  ENDED = 'ENDED',
  INACTIVE = 'INACTIVE',
  NOT_PAID = 'NOT_PAID',
  NO_ACTION = 'NO_ACTION',
  NO_USER = 'NO_USER',
  REJECTED = 'REJECTED',
  SIGNED = 'SIGNED',
  TEST_MODE = 'TEST_MODE'
}

export type ApplicationPagination = {
  /** Current page of the cursor */
  current_page: Scalars['Int']['output'];
  /** List of items on the current page */
  data?: Maybe<Array<Maybe<Application>>>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']['output']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean']['output'];
  /** The last page (number of pages) */
  last_page: Scalars['Int']['output'];
  /** Number of items returned per page */
  per_page: Scalars['Int']['output'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']['output']>;
  /** Number of total items selected by the query */
  total: Scalars['Int']['output'];
};

export enum CashierApplicationSpecialSettings {
  NONE = 'NONE',
  NO_CONTRACT_FEE = 'NO_CONTRACT_FEE'
}

export type CreditAccountWithdrawalPagination = {
  /** Current page of the cursor */
  current_page: Scalars['Int']['output'];
  /** List of items on the current page */
  data?: Maybe<Array<Maybe<CreditAccountWithdrawal>>>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']['output']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean']['output'];
  /** The last page (number of pages) */
  last_page: Scalars['Int']['output'];
  /** Number of items returned per page */
  per_page: Scalars['Int']['output'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']['output']>;
  /** Number of total items selected by the query */
  total: Scalars['Int']['output'];
};

export enum CreditInfoBonusType {
  CREDIT_INCOME = 'CREDIT_INCOME',
  NONE = 'NONE',
  PRINCIPAL = 'PRINCIPAL'
}

export type InvoicePagination = {
  /** Current page of the cursor */
  current_page: Scalars['Int']['output'];
  /** List of items on the current page */
  data?: Maybe<Array<Maybe<Invoice>>>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']['output']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean']['output'];
  /** The last page (number of pages) */
  last_page: Scalars['Int']['output'];
  /** Number of items returned per page */
  per_page: Scalars['Int']['output'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']['output']>;
  /** Number of total items selected by the query */
  total: Scalars['Int']['output'];
};

export type MerchantApplicationIndexPagination = {
  /** Current page of the cursor */
  current_page: Scalars['Int']['output'];
  /** List of items on the current page */
  data?: Maybe<Array<Maybe<MerchantApplicationIndex>>>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']['output']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean']['output'];
  /** The last page (number of pages) */
  last_page: Scalars['Int']['output'];
  /** Number of items returned per page */
  per_page: Scalars['Int']['output'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']['output']>;
  /** Number of total items selected by the query */
  total: Scalars['Int']['output'];
};

export enum MerchantSettingsBonusType {
  CREDIT_INCOME = 'CREDIT_INCOME',
  NONE = 'NONE',
  PRINCIPAL = 'PRINCIPAL'
}

export type PaymentPagination = {
  /** Current page of the cursor */
  current_page: Scalars['Int']['output'];
  /** List of items on the current page */
  data?: Maybe<Array<Maybe<Payment>>>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']['output']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean']['output'];
  /** The last page (number of pages) */
  last_page: Scalars['Int']['output'];
  /** Number of items returned per page */
  per_page: Scalars['Int']['output'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']['output']>;
  /** Number of total items selected by the query */
  total: Scalars['Int']['output'];
};

export enum RetFundAvailabilityStatus {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  NOT_OPEN_YET = 'NOT_OPEN_YET',
  NO_FUND = 'NO_FUND',
  RETIRED = 'RETIRED'
}

export enum SigningMethod {
  EPARAKSTS = 'eparaksts',
  HASH = 'hash',
  ID_CARD = 'id_card',
  MAGIC_LINK = 'magic_link',
  MOBILE = 'mobile',
  PASSWORD = 'password',
  PAYSERA_BANKLINK = 'paysera_banklink',
  SMART_ID = 'smart_id'
}

export type TransactionPagination = {
  /** Current page of the cursor */
  current_page: Scalars['Int']['output'];
  /** List of items on the current page */
  data?: Maybe<Array<Maybe<Transaction>>>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']['output']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean']['output'];
  /** The last page (number of pages) */
  last_page: Scalars['Int']['output'];
  /** Number of items returned per page */
  per_page: Scalars['Int']['output'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']['output']>;
  /** Number of total items selected by the query */
  total: Scalars['Int']['output'];
};

export type TransactionRowPagination = {
  /** Current page of the cursor */
  current_page: Scalars['Int']['output'];
  /** List of items on the current page */
  data?: Maybe<Array<Maybe<TransactionRow>>>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']['output']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean']['output'];
  /** The last page (number of pages) */
  last_page: Scalars['Int']['output'];
  /** Number of items returned per page */
  per_page: Scalars['Int']['output'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']['output']>;
  /** Number of total items selected by the query */
  total: Scalars['Int']['output'];
};

export type UserPagination = {
  /** Current page of the cursor */
  current_page: Scalars['Int']['output'];
  /** List of items on the current page */
  data?: Maybe<Array<Maybe<User>>>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']['output']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean']['output'];
  /** The last page (number of pages) */
  last_page: Scalars['Int']['output'];
  /** Number of items returned per page */
  per_page: Scalars['Int']['output'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']['output']>;
  /** Number of total items selected by the query */
  total: Scalars['Int']['output'];
};

export type UserApplications = {
  /** Current page of the cursor */
  current_page: Scalars['Int']['output'];
  /** List of items on the current page */
  data?: Maybe<Array<Maybe<UserApplicationIndex>>>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']['output']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean']['output'];
  /** The last page (number of pages) */
  last_page: Scalars['Int']['output'];
  /** Number of items returned per page */
  per_page: Scalars['Int']['output'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']['output']>;
  /** Number of total items selected by the query */
  total: Scalars['Int']['output'];
};

export type UserTransactionPagination = {
  /** Current page of the cursor */
  current_page: Scalars['Int']['output'];
  /** List of items on the current page */
  data?: Maybe<Array<Maybe<UserTransaction>>>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']['output']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean']['output'];
  /** The last page (number of pages) */
  last_page: Scalars['Int']['output'];
  /** Number of items returned per page */
  per_page: Scalars['Int']['output'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']['output']>;
  /** Number of total items selected by the query */
  total: Scalars['Int']['output'];
};
