export const PAYSERA_SIGNING_STATUS_SEARCH_NAME = 'payseraSigningResult';

export enum PayseraSigningStatus {
	SUCCESS = 'success',
	REJECT = 'reject',
}

export enum PayseraPaymentStatus {
	SUCCESS = 'SUCCESS',
	FAILED = 'FAILED',
	NOT_FOUND = 'NOT_FOUND',
	PENDING = 'PENDING',
}

export const RESET_PAYSERA_SIGNING_PARAMS = {
	[PAYSERA_SIGNING_STATUS_SEARCH_NAME]: undefined,
	data: undefined,
	ss1: undefined,
	ss2: undefined,
};
