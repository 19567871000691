import { APP_CONFIG } from '@config/app';
import { ROUTE_NAMES } from '@config/routes';
import { creditAccountLimitRecalculationApi } from '@entities/credit-account-limit-recalculation/api';
import { CreditAccountLimitRecalculationRequestStatuses } from '@entities/credit-account-limit-recalculation/config';

import {
	LimitIncreaseView,
	RecalculationStatuses,
} from '@features/credit-account-limit-increase/config';
import { ErrorPage } from '@pages/error';
import { createFileRoute, redirect } from '@tanstack/react-router';
import * as z from 'zod';

const creditAccountLimitIncreaseSearchSchema = z.object({
	hash: z.string().optional().catch(undefined),
	fromPathname: z.string().optional().catch(undefined),
	view: z.nativeEnum(LimitIncreaseView).optional().catch(undefined),
	status: z
		.nativeEnum(CreditAccountLimitRecalculationRequestStatuses)
		.optional()
		.catch(undefined),
});

export const Route = createFileRoute(
	'/_protected/credit-account-limit-increase',
)({
	validateSearch: creditAccountLimitIncreaseSearchSchema,
	beforeLoad: async ({ search: { hash }, context: { queryClient } }) => {
		if (hash) {
			if (location.pathname === ROUTE_NAMES.creditAccountLimitIncreaseFailure) {
				return;
			}
			const { creditAccountLimitRecalculation } = await queryClient.fetchQuery({
				queryKey:
					creditAccountLimitRecalculationApi.useGetCreditAccountLimitRecalculationQuery.getKey(
						{
							hash,
						},
					),
				queryFn:
					creditAccountLimitRecalculationApi.useGetCreditAccountLimitRecalculationQuery.fetcher(
						{
							hash,
						},
					),
			});

			switch (creditAccountLimitRecalculation?.status) {
				case CreditAccountLimitRecalculationRequestStatuses.PENDING: {
					const { purchaseFlowUrl } = APP_CONFIG;

					window.open(
						`${purchaseFlowUrl}/credit-limit-recalculation?hash=${hash}`,
						'_self',
					);

					return new Promise(() => {});
				}
				case CreditAccountLimitRecalculationRequestStatuses.ACCEPTED: {
					throw redirect({
						replace: true,
						to: ROUTE_NAMES.creditAccountWithdrawalForm,
						search: {
							recalculation: RecalculationStatuses.SUCCESS,
						},
					});
				}
				case CreditAccountLimitRecalculationRequestStatuses.REJECTED: {
					throw redirect({
						replace: true,
						to: ROUTE_NAMES.creditAccountWithdrawalForm,
						search: {
							recalculation: RecalculationStatuses.REJECTED,
						},
					});
				}
				case CreditAccountLimitRecalculationRequestStatuses.APPROVED: {
					const { current_credit_limit, new_credit_limit } =
						creditAccountLimitRecalculation;

					const validNewLimit = new_credit_limit ?? 0;
					const validCurrentLimit = current_credit_limit ?? 0;

					const view =
						validCurrentLimit < validNewLimit
							? LimitIncreaseView.HIGHER_LIMIT
							: LimitIncreaseView.LOWER_OR_SAME_LIMIT;

					if (
						location.pathname !== ROUTE_NAMES.creditAccountLimitIncreaseSigning
					) {
						throw redirect({
							replace: true,
							to: ROUTE_NAMES.creditAccountLimitIncreaseSigning,
							search: {
								hash: hash,
								view: view,
							},
						});
					}
				}
			}
		} else {
			throw new Error('There is no hash');
		}
	},
	errorComponent: ErrorPage,
});
