import { CreditAccountStatus } from '@/shared/types';
import { ROUTE_NAMES } from '@config/routes';
import { applicationApi } from '@entities/application/api';
import { userApi } from '@entities/user';
import { CreditAccountConversionStatuses } from '@features/credit-account-conversion/config';
import { ErrorPage } from '@pages/error';
import { createFileRoute, redirect } from '@tanstack/react-router';
import * as z from 'zod';

const CreditAccountConversionSearchSchema = z.object({
	fromPathname: z.string().optional().catch(undefined),
	referenceKey: z.string().optional().catch(undefined),
});

export const Route = createFileRoute('/_protected/credit-account-conversion')({
	validateSearch: CreditAccountConversionSearchSchema,
	beforeLoad: async ({
		search: { referenceKey },
		context: { queryClient },
	}) => {
		if (location.pathname === ROUTE_NAMES.creditAccountConversionReject) {
			return;
		}

		const applicationData = await queryClient.ensureQueryData({
			queryKey: applicationApi.useSuspenseApplicationQuery.getKey({
				referenceKey: referenceKey,
			}),
			queryFn: applicationApi.useApplicationQuery.fetcher({
				referenceKey: referenceKey,
			}),
		});

		const creditAccountData = await queryClient.ensureQueryData({
			queryKey: userApi.useSuspenseUserCreditAccountQuery.getKey(),
			queryFn: userApi.useUserCreditAccountQuery.fetcher(),
		});

		const creditAccount = creditAccountData?.me?.credit_accounts?.at(0);

		const canManuallyConvertToCreditAccount =
			applicationData.application_by_reference
				?.can_manually_convert_to_credit_account;

		if (!canManuallyConvertToCreditAccount) {
			if (creditAccount?.status === CreditAccountStatus.ACTIVE) {
				throw redirect({
					to: ROUTE_NAMES.creditAccountWithdrawalForm,
					replace: true,
					search: {
						conversion: CreditAccountConversionStatuses.REJECTED,
					},
				});
			}

			throw redirect({
				replace: true,
				to: ROUTE_NAMES.creditAccountConversionReject,
			});
		}
	},
	errorComponent: ErrorPage,
});
