import {
	FIREBASE_CONFIG,
	VITE_FIREBASE_CLOUD_MESSAGING_VAPID_KEY,
} from '@config/envs';
import { useToast } from '@hooks/system';
import { getApp, initializeApp as initialize } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import values from 'lodash/values';
import { useCallback } from 'react';
import { useLocalStorage } from 'react-use';
import { firebaseApi } from './api';
import type { StoreFirebaseCmTokenMutationVariables } from './api/mutations.gen';

const FCM_TOKEN_KEY = 'fcmToken';

const isSupported =
	'Notification' in window &&
	'serviceWorker' in navigator &&
	'PushManager' in window;

export const useFirebase = () => {
	const [fcmToken, setFcmToken] = useLocalStorage(FCM_TOKEN_KEY, '');
	const { showErrorMessage } = useToast();

	const firebaseCfmTokenMutation = firebaseApi.useStoreFirebaseCmTokenMutation({
		onError: () => {
			showErrorMessage('Failed to store FCM token');
		},
	});

	const initializeApp = () => {
		if (values(FIREBASE_CONFIG).some((key) => !key)) {
			return;
		}

		initialize(FIREBASE_CONFIG);
	};

	const requestNotificationPermission = useCallback(
		async (userId?: number) => {
			if (!isSupported) {
				return;
			}

			const permission = await Notification.requestPermission();
			if (permission === 'granted') {
				const swRegistration = await navigator.serviceWorker.register(
					'/firebase-messaging-sw.js',
				);

				const app = getApp();

				if (!app) {
					throw new Error('Firebase app is not initialized');
				}

				const token = await getToken(getMessaging(app), {
					vapidKey: VITE_FIREBASE_CLOUD_MESSAGING_VAPID_KEY,
					serviceWorkerRegistration: swRegistration,
				});

				if (fcmToken === token) {
					return;
				}

				if (userId) {
					const variables: StoreFirebaseCmTokenMutationVariables = {
						userId: userId,
						fcmToken: token,
					};

					if (fcmToken) {
						variables.oldFcmToken = fcmToken;
					}

					firebaseCfmTokenMutation.mutate(variables);
				}

				setFcmToken(token);
			}
		},
		[fcmToken, setFcmToken, firebaseCfmTokenMutation],
	);

	return {
		initializeApp,
		requestNotificationPermission,
	};
};
