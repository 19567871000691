import { AppLanguage } from '@/shared/types';
import { LOCIZE_DEALS_KEYS } from '@config/locize';
import { type Deal, DealId } from './types';

export const DEAL: Record<DealId, Deal> = {
	[DealId.ELIX_HEALTH_EE]: {
		id: DealId.ELIX_HEALTH_EE,
		brand: 'Elix Health',
		title: LOCIZE_DEALS_KEYS.elixHealth,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO10',
		discount: '-10%',
		imageSrc: '/images/dashboard/deals/elix-health.webp',
		websiteUrl: {
			[AppLanguage.EN]: 'https://elixhealth.eu/?ref=estoelix10',
			[AppLanguage.ET]: 'https://elixhealth.eu/et/?ref=estoelix10',
			[AppLanguage.RU]: 'https://elixhealth.eu/ru/?ref=estoelix10',
			[AppLanguage.LV]: '',
			[AppLanguage.LT]: '',
		},
	},
	[DealId.STREET_STRIDER_EE]: {
		id: DealId.STREET_STRIDER_EE,
		brand: 'Street Strider',
		title: LOCIZE_DEALS_KEYS.streetStrider,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO10',
		discount: '-10%',
		imageSrc: '/images/dashboard/deals/street-strider.webp',
		websiteUrl: 'https://streetstrider.eu/streetstriderid',
	},
	[DealId.BILANCE]: {
		id: DealId.BILANCE,
		brand: 'Bilance',
		title: LOCIZE_DEALS_KEYS.bilance,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO3',
		discount: LOCIZE_DEALS_KEYS.bilanceDiscount,
		imageSrc: '/images/dashboard/deals/bilance.webp',
		websiteUrl: 'https://www.bilanceapp.com',
	},
	[DealId.MEREVARUSTUS_EE]: {
		id: DealId.MEREVARUSTUS_EE,
		brand: 'Merevarustus',
		title: LOCIZE_DEALS_KEYS.merevarustus,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO60',
		discount: '-60€',
		imageSrc: '/images/dashboard/deals/merevarustus.webp',
		websiteUrl: 'https://www.merevarustus.ee/toode/sup-lauad/aqua-marina-beast',
	},
	[DealId.INPUIT_EE]: {
		id: DealId.INPUIT_EE,
		brand: 'Inpuit',
		title: LOCIZE_DEALS_KEYS.inpuit,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO10',
		discount: '-10%',
		imageSrc: '/images/dashboard/deals/inpuit.webp',
		websiteUrl:
			'https://www.inpuit.eu/et/a/soojustatud-koerakuut-terrassiga-boss-4-4',
	},
	[DealId.ANIX_SHOP_EE]: {
		id: DealId.ANIX_SHOP_EE,
		brand: 'Anix Shop',
		title: LOCIZE_DEALS_KEYS.anixShop,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO25',
		discount: '-25%',
		imageSrc: '/images/dashboard/deals/anix-shop.webp',
		websiteUrl: 'https://anix.ee/shop',
	},
	[DealId.PROBIKE_LV]: {
		id: DealId.PROBIKE_LV,
		brand: 'Probike',
		title: LOCIZE_DEALS_KEYS.probike,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO20',
		discount: '-20%',
		imageSrc: '/images/dashboard/deals/probike.webp',
		websiteUrl: 'https://probike.lv',
	},
	[DealId.TOPCIK_LV]: {
		id: DealId.TOPCIK_LV,
		brand: 'Topcik',
		title: LOCIZE_DEALS_KEYS.topcik,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO20',
		discount: '-20%',
		discountDescription: LOCIZE_DEALS_KEYS.dialogDiscountDescriptionTopcik,
		imageSrc: '/images/dashboard/deals/topcik.webp',
		websiteUrl:
			'https://topcik.lv/products/lv/438/364242/sort/5/filter/0_0_0_0/15.6-Ideapad-Slim-3-i5-12450H-8GB-512GB-SSD-Windows-11-15IAH8-Portat%C4%ABvais-dators.html',
	},
	[DealId.SMARTPC_LT]: {
		id: DealId.SMARTPC_LT,
		brand: 'SmartPC',
		title: LOCIZE_DEALS_KEYS.smartpc,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO15',
		discount: '-15%',
		imageSrc: '/images/dashboard/deals/smartpc.webp',
		websiteUrl:
			'https://smartpc.lt/products/lt/438/349687/sort/1/filter/0_0_0_0/15.6-Ideapad-1-N4120-4GB-128GB-SSD-Windows-11-15IGL05-Ne%C5%A1iojamas-kompiuteris.html',
	},
	[DealId.LIGHT_CONCEPT_EE]: {
		id: DealId.LIGHT_CONCEPT_EE,
		brand: 'Light Concept',
		title: LOCIZE_DEALS_KEYS.lightConcept,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO20',
		discount: '-20%',
		imageSrc: '/images/dashboard/deals/light-concept.webp',
		websiteUrl: 'https://lightconcept.ee/kategooria/lauavalgustid',
	},
	[DealId.ALEXANTO_EE]: {
		id: DealId.ALEXANTO_EE,
		brand: 'Alexanto',
		title: LOCIZE_DEALS_KEYS.alexanto,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO35',
		discount: '-35%',
		imageSrc: '/images/dashboard/deals/alexanto.webp',
		websiteUrl: 'https://alexanto.ee/tootegrupp/diivanid',
	},
	[DealId.MOKYKLINE]: {
		id: DealId.MOKYKLINE,
		brand: 'Mokyklines',
		title: LOCIZE_DEALS_KEYS.mokykline,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO10',
		discount: '-10%',
		imageSrc: '/images/dashboard/deals/mokyklines.webp',
		websiteUrl: 'https://www.mokyklinesuniformos.lt',
	},
	[DealId.WOLT_LV]: {
		id: DealId.WOLT_LV,
		brand: 'Wolt',
		title: LOCIZE_DEALS_KEYS.wolt,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'AFFWOLTLVA24',
		discount: '3+3',
		imageSrc: '/images/dashboard/deals/wolt.webp',
		websiteUrl: 'https://clk.tradedoubler.com/click?p=331829&a=3379320',
	},
	[DealId.WOLT_LT]: {
		id: DealId.WOLT_LT,
		brand: 'Wolt',
		title: LOCIZE_DEALS_KEYS.wolt,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'AFFWOLTLTU24',
		discount: '3+3',
		imageSrc: '/images/dashboard/deals/wolt.webp',
		websiteUrl: 'https://clk.tradedoubler.com/click?p=331830&a=3379320',
	},
	[DealId.UPGREAT_EE]: {
		id: DealId.UPGREAT_EE,
		brand: 'Upgreat',
		title: LOCIZE_DEALS_KEYS.upgreat,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO10',
		discount: '-10%',
		imageSrc: '/images/dashboard/deals/upgreat.webp',
		websiteUrl: {
			[AppLanguage.EN]: 'https://en.upgreat.ee/?ref=ESTO10',
			[AppLanguage.ET]: 'https://upgreat.ee/?ref=ESTO10',
			[AppLanguage.RU]: 'https://ru.upgreat.ee/?ref=ESTO10',
			[AppLanguage.LV]: '',
			[AppLanguage.LT]: '',
		},
	},
	[DealId.UPGREAT_LV]: {
		id: DealId.UPGREAT_LV,
		brand: 'Upgreat',
		title: LOCIZE_DEALS_KEYS.upgreat,
		discountCode: 'ESTO10',
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discount: '-10%',
		imageSrc: '/images/dashboard/deals/upgreat.webp',
		websiteUrl: 'https://upgreat.lv/?ref=ESTO10',
	},
	[DealId.UPGREAT_LT]: {
		id: DealId.UPGREAT_LT,
		brand: 'Upgreat',
		title: LOCIZE_DEALS_KEYS.upgreat,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO10',
		discount: '-10%',
		imageSrc: '/images/dashboard/deals/upgreat.webp',
		websiteUrl: 'https://upgreat.lt/?ref=ESTO10',
	},
	[DealId.FITLAP]: {
		id: DealId.FITLAP,
		brand: 'Fitlap',
		title: LOCIZE_DEALS_KEYS.fitlap,
		description: LOCIZE_DEALS_KEYS.dialogDescription2,
		discountCode: '',
		discount: '-30%',
		imageSrc: '/images/dashboard/deals/fitlap.webp',
		websiteUrl: 'https://fitlap.ee/s/esto30',
	},
	[DealId.KULDAN]: {
		id: DealId.KULDAN,
		brand: 'Kuldan',
		title: LOCIZE_DEALS_KEYS.kuldan,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO30',
		discount: '-30%',
		imageSrc: '/images/dashboard/deals/kuldan.webp',
		websiteUrl: 'https://kuldan.ee',
	},
	[DealId.MLILY_EE]: {
		id: DealId.MLILY_EE,
		brand: 'Mlily',
		title: LOCIZE_DEALS_KEYS.mlily,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO20',
		discount: '-20%',
		imageSrc: '/images/dashboard/deals/mlily.webp',
		websiteUrl: 'https://mlily.ee',
	},
	[DealId.GIVEN_EE]: {
		id: DealId.GIVEN_EE,
		brand: 'Given',
		title: LOCIZE_DEALS_KEYS.given,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO25',
		discount: '-25%',
		imageSrc: '/images/dashboard/deals/given.webp',
		websiteUrl: {
			[AppLanguage.EN]: 'https://given.ee/en/brandid/NINE',
			[AppLanguage.ET]: 'https://given.ee/ee/brandid/NINE',
			[AppLanguage.RU]: 'https://given.ee/ru/brandid/NINE',
			[AppLanguage.LV]: '',
			[AppLanguage.LT]: '',
		},
	},
	[DealId.GOLDEN_LOMBARD_EE]: {
		id: DealId.GOLDEN_LOMBARD_EE,
		brand: 'Golden Lombard',
		title: LOCIZE_DEALS_KEYS.goldenLombard,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO25',
		discount: '-25%',
		imageSrc: '/images/dashboard/deals/golden-lombard.webp',
		websiteUrl: 'https://goldenlombard.ee/store',
	},
	[DealId.MADRATSIPOOD_EE]: {
		id: DealId.MADRATSIPOOD_EE,
		brand: 'Madratsipood',
		title: LOCIZE_DEALS_KEYS.madratsipood,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO20',
		discount: '-20%',
		imageSrc: '/images/dashboard/deals/madratsipood.webp',
		websiteUrl:
			'https://madratsipood.ee/product-category/1-madratsid/?product_brand=207',
	},
	[DealId.AIRSOFTGO_EE]: {
		id: DealId.AIRSOFTGO_EE,
		brand: 'Airsoftgo',
		title: LOCIZE_DEALS_KEYS.airsoftgo,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTODeals',
		discount: '-25%',
		imageSrc: '/images/dashboard/deals/airsoftgo.webp',
		websiteUrl:
			'https://airsoftgo.ee/tootekategooria/roivad/fliisid-jakid-joped/joped',
	},
	[DealId.ROBOTIMAAILM_EE]: {
		id: DealId.ROBOTIMAAILM_EE,
		brand: 'Robotimaailm',
		title: LOCIZE_DEALS_KEYS.robotimaailm,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO30',
		discount: '-30 eur',
		discountDescription:
			LOCIZE_DEALS_KEYS.dialogDiscountDescriptionRobotimaailm,
		imageSrc: '/images/dashboard/deals/robotimaailm.webp',
		websiteUrl:
			'https://robotimaailm.ee/robottolmuimeja-ecovacs-deebot-x5-omni',
	},
	[DealId.MSL_EE]: {
		id: DealId.MSL_EE,
		brand: 'MSL',
		title: LOCIZE_DEALS_KEYS.msl,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO1',
		discount: '-25%',
		imageSrc: '/images/dashboard/deals/msl.webp',
		websiteUrl: 'https://www.msl.ee/et/a/voodi-hugo-valge',
	},
	[DealId.EKOPLANET_EE]: {
		id: DealId.EKOPLANET_EE,
		brand: 'Ekoplanet',
		title: LOCIZE_DEALS_KEYS.ekoplanet,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO20',
		discount: '-20%',
		imageSrc: '/images/dashboard/deals/ekoplanet.webp',
		websiteUrl: 'https://ekoplanet.lt/en/350-bartolini',
	},
	[DealId.EKOPLANET_LV]: {
		id: DealId.EKOPLANET_EE,
		brand: 'Ekoplanet',
		title: LOCIZE_DEALS_KEYS.ekoplanet,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO20',
		discount: '-20%',
		imageSrc: '/images/dashboard/deals/ekoplanet-2.webp',
		websiteUrl: 'https://ekoplanet.lv/350-bartolini',
	},
	[DealId.EKOPLANET_LT]: {
		id: DealId.EKOPLANET_EE,
		brand: 'Ekoplanet',
		title: LOCIZE_DEALS_KEYS.ekoplanet,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO20',
		discount: '-20%',
		imageSrc: '/images/dashboard/deals/ekoplanet-2.webp',
		websiteUrl: 'https://ekoplanet.lt/en/350-bartolini',
	},
	[DealId.ROKOKO_LV]: {
		id: DealId.ROKOKO_LV,
		brand: 'Rokoko',
		title: LOCIZE_DEALS_KEYS.rokokoLV,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ROKOKO25%',
		discount: '-25%',
		imageSrc: '/images/dashboard/deals/rokoko.webp',
		websiteUrl: 'https://rokoko.lv/en-gb/interior-items/',
	},
	[DealId.MANGUARVUTID_EE]: {
		id: DealId.MANGUARVUTID_EE,
		brand: 'Manguarvutid',
		title: LOCIZE_DEALS_KEYS.manguarvutid,
		description: LOCIZE_DEALS_KEYS.dialogDescription,
		discountCode: 'ESTO29',
		discount: '-29 eur',
		discountDescription:
			LOCIZE_DEALS_KEYS.dialogDiscountDescriptionManguarvutid,
		imageSrc: '/images/dashboard/deals/manguarvutid.webp',
		websiteUrl: 'https://manguarvutid.ee/shop',
	},
};
