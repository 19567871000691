import type { ROUTE_NAMES } from '@config/routes';
import CloseIcon from '@icons/close-sharp.svg?react';
import { Link } from '@tanstack/react-router';
import type { FC, PropsWithChildren, ReactNode } from 'react';
import styles from './DualPanelLayout.module.css';

type DualPanelLayoutProps = PropsWithChildren<{
	header?: ReactNode;
	left: ReactNode;
	right: ReactNode;
	fromPage: (typeof ROUTE_NAMES)[keyof typeof ROUTE_NAMES];
}>;

/**
 * DualPanelLayout component provides a responsive dual-panel layout with a left and a right panel.
 *
 * @param {(typeof ROUTE_NAMES)[keyof typeof ROUTE_NAMES]} fromPage - The route name to navigate back to, typically used for the close button functionality.
 * @param {ReactNode} left - The content to be displayed in the left panel.
 * @param {ReactNode} right - The content to be displayed in the right panel.
 * @returns {JSX.Element} A JSX element representing the dual-panel layout.
 */
export const DualPanelLayout: FC<DualPanelLayoutProps> = ({
	fromPage,
	header = (
		<Link to={fromPage}>
			<CloseIcon />
		</Link>
	),
	left,
	right,
}) => (
	<div className={styles.container}>
		<header className={styles.header}>{header}</header>
		<main className={styles.main}>
			{left ? <div className={styles.left}>{left}</div> : null}
			{right ? <div className={styles.right}>{right}</div> : null}
		</main>
	</div>
);
