import { useNavigate } from '@tanstack/react-router';
import { useLocalStorage } from 'react-use';
import { useGetAnalyticsCampaignParametersFromSearch } from './useGetAnalyticsCampaignParametersFromSearch';

const ANALYTICS_CAMPAIGN_PARAMETERS_LOCAL_STORAGE_KEY = 'acp'; // analytics campaign parameters (acp)

const DEFAULT_ANALYTICS_CAMPAIGN_PARAMETERS_LOCAL_STORAGE_VALUE =
	JSON.stringify({});

export const useHandleAnalyticsCampaignParameters = () => {
	const navigate = useNavigate();

	const [
		analyticsCampaignParameters = DEFAULT_ANALYTICS_CAMPAIGN_PARAMETERS_LOCAL_STORAGE_VALUE,
		setAnalyticsCampaignParameters,
	] = useLocalStorage(
		ANALYTICS_CAMPAIGN_PARAMETERS_LOCAL_STORAGE_KEY,
		DEFAULT_ANALYTICS_CAMPAIGN_PARAMETERS_LOCAL_STORAGE_VALUE,
	);

	const analyticsCampaignParametersFromSearch =
		useGetAnalyticsCampaignParametersFromSearch();

	const setParamsToLocalStorage = () => {
		setAnalyticsCampaignParameters(
			JSON.stringify(analyticsCampaignParametersFromSearch),
		);
	};

	const clearParamsFromLocalStorage = () => {
		setAnalyticsCampaignParameters(
			DEFAULT_ANALYTICS_CAMPAIGN_PARAMETERS_LOCAL_STORAGE_VALUE,
		);
	};

	const addParamsFromLocalStorageToUrl = () => {
		navigate({
			to: '.',
			search: (prev) => ({
				...prev,
				...JSON.parse(analyticsCampaignParameters),
			}),
		});
	};

	return {
		setParamsToLocalStorage,
		clearParamsFromLocalStorage,
		addParamsFromLocalStorageToUrl,
	};
};
