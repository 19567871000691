export enum MarketingSearchParam {
	UTM_SOURCE = 'utm_source',
	UTM_CAMPAIGN = 'utm_campaign',
	UTM_MEDIUM = 'utm_medium',
	UTM_CONTENT = 'utm_content',
	UTM_TERM = 'utm_term',
	GCLID = 'gclid',
	UTM_NAME = 'utm_name',
}

export enum MarketingSearchParamValue {
	UTM_SOURCE_CUSTOMER_PROFILE = 'cp',
}
