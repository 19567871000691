// @ts-expect-error plugin written in js
import tailwindcss_grid_areas from '@savvywombat/tailwindcss-grid-areas';
import type { Config } from 'tailwindcss';
import tailwindcss_animate from 'tailwindcss-animate';

const colorMixAlphaValueWithCustomProperty = (customPropertyName: string) => `color-mix(
  in srgb,
  var(${customPropertyName}),
  transparent calc(100% - 100% * <alpha-value>)
)`;

const config = {
	darkMode: 'class',
	content: [
		'./pages/**/*.{ts,tsx}',
		'./components/**/*.{ts,tsx}',
		'./src/**/*.{ts,tsx}',
	],
	prefix: '',
	theme: {
		container: {
			center: true,
			padding: '2rem',
			screens: {
				md: '768px',
				'2xl': '1400px',
			},
		},
		fontWeight: {
			normal: '400',
			semibold: '600',
			medium: '500',
			bold: '700',
		},
		fontFamily: {
			inter: ['Inter', 'sans-serif'],
		},
		extend: {
			borderRadius: {
				'1xl': '0.875rem',
			},
			boxShadow: {
				container: '0px 5px 20px 0px rgba(42, 40, 135, 0.05)',
				hover: '0px 6px 15px 0px rgba(42, 40, 135, 0.07)',
			},
			colors: {
				border: 'hsl(var(--border))',
				input: 'hsl(var(--input))',
				ring: 'hsl(var(--ring))',
				background: 'hsl(var(--background))',
				foreground: 'hsl(var(--foreground))',
				neutral: {
					900: 'var(--color-neutral-900)',
					800: 'var(--color-neutral-800)',
					700: 'var(--color-neutral-700)',
					600: 'var(--color-neutral-600)',
					500: 'var(--color-neutral-500)',
					400: 'var(--color-neutral-400)',
					300: 'var(--color-neutral-300)',
					200: 'var(--color-neutral-200)',
					100: 'var(--color-neutral-100)',
					50: 'var(--color-neutral-50)',
				},
				primary: {
					DEFAULT: 'hsl(var(--primary))',
					foreground: 'hsl(var(--primary-foreground))',
					brand02: colorMixAlphaValueWithCustomProperty(
						'--color-primary-brand-02',
					),
					black: colorMixAlphaValueWithCustomProperty('--color-primary-black'),
					white: colorMixAlphaValueWithCustomProperty('--color-primary-white'),
				},
				typography: {
					black: colorMixAlphaValueWithCustomProperty('--color-primary-black'),
					neutral400: colorMixAlphaValueWithCustomProperty(
						'--color-neutral-400',
					),
					neutral300: colorMixAlphaValueWithCustomProperty(
						'--color-neutral-300',
					),
					white: colorMixAlphaValueWithCustomProperty('--color-primary-white'),
				},
				borders: {
					neutral200: colorMixAlphaValueWithCustomProperty(
						'--color-neutral-200',
					),
				},
				system: {
					green: colorMixAlphaValueWithCustomProperty('--color-system-green'),
					yellow: colorMixAlphaValueWithCustomProperty('--color-system-green'),
					orange: colorMixAlphaValueWithCustomProperty('--color-system-orange'),
					red: colorMixAlphaValueWithCustomProperty('--color-system-red'),
				},
				secondary: {
					DEFAULT: 'hsl(var(--secondary))',
					foreground: 'hsl(var(--secondary-foreground))',
				},
				destructive: {
					DEFAULT: 'hsl(var(--destructive))',
					foreground: 'hsl(var(--destructive-foreground))',
				},
				muted: {
					DEFAULT: 'hsl(var(--muted))',
					foreground: 'hsl(var(--muted-foreground))',
				},
				accent: {
					DEFAULT: 'hsl(var(--accent))',
					foreground: 'hsl(var(--accent-foreground))',
				},
				popover: {
					DEFAULT: 'hsl(var(--popover))',
					foreground: 'hsl(var(--popover-foreground))',
				},
				card: {
					DEFAULT: 'hsl(var(--card))',
					foreground: 'hsl(var(--card-foreground))',
				},
			},
			transitionDelay: {
				'3000': '3000ms',
			},
			keyframes: {
				loader: {
					to: {
						opacity: '0.1',
						transform: 'translate3d(0, -0.25rem, 0)',
					},
				},
				'fade-out': {
					from: {
						opacity: '1',
					},
					to: {
						opacity: '0',
					},
				},
				'ride-in-right': {
					from: {
						transform: 'translateX(100%)',
						opacity: '0',
					},
					to: {
						transform: 'translateX(0)',
						opacity: '1',
					},
				},
				'ride-in-left': {
					from: {
						transform: 'translateX(-100%)',
						opacity: '0',
					},
					to: {
						transform: 'translateX(0)',
						opacity: '1',
					},
				},
				'loader-spinning': {
					'0%': {
						transform: 'rotate(0deg)',
					},
					'5.9%': {
						transform: 'rotate(90deg)',
					},
					'11.8%': {
						transform: 'rotate(90deg)',
					},
					'17.7%': {
						transform: 'rotate(90deg)',
					},
					'23.6%': {
						transform: 'rotate(90deg)',
					},
					'29.5%': {
						transform: 'rotate(180deg)',
					},
					'35.4%': {
						transform: 'rotate(180deg)',
					},
					'41.3%': {
						transform: 'rotate(180deg)',
					},
					'47.2%': {
						transform: 'rotate(180deg)',
					},
					'53.1%': {
						transform: 'rotate(270deg)',
					},
					'58.9%': {
						transform: 'rotate(270deg)',
					},
					'64.8%': {
						transform: 'rotate(270deg)',
					},
					'70.7%': {
						transform: 'rotate(270deg)',
					},
					'76.6%': {
						transform: 'rotate(360deg)',
					},
					'82.5%': {
						transform: 'rotate(360deg)',
					},
					'88.4%': {
						transform: 'rotate(360deg)',
					},
					'94.3%': {
						transform: 'rotate(360deg)',
					},
					'100%': {
						transform: 'rotate(360deg)',
					},
				},
				slideUp1: {
					// let's make a tick 4 ticks by 5.9% and update translate
					'0%': {
						transform: 'translateY(0)',
					},
					'5.9%': {
						transform: 'translateY(0)',
					},
					'11.8%': {
						transform: 'translateY(0)',
					},
					'17.7%': {
						transform: 'translateY(0)',
					},
					'23.6%': {
						transform: 'translateY(-100%)',
					},
					'29.5%': {
						transform: 'translateY(-100%)',
					},
					'35.4%': {
						transform: 'translateY(-100%)',
					},
					'41.3%': {
						transform: 'translateY(-100%)',
					},
					'47.2%': {
						transform: 'translateY(0)',
					},
					'53.1%': {
						transform: 'translateY(0)',
					},
					'58.9%': {
						transform: 'translateY(0)',
					},
					'64.8%': {
						transform: 'translateY(0)',
					},
					'70.7%': {
						transform: 'translateY(-100%)',
					},
					'76.6%': {
						transform: 'translateY(-100%)',
					},
					'82.5%': {
						transform: 'translateY(-100%)',
					},
					'88.4%': {
						transform: 'translateY(-100%)',
					},
					'94.3%': {
						transform: 'translateY(0)',
					},
					'100%': {
						transform: 'translateY(0)',
					},
				},
				slideUp2: {
					'0%': {
						transform: 'translateY(105%)',
					},
					'5.9%': {
						transform: 'translateY(105%)',
					},
					'11.8%': {
						transform: 'translateY(105%)',
					},
					'17.7%': {
						transform: 'translateY(105%)',
					},
					'23.6%': {
						transform: 'translateY(10%)',
					},
					'29.5%': {
						transform: 'translateY(10%)',
					},
					'35.4%': {
						transform: 'translateY(10%)',
					},
					'41.3%': {
						transform: 'translateY(10%)',
					},
					'47.2%': {
						transform: 'translateY(105%)',
					},
					'53.1%': {
						transform: 'translateY(105%)',
					},
					'58.9%': {
						transform: 'translateY(105%)',
					},
					'64.8%': {
						transform: 'translateY(105%)',
					},
					'70.7%': {
						transform: 'translateY(10%)',
					},
					'76.6%': {
						transform: 'translateY(10%)',
					},
					'82.5%': {
						transform: 'translateY(10%)',
					},
					'88.4%': {
						transform: 'translateY(10%)',
					},
					'94.3%': {
						transform: 'translateY(105%)',
					},
					'100%': {
						transform: 'translateY(105%)',
					},
				},
				fade: {
					'0%': {
						opacity: '1',
						height: '100%',
					},
					'50%': {
						opacity: '1',
						height: '100%',
					},
					'100%': {
						opacity: '0',
						height: '0',
					},
				},
				indicator: {
					'0%': {
						width: '0%',
						height: '0%',
						opacity: '0',
					},
					'15%': {
						opacity: '1',
					},
					'100%': {
						width: '100%',
						height: '100%',
						opacity: '0',
					},
				},
				'accordion-down': {
					from: { height: '0' },
					to: { height: 'var(--radix-accordion-content-height)' },
				},
				'accordion-up': {
					from: { height: 'var(--radix-accordion-content-height)' },
					to: { height: '0' },
				},
				bounce1: {
					'0%': { transform: 'translateY(0)' },
					'15%, 45%': { transform: 'translateY(-8px)' },
					'60%': { transform: 'translateY(0)' },
					'100%': { transform: 'translateY(0)' },
				},
				bounce2: {
					'0%, 15%': { transform: 'translateY(0)' },
					'30%, 45%': { transform: 'translateY(-12px)' },
					'60%': { transform: 'translateY(0)' },
					'100%': { transform: 'translateY(0)' },
				},
				bounce3: {
					'0%, 30%': { transform: 'translateY(0)' },
					'45%': { transform: 'translateY(-16px)' },
					'60%': { transform: 'translateY(0)' },
					'100%': { transform: 'translateY(0)' },
				},
			},
			animation: {
				bounce1: 'bounce1 2s cubic-bezier(0.4, 0, 0.2, 1) infinite',
				bounce2: 'bounce2 2s cubic-bezier(0.4, 0, 0.2, 1) infinite',
				bounce3: 'bounce3 2s cubic-bezier(0.4, 0, 0.2, 1) infinite',
				'accordion-down': 'accordion-down 0.2s ease-out',
				'accordion-up': 'accordion-up 0.2s ease-out',
				loader: 'loader 0.6s infinite alternate',
				'fade-out': 'fade-out 2s ease 0s 1 normal forwards',
				'ride-in-right': 'ride-in-right 2s ease 0s 1 normal forwards',
				'ride-in-left': 'ride-in-left 2s ease 0s 1 normal forwards',
				'loader-arrows-spin': 'loader-spinning 10s ease-in-out infinite;',
				'loader-diamond-spin':
					'loader-spinning 10s ease-in-out 1176ms infinite',
				'slide-up1': 'slideUp1 10s ease-in-out infinite',
				'slide-up2': 'slideUp2 10s ease-in-out infinite',
				fade: 'fade 1.25s ease-out forwards',
				indicator: 'indicator 2s ease-in-out infinite',
			},
		},
	},
	plugins: [tailwindcss_animate, tailwindcss_grid_areas],
} satisfies Config;

export default config;
