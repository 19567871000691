import { OLD_APP_DOMAIN, PURCHASE_FLOW_DOMAIN } from './envs';

export const ROUTE_NAMES = {
	current: '.',
	root: '/',
	auth: '/auth',
	authAdditionalInfo: '/auth-additional-info',
	dashboard: '/dashboard',
	premium: '/premium',
	profile: '/profile',
	profileComplete: '/profile/complete',
	profileEdit: '/profile/edit',
	premiumSubscribe: '/premium/subscribe',
	premiumUnsubscribe: '/premium/unsubscribe',
	creditAccountWithdrawal: '/credit-account-withdrawal',
	creditAccountWithdrawalForm: '/credit-account-withdrawal/form',
	creditAccountWithdrawalSuccess: '/credit-account-withdrawal/success',
	creditAccountWithdrawalReject: '/credit-account-withdrawal/reject',
	creditAccountModification: '/credit-account-modification',
	creditAccountModificationFailure: '/credit-account-modification/failure',
	creditAccountModificationSigning: '/credit-account-modification/signing',
	creditAccountLimitIncrease: '/credit-account-limit-increase',
	creditAccountLimitIncreaseFailure: '/credit-account-limit-increase/failure',
	creditAccountLimitIncreaseSigning: '/credit-account-limit-increase/signing',
	creditAccountConversion: '/credit-account-conversion',
	creditAccountConversionSigning: '/credit-account-conversion/signing',
	creditAccountConversionApply: '/credit-account-conversion/apply',
	creditAccountConversionReject: '/credit-account-conversion/reject',
	creditLine: '/credit-line',
	invoices: '/invoices',
	agreements: '/agreements',
	paymentAgreement: '/payment/agreement',
	paymentAgreementSuccess: '/payment/agreement/success',
	paymentAgreementReject: '/payment/agreement/reject',
	paymentAgreementPending: '/payment/agreement/pending',
	gracePeriod: '/grace-period',
	gracePeriodReject: '/grace-period/reject',
	gracePeriodSuccess: '/grace-period/success',
	standingPayment: '/standing-payment',
	standingPaymentEnable: '/standing-payment/enable',
	standingPaymentDisable: '/standing-payment/disable',
	standingPaymentReject: '/standing-payment/reject',
	standingPaymentSuccess: '/standing-payment/success',
	paymentInvoice: '/payment/invoice',
	paymentInvoiceSuccess: '/payment/invoice/success',
	paymentInvoiceReject: '/payment/invoice/reject',
	paymentInvoicePending: '/payment/invoice/pending',
	paymentCreditLine: '/payment/credit-line',
	paymentCreditLineSuccess: '/payment/credit-line/success',
	paymentCreditLineReject: '/payment/credit-line/reject',
	paymentCreditLinePending: '/payment/credit-line/pending',
} as const;

export const OLD_APP_ROUTE_NAME = {
	pay: `${OLD_APP_DOMAIN}/pay`,
	payApplication: `${OLD_APP_DOMAIN}/pay/application/:applicationReferenceKey`,
	payPremium: `${OLD_APP_DOMAIN}/pay/invoice?opt-out-onboard-to-premium-account-overdue=1`,
	payCreditAccount: `${OLD_APP_DOMAIN}/pay/credit-account/:creditAccountId`,
	invoices: `${OLD_APP_DOMAIN}/invoices`,
	profile: `${OLD_APP_DOMAIN}/profile`,
	contracts: `${OLD_APP_DOMAIN}/contracts`,
	applicationContract: `${OLD_APP_DOMAIN}/contracts/:applicationReferenceKey`,
	creditAccountContract: `${OLD_APP_DOMAIN}#getCredit`,
	applicationReduceMonthlyPayment: `${OLD_APP_DOMAIN}/reduce-monthly-payment/:applicationReferenceKey`,
	creditAccountLimitIncrease: `${OLD_APP_DOMAIN}/ca-limit-increase`,
	creditAccountModification: `${OLD_APP_DOMAIN}/ca-modification`,
} as const;

export const PURCHASE_FLOW_ROUTE_NAME = {
	creditLine: `${PURCHASE_FLOW_DOMAIN}/credit-line`,
	creditLineInterestFree: `${PURCHASE_FLOW_DOMAIN}/credit-line?onboarding=ca-interest-free`,
	creditLineWithdrawal: `${PURCHASE_FLOW_DOMAIN}/credit-line-withdrawal?hash=$hash`,
	creditLineConversion: `${PURCHASE_FLOW_DOMAIN}/credit-line?rmp_app_ref=$applicationReferenceKey`,
} as const;
