import { ROUTE_NAMES } from '@config/routes';
import { StandingPaymentStatus } from '@features/standing-payment-enable/config';
import { createFileRoute, redirect } from '@tanstack/react-router';
import * as z from 'zod';

const StandingPaymentSearchSchema = z.object({
	fromPathname: z.string().optional().catch(undefined),
	payment_status: z
		.nativeEnum(StandingPaymentStatus)
		.optional()
		.catch(undefined),
});

export const Route = createFileRoute('/_protected/standing-payment')({
	validateSearch: StandingPaymentSearchSchema,
	beforeLoad: async ({ search: { payment_status } }) => {
		if (
			payment_status === StandingPaymentStatus.SUCCESS &&
			location.pathname !== ROUTE_NAMES.standingPaymentSuccess
		) {
			throw redirect({
				to: ROUTE_NAMES.standingPaymentSuccess,
				replace: true,
			});
		}

		if (
			payment_status === StandingPaymentStatus.FAILED &&
			location.pathname !== ROUTE_NAMES.standingPaymentReject
		) {
			throw redirect({
				to: ROUTE_NAMES.standingPaymentReject,
				replace: true,
			});
		}
	},
});
