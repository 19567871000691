import { Typography } from '@components/typography';
import { Indicator, type IndicatorProps } from '@components/ui/indicator';
import { Skeleton } from '@components/ui/skeleton';
import ChevronIcon from '@icons/chevron-right.svg?react';
import { cn } from '@utils/tailwind';
import type { FC } from 'react';

const DOT_CLASSNAMES = 'bg-primary-black animate-loader rounded-full size-2';

type CtaProps = {
	dataTestId?: string;
	text: string;
	actionText?: string;
	actionButton?: boolean;
	onClick: () => void;
	loading?: boolean;
	className?: string;
	indicatorProps?: Pick<IndicatorProps, 'variant' | 'isAnimated'>;
};

export const Cta: FC<CtaProps> = ({
	text,
	actionText,
	onClick,
	loading,
	className,
	actionButton,
	indicatorProps,
	dataTestId,
}) => (
	<button
		type="button"
		onClick={onClick}
		className={cn(
			'group flex items-center gap-2 rounded-full border border-neutral-100 bg-primary-white py-3 pr-6 pl-4 transition-shadow hover:shadow-hover',
			className,
		)}
		data-testid={dataTestId}
		disabled={loading}
	>
		<Indicator {...indicatorProps} />
		<Typography variant="text-s" className="flex-1 text-left">
			{text}
		</Typography>
		{loading ? (
			<div className="flex h-3 items-center justify-center gap-[0.3125rem]">
				<div className={DOT_CLASSNAMES} />
				<div className={cn('animation-delay-200', DOT_CLASSNAMES)} />
				<div className={cn('animation-delay-400', DOT_CLASSNAMES)} />
			</div>
		) : actionText ? (
			<Typography
				variant="text-s"
				className="underline-from-font underline transition-colors group-hover:text-primary-brand02"
			>
				{actionText}
			</Typography>
		) : actionButton ? (
			<ChevronIcon className="group-hover:text-primary-brand02" />
		) : null}
	</button>
);

type CtaSkeletonProps = {
	className?: string;
};

export const CtaSkeleton: FC<CtaSkeletonProps> = ({ className }) => (
	<Skeleton
		className={cn('h-[2.875rem] w-full rounded-full border p-4', className)}
	/>
);
