/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../shared/types/api.gen';

import { useQuery, useSuspenseQuery, UseQueryOptions, UseSuspenseQueryOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type UserInvoiceQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserInvoiceQuery = { me?: { unpaid_invoice_amount: number, recent_invoice?: { total_amount: number, due_at: string, url?: string | null, invoice_reference_nr: string } | null, subscription?: { id: number } | null } | null };

export type UserPaymentDataQueryVariables = Types.Exact<{
  referenceKey: Types.Scalars['String']['input'];
}>;


export type UserPaymentDataQuery = { user_payment_data?: { user_id: number, invoice_reference_nr: string, amount: number, recent_invoice?: { id: number, url?: string | null, due_at: string, invoice_reference_nr: string } | null } | null };



export const UserInvoiceDocument = `
    query UserInvoice {
  me(is_me: true) {
    unpaid_invoice_amount
    recent_invoice {
      total_amount
      due_at
      url
      invoice_reference_nr
    }
    subscription {
      id
    }
  }
}
    `;

export const useUserInvoiceQuery = <
      TData = UserInvoiceQuery,
      TError = unknown
    >(
      variables?: UserInvoiceQueryVariables,
      options?: Omit<UseQueryOptions<UserInvoiceQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<UserInvoiceQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<UserInvoiceQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['UserInvoice'] : ['UserInvoice', variables],
    queryFn: fetcher<UserInvoiceQuery, UserInvoiceQueryVariables>(UserInvoiceDocument, variables),
    ...options
  }
    )};

useUserInvoiceQuery.getKey = (variables?: UserInvoiceQueryVariables) => variables === undefined ? ['UserInvoice'] : ['UserInvoice', variables];

export const useSuspenseUserInvoiceQuery = <
      TData = UserInvoiceQuery,
      TError = unknown
    >(
      variables?: UserInvoiceQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<UserInvoiceQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<UserInvoiceQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<UserInvoiceQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['UserInvoiceSuspense'] : ['UserInvoiceSuspense', variables],
    queryFn: fetcher<UserInvoiceQuery, UserInvoiceQueryVariables>(UserInvoiceDocument, variables),
    ...options
  }
    )};

useSuspenseUserInvoiceQuery.getKey = (variables?: UserInvoiceQueryVariables) => variables === undefined ? ['UserInvoiceSuspense'] : ['UserInvoiceSuspense', variables];


useUserInvoiceQuery.fetcher = (variables?: UserInvoiceQueryVariables, options?: RequestInit['headers']) => fetcher<UserInvoiceQuery, UserInvoiceQueryVariables>(UserInvoiceDocument, variables, options);

export const UserPaymentDataDocument = `
    query UserPaymentData($referenceKey: String!) {
  user_payment_data(reference_key: $referenceKey) {
    user_id
    invoice_reference_nr
    amount
    recent_invoice {
      id
      url
      due_at
      invoice_reference_nr
    }
  }
}
    `;

export const useUserPaymentDataQuery = <
      TData = UserPaymentDataQuery,
      TError = unknown
    >(
      variables: UserPaymentDataQueryVariables,
      options?: Omit<UseQueryOptions<UserPaymentDataQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<UserPaymentDataQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<UserPaymentDataQuery, TError, TData>(
      {
    queryKey: ['UserPaymentData', variables],
    queryFn: fetcher<UserPaymentDataQuery, UserPaymentDataQueryVariables>(UserPaymentDataDocument, variables),
    ...options
  }
    )};

useUserPaymentDataQuery.getKey = (variables: UserPaymentDataQueryVariables) => ['UserPaymentData', variables];

export const useSuspenseUserPaymentDataQuery = <
      TData = UserPaymentDataQuery,
      TError = unknown
    >(
      variables: UserPaymentDataQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<UserPaymentDataQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<UserPaymentDataQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<UserPaymentDataQuery, TError, TData>(
      {
    queryKey: ['UserPaymentDataSuspense', variables],
    queryFn: fetcher<UserPaymentDataQuery, UserPaymentDataQueryVariables>(UserPaymentDataDocument, variables),
    ...options
  }
    )};

useSuspenseUserPaymentDataQuery.getKey = (variables: UserPaymentDataQueryVariables) => ['UserPaymentDataSuspense', variables];


useUserPaymentDataQuery.fetcher = (variables: UserPaymentDataQueryVariables, options?: RequestInit['headers']) => fetcher<UserPaymentDataQuery, UserPaymentDataQueryVariables>(UserPaymentDataDocument, variables, options);
