import {
	AGREEMENTS_FEATURE,
	CREDIT_ACCOUNT_CONVERSION_FEATURE,
	CREDIT_ACCOUNT_LIMIT_INCREASE_FEATURE,
	CREDIT_ACCOUNT_MODIFICATION_FEATURE,
	CREDIT_LINE_PAYMENT_FEATURE,
	INVOICES_FEATURE,
	PROFILE_FEATURE,
} from '@config/envs';

export const useFeatureToggles = () => ({
	invoicesFeature: INVOICES_FEATURE,
	profileFeature: PROFILE_FEATURE,
	agreementsFeature: AGREEMENTS_FEATURE,
	creditAccountModificationFeature: CREDIT_ACCOUNT_MODIFICATION_FEATURE,
	creditAccountLimitIncreaseFeature: CREDIT_ACCOUNT_LIMIT_INCREASE_FEATURE,
	creditAccountConversionFeature: CREDIT_ACCOUNT_CONVERSION_FEATURE,
	creditLinePaymentFeature: CREDIT_LINE_PAYMENT_FEATURE,
});
