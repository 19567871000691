import { SupportedCountries } from '@/shared/types';
import { APP_COUNTRY } from '@config/envs';
import { DealId } from '@entities/deals';

export const DEALS_IDS_BY_COUNTRY = {
	[SupportedCountries.EE]: [
		DealId.ELIX_HEALTH_EE,
		DealId.STREET_STRIDER_EE,
		DealId.BILANCE,
		DealId.MEREVARUSTUS_EE,
		DealId.INPUIT_EE,
		DealId.ANIX_SHOP_EE,
		DealId.LIGHT_CONCEPT_EE,
		DealId.ALEXANTO_EE,
		DealId.MLILY_EE,
		DealId.GIVEN_EE,
		DealId.GOLDEN_LOMBARD_EE,
		DealId.MADRATSIPOOD_EE,
		DealId.AIRSOFTGO_EE,
		DealId.ROBOTIMAAILM_EE,
		DealId.MSL_EE,
		DealId.EKOPLANET_EE,
		DealId.MANGUARVUTID_EE,
		DealId.KULDAN,
		DealId.UPGREAT_EE,
		DealId.FITLAP,
	],
	[SupportedCountries.LV]: [
		DealId.BILANCE,
		DealId.PROBIKE_LV,
		DealId.TOPCIK_LV,
		DealId.ROKOKO_LV,
		DealId.EKOPLANET_LV,
		DealId.UPGREAT_LV,
		DealId.WOLT_LV,
	],
	[SupportedCountries.LT]: [
		DealId.SMARTPC_LT,
		DealId.BILANCE,
		DealId.EKOPLANET_LT,
		DealId.UPGREAT_LT,
		DealId.WOLT_LT,
		DealId.MOKYKLINE,
	],
} as const satisfies Record<SupportedCountries, Array<DealId>>;

export const DEALS_IDS = DEALS_IDS_BY_COUNTRY[APP_COUNTRY];

export const RUDDERSTACK_DEALS_EVENTS = {
	CUSTOMER_PROFILE_ESTO_DEAL_CLICKED: 'CUSTOMER_PROFILE_ESTO_DEAL_CLICKED',
} as const;
