export const LOCIZE_GRACE_PERIOD_KEYS = {
	agreementLink: 'agreement-link',
	conditionsLink: 'conditions-link',
	continueButton: 'continue-button',
	infoDescription1: 'info-description-1',
	infoDescription2: 'info-description-2',
	infoDescription3: 'info-description-3',
	infoTitle1: 'info-title-1',
	infoTitle2: 'info-title-2',
	infoTitle3: 'info-title-3',
	pageHeading: 'page-heading',
	pageTitle: 'page-title',
	periodSelectLabel: 'period-select-label',
	periodSelectMonth: 'period-select-month',
	periodSelectMonths: 'period-select-months',
	periodSelectPlaceholder: 'period-select-placeholder',
	rejectDescription: 'reject.description',
	rejectTitle: 'reject.title',
	successDescription: 'success.description',
	successTitle: 'success.title',
} as const;
