/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AuthRouteImport } from './routes/auth/route'
import { Route as ProtectedRouteImport } from './routes/_protected/route'
import { Route as ProtectedStandingPaymentRouteImport } from './routes/_protected/standing-payment/route'
import { Route as ProtectedCreditAccountWithdrawalRouteImport } from './routes/_protected/credit-account-withdrawal/route'
import { Route as ProtectedCreditAccountModificationRouteImport } from './routes/_protected/credit-account-modification/route'
import { Route as ProtectedCreditAccountLimitIncreaseRouteImport } from './routes/_protected/credit-account-limit-increase/route'
import { Route as ProtectedCreditAccountConversionRouteImport } from './routes/_protected/credit-account-conversion/route'
import { Route as ProtectedAuthAdditionalInfoRouteImport } from './routes/_protected/auth-additional-info/route'
import { Route as ProtectedMainRouteImport } from './routes/_protected/_main/route'
import { Route as PaymentInvoicePendingRouteImport } from './routes/payment/invoice/pending/route'
import { Route as PaymentCreditLinePendingRouteImport } from './routes/payment/credit-line/pending/route'
import { Route as PaymentAgreementPendingRouteImport } from './routes/payment/agreement/pending/route'
import { Route as ProtectedCreditAccountConversionSigningRouteImport } from './routes/_protected/credit-account-conversion/signing/route'
import { Route as ProtectedCreditAccountConversionRejectRouteImport } from './routes/_protected/credit-account-conversion/reject/route'
import { Route as ProtectedCreditAccountConversionApplyRouteImport } from './routes/_protected/credit-account-conversion/apply/route'
import { Route as ProtectedMainProfileRouteImport } from './routes/_protected/_main/profile/route'
import { Route as ProtectedMainInvoicesRouteImport } from './routes/_protected/_main/invoices/route'
import { Route as ProtectedMainDashboardRouteImport } from './routes/_protected/_main/dashboard/route'
import { Route as ProtectedMainCreditLineRouteImport } from './routes/_protected/_main/credit-line/route'
import { Route as ProtectedMainAgreementsRouteImport } from './routes/_protected/_main/agreements/route'
import { Route as PaymentInvoiceIndexRouteImport } from './routes/payment/invoice/index/route'
import { Route as PaymentCreditLineIndexRouteImport } from './routes/payment/credit-line/index/route'
import { Route as PaymentAgreementIndexRouteImport } from './routes/payment/agreement/index/route'
import { Route as ProtectedGracePeriodIndexRouteImport } from './routes/_protected/grace-period/index/route'

// Create Virtual Routes

const PaymentRouteLazyImport = createFileRoute('/payment')()
const IndexLazyImport = createFileRoute('/')()
const PaymentInvoiceSuccessRouteLazyImport = createFileRoute(
  '/payment/invoice/success',
)()
const PaymentInvoiceRejectRouteLazyImport = createFileRoute(
  '/payment/invoice/reject',
)()
const PaymentCreditLineSuccessRouteLazyImport = createFileRoute(
  '/payment/credit-line/success',
)()
const PaymentCreditLineRejectRouteLazyImport = createFileRoute(
  '/payment/credit-line/reject',
)()
const PaymentAgreementSuccessRouteLazyImport = createFileRoute(
  '/payment/agreement/success',
)()
const PaymentAgreementRejectRouteLazyImport = createFileRoute(
  '/payment/agreement/reject',
)()
const ProtectedStandingPaymentSuccessRouteLazyImport = createFileRoute(
  '/_protected/standing-payment/success',
)()
const ProtectedStandingPaymentRejectRouteLazyImport = createFileRoute(
  '/_protected/standing-payment/reject',
)()
const ProtectedStandingPaymentEnableRouteLazyImport = createFileRoute(
  '/_protected/standing-payment/enable',
)()
const ProtectedStandingPaymentDisableRouteLazyImport = createFileRoute(
  '/_protected/standing-payment/disable',
)()
const ProtectedGracePeriodSuccessRouteLazyImport = createFileRoute(
  '/_protected/grace-period/success',
)()
const ProtectedGracePeriodRejectRouteLazyImport = createFileRoute(
  '/_protected/grace-period/reject',
)()
const ProtectedCreditAccountWithdrawalSuccessRouteLazyImport = createFileRoute(
  '/_protected/credit-account-withdrawal/success',
)()
const ProtectedCreditAccountWithdrawalRejectRouteLazyImport = createFileRoute(
  '/_protected/credit-account-withdrawal/reject',
)()
const ProtectedCreditAccountWithdrawalFormRouteLazyImport = createFileRoute(
  '/_protected/credit-account-withdrawal/form',
)()
const ProtectedCreditAccountModificationSigningRouteLazyImport =
  createFileRoute('/_protected/credit-account-modification/signing')()
const ProtectedCreditAccountModificationFailureRouteLazyImport =
  createFileRoute('/_protected/credit-account-modification/failure')()
const ProtectedCreditAccountLimitIncreaseSigningRouteLazyImport =
  createFileRoute('/_protected/credit-account-limit-increase/signing')()
const ProtectedCreditAccountLimitIncreaseFailureRouteLazyImport =
  createFileRoute('/_protected/credit-account-limit-increase/failure')()
const ProtectedMainPremiumRouteLazyImport = createFileRoute(
  '/_protected/_main/premium',
)()
const ProtectedMainProfileEditRouteLazyImport = createFileRoute(
  '/_protected/_main/profile/edit',
)()
const ProtectedMainProfileCompleteRouteLazyImport = createFileRoute(
  '/_protected/_main/profile/complete',
)()
const ProtectedMainPremiumUnsubscribeRouteLazyImport = createFileRoute(
  '/_protected/_main/premium/unsubscribe',
)()
const ProtectedMainPremiumSubscribeRouteLazyImport = createFileRoute(
  '/_protected/_main/premium/subscribe',
)()

// Create/Update Routes

const PaymentRouteLazyRoute = PaymentRouteLazyImport.update({
  id: '/payment',
  path: '/payment',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/payment/route.lazy').then((d) => d.Route))

const AuthRouteRoute = AuthRouteImport.update({
  id: '/auth',
  path: '/auth',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/auth/route.lazy').then((d) => d.Route))

const ProtectedRouteRoute = ProtectedRouteImport.update({
  id: '/_protected',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/_protected/route.lazy').then((d) => d.Route),
)

const IndexLazyRoute = IndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/index.lazy').then((d) => d.Route))

const ProtectedStandingPaymentRouteRoute =
  ProtectedStandingPaymentRouteImport.update({
    id: '/standing-payment',
    path: '/standing-payment',
    getParentRoute: () => ProtectedRouteRoute,
  } as any).lazy(() =>
    import('./routes/_protected/standing-payment/route.lazy').then(
      (d) => d.Route,
    ),
  )

const ProtectedCreditAccountWithdrawalRouteRoute =
  ProtectedCreditAccountWithdrawalRouteImport.update({
    id: '/credit-account-withdrawal',
    path: '/credit-account-withdrawal',
    getParentRoute: () => ProtectedRouteRoute,
  } as any).lazy(() =>
    import('./routes/_protected/credit-account-withdrawal/route.lazy').then(
      (d) => d.Route,
    ),
  )

const ProtectedCreditAccountModificationRouteRoute =
  ProtectedCreditAccountModificationRouteImport.update({
    id: '/credit-account-modification',
    path: '/credit-account-modification',
    getParentRoute: () => ProtectedRouteRoute,
  } as any).lazy(() =>
    import('./routes/_protected/credit-account-modification/route.lazy').then(
      (d) => d.Route,
    ),
  )

const ProtectedCreditAccountLimitIncreaseRouteRoute =
  ProtectedCreditAccountLimitIncreaseRouteImport.update({
    id: '/credit-account-limit-increase',
    path: '/credit-account-limit-increase',
    getParentRoute: () => ProtectedRouteRoute,
  } as any).lazy(() =>
    import('./routes/_protected/credit-account-limit-increase/route.lazy').then(
      (d) => d.Route,
    ),
  )

const ProtectedCreditAccountConversionRouteRoute =
  ProtectedCreditAccountConversionRouteImport.update({
    id: '/credit-account-conversion',
    path: '/credit-account-conversion',
    getParentRoute: () => ProtectedRouteRoute,
  } as any).lazy(() =>
    import('./routes/_protected/credit-account-conversion/route.lazy').then(
      (d) => d.Route,
    ),
  )

const ProtectedAuthAdditionalInfoRouteRoute =
  ProtectedAuthAdditionalInfoRouteImport.update({
    id: '/auth-additional-info',
    path: '/auth-additional-info',
    getParentRoute: () => ProtectedRouteRoute,
  } as any).lazy(() =>
    import('./routes/_protected/auth-additional-info/route.lazy').then(
      (d) => d.Route,
    ),
  )

const ProtectedMainRouteRoute = ProtectedMainRouteImport.update({
  id: '/_main',
  getParentRoute: () => ProtectedRouteRoute,
} as any).lazy(() =>
  import('./routes/_protected/_main/route.lazy').then((d) => d.Route),
)

const PaymentInvoiceSuccessRouteLazyRoute =
  PaymentInvoiceSuccessRouteLazyImport.update({
    id: '/invoice/success',
    path: '/invoice/success',
    getParentRoute: () => PaymentRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/payment/invoice/success/route.lazy').then((d) => d.Route),
  )

const PaymentInvoiceRejectRouteLazyRoute =
  PaymentInvoiceRejectRouteLazyImport.update({
    id: '/invoice/reject',
    path: '/invoice/reject',
    getParentRoute: () => PaymentRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/payment/invoice/reject/route.lazy').then((d) => d.Route),
  )

const PaymentCreditLineSuccessRouteLazyRoute =
  PaymentCreditLineSuccessRouteLazyImport.update({
    id: '/credit-line/success',
    path: '/credit-line/success',
    getParentRoute: () => PaymentRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/payment/credit-line/success/route.lazy').then(
      (d) => d.Route,
    ),
  )

const PaymentCreditLineRejectRouteLazyRoute =
  PaymentCreditLineRejectRouteLazyImport.update({
    id: '/credit-line/reject',
    path: '/credit-line/reject',
    getParentRoute: () => PaymentRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/payment/credit-line/reject/route.lazy').then(
      (d) => d.Route,
    ),
  )

const PaymentAgreementSuccessRouteLazyRoute =
  PaymentAgreementSuccessRouteLazyImport.update({
    id: '/agreement/success',
    path: '/agreement/success',
    getParentRoute: () => PaymentRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/payment/agreement/success/route.lazy').then(
      (d) => d.Route,
    ),
  )

const PaymentAgreementRejectRouteLazyRoute =
  PaymentAgreementRejectRouteLazyImport.update({
    id: '/agreement/reject',
    path: '/agreement/reject',
    getParentRoute: () => PaymentRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/payment/agreement/reject/route.lazy').then((d) => d.Route),
  )

const ProtectedStandingPaymentSuccessRouteLazyRoute =
  ProtectedStandingPaymentSuccessRouteLazyImport.update({
    id: '/success',
    path: '/success',
    getParentRoute: () => ProtectedStandingPaymentRouteRoute,
  } as any).lazy(() =>
    import('./routes/_protected/standing-payment/success/route.lazy').then(
      (d) => d.Route,
    ),
  )

const ProtectedStandingPaymentRejectRouteLazyRoute =
  ProtectedStandingPaymentRejectRouteLazyImport.update({
    id: '/reject',
    path: '/reject',
    getParentRoute: () => ProtectedStandingPaymentRouteRoute,
  } as any).lazy(() =>
    import('./routes/_protected/standing-payment/reject/route.lazy').then(
      (d) => d.Route,
    ),
  )

const ProtectedStandingPaymentEnableRouteLazyRoute =
  ProtectedStandingPaymentEnableRouteLazyImport.update({
    id: '/enable',
    path: '/enable',
    getParentRoute: () => ProtectedStandingPaymentRouteRoute,
  } as any).lazy(() =>
    import('./routes/_protected/standing-payment/enable/route.lazy').then(
      (d) => d.Route,
    ),
  )

const ProtectedStandingPaymentDisableRouteLazyRoute =
  ProtectedStandingPaymentDisableRouteLazyImport.update({
    id: '/disable',
    path: '/disable',
    getParentRoute: () => ProtectedStandingPaymentRouteRoute,
  } as any).lazy(() =>
    import('./routes/_protected/standing-payment/disable/route.lazy').then(
      (d) => d.Route,
    ),
  )

const ProtectedGracePeriodSuccessRouteLazyRoute =
  ProtectedGracePeriodSuccessRouteLazyImport.update({
    id: '/grace-period/success',
    path: '/grace-period/success',
    getParentRoute: () => ProtectedRouteRoute,
  } as any).lazy(() =>
    import('./routes/_protected/grace-period/success/route.lazy').then(
      (d) => d.Route,
    ),
  )

const ProtectedGracePeriodRejectRouteLazyRoute =
  ProtectedGracePeriodRejectRouteLazyImport.update({
    id: '/grace-period/reject',
    path: '/grace-period/reject',
    getParentRoute: () => ProtectedRouteRoute,
  } as any).lazy(() =>
    import('./routes/_protected/grace-period/reject/route.lazy').then(
      (d) => d.Route,
    ),
  )

const ProtectedCreditAccountWithdrawalSuccessRouteLazyRoute =
  ProtectedCreditAccountWithdrawalSuccessRouteLazyImport.update({
    id: '/success',
    path: '/success',
    getParentRoute: () => ProtectedCreditAccountWithdrawalRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/_protected/credit-account-withdrawal/success/route.lazy'
    ).then((d) => d.Route),
  )

const ProtectedCreditAccountWithdrawalRejectRouteLazyRoute =
  ProtectedCreditAccountWithdrawalRejectRouteLazyImport.update({
    id: '/reject',
    path: '/reject',
    getParentRoute: () => ProtectedCreditAccountWithdrawalRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/_protected/credit-account-withdrawal/reject/route.lazy'
    ).then((d) => d.Route),
  )

const ProtectedCreditAccountWithdrawalFormRouteLazyRoute =
  ProtectedCreditAccountWithdrawalFormRouteLazyImport.update({
    id: '/form',
    path: '/form',
    getParentRoute: () => ProtectedCreditAccountWithdrawalRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/_protected/credit-account-withdrawal/form/route.lazy'
    ).then((d) => d.Route),
  )

const ProtectedCreditAccountModificationSigningRouteLazyRoute =
  ProtectedCreditAccountModificationSigningRouteLazyImport.update({
    id: '/signing',
    path: '/signing',
    getParentRoute: () => ProtectedCreditAccountModificationRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/_protected/credit-account-modification/signing/route.lazy'
    ).then((d) => d.Route),
  )

const ProtectedCreditAccountModificationFailureRouteLazyRoute =
  ProtectedCreditAccountModificationFailureRouteLazyImport.update({
    id: '/failure',
    path: '/failure',
    getParentRoute: () => ProtectedCreditAccountModificationRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/_protected/credit-account-modification/failure/route.lazy'
    ).then((d) => d.Route),
  )

const ProtectedCreditAccountLimitIncreaseSigningRouteLazyRoute =
  ProtectedCreditAccountLimitIncreaseSigningRouteLazyImport.update({
    id: '/signing',
    path: '/signing',
    getParentRoute: () => ProtectedCreditAccountLimitIncreaseRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/_protected/credit-account-limit-increase/signing/route.lazy'
    ).then((d) => d.Route),
  )

const ProtectedCreditAccountLimitIncreaseFailureRouteLazyRoute =
  ProtectedCreditAccountLimitIncreaseFailureRouteLazyImport.update({
    id: '/failure',
    path: '/failure',
    getParentRoute: () => ProtectedCreditAccountLimitIncreaseRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/_protected/credit-account-limit-increase/failure/route.lazy'
    ).then((d) => d.Route),
  )

const ProtectedMainPremiumRouteLazyRoute =
  ProtectedMainPremiumRouteLazyImport.update({
    id: '/premium',
    path: '/premium',
    getParentRoute: () => ProtectedMainRouteRoute,
  } as any).lazy(() =>
    import('./routes/_protected/_main/premium/route.lazy').then((d) => d.Route),
  )

const PaymentInvoicePendingRouteRoute = PaymentInvoicePendingRouteImport.update(
  {
    id: '/invoice/pending',
    path: '/invoice/pending',
    getParentRoute: () => PaymentRouteLazyRoute,
  } as any,
).lazy(() =>
  import('./routes/payment/invoice/pending/route.lazy').then((d) => d.Route),
)

const PaymentCreditLinePendingRouteRoute =
  PaymentCreditLinePendingRouteImport.update({
    id: '/credit-line/pending',
    path: '/credit-line/pending',
    getParentRoute: () => PaymentRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/payment/credit-line/pending/route.lazy').then(
      (d) => d.Route,
    ),
  )

const PaymentAgreementPendingRouteRoute =
  PaymentAgreementPendingRouteImport.update({
    id: '/agreement/pending',
    path: '/agreement/pending',
    getParentRoute: () => PaymentRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/payment/agreement/pending/route.lazy').then(
      (d) => d.Route,
    ),
  )

const ProtectedCreditAccountConversionSigningRouteRoute =
  ProtectedCreditAccountConversionSigningRouteImport.update({
    id: '/signing',
    path: '/signing',
    getParentRoute: () => ProtectedCreditAccountConversionRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/_protected/credit-account-conversion/signing/route.lazy'
    ).then((d) => d.Route),
  )

const ProtectedCreditAccountConversionRejectRouteRoute =
  ProtectedCreditAccountConversionRejectRouteImport.update({
    id: '/reject',
    path: '/reject',
    getParentRoute: () => ProtectedCreditAccountConversionRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/_protected/credit-account-conversion/reject/route.lazy'
    ).then((d) => d.Route),
  )

const ProtectedCreditAccountConversionApplyRouteRoute =
  ProtectedCreditAccountConversionApplyRouteImport.update({
    id: '/apply',
    path: '/apply',
    getParentRoute: () => ProtectedCreditAccountConversionRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/_protected/credit-account-conversion/apply/route.lazy'
    ).then((d) => d.Route),
  )

const ProtectedMainProfileRouteRoute = ProtectedMainProfileRouteImport.update({
  id: '/profile',
  path: '/profile',
  getParentRoute: () => ProtectedMainRouteRoute,
} as any).lazy(() =>
  import('./routes/_protected/_main/profile/route.lazy').then((d) => d.Route),
)

const ProtectedMainInvoicesRouteRoute = ProtectedMainInvoicesRouteImport.update(
  {
    id: '/invoices',
    path: '/invoices',
    getParentRoute: () => ProtectedMainRouteRoute,
  } as any,
).lazy(() =>
  import('./routes/_protected/_main/invoices/route.lazy').then((d) => d.Route),
)

const ProtectedMainDashboardRouteRoute =
  ProtectedMainDashboardRouteImport.update({
    id: '/dashboard',
    path: '/dashboard',
    getParentRoute: () => ProtectedMainRouteRoute,
  } as any).lazy(() =>
    import('./routes/_protected/_main/dashboard/route.lazy').then(
      (d) => d.Route,
    ),
  )

const ProtectedMainCreditLineRouteRoute =
  ProtectedMainCreditLineRouteImport.update({
    id: '/credit-line',
    path: '/credit-line',
    getParentRoute: () => ProtectedMainRouteRoute,
  } as any).lazy(() =>
    import('./routes/_protected/_main/credit-line/route.lazy').then(
      (d) => d.Route,
    ),
  )

const ProtectedMainAgreementsRouteRoute =
  ProtectedMainAgreementsRouteImport.update({
    id: '/agreements',
    path: '/agreements',
    getParentRoute: () => ProtectedMainRouteRoute,
  } as any).lazy(() =>
    import('./routes/_protected/_main/agreements/route.lazy').then(
      (d) => d.Route,
    ),
  )

const PaymentInvoiceIndexRouteRoute = PaymentInvoiceIndexRouteImport.update({
  id: '/invoice/',
  path: '/invoice/',
  getParentRoute: () => PaymentRouteLazyRoute,
} as any).lazy(() =>
  import('./routes/payment/invoice/index/route.lazy').then((d) => d.Route),
)

const PaymentCreditLineIndexRouteRoute =
  PaymentCreditLineIndexRouteImport.update({
    id: '/credit-line/',
    path: '/credit-line/',
    getParentRoute: () => PaymentRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/payment/credit-line/index/route.lazy').then(
      (d) => d.Route,
    ),
  )

const PaymentAgreementIndexRouteRoute = PaymentAgreementIndexRouteImport.update(
  {
    id: '/agreement/',
    path: '/agreement/',
    getParentRoute: () => PaymentRouteLazyRoute,
  } as any,
).lazy(() =>
  import('./routes/payment/agreement/index/route.lazy').then((d) => d.Route),
)

const ProtectedGracePeriodIndexRouteRoute =
  ProtectedGracePeriodIndexRouteImport.update({
    id: '/grace-period/',
    path: '/grace-period/',
    getParentRoute: () => ProtectedRouteRoute,
  } as any).lazy(() =>
    import('./routes/_protected/grace-period/index/route.lazy').then(
      (d) => d.Route,
    ),
  )

const ProtectedMainProfileEditRouteLazyRoute =
  ProtectedMainProfileEditRouteLazyImport.update({
    id: '/edit',
    path: '/edit',
    getParentRoute: () => ProtectedMainProfileRouteRoute,
  } as any).lazy(() =>
    import('./routes/_protected/_main/profile/edit/route.lazy').then(
      (d) => d.Route,
    ),
  )

const ProtectedMainProfileCompleteRouteLazyRoute =
  ProtectedMainProfileCompleteRouteLazyImport.update({
    id: '/complete',
    path: '/complete',
    getParentRoute: () => ProtectedMainProfileRouteRoute,
  } as any).lazy(() =>
    import('./routes/_protected/_main/profile/complete/route.lazy').then(
      (d) => d.Route,
    ),
  )

const ProtectedMainPremiumUnsubscribeRouteLazyRoute =
  ProtectedMainPremiumUnsubscribeRouteLazyImport.update({
    id: '/unsubscribe',
    path: '/unsubscribe',
    getParentRoute: () => ProtectedMainPremiumRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/_protected/_main/premium/unsubscribe/route.lazy').then(
      (d) => d.Route,
    ),
  )

const ProtectedMainPremiumSubscribeRouteLazyRoute =
  ProtectedMainPremiumSubscribeRouteLazyImport.update({
    id: '/subscribe',
    path: '/subscribe',
    getParentRoute: () => ProtectedMainPremiumRouteLazyRoute,
  } as any).lazy(() =>
    import('./routes/_protected/_main/premium/subscribe/route.lazy').then(
      (d) => d.Route,
    ),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/_protected': {
      id: '/_protected'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof ProtectedRouteImport
      parentRoute: typeof rootRoute
    }
    '/auth': {
      id: '/auth'
      path: '/auth'
      fullPath: '/auth'
      preLoaderRoute: typeof AuthRouteImport
      parentRoute: typeof rootRoute
    }
    '/payment': {
      id: '/payment'
      path: '/payment'
      fullPath: '/payment'
      preLoaderRoute: typeof PaymentRouteLazyImport
      parentRoute: typeof rootRoute
    }
    '/_protected/_main': {
      id: '/_protected/_main'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof ProtectedMainRouteImport
      parentRoute: typeof ProtectedRouteImport
    }
    '/_protected/auth-additional-info': {
      id: '/_protected/auth-additional-info'
      path: '/auth-additional-info'
      fullPath: '/auth-additional-info'
      preLoaderRoute: typeof ProtectedAuthAdditionalInfoRouteImport
      parentRoute: typeof ProtectedRouteImport
    }
    '/_protected/credit-account-conversion': {
      id: '/_protected/credit-account-conversion'
      path: '/credit-account-conversion'
      fullPath: '/credit-account-conversion'
      preLoaderRoute: typeof ProtectedCreditAccountConversionRouteImport
      parentRoute: typeof ProtectedRouteImport
    }
    '/_protected/credit-account-limit-increase': {
      id: '/_protected/credit-account-limit-increase'
      path: '/credit-account-limit-increase'
      fullPath: '/credit-account-limit-increase'
      preLoaderRoute: typeof ProtectedCreditAccountLimitIncreaseRouteImport
      parentRoute: typeof ProtectedRouteImport
    }
    '/_protected/credit-account-modification': {
      id: '/_protected/credit-account-modification'
      path: '/credit-account-modification'
      fullPath: '/credit-account-modification'
      preLoaderRoute: typeof ProtectedCreditAccountModificationRouteImport
      parentRoute: typeof ProtectedRouteImport
    }
    '/_protected/credit-account-withdrawal': {
      id: '/_protected/credit-account-withdrawal'
      path: '/credit-account-withdrawal'
      fullPath: '/credit-account-withdrawal'
      preLoaderRoute: typeof ProtectedCreditAccountWithdrawalRouteImport
      parentRoute: typeof ProtectedRouteImport
    }
    '/_protected/standing-payment': {
      id: '/_protected/standing-payment'
      path: '/standing-payment'
      fullPath: '/standing-payment'
      preLoaderRoute: typeof ProtectedStandingPaymentRouteImport
      parentRoute: typeof ProtectedRouteImport
    }
    '/_protected/grace-period/': {
      id: '/_protected/grace-period/'
      path: '/grace-period'
      fullPath: '/grace-period'
      preLoaderRoute: typeof ProtectedGracePeriodIndexRouteImport
      parentRoute: typeof ProtectedRouteImport
    }
    '/payment/agreement/': {
      id: '/payment/agreement/'
      path: '/agreement'
      fullPath: '/payment/agreement'
      preLoaderRoute: typeof PaymentAgreementIndexRouteImport
      parentRoute: typeof PaymentRouteLazyImport
    }
    '/payment/credit-line/': {
      id: '/payment/credit-line/'
      path: '/credit-line'
      fullPath: '/payment/credit-line'
      preLoaderRoute: typeof PaymentCreditLineIndexRouteImport
      parentRoute: typeof PaymentRouteLazyImport
    }
    '/payment/invoice/': {
      id: '/payment/invoice/'
      path: '/invoice'
      fullPath: '/payment/invoice'
      preLoaderRoute: typeof PaymentInvoiceIndexRouteImport
      parentRoute: typeof PaymentRouteLazyImport
    }
    '/_protected/_main/agreements': {
      id: '/_protected/_main/agreements'
      path: '/agreements'
      fullPath: '/agreements'
      preLoaderRoute: typeof ProtectedMainAgreementsRouteImport
      parentRoute: typeof ProtectedMainRouteImport
    }
    '/_protected/_main/credit-line': {
      id: '/_protected/_main/credit-line'
      path: '/credit-line'
      fullPath: '/credit-line'
      preLoaderRoute: typeof ProtectedMainCreditLineRouteImport
      parentRoute: typeof ProtectedMainRouteImport
    }
    '/_protected/_main/dashboard': {
      id: '/_protected/_main/dashboard'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof ProtectedMainDashboardRouteImport
      parentRoute: typeof ProtectedMainRouteImport
    }
    '/_protected/_main/invoices': {
      id: '/_protected/_main/invoices'
      path: '/invoices'
      fullPath: '/invoices'
      preLoaderRoute: typeof ProtectedMainInvoicesRouteImport
      parentRoute: typeof ProtectedMainRouteImport
    }
    '/_protected/_main/profile': {
      id: '/_protected/_main/profile'
      path: '/profile'
      fullPath: '/profile'
      preLoaderRoute: typeof ProtectedMainProfileRouteImport
      parentRoute: typeof ProtectedMainRouteImport
    }
    '/_protected/credit-account-conversion/apply': {
      id: '/_protected/credit-account-conversion/apply'
      path: '/apply'
      fullPath: '/credit-account-conversion/apply'
      preLoaderRoute: typeof ProtectedCreditAccountConversionApplyRouteImport
      parentRoute: typeof ProtectedCreditAccountConversionRouteImport
    }
    '/_protected/credit-account-conversion/reject': {
      id: '/_protected/credit-account-conversion/reject'
      path: '/reject'
      fullPath: '/credit-account-conversion/reject'
      preLoaderRoute: typeof ProtectedCreditAccountConversionRejectRouteImport
      parentRoute: typeof ProtectedCreditAccountConversionRouteImport
    }
    '/_protected/credit-account-conversion/signing': {
      id: '/_protected/credit-account-conversion/signing'
      path: '/signing'
      fullPath: '/credit-account-conversion/signing'
      preLoaderRoute: typeof ProtectedCreditAccountConversionSigningRouteImport
      parentRoute: typeof ProtectedCreditAccountConversionRouteImport
    }
    '/payment/agreement/pending': {
      id: '/payment/agreement/pending'
      path: '/agreement/pending'
      fullPath: '/payment/agreement/pending'
      preLoaderRoute: typeof PaymentAgreementPendingRouteImport
      parentRoute: typeof PaymentRouteLazyImport
    }
    '/payment/credit-line/pending': {
      id: '/payment/credit-line/pending'
      path: '/credit-line/pending'
      fullPath: '/payment/credit-line/pending'
      preLoaderRoute: typeof PaymentCreditLinePendingRouteImport
      parentRoute: typeof PaymentRouteLazyImport
    }
    '/payment/invoice/pending': {
      id: '/payment/invoice/pending'
      path: '/invoice/pending'
      fullPath: '/payment/invoice/pending'
      preLoaderRoute: typeof PaymentInvoicePendingRouteImport
      parentRoute: typeof PaymentRouteLazyImport
    }
    '/_protected/_main/premium': {
      id: '/_protected/_main/premium'
      path: '/premium'
      fullPath: '/premium'
      preLoaderRoute: typeof ProtectedMainPremiumRouteLazyImport
      parentRoute: typeof ProtectedMainRouteImport
    }
    '/_protected/credit-account-limit-increase/failure': {
      id: '/_protected/credit-account-limit-increase/failure'
      path: '/failure'
      fullPath: '/credit-account-limit-increase/failure'
      preLoaderRoute: typeof ProtectedCreditAccountLimitIncreaseFailureRouteLazyImport
      parentRoute: typeof ProtectedCreditAccountLimitIncreaseRouteImport
    }
    '/_protected/credit-account-limit-increase/signing': {
      id: '/_protected/credit-account-limit-increase/signing'
      path: '/signing'
      fullPath: '/credit-account-limit-increase/signing'
      preLoaderRoute: typeof ProtectedCreditAccountLimitIncreaseSigningRouteLazyImport
      parentRoute: typeof ProtectedCreditAccountLimitIncreaseRouteImport
    }
    '/_protected/credit-account-modification/failure': {
      id: '/_protected/credit-account-modification/failure'
      path: '/failure'
      fullPath: '/credit-account-modification/failure'
      preLoaderRoute: typeof ProtectedCreditAccountModificationFailureRouteLazyImport
      parentRoute: typeof ProtectedCreditAccountModificationRouteImport
    }
    '/_protected/credit-account-modification/signing': {
      id: '/_protected/credit-account-modification/signing'
      path: '/signing'
      fullPath: '/credit-account-modification/signing'
      preLoaderRoute: typeof ProtectedCreditAccountModificationSigningRouteLazyImport
      parentRoute: typeof ProtectedCreditAccountModificationRouteImport
    }
    '/_protected/credit-account-withdrawal/form': {
      id: '/_protected/credit-account-withdrawal/form'
      path: '/form'
      fullPath: '/credit-account-withdrawal/form'
      preLoaderRoute: typeof ProtectedCreditAccountWithdrawalFormRouteLazyImport
      parentRoute: typeof ProtectedCreditAccountWithdrawalRouteImport
    }
    '/_protected/credit-account-withdrawal/reject': {
      id: '/_protected/credit-account-withdrawal/reject'
      path: '/reject'
      fullPath: '/credit-account-withdrawal/reject'
      preLoaderRoute: typeof ProtectedCreditAccountWithdrawalRejectRouteLazyImport
      parentRoute: typeof ProtectedCreditAccountWithdrawalRouteImport
    }
    '/_protected/credit-account-withdrawal/success': {
      id: '/_protected/credit-account-withdrawal/success'
      path: '/success'
      fullPath: '/credit-account-withdrawal/success'
      preLoaderRoute: typeof ProtectedCreditAccountWithdrawalSuccessRouteLazyImport
      parentRoute: typeof ProtectedCreditAccountWithdrawalRouteImport
    }
    '/_protected/grace-period/reject': {
      id: '/_protected/grace-period/reject'
      path: '/grace-period/reject'
      fullPath: '/grace-period/reject'
      preLoaderRoute: typeof ProtectedGracePeriodRejectRouteLazyImport
      parentRoute: typeof ProtectedRouteImport
    }
    '/_protected/grace-period/success': {
      id: '/_protected/grace-period/success'
      path: '/grace-period/success'
      fullPath: '/grace-period/success'
      preLoaderRoute: typeof ProtectedGracePeriodSuccessRouteLazyImport
      parentRoute: typeof ProtectedRouteImport
    }
    '/_protected/standing-payment/disable': {
      id: '/_protected/standing-payment/disable'
      path: '/disable'
      fullPath: '/standing-payment/disable'
      preLoaderRoute: typeof ProtectedStandingPaymentDisableRouteLazyImport
      parentRoute: typeof ProtectedStandingPaymentRouteImport
    }
    '/_protected/standing-payment/enable': {
      id: '/_protected/standing-payment/enable'
      path: '/enable'
      fullPath: '/standing-payment/enable'
      preLoaderRoute: typeof ProtectedStandingPaymentEnableRouteLazyImport
      parentRoute: typeof ProtectedStandingPaymentRouteImport
    }
    '/_protected/standing-payment/reject': {
      id: '/_protected/standing-payment/reject'
      path: '/reject'
      fullPath: '/standing-payment/reject'
      preLoaderRoute: typeof ProtectedStandingPaymentRejectRouteLazyImport
      parentRoute: typeof ProtectedStandingPaymentRouteImport
    }
    '/_protected/standing-payment/success': {
      id: '/_protected/standing-payment/success'
      path: '/success'
      fullPath: '/standing-payment/success'
      preLoaderRoute: typeof ProtectedStandingPaymentSuccessRouteLazyImport
      parentRoute: typeof ProtectedStandingPaymentRouteImport
    }
    '/payment/agreement/reject': {
      id: '/payment/agreement/reject'
      path: '/agreement/reject'
      fullPath: '/payment/agreement/reject'
      preLoaderRoute: typeof PaymentAgreementRejectRouteLazyImport
      parentRoute: typeof PaymentRouteLazyImport
    }
    '/payment/agreement/success': {
      id: '/payment/agreement/success'
      path: '/agreement/success'
      fullPath: '/payment/agreement/success'
      preLoaderRoute: typeof PaymentAgreementSuccessRouteLazyImport
      parentRoute: typeof PaymentRouteLazyImport
    }
    '/payment/credit-line/reject': {
      id: '/payment/credit-line/reject'
      path: '/credit-line/reject'
      fullPath: '/payment/credit-line/reject'
      preLoaderRoute: typeof PaymentCreditLineRejectRouteLazyImport
      parentRoute: typeof PaymentRouteLazyImport
    }
    '/payment/credit-line/success': {
      id: '/payment/credit-line/success'
      path: '/credit-line/success'
      fullPath: '/payment/credit-line/success'
      preLoaderRoute: typeof PaymentCreditLineSuccessRouteLazyImport
      parentRoute: typeof PaymentRouteLazyImport
    }
    '/payment/invoice/reject': {
      id: '/payment/invoice/reject'
      path: '/invoice/reject'
      fullPath: '/payment/invoice/reject'
      preLoaderRoute: typeof PaymentInvoiceRejectRouteLazyImport
      parentRoute: typeof PaymentRouteLazyImport
    }
    '/payment/invoice/success': {
      id: '/payment/invoice/success'
      path: '/invoice/success'
      fullPath: '/payment/invoice/success'
      preLoaderRoute: typeof PaymentInvoiceSuccessRouteLazyImport
      parentRoute: typeof PaymentRouteLazyImport
    }
    '/_protected/_main/premium/subscribe': {
      id: '/_protected/_main/premium/subscribe'
      path: '/subscribe'
      fullPath: '/premium/subscribe'
      preLoaderRoute: typeof ProtectedMainPremiumSubscribeRouteLazyImport
      parentRoute: typeof ProtectedMainPremiumRouteLazyImport
    }
    '/_protected/_main/premium/unsubscribe': {
      id: '/_protected/_main/premium/unsubscribe'
      path: '/unsubscribe'
      fullPath: '/premium/unsubscribe'
      preLoaderRoute: typeof ProtectedMainPremiumUnsubscribeRouteLazyImport
      parentRoute: typeof ProtectedMainPremiumRouteLazyImport
    }
    '/_protected/_main/profile/complete': {
      id: '/_protected/_main/profile/complete'
      path: '/complete'
      fullPath: '/profile/complete'
      preLoaderRoute: typeof ProtectedMainProfileCompleteRouteLazyImport
      parentRoute: typeof ProtectedMainProfileRouteImport
    }
    '/_protected/_main/profile/edit': {
      id: '/_protected/_main/profile/edit'
      path: '/edit'
      fullPath: '/profile/edit'
      preLoaderRoute: typeof ProtectedMainProfileEditRouteLazyImport
      parentRoute: typeof ProtectedMainProfileRouteImport
    }
  }
}

// Create and export the route tree

interface ProtectedMainProfileRouteRouteChildren {
  ProtectedMainProfileCompleteRouteLazyRoute: typeof ProtectedMainProfileCompleteRouteLazyRoute
  ProtectedMainProfileEditRouteLazyRoute: typeof ProtectedMainProfileEditRouteLazyRoute
}

const ProtectedMainProfileRouteRouteChildren: ProtectedMainProfileRouteRouteChildren =
  {
    ProtectedMainProfileCompleteRouteLazyRoute:
      ProtectedMainProfileCompleteRouteLazyRoute,
    ProtectedMainProfileEditRouteLazyRoute:
      ProtectedMainProfileEditRouteLazyRoute,
  }

const ProtectedMainProfileRouteRouteWithChildren =
  ProtectedMainProfileRouteRoute._addFileChildren(
    ProtectedMainProfileRouteRouteChildren,
  )

interface ProtectedMainPremiumRouteLazyRouteChildren {
  ProtectedMainPremiumSubscribeRouteLazyRoute: typeof ProtectedMainPremiumSubscribeRouteLazyRoute
  ProtectedMainPremiumUnsubscribeRouteLazyRoute: typeof ProtectedMainPremiumUnsubscribeRouteLazyRoute
}

const ProtectedMainPremiumRouteLazyRouteChildren: ProtectedMainPremiumRouteLazyRouteChildren =
  {
    ProtectedMainPremiumSubscribeRouteLazyRoute:
      ProtectedMainPremiumSubscribeRouteLazyRoute,
    ProtectedMainPremiumUnsubscribeRouteLazyRoute:
      ProtectedMainPremiumUnsubscribeRouteLazyRoute,
  }

const ProtectedMainPremiumRouteLazyRouteWithChildren =
  ProtectedMainPremiumRouteLazyRoute._addFileChildren(
    ProtectedMainPremiumRouteLazyRouteChildren,
  )

interface ProtectedMainRouteRouteChildren {
  ProtectedMainAgreementsRouteRoute: typeof ProtectedMainAgreementsRouteRoute
  ProtectedMainCreditLineRouteRoute: typeof ProtectedMainCreditLineRouteRoute
  ProtectedMainDashboardRouteRoute: typeof ProtectedMainDashboardRouteRoute
  ProtectedMainInvoicesRouteRoute: typeof ProtectedMainInvoicesRouteRoute
  ProtectedMainProfileRouteRoute: typeof ProtectedMainProfileRouteRouteWithChildren
  ProtectedMainPremiumRouteLazyRoute: typeof ProtectedMainPremiumRouteLazyRouteWithChildren
}

const ProtectedMainRouteRouteChildren: ProtectedMainRouteRouteChildren = {
  ProtectedMainAgreementsRouteRoute: ProtectedMainAgreementsRouteRoute,
  ProtectedMainCreditLineRouteRoute: ProtectedMainCreditLineRouteRoute,
  ProtectedMainDashboardRouteRoute: ProtectedMainDashboardRouteRoute,
  ProtectedMainInvoicesRouteRoute: ProtectedMainInvoicesRouteRoute,
  ProtectedMainProfileRouteRoute: ProtectedMainProfileRouteRouteWithChildren,
  ProtectedMainPremiumRouteLazyRoute:
    ProtectedMainPremiumRouteLazyRouteWithChildren,
}

const ProtectedMainRouteRouteWithChildren =
  ProtectedMainRouteRoute._addFileChildren(ProtectedMainRouteRouteChildren)

interface ProtectedCreditAccountConversionRouteRouteChildren {
  ProtectedCreditAccountConversionApplyRouteRoute: typeof ProtectedCreditAccountConversionApplyRouteRoute
  ProtectedCreditAccountConversionRejectRouteRoute: typeof ProtectedCreditAccountConversionRejectRouteRoute
  ProtectedCreditAccountConversionSigningRouteRoute: typeof ProtectedCreditAccountConversionSigningRouteRoute
}

const ProtectedCreditAccountConversionRouteRouteChildren: ProtectedCreditAccountConversionRouteRouteChildren =
  {
    ProtectedCreditAccountConversionApplyRouteRoute:
      ProtectedCreditAccountConversionApplyRouteRoute,
    ProtectedCreditAccountConversionRejectRouteRoute:
      ProtectedCreditAccountConversionRejectRouteRoute,
    ProtectedCreditAccountConversionSigningRouteRoute:
      ProtectedCreditAccountConversionSigningRouteRoute,
  }

const ProtectedCreditAccountConversionRouteRouteWithChildren =
  ProtectedCreditAccountConversionRouteRoute._addFileChildren(
    ProtectedCreditAccountConversionRouteRouteChildren,
  )

interface ProtectedCreditAccountLimitIncreaseRouteRouteChildren {
  ProtectedCreditAccountLimitIncreaseFailureRouteLazyRoute: typeof ProtectedCreditAccountLimitIncreaseFailureRouteLazyRoute
  ProtectedCreditAccountLimitIncreaseSigningRouteLazyRoute: typeof ProtectedCreditAccountLimitIncreaseSigningRouteLazyRoute
}

const ProtectedCreditAccountLimitIncreaseRouteRouteChildren: ProtectedCreditAccountLimitIncreaseRouteRouteChildren =
  {
    ProtectedCreditAccountLimitIncreaseFailureRouteLazyRoute:
      ProtectedCreditAccountLimitIncreaseFailureRouteLazyRoute,
    ProtectedCreditAccountLimitIncreaseSigningRouteLazyRoute:
      ProtectedCreditAccountLimitIncreaseSigningRouteLazyRoute,
  }

const ProtectedCreditAccountLimitIncreaseRouteRouteWithChildren =
  ProtectedCreditAccountLimitIncreaseRouteRoute._addFileChildren(
    ProtectedCreditAccountLimitIncreaseRouteRouteChildren,
  )

interface ProtectedCreditAccountModificationRouteRouteChildren {
  ProtectedCreditAccountModificationFailureRouteLazyRoute: typeof ProtectedCreditAccountModificationFailureRouteLazyRoute
  ProtectedCreditAccountModificationSigningRouteLazyRoute: typeof ProtectedCreditAccountModificationSigningRouteLazyRoute
}

const ProtectedCreditAccountModificationRouteRouteChildren: ProtectedCreditAccountModificationRouteRouteChildren =
  {
    ProtectedCreditAccountModificationFailureRouteLazyRoute:
      ProtectedCreditAccountModificationFailureRouteLazyRoute,
    ProtectedCreditAccountModificationSigningRouteLazyRoute:
      ProtectedCreditAccountModificationSigningRouteLazyRoute,
  }

const ProtectedCreditAccountModificationRouteRouteWithChildren =
  ProtectedCreditAccountModificationRouteRoute._addFileChildren(
    ProtectedCreditAccountModificationRouteRouteChildren,
  )

interface ProtectedCreditAccountWithdrawalRouteRouteChildren {
  ProtectedCreditAccountWithdrawalFormRouteLazyRoute: typeof ProtectedCreditAccountWithdrawalFormRouteLazyRoute
  ProtectedCreditAccountWithdrawalRejectRouteLazyRoute: typeof ProtectedCreditAccountWithdrawalRejectRouteLazyRoute
  ProtectedCreditAccountWithdrawalSuccessRouteLazyRoute: typeof ProtectedCreditAccountWithdrawalSuccessRouteLazyRoute
}

const ProtectedCreditAccountWithdrawalRouteRouteChildren: ProtectedCreditAccountWithdrawalRouteRouteChildren =
  {
    ProtectedCreditAccountWithdrawalFormRouteLazyRoute:
      ProtectedCreditAccountWithdrawalFormRouteLazyRoute,
    ProtectedCreditAccountWithdrawalRejectRouteLazyRoute:
      ProtectedCreditAccountWithdrawalRejectRouteLazyRoute,
    ProtectedCreditAccountWithdrawalSuccessRouteLazyRoute:
      ProtectedCreditAccountWithdrawalSuccessRouteLazyRoute,
  }

const ProtectedCreditAccountWithdrawalRouteRouteWithChildren =
  ProtectedCreditAccountWithdrawalRouteRoute._addFileChildren(
    ProtectedCreditAccountWithdrawalRouteRouteChildren,
  )

interface ProtectedStandingPaymentRouteRouteChildren {
  ProtectedStandingPaymentDisableRouteLazyRoute: typeof ProtectedStandingPaymentDisableRouteLazyRoute
  ProtectedStandingPaymentEnableRouteLazyRoute: typeof ProtectedStandingPaymentEnableRouteLazyRoute
  ProtectedStandingPaymentRejectRouteLazyRoute: typeof ProtectedStandingPaymentRejectRouteLazyRoute
  ProtectedStandingPaymentSuccessRouteLazyRoute: typeof ProtectedStandingPaymentSuccessRouteLazyRoute
}

const ProtectedStandingPaymentRouteRouteChildren: ProtectedStandingPaymentRouteRouteChildren =
  {
    ProtectedStandingPaymentDisableRouteLazyRoute:
      ProtectedStandingPaymentDisableRouteLazyRoute,
    ProtectedStandingPaymentEnableRouteLazyRoute:
      ProtectedStandingPaymentEnableRouteLazyRoute,
    ProtectedStandingPaymentRejectRouteLazyRoute:
      ProtectedStandingPaymentRejectRouteLazyRoute,
    ProtectedStandingPaymentSuccessRouteLazyRoute:
      ProtectedStandingPaymentSuccessRouteLazyRoute,
  }

const ProtectedStandingPaymentRouteRouteWithChildren =
  ProtectedStandingPaymentRouteRoute._addFileChildren(
    ProtectedStandingPaymentRouteRouteChildren,
  )

interface ProtectedRouteRouteChildren {
  ProtectedMainRouteRoute: typeof ProtectedMainRouteRouteWithChildren
  ProtectedAuthAdditionalInfoRouteRoute: typeof ProtectedAuthAdditionalInfoRouteRoute
  ProtectedCreditAccountConversionRouteRoute: typeof ProtectedCreditAccountConversionRouteRouteWithChildren
  ProtectedCreditAccountLimitIncreaseRouteRoute: typeof ProtectedCreditAccountLimitIncreaseRouteRouteWithChildren
  ProtectedCreditAccountModificationRouteRoute: typeof ProtectedCreditAccountModificationRouteRouteWithChildren
  ProtectedCreditAccountWithdrawalRouteRoute: typeof ProtectedCreditAccountWithdrawalRouteRouteWithChildren
  ProtectedStandingPaymentRouteRoute: typeof ProtectedStandingPaymentRouteRouteWithChildren
  ProtectedGracePeriodIndexRouteRoute: typeof ProtectedGracePeriodIndexRouteRoute
  ProtectedGracePeriodRejectRouteLazyRoute: typeof ProtectedGracePeriodRejectRouteLazyRoute
  ProtectedGracePeriodSuccessRouteLazyRoute: typeof ProtectedGracePeriodSuccessRouteLazyRoute
}

const ProtectedRouteRouteChildren: ProtectedRouteRouteChildren = {
  ProtectedMainRouteRoute: ProtectedMainRouteRouteWithChildren,
  ProtectedAuthAdditionalInfoRouteRoute: ProtectedAuthAdditionalInfoRouteRoute,
  ProtectedCreditAccountConversionRouteRoute:
    ProtectedCreditAccountConversionRouteRouteWithChildren,
  ProtectedCreditAccountLimitIncreaseRouteRoute:
    ProtectedCreditAccountLimitIncreaseRouteRouteWithChildren,
  ProtectedCreditAccountModificationRouteRoute:
    ProtectedCreditAccountModificationRouteRouteWithChildren,
  ProtectedCreditAccountWithdrawalRouteRoute:
    ProtectedCreditAccountWithdrawalRouteRouteWithChildren,
  ProtectedStandingPaymentRouteRoute:
    ProtectedStandingPaymentRouteRouteWithChildren,
  ProtectedGracePeriodIndexRouteRoute: ProtectedGracePeriodIndexRouteRoute,
  ProtectedGracePeriodRejectRouteLazyRoute:
    ProtectedGracePeriodRejectRouteLazyRoute,
  ProtectedGracePeriodSuccessRouteLazyRoute:
    ProtectedGracePeriodSuccessRouteLazyRoute,
}

const ProtectedRouteRouteWithChildren = ProtectedRouteRoute._addFileChildren(
  ProtectedRouteRouteChildren,
)

interface PaymentRouteLazyRouteChildren {
  PaymentAgreementIndexRouteRoute: typeof PaymentAgreementIndexRouteRoute
  PaymentCreditLineIndexRouteRoute: typeof PaymentCreditLineIndexRouteRoute
  PaymentInvoiceIndexRouteRoute: typeof PaymentInvoiceIndexRouteRoute
  PaymentAgreementPendingRouteRoute: typeof PaymentAgreementPendingRouteRoute
  PaymentCreditLinePendingRouteRoute: typeof PaymentCreditLinePendingRouteRoute
  PaymentInvoicePendingRouteRoute: typeof PaymentInvoicePendingRouteRoute
  PaymentAgreementRejectRouteLazyRoute: typeof PaymentAgreementRejectRouteLazyRoute
  PaymentAgreementSuccessRouteLazyRoute: typeof PaymentAgreementSuccessRouteLazyRoute
  PaymentCreditLineRejectRouteLazyRoute: typeof PaymentCreditLineRejectRouteLazyRoute
  PaymentCreditLineSuccessRouteLazyRoute: typeof PaymentCreditLineSuccessRouteLazyRoute
  PaymentInvoiceRejectRouteLazyRoute: typeof PaymentInvoiceRejectRouteLazyRoute
  PaymentInvoiceSuccessRouteLazyRoute: typeof PaymentInvoiceSuccessRouteLazyRoute
}

const PaymentRouteLazyRouteChildren: PaymentRouteLazyRouteChildren = {
  PaymentAgreementIndexRouteRoute: PaymentAgreementIndexRouteRoute,
  PaymentCreditLineIndexRouteRoute: PaymentCreditLineIndexRouteRoute,
  PaymentInvoiceIndexRouteRoute: PaymentInvoiceIndexRouteRoute,
  PaymentAgreementPendingRouteRoute: PaymentAgreementPendingRouteRoute,
  PaymentCreditLinePendingRouteRoute: PaymentCreditLinePendingRouteRoute,
  PaymentInvoicePendingRouteRoute: PaymentInvoicePendingRouteRoute,
  PaymentAgreementRejectRouteLazyRoute: PaymentAgreementRejectRouteLazyRoute,
  PaymentAgreementSuccessRouteLazyRoute: PaymentAgreementSuccessRouteLazyRoute,
  PaymentCreditLineRejectRouteLazyRoute: PaymentCreditLineRejectRouteLazyRoute,
  PaymentCreditLineSuccessRouteLazyRoute:
    PaymentCreditLineSuccessRouteLazyRoute,
  PaymentInvoiceRejectRouteLazyRoute: PaymentInvoiceRejectRouteLazyRoute,
  PaymentInvoiceSuccessRouteLazyRoute: PaymentInvoiceSuccessRouteLazyRoute,
}

const PaymentRouteLazyRouteWithChildren =
  PaymentRouteLazyRoute._addFileChildren(PaymentRouteLazyRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexLazyRoute
  '': typeof ProtectedMainRouteRouteWithChildren
  '/auth': typeof AuthRouteRoute
  '/payment': typeof PaymentRouteLazyRouteWithChildren
  '/auth-additional-info': typeof ProtectedAuthAdditionalInfoRouteRoute
  '/credit-account-conversion': typeof ProtectedCreditAccountConversionRouteRouteWithChildren
  '/credit-account-limit-increase': typeof ProtectedCreditAccountLimitIncreaseRouteRouteWithChildren
  '/credit-account-modification': typeof ProtectedCreditAccountModificationRouteRouteWithChildren
  '/credit-account-withdrawal': typeof ProtectedCreditAccountWithdrawalRouteRouteWithChildren
  '/standing-payment': typeof ProtectedStandingPaymentRouteRouteWithChildren
  '/grace-period': typeof ProtectedGracePeriodIndexRouteRoute
  '/payment/agreement': typeof PaymentAgreementIndexRouteRoute
  '/payment/credit-line': typeof PaymentCreditLineIndexRouteRoute
  '/payment/invoice': typeof PaymentInvoiceIndexRouteRoute
  '/agreements': typeof ProtectedMainAgreementsRouteRoute
  '/credit-line': typeof ProtectedMainCreditLineRouteRoute
  '/dashboard': typeof ProtectedMainDashboardRouteRoute
  '/invoices': typeof ProtectedMainInvoicesRouteRoute
  '/profile': typeof ProtectedMainProfileRouteRouteWithChildren
  '/credit-account-conversion/apply': typeof ProtectedCreditAccountConversionApplyRouteRoute
  '/credit-account-conversion/reject': typeof ProtectedCreditAccountConversionRejectRouteRoute
  '/credit-account-conversion/signing': typeof ProtectedCreditAccountConversionSigningRouteRoute
  '/payment/agreement/pending': typeof PaymentAgreementPendingRouteRoute
  '/payment/credit-line/pending': typeof PaymentCreditLinePendingRouteRoute
  '/payment/invoice/pending': typeof PaymentInvoicePendingRouteRoute
  '/premium': typeof ProtectedMainPremiumRouteLazyRouteWithChildren
  '/credit-account-limit-increase/failure': typeof ProtectedCreditAccountLimitIncreaseFailureRouteLazyRoute
  '/credit-account-limit-increase/signing': typeof ProtectedCreditAccountLimitIncreaseSigningRouteLazyRoute
  '/credit-account-modification/failure': typeof ProtectedCreditAccountModificationFailureRouteLazyRoute
  '/credit-account-modification/signing': typeof ProtectedCreditAccountModificationSigningRouteLazyRoute
  '/credit-account-withdrawal/form': typeof ProtectedCreditAccountWithdrawalFormRouteLazyRoute
  '/credit-account-withdrawal/reject': typeof ProtectedCreditAccountWithdrawalRejectRouteLazyRoute
  '/credit-account-withdrawal/success': typeof ProtectedCreditAccountWithdrawalSuccessRouteLazyRoute
  '/grace-period/reject': typeof ProtectedGracePeriodRejectRouteLazyRoute
  '/grace-period/success': typeof ProtectedGracePeriodSuccessRouteLazyRoute
  '/standing-payment/disable': typeof ProtectedStandingPaymentDisableRouteLazyRoute
  '/standing-payment/enable': typeof ProtectedStandingPaymentEnableRouteLazyRoute
  '/standing-payment/reject': typeof ProtectedStandingPaymentRejectRouteLazyRoute
  '/standing-payment/success': typeof ProtectedStandingPaymentSuccessRouteLazyRoute
  '/payment/agreement/reject': typeof PaymentAgreementRejectRouteLazyRoute
  '/payment/agreement/success': typeof PaymentAgreementSuccessRouteLazyRoute
  '/payment/credit-line/reject': typeof PaymentCreditLineRejectRouteLazyRoute
  '/payment/credit-line/success': typeof PaymentCreditLineSuccessRouteLazyRoute
  '/payment/invoice/reject': typeof PaymentInvoiceRejectRouteLazyRoute
  '/payment/invoice/success': typeof PaymentInvoiceSuccessRouteLazyRoute
  '/premium/subscribe': typeof ProtectedMainPremiumSubscribeRouteLazyRoute
  '/premium/unsubscribe': typeof ProtectedMainPremiumUnsubscribeRouteLazyRoute
  '/profile/complete': typeof ProtectedMainProfileCompleteRouteLazyRoute
  '/profile/edit': typeof ProtectedMainProfileEditRouteLazyRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexLazyRoute
  '': typeof ProtectedMainRouteRouteWithChildren
  '/auth': typeof AuthRouteRoute
  '/payment': typeof PaymentRouteLazyRouteWithChildren
  '/auth-additional-info': typeof ProtectedAuthAdditionalInfoRouteRoute
  '/credit-account-conversion': typeof ProtectedCreditAccountConversionRouteRouteWithChildren
  '/credit-account-limit-increase': typeof ProtectedCreditAccountLimitIncreaseRouteRouteWithChildren
  '/credit-account-modification': typeof ProtectedCreditAccountModificationRouteRouteWithChildren
  '/credit-account-withdrawal': typeof ProtectedCreditAccountWithdrawalRouteRouteWithChildren
  '/standing-payment': typeof ProtectedStandingPaymentRouteRouteWithChildren
  '/grace-period': typeof ProtectedGracePeriodIndexRouteRoute
  '/payment/agreement': typeof PaymentAgreementIndexRouteRoute
  '/payment/credit-line': typeof PaymentCreditLineIndexRouteRoute
  '/payment/invoice': typeof PaymentInvoiceIndexRouteRoute
  '/agreements': typeof ProtectedMainAgreementsRouteRoute
  '/credit-line': typeof ProtectedMainCreditLineRouteRoute
  '/dashboard': typeof ProtectedMainDashboardRouteRoute
  '/invoices': typeof ProtectedMainInvoicesRouteRoute
  '/profile': typeof ProtectedMainProfileRouteRouteWithChildren
  '/credit-account-conversion/apply': typeof ProtectedCreditAccountConversionApplyRouteRoute
  '/credit-account-conversion/reject': typeof ProtectedCreditAccountConversionRejectRouteRoute
  '/credit-account-conversion/signing': typeof ProtectedCreditAccountConversionSigningRouteRoute
  '/payment/agreement/pending': typeof PaymentAgreementPendingRouteRoute
  '/payment/credit-line/pending': typeof PaymentCreditLinePendingRouteRoute
  '/payment/invoice/pending': typeof PaymentInvoicePendingRouteRoute
  '/premium': typeof ProtectedMainPremiumRouteLazyRouteWithChildren
  '/credit-account-limit-increase/failure': typeof ProtectedCreditAccountLimitIncreaseFailureRouteLazyRoute
  '/credit-account-limit-increase/signing': typeof ProtectedCreditAccountLimitIncreaseSigningRouteLazyRoute
  '/credit-account-modification/failure': typeof ProtectedCreditAccountModificationFailureRouteLazyRoute
  '/credit-account-modification/signing': typeof ProtectedCreditAccountModificationSigningRouteLazyRoute
  '/credit-account-withdrawal/form': typeof ProtectedCreditAccountWithdrawalFormRouteLazyRoute
  '/credit-account-withdrawal/reject': typeof ProtectedCreditAccountWithdrawalRejectRouteLazyRoute
  '/credit-account-withdrawal/success': typeof ProtectedCreditAccountWithdrawalSuccessRouteLazyRoute
  '/grace-period/reject': typeof ProtectedGracePeriodRejectRouteLazyRoute
  '/grace-period/success': typeof ProtectedGracePeriodSuccessRouteLazyRoute
  '/standing-payment/disable': typeof ProtectedStandingPaymentDisableRouteLazyRoute
  '/standing-payment/enable': typeof ProtectedStandingPaymentEnableRouteLazyRoute
  '/standing-payment/reject': typeof ProtectedStandingPaymentRejectRouteLazyRoute
  '/standing-payment/success': typeof ProtectedStandingPaymentSuccessRouteLazyRoute
  '/payment/agreement/reject': typeof PaymentAgreementRejectRouteLazyRoute
  '/payment/agreement/success': typeof PaymentAgreementSuccessRouteLazyRoute
  '/payment/credit-line/reject': typeof PaymentCreditLineRejectRouteLazyRoute
  '/payment/credit-line/success': typeof PaymentCreditLineSuccessRouteLazyRoute
  '/payment/invoice/reject': typeof PaymentInvoiceRejectRouteLazyRoute
  '/payment/invoice/success': typeof PaymentInvoiceSuccessRouteLazyRoute
  '/premium/subscribe': typeof ProtectedMainPremiumSubscribeRouteLazyRoute
  '/premium/unsubscribe': typeof ProtectedMainPremiumUnsubscribeRouteLazyRoute
  '/profile/complete': typeof ProtectedMainProfileCompleteRouteLazyRoute
  '/profile/edit': typeof ProtectedMainProfileEditRouteLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexLazyRoute
  '/_protected': typeof ProtectedRouteRouteWithChildren
  '/auth': typeof AuthRouteRoute
  '/payment': typeof PaymentRouteLazyRouteWithChildren
  '/_protected/_main': typeof ProtectedMainRouteRouteWithChildren
  '/_protected/auth-additional-info': typeof ProtectedAuthAdditionalInfoRouteRoute
  '/_protected/credit-account-conversion': typeof ProtectedCreditAccountConversionRouteRouteWithChildren
  '/_protected/credit-account-limit-increase': typeof ProtectedCreditAccountLimitIncreaseRouteRouteWithChildren
  '/_protected/credit-account-modification': typeof ProtectedCreditAccountModificationRouteRouteWithChildren
  '/_protected/credit-account-withdrawal': typeof ProtectedCreditAccountWithdrawalRouteRouteWithChildren
  '/_protected/standing-payment': typeof ProtectedStandingPaymentRouteRouteWithChildren
  '/_protected/grace-period/': typeof ProtectedGracePeriodIndexRouteRoute
  '/payment/agreement/': typeof PaymentAgreementIndexRouteRoute
  '/payment/credit-line/': typeof PaymentCreditLineIndexRouteRoute
  '/payment/invoice/': typeof PaymentInvoiceIndexRouteRoute
  '/_protected/_main/agreements': typeof ProtectedMainAgreementsRouteRoute
  '/_protected/_main/credit-line': typeof ProtectedMainCreditLineRouteRoute
  '/_protected/_main/dashboard': typeof ProtectedMainDashboardRouteRoute
  '/_protected/_main/invoices': typeof ProtectedMainInvoicesRouteRoute
  '/_protected/_main/profile': typeof ProtectedMainProfileRouteRouteWithChildren
  '/_protected/credit-account-conversion/apply': typeof ProtectedCreditAccountConversionApplyRouteRoute
  '/_protected/credit-account-conversion/reject': typeof ProtectedCreditAccountConversionRejectRouteRoute
  '/_protected/credit-account-conversion/signing': typeof ProtectedCreditAccountConversionSigningRouteRoute
  '/payment/agreement/pending': typeof PaymentAgreementPendingRouteRoute
  '/payment/credit-line/pending': typeof PaymentCreditLinePendingRouteRoute
  '/payment/invoice/pending': typeof PaymentInvoicePendingRouteRoute
  '/_protected/_main/premium': typeof ProtectedMainPremiumRouteLazyRouteWithChildren
  '/_protected/credit-account-limit-increase/failure': typeof ProtectedCreditAccountLimitIncreaseFailureRouteLazyRoute
  '/_protected/credit-account-limit-increase/signing': typeof ProtectedCreditAccountLimitIncreaseSigningRouteLazyRoute
  '/_protected/credit-account-modification/failure': typeof ProtectedCreditAccountModificationFailureRouteLazyRoute
  '/_protected/credit-account-modification/signing': typeof ProtectedCreditAccountModificationSigningRouteLazyRoute
  '/_protected/credit-account-withdrawal/form': typeof ProtectedCreditAccountWithdrawalFormRouteLazyRoute
  '/_protected/credit-account-withdrawal/reject': typeof ProtectedCreditAccountWithdrawalRejectRouteLazyRoute
  '/_protected/credit-account-withdrawal/success': typeof ProtectedCreditAccountWithdrawalSuccessRouteLazyRoute
  '/_protected/grace-period/reject': typeof ProtectedGracePeriodRejectRouteLazyRoute
  '/_protected/grace-period/success': typeof ProtectedGracePeriodSuccessRouteLazyRoute
  '/_protected/standing-payment/disable': typeof ProtectedStandingPaymentDisableRouteLazyRoute
  '/_protected/standing-payment/enable': typeof ProtectedStandingPaymentEnableRouteLazyRoute
  '/_protected/standing-payment/reject': typeof ProtectedStandingPaymentRejectRouteLazyRoute
  '/_protected/standing-payment/success': typeof ProtectedStandingPaymentSuccessRouteLazyRoute
  '/payment/agreement/reject': typeof PaymentAgreementRejectRouteLazyRoute
  '/payment/agreement/success': typeof PaymentAgreementSuccessRouteLazyRoute
  '/payment/credit-line/reject': typeof PaymentCreditLineRejectRouteLazyRoute
  '/payment/credit-line/success': typeof PaymentCreditLineSuccessRouteLazyRoute
  '/payment/invoice/reject': typeof PaymentInvoiceRejectRouteLazyRoute
  '/payment/invoice/success': typeof PaymentInvoiceSuccessRouteLazyRoute
  '/_protected/_main/premium/subscribe': typeof ProtectedMainPremiumSubscribeRouteLazyRoute
  '/_protected/_main/premium/unsubscribe': typeof ProtectedMainPremiumUnsubscribeRouteLazyRoute
  '/_protected/_main/profile/complete': typeof ProtectedMainProfileCompleteRouteLazyRoute
  '/_protected/_main/profile/edit': typeof ProtectedMainProfileEditRouteLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/auth'
    | '/payment'
    | '/auth-additional-info'
    | '/credit-account-conversion'
    | '/credit-account-limit-increase'
    | '/credit-account-modification'
    | '/credit-account-withdrawal'
    | '/standing-payment'
    | '/grace-period'
    | '/payment/agreement'
    | '/payment/credit-line'
    | '/payment/invoice'
    | '/agreements'
    | '/credit-line'
    | '/dashboard'
    | '/invoices'
    | '/profile'
    | '/credit-account-conversion/apply'
    | '/credit-account-conversion/reject'
    | '/credit-account-conversion/signing'
    | '/payment/agreement/pending'
    | '/payment/credit-line/pending'
    | '/payment/invoice/pending'
    | '/premium'
    | '/credit-account-limit-increase/failure'
    | '/credit-account-limit-increase/signing'
    | '/credit-account-modification/failure'
    | '/credit-account-modification/signing'
    | '/credit-account-withdrawal/form'
    | '/credit-account-withdrawal/reject'
    | '/credit-account-withdrawal/success'
    | '/grace-period/reject'
    | '/grace-period/success'
    | '/standing-payment/disable'
    | '/standing-payment/enable'
    | '/standing-payment/reject'
    | '/standing-payment/success'
    | '/payment/agreement/reject'
    | '/payment/agreement/success'
    | '/payment/credit-line/reject'
    | '/payment/credit-line/success'
    | '/payment/invoice/reject'
    | '/payment/invoice/success'
    | '/premium/subscribe'
    | '/premium/unsubscribe'
    | '/profile/complete'
    | '/profile/edit'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/auth'
    | '/payment'
    | '/auth-additional-info'
    | '/credit-account-conversion'
    | '/credit-account-limit-increase'
    | '/credit-account-modification'
    | '/credit-account-withdrawal'
    | '/standing-payment'
    | '/grace-period'
    | '/payment/agreement'
    | '/payment/credit-line'
    | '/payment/invoice'
    | '/agreements'
    | '/credit-line'
    | '/dashboard'
    | '/invoices'
    | '/profile'
    | '/credit-account-conversion/apply'
    | '/credit-account-conversion/reject'
    | '/credit-account-conversion/signing'
    | '/payment/agreement/pending'
    | '/payment/credit-line/pending'
    | '/payment/invoice/pending'
    | '/premium'
    | '/credit-account-limit-increase/failure'
    | '/credit-account-limit-increase/signing'
    | '/credit-account-modification/failure'
    | '/credit-account-modification/signing'
    | '/credit-account-withdrawal/form'
    | '/credit-account-withdrawal/reject'
    | '/credit-account-withdrawal/success'
    | '/grace-period/reject'
    | '/grace-period/success'
    | '/standing-payment/disable'
    | '/standing-payment/enable'
    | '/standing-payment/reject'
    | '/standing-payment/success'
    | '/payment/agreement/reject'
    | '/payment/agreement/success'
    | '/payment/credit-line/reject'
    | '/payment/credit-line/success'
    | '/payment/invoice/reject'
    | '/payment/invoice/success'
    | '/premium/subscribe'
    | '/premium/unsubscribe'
    | '/profile/complete'
    | '/profile/edit'
  id:
    | '__root__'
    | '/'
    | '/_protected'
    | '/auth'
    | '/payment'
    | '/_protected/_main'
    | '/_protected/auth-additional-info'
    | '/_protected/credit-account-conversion'
    | '/_protected/credit-account-limit-increase'
    | '/_protected/credit-account-modification'
    | '/_protected/credit-account-withdrawal'
    | '/_protected/standing-payment'
    | '/_protected/grace-period/'
    | '/payment/agreement/'
    | '/payment/credit-line/'
    | '/payment/invoice/'
    | '/_protected/_main/agreements'
    | '/_protected/_main/credit-line'
    | '/_protected/_main/dashboard'
    | '/_protected/_main/invoices'
    | '/_protected/_main/profile'
    | '/_protected/credit-account-conversion/apply'
    | '/_protected/credit-account-conversion/reject'
    | '/_protected/credit-account-conversion/signing'
    | '/payment/agreement/pending'
    | '/payment/credit-line/pending'
    | '/payment/invoice/pending'
    | '/_protected/_main/premium'
    | '/_protected/credit-account-limit-increase/failure'
    | '/_protected/credit-account-limit-increase/signing'
    | '/_protected/credit-account-modification/failure'
    | '/_protected/credit-account-modification/signing'
    | '/_protected/credit-account-withdrawal/form'
    | '/_protected/credit-account-withdrawal/reject'
    | '/_protected/credit-account-withdrawal/success'
    | '/_protected/grace-period/reject'
    | '/_protected/grace-period/success'
    | '/_protected/standing-payment/disable'
    | '/_protected/standing-payment/enable'
    | '/_protected/standing-payment/reject'
    | '/_protected/standing-payment/success'
    | '/payment/agreement/reject'
    | '/payment/agreement/success'
    | '/payment/credit-line/reject'
    | '/payment/credit-line/success'
    | '/payment/invoice/reject'
    | '/payment/invoice/success'
    | '/_protected/_main/premium/subscribe'
    | '/_protected/_main/premium/unsubscribe'
    | '/_protected/_main/profile/complete'
    | '/_protected/_main/profile/edit'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexLazyRoute: typeof IndexLazyRoute
  ProtectedRouteRoute: typeof ProtectedRouteRouteWithChildren
  AuthRouteRoute: typeof AuthRouteRoute
  PaymentRouteLazyRoute: typeof PaymentRouteLazyRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  IndexLazyRoute: IndexLazyRoute,
  ProtectedRouteRoute: ProtectedRouteRouteWithChildren,
  AuthRouteRoute: AuthRouteRoute,
  PaymentRouteLazyRoute: PaymentRouteLazyRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_protected",
        "/auth",
        "/payment"
      ]
    },
    "/": {
      "filePath": "index.lazy.tsx"
    },
    "/_protected": {
      "filePath": "_protected/route.ts",
      "children": [
        "/_protected/_main",
        "/_protected/auth-additional-info",
        "/_protected/credit-account-conversion",
        "/_protected/credit-account-limit-increase",
        "/_protected/credit-account-modification",
        "/_protected/credit-account-withdrawal",
        "/_protected/standing-payment",
        "/_protected/grace-period/",
        "/_protected/grace-period/reject",
        "/_protected/grace-period/success"
      ]
    },
    "/auth": {
      "filePath": "auth/route.tsx"
    },
    "/payment": {
      "filePath": "payment/route.lazy.tsx",
      "children": [
        "/payment/agreement/",
        "/payment/credit-line/",
        "/payment/invoice/",
        "/payment/agreement/pending",
        "/payment/credit-line/pending",
        "/payment/invoice/pending",
        "/payment/agreement/reject",
        "/payment/agreement/success",
        "/payment/credit-line/reject",
        "/payment/credit-line/success",
        "/payment/invoice/reject",
        "/payment/invoice/success"
      ]
    },
    "/_protected/_main": {
      "filePath": "_protected/_main/route.tsx",
      "parent": "/_protected",
      "children": [
        "/_protected/_main/agreements",
        "/_protected/_main/credit-line",
        "/_protected/_main/dashboard",
        "/_protected/_main/invoices",
        "/_protected/_main/profile",
        "/_protected/_main/premium"
      ]
    },
    "/_protected/auth-additional-info": {
      "filePath": "_protected/auth-additional-info/route.tsx",
      "parent": "/_protected"
    },
    "/_protected/credit-account-conversion": {
      "filePath": "_protected/credit-account-conversion/route.ts",
      "parent": "/_protected",
      "children": [
        "/_protected/credit-account-conversion/apply",
        "/_protected/credit-account-conversion/reject",
        "/_protected/credit-account-conversion/signing"
      ]
    },
    "/_protected/credit-account-limit-increase": {
      "filePath": "_protected/credit-account-limit-increase/route.ts",
      "parent": "/_protected",
      "children": [
        "/_protected/credit-account-limit-increase/failure",
        "/_protected/credit-account-limit-increase/signing"
      ]
    },
    "/_protected/credit-account-modification": {
      "filePath": "_protected/credit-account-modification/route.ts",
      "parent": "/_protected",
      "children": [
        "/_protected/credit-account-modification/failure",
        "/_protected/credit-account-modification/signing"
      ]
    },
    "/_protected/credit-account-withdrawal": {
      "filePath": "_protected/credit-account-withdrawal/route.tsx",
      "parent": "/_protected",
      "children": [
        "/_protected/credit-account-withdrawal/form",
        "/_protected/credit-account-withdrawal/reject",
        "/_protected/credit-account-withdrawal/success"
      ]
    },
    "/_protected/standing-payment": {
      "filePath": "_protected/standing-payment/route.ts",
      "parent": "/_protected",
      "children": [
        "/_protected/standing-payment/disable",
        "/_protected/standing-payment/enable",
        "/_protected/standing-payment/reject",
        "/_protected/standing-payment/success"
      ]
    },
    "/_protected/grace-period/": {
      "filePath": "_protected/grace-period/index/route.ts",
      "parent": "/_protected"
    },
    "/payment/agreement/": {
      "filePath": "payment/agreement/index/route.ts",
      "parent": "/payment"
    },
    "/payment/credit-line/": {
      "filePath": "payment/credit-line/index/route.ts",
      "parent": "/payment"
    },
    "/payment/invoice/": {
      "filePath": "payment/invoice/index/route.ts",
      "parent": "/payment"
    },
    "/_protected/_main/agreements": {
      "filePath": "_protected/_main/agreements/route.tsx",
      "parent": "/_protected/_main"
    },
    "/_protected/_main/credit-line": {
      "filePath": "_protected/_main/credit-line/route.tsx",
      "parent": "/_protected/_main"
    },
    "/_protected/_main/dashboard": {
      "filePath": "_protected/_main/dashboard/route.tsx",
      "parent": "/_protected/_main"
    },
    "/_protected/_main/invoices": {
      "filePath": "_protected/_main/invoices/route.tsx",
      "parent": "/_protected/_main"
    },
    "/_protected/_main/profile": {
      "filePath": "_protected/_main/profile/route.tsx",
      "parent": "/_protected/_main",
      "children": [
        "/_protected/_main/profile/complete",
        "/_protected/_main/profile/edit"
      ]
    },
    "/_protected/credit-account-conversion/apply": {
      "filePath": "_protected/credit-account-conversion/apply/route.ts",
      "parent": "/_protected/credit-account-conversion"
    },
    "/_protected/credit-account-conversion/reject": {
      "filePath": "_protected/credit-account-conversion/reject/route.ts",
      "parent": "/_protected/credit-account-conversion"
    },
    "/_protected/credit-account-conversion/signing": {
      "filePath": "_protected/credit-account-conversion/signing/route.ts",
      "parent": "/_protected/credit-account-conversion"
    },
    "/payment/agreement/pending": {
      "filePath": "payment/agreement/pending/route.tsx",
      "parent": "/payment"
    },
    "/payment/credit-line/pending": {
      "filePath": "payment/credit-line/pending/route.tsx",
      "parent": "/payment"
    },
    "/payment/invoice/pending": {
      "filePath": "payment/invoice/pending/route.tsx",
      "parent": "/payment"
    },
    "/_protected/_main/premium": {
      "filePath": "_protected/_main/premium/route.lazy.tsx",
      "parent": "/_protected/_main",
      "children": [
        "/_protected/_main/premium/subscribe",
        "/_protected/_main/premium/unsubscribe"
      ]
    },
    "/_protected/credit-account-limit-increase/failure": {
      "filePath": "_protected/credit-account-limit-increase/failure/route.lazy.tsx",
      "parent": "/_protected/credit-account-limit-increase"
    },
    "/_protected/credit-account-limit-increase/signing": {
      "filePath": "_protected/credit-account-limit-increase/signing/route.lazy.tsx",
      "parent": "/_protected/credit-account-limit-increase"
    },
    "/_protected/credit-account-modification/failure": {
      "filePath": "_protected/credit-account-modification/failure/route.lazy.tsx",
      "parent": "/_protected/credit-account-modification"
    },
    "/_protected/credit-account-modification/signing": {
      "filePath": "_protected/credit-account-modification/signing/route.lazy.tsx",
      "parent": "/_protected/credit-account-modification"
    },
    "/_protected/credit-account-withdrawal/form": {
      "filePath": "_protected/credit-account-withdrawal/form/route.lazy.tsx",
      "parent": "/_protected/credit-account-withdrawal"
    },
    "/_protected/credit-account-withdrawal/reject": {
      "filePath": "_protected/credit-account-withdrawal/reject/route.lazy.tsx",
      "parent": "/_protected/credit-account-withdrawal"
    },
    "/_protected/credit-account-withdrawal/success": {
      "filePath": "_protected/credit-account-withdrawal/success/route.lazy.tsx",
      "parent": "/_protected/credit-account-withdrawal"
    },
    "/_protected/grace-period/reject": {
      "filePath": "_protected/grace-period/reject/route.lazy.tsx",
      "parent": "/_protected"
    },
    "/_protected/grace-period/success": {
      "filePath": "_protected/grace-period/success/route.lazy.tsx",
      "parent": "/_protected"
    },
    "/_protected/standing-payment/disable": {
      "filePath": "_protected/standing-payment/disable/route.lazy.tsx",
      "parent": "/_protected/standing-payment"
    },
    "/_protected/standing-payment/enable": {
      "filePath": "_protected/standing-payment/enable/route.lazy.tsx",
      "parent": "/_protected/standing-payment"
    },
    "/_protected/standing-payment/reject": {
      "filePath": "_protected/standing-payment/reject/route.lazy.tsx",
      "parent": "/_protected/standing-payment"
    },
    "/_protected/standing-payment/success": {
      "filePath": "_protected/standing-payment/success/route.lazy.tsx",
      "parent": "/_protected/standing-payment"
    },
    "/payment/agreement/reject": {
      "filePath": "payment/agreement/reject/route.lazy.tsx",
      "parent": "/payment"
    },
    "/payment/agreement/success": {
      "filePath": "payment/agreement/success/route.lazy.tsx",
      "parent": "/payment"
    },
    "/payment/credit-line/reject": {
      "filePath": "payment/credit-line/reject/route.lazy.tsx",
      "parent": "/payment"
    },
    "/payment/credit-line/success": {
      "filePath": "payment/credit-line/success/route.lazy.tsx",
      "parent": "/payment"
    },
    "/payment/invoice/reject": {
      "filePath": "payment/invoice/reject/route.lazy.tsx",
      "parent": "/payment"
    },
    "/payment/invoice/success": {
      "filePath": "payment/invoice/success/route.lazy.tsx",
      "parent": "/payment"
    },
    "/_protected/_main/premium/subscribe": {
      "filePath": "_protected/_main/premium/subscribe/route.lazy.tsx",
      "parent": "/_protected/_main/premium"
    },
    "/_protected/_main/premium/unsubscribe": {
      "filePath": "_protected/_main/premium/unsubscribe/route.lazy.tsx",
      "parent": "/_protected/_main/premium"
    },
    "/_protected/_main/profile/complete": {
      "filePath": "_protected/_main/profile/complete/route.lazy.tsx",
      "parent": "/_protected/_main/profile"
    },
    "/_protected/_main/profile/edit": {
      "filePath": "_protected/_main/profile/edit/route.lazy.tsx",
      "parent": "/_protected/_main/profile"
    }
  }
}
ROUTE_MANIFEST_END */
