import { cn } from '@utils/tailwind';
import type { FC } from 'react';

export type IndicatorVariants = 'green' | 'red' | 'blue' | 'gray';

const INDICATOR_CONFIG: Record<
	IndicatorVariants,
	{
		color: string;
	}
> = {
	green: {
		color: 'bg-system-green',
	},
	red: {
		color: 'bg-system-red',
	},
	blue: {
		color: 'bg-primary-brand002',
	},
	gray: {
		color: 'bg-neutral-400',
	},
};

export type IndicatorProps = {
	variant?: IndicatorVariants;
	className?: string;
	isAnimated?: boolean;
};

export const Indicator: FC<IndicatorProps> = ({
	variant = 'green',
	className,
	isAnimated = true,
}) => {
	const { color } = INDICATOR_CONFIG[variant];

	return (
		<div className="relative flex size-5 items-center justify-center">
			<div
				className={cn(
					'rounded-full',
					color,
					isAnimated && 'animate-indicator',
					className,
				)}
			/>
			<div
				className={cn(
					'-translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2 z-[1] size-1.5 rounded-full',
					color,
				)}
			/>
		</div>
	);
};
