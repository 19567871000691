import { createFileRoute } from '@tanstack/react-router';
import * as z from 'zod';

const CreditLinePaymentPendingSearchSchema = z.object({
	session_id: z.string().optional().catch(undefined),
	hash: z.string().optional().catch(undefined),
});

export const Route = createFileRoute('/payment/credit-line/pending')({
	validateSearch: CreditLinePaymentPendingSearchSchema,
});
