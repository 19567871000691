import { AuthMethod, EparakstsAuthorizationMethod } from './api.gen';
import type { EstoProductType } from './product';

export enum SupportedCountries {
	EE = 'EE',
	LV = 'LV',
	LT = 'LT',
}

export enum AppLanguage {
	EN = 'en',
	ET = 'et',
	LV = 'lv',
	LT = 'lt',
	RU = 'ru',
}

export type LegalInfo = {
	infoEmail: string;
	phone: {
		number: string;
		label: string;
	};
	address: string;
	name: string;
};

export type CreditLine = {
	isCreditAccountInterestFreeBannerEnabled: boolean;
	creditLineInterestFreeDays: number;
};

export enum CountryPhoneCode {
	EE = '+372',
	LV = '+371',
	LT = '+370',
}

type CountryConfig = {
	authMethods: Array<AppAuthMethod>;
	starProduct: EstoProductType;
	supportUrlByLanguage: Record<AppLanguage, string>;
	legalInfo: LegalInfo;
	termsUrlByLanguage: Record<AppLanguage, string>;
	premiumTermsUrlByLanguage: Record<AppLanguage, string>;
	phoneCode: CountryPhoneCode;
	isRejectedCAWRedirectionToCamEnabled: boolean;
	isStandingPaymentEnabled: boolean;
	creditLine: CreditLine;
	purchaseFlowUrl: string;
	isOnlyPasswordGracePeriodSigningEnabled: boolean;
	isOnlyPasswordCreditAccountModificationSigningEnabled: boolean;
	isOnlyPasswordCreditAccountLimitIncreaseSigningEnabled: boolean;
	homepageDeals: Record<AppLanguage, string>;
};

export type AppConfigByCountry = Record<SupportedCountries, CountryConfig>;

export type AppConfig = { name: string } & CountryConfig;

export enum AppAuthMethod {
	ID_CARD = AuthMethod.ID_CARD,
	MOBILE = AuthMethod.MOBILE,
	PASSWORD = AuthMethod.PASSWORD,
	PAYSERA_BANKLINK = AuthMethod.PAYSERA_BANKLINK,
	SMART_ID = AuthMethod.SMART_ID,
	EPARAKSTS_MOBILE = `${AuthMethod.EPARAKSTS}_${EparakstsAuthorizationMethod.MOBILE}`,
	EPARAKSTS_SMARTCARD = `${AuthMethod.EPARAKSTS}_${EparakstsAuthorizationMethod.SMARTCARD}`,
}
