import { createFileRoute } from '@tanstack/react-router';
import * as z from 'zod';

const CreditAccountConversionSigningSearchSchema = z.object({
	referenceKey: z.string().optional().catch(undefined),
});

export const Route = createFileRoute(
	'/_protected/credit-account-conversion/signing',
)({
	validateSearch: CreditAccountConversionSigningSearchSchema,
});
