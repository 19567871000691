import { GRACE_PERIOD_MONTHS_OPTIONS } from '@features/grace-period-period-select';
import {
	PAYSERA_SIGNING_STATUS_SEARCH_NAME,
	PayseraSigningStatus,
} from '@features/signing/by-paysera-banklink/constants';
import { GracePeriodFormView } from '@pages/grace-period';
import { createFileRoute } from '@tanstack/react-router';
import * as z from 'zod';

const GracePeriodSearchSchema = z.object({
	view: z.nativeEnum(GracePeriodFormView).optional().catch(undefined),
	period: z.enum(GRACE_PERIOD_MONTHS_OPTIONS).optional().catch(undefined),
	[PAYSERA_SIGNING_STATUS_SEARCH_NAME]: z
		.nativeEnum(PayseraSigningStatus)
		.optional()
		.catch(undefined),
});

export const Route = createFileRoute('/_protected/grace-period/')({
	validateSearch: GracePeriodSearchSchema,
});
