import type { PaymentLeave, User } from '@/shared/types';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@components/ui/select';
import { LOCIZE_NAMESPACES } from '@config/locize';
import { LOCIZE_GRACE_PERIOD_KEYS } from '@config/locize/grace-period';
import { gracePeriodPeriodSelectApi } from '@features/grace-period-period-select/api';
import { GRACE_PERIOD_MONTHS_OPTIONS } from '@features/grace-period-period-select/config';
import { useToast } from '@hooks/system';
import { Label } from '@radix-ui/react-label';
import { cn } from '@utils/tailwind';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './GracePeriodPeriodSelect.module.css';

const SELECT_ID = 'grace-period-period-select';

export type GracePeriodPeriodSelectProps = {
	userId: User['id'];
	onChange?: (value: ArrayElement<typeof GRACE_PERIOD_MONTHS_OPTIONS>) => void;
	value?: string;
	paymentLeaveId: PaymentLeave['id'];
	className?: string;
};

export const GracePeriodPeriodSelect: FC<GracePeriodPeriodSelectProps> = ({
	userId,
	value,
	onChange,
	paymentLeaveId,
	className,
}) => {
	const { t } = useTranslation(LOCIZE_NAMESPACES.gracePeriod);

	const { showErrorMessage } = useToast();
	const updateGracePeriodPeriod =
		gracePeriodPeriodSelectApi.useUpdatePaymentLeaveMonthPeriodMutation();

	const handleSelectValueChange = async (
		period: ArrayElement<typeof GRACE_PERIOD_MONTHS_OPTIONS>,
	) => {
		try {
			await updateGracePeriodPeriod.mutateAsync({
				userId,
				months: +period,
				paymentLeaveId,
			});

			onChange?.(period);
		} catch {
			showErrorMessage();
		}
	};

	return (
		<div className={cn(styles.container, className)}>
			<Label htmlFor={SELECT_ID}>
				{t(LOCIZE_GRACE_PERIOD_KEYS.periodSelectLabel)}
			</Label>
			<Select
				onValueChange={handleSelectValueChange}
				value={value}
				disabled={updateGracePeriodPeriod.isPending}
			>
				<SelectTrigger id={SELECT_ID}>
					<SelectValue
						placeholder={t(LOCIZE_GRACE_PERIOD_KEYS.periodSelectPlaceholder)}
					/>
				</SelectTrigger>
				<SelectContent>
					<div
						ref={(ref) => {
							if (!ref) return;
							ref.ontouchstart = (e) => e.preventDefault();
						}}
					>
						{GRACE_PERIOD_MONTHS_OPTIONS.map((period) => (
							<SelectItem key={period} value={period}>
								{period === '1'
									? t(LOCIZE_GRACE_PERIOD_KEYS.periodSelectMonth)
									: t(LOCIZE_GRACE_PERIOD_KEYS.periodSelectMonths, {
											month: period,
										})}
							</SelectItem>
						))}
					</div>
				</SelectContent>
			</Select>
		</div>
	);
};
