import { APP_CONFIG } from '@config/app';
import { ROUTE_NAMES } from '@config/routes';
import { userApi } from '@entities/user';
import { queryOptions } from '@tanstack/react-query';
import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_protected/auth-additional-info')({
	loader: async ({ context: { queryClient } }) => {
		const data = await queryClient.ensureQueryData(
			queryOptions({
				queryKey: userApi.useSuspenseUserQuery.getKey(),
				queryFn: userApi.useUserQuery.fetcher(),
			}),
		);

		if (!data.me) {
			throw redirect({
				to: ROUTE_NAMES.auth,
				search: {
					authMethod: APP_CONFIG.authMethods[0],
				},
				replace: true,
			});
		}

		const {
			phone,
			email,
			id,
			created_at: createdAt,
			newsletter_agreement: isAcceptedNewsLetterAgreement,
			profile,
		} = data.me;

		if ([phone, email].every(Boolean)) {
			throw redirect({
				to: ROUTE_NAMES.root,
				replace: true,
			});
		}

		return {
			phone: phone ?? '',
			email: email ?? '',
			userId: id,
			createdAt,
			isAcceptedNewsLetterAgreement,
			firstName: profile?.first_name ?? '',
		};
	},
});
