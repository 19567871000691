/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../shared/types/api.gen';

import { useQuery, useSuspenseQuery, UseQueryOptions, UseSuspenseQueryOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type GetCreditAccountLimitRecalculationQueryVariables = Types.Exact<{
  hash: Types.Scalars['String']['input'];
}>;


export type GetCreditAccountLimitRecalculationQuery = { creditAccountLimitRecalculation?: { status: string, new_credit_limit?: number | null, current_credit_limit: number } | null };



export const GetCreditAccountLimitRecalculationDocument = `
    query GetCreditAccountLimitRecalculation($hash: String!) {
  creditAccountLimitRecalculation: credit_account_limit_recalculation(hash: $hash) {
    status
    new_credit_limit
    current_credit_limit
  }
}
    `;

export const useGetCreditAccountLimitRecalculationQuery = <
      TData = GetCreditAccountLimitRecalculationQuery,
      TError = unknown
    >(
      variables: GetCreditAccountLimitRecalculationQueryVariables,
      options?: Omit<UseQueryOptions<GetCreditAccountLimitRecalculationQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetCreditAccountLimitRecalculationQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<GetCreditAccountLimitRecalculationQuery, TError, TData>(
      {
    queryKey: ['GetCreditAccountLimitRecalculation', variables],
    queryFn: fetcher<GetCreditAccountLimitRecalculationQuery, GetCreditAccountLimitRecalculationQueryVariables>(GetCreditAccountLimitRecalculationDocument, variables),
    ...options
  }
    )};

useGetCreditAccountLimitRecalculationQuery.getKey = (variables: GetCreditAccountLimitRecalculationQueryVariables) => ['GetCreditAccountLimitRecalculation', variables];

export const useSuspenseGetCreditAccountLimitRecalculationQuery = <
      TData = GetCreditAccountLimitRecalculationQuery,
      TError = unknown
    >(
      variables: GetCreditAccountLimitRecalculationQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<GetCreditAccountLimitRecalculationQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<GetCreditAccountLimitRecalculationQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<GetCreditAccountLimitRecalculationQuery, TError, TData>(
      {
    queryKey: ['GetCreditAccountLimitRecalculationSuspense', variables],
    queryFn: fetcher<GetCreditAccountLimitRecalculationQuery, GetCreditAccountLimitRecalculationQueryVariables>(GetCreditAccountLimitRecalculationDocument, variables),
    ...options
  }
    )};

useSuspenseGetCreditAccountLimitRecalculationQuery.getKey = (variables: GetCreditAccountLimitRecalculationQueryVariables) => ['GetCreditAccountLimitRecalculationSuspense', variables];


useGetCreditAccountLimitRecalculationQuery.fetcher = (variables: GetCreditAccountLimitRecalculationQueryVariables, options?: RequestInit['headers']) => fetcher<GetCreditAccountLimitRecalculationQuery, GetCreditAccountLimitRecalculationQueryVariables>(GetCreditAccountLimitRecalculationDocument, variables, options);
