import { withProviders } from '@/app/providers';
import { AppRouter } from '@/app/router/AppRouter';
import { Suspense, useEffect } from 'react';
import '@config/envs';
import '@lib/i18Next';
import './sentry';
import './styles/index.css';
import React from 'react';

// Preload critical components
const preloadAuthComponents = () => {
	// Preload auth form components
	import('@pages/auth/ui/AuthForm');
	import('@features/auth/by-smartId');
	import('@features/auth/by-mobile-id');
	import('@features/auth/by-id-card');
	import('@features/product-introduction-carousel');
};

// Move Toaster to a separate component
const ToasterComponent = () => {
	const Toaster = React.lazy(() =>
		import('@components/ui/sonner').then((mod) => ({
			default: mod.Toaster,
		})),
	);

	return (
		<Suspense fallback={null}>
			<Toaster />
		</Suspense>
	);
};

const App = () => {
	useEffect(() => {
		// Preload auth components after initial render
		const timer = setTimeout(preloadAuthComponents, 1000);
		return () => clearTimeout(timer);
	}, []);

	return (
		<Suspense fallback={null}>
			<AppRouter />
			<ToasterComponent />
		</Suspense>
	);
};

export default withProviders(App);
