import { KlixPaymentStatusType } from '@/shared/types';
import { ROUTE_NAMES } from '@config/routes';
import { AppPaymentMethod, paymentsApi } from '@entities/payments';
import { userApi } from '@entities/user';
import { CreditCardPaymentStatus } from '@features/credit-card-payment';
import { CreditLinePaymentFormView } from '@pages/credit-line-payment/config';
import { createFileRoute, redirect } from '@tanstack/react-router';
import * as z from 'zod';

const paymentCreditLineSearchSchema = z.object({
	withPremium: z.boolean().optional().catch(false),
	paymentMethod: z
		.nativeEnum(AppPaymentMethod)
		.catch(AppPaymentMethod.BANKLINK),
	formView: z
		.nativeEnum(CreditLinePaymentFormView)
		.optional()
		.catch(CreditLinePaymentFormView.OVERVIEW),
	amount: z.number().optional().catch(undefined),
	creditLinePaymentConfirm: z.boolean().optional().catch(undefined),

	// unique identifier for credit account
	creditAccountHash: z.string().optional().catch(undefined),

	// banklink
	session_id: z.string().optional().catch(undefined),
	hash: z.string().optional().catch(undefined),
	// credit card
	payment_status: z
		.nativeEnum(CreditCardPaymentStatus)
		.optional()
		.catch(undefined),
});

export const Route = createFileRoute('/payment/credit-line/')({
	validateSearch: paymentCreditLineSearchSchema,
	beforeLoad: async ({
		search: { session_id, hash, payment_status, creditAccountHash },
		context: { queryClient },
	}) => {
		if (session_id && hash) {
			const data = await queryClient.fetchQuery({
				queryKey: paymentsApi.useKlixPaymentQuery.getKey({
					sessionId: session_id,
					hash,
				}),
				queryFn: paymentsApi.useKlixPaymentQuery.fetcher({
					sessionId: session_id,
					hash,
				}),
			});

			switch (data?.payment?.status) {
				case KlixPaymentStatusType.PAID: {
					throw redirect({
						to: ROUTE_NAMES.paymentCreditLineSuccess,
						replace: true,
					});
				}
				case KlixPaymentStatusType.ERROR:
				case KlixPaymentStatusType.CANCELLED:
				case KlixPaymentStatusType.BLOCKED:
				case KlixPaymentStatusType.EXPIRED:
				case KlixPaymentStatusType.OVERDUE: {
					throw redirect({
						to: ROUTE_NAMES.paymentCreditLineReject,
						replace: true,
					});
				}
				default: {
					throw redirect({
						to: ROUTE_NAMES.paymentCreditLinePending,
						replace: true,
						search: {
							session_id,
							hash,
						},
					});
				}
			}
		}

		if (payment_status === CreditCardPaymentStatus.SUCCESS) {
			throw redirect({
				to: ROUTE_NAMES.paymentCreditLineSuccess,
				replace: true,
			});
		}

		if (payment_status === CreditCardPaymentStatus.FAILED) {
			throw redirect({
				to: ROUTE_NAMES.paymentCreditLineReject,
				replace: true,
			});
		}

		if (creditAccountHash) {
			const data = await queryClient.ensureQueryData({
				queryKey: userApi.useSuspenseUserCreditAccountByHashQuery.getKey({
					hash: creditAccountHash,
				}),
				queryFn: userApi.useUserCreditAccountByHashQuery.fetcher({
					hash: creditAccountHash,
				}),
			});

			return {
				creditAccountId: data.creditAccount?.id,
			};
		}

		return {
			creditAccountId: undefined,
		};
	},
});
