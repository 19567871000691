/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../shared/types/api.gen';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type StoreFirebaseCmTokenMutationVariables = Types.Exact<{
  userId: Types.Scalars['Int']['input'];
  fcmToken: Types.Scalars['String']['input'];
  oldFcmToken?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type StoreFirebaseCmTokenMutation = { success: boolean };



export const StoreFirebaseCmTokenDocument = `
    mutation StoreFirebaseCMToken($userId: Int!, $fcmToken: String!, $oldFcmToken: String) {
  success: store_fcm_token(
    user_id: $userId
    fcm_token: $fcmToken
    old_fcm_token: $oldFcmToken
  )
}
    `;

export const useStoreFirebaseCmTokenMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<StoreFirebaseCmTokenMutation, TError, StoreFirebaseCmTokenMutationVariables, TContext>) => {
    
    return useMutation<StoreFirebaseCmTokenMutation, TError, StoreFirebaseCmTokenMutationVariables, TContext>(
      {
    mutationKey: ['StoreFirebaseCMToken'],
    mutationFn: (variables?: StoreFirebaseCmTokenMutationVariables) => fetcher<StoreFirebaseCmTokenMutation, StoreFirebaseCmTokenMutationVariables>(StoreFirebaseCmTokenDocument, variables)(),
    ...options
  }
    )};


useStoreFirebaseCmTokenMutation.fetcher = (variables: StoreFirebaseCmTokenMutationVariables, options?: RequestInit['headers']) => fetcher<StoreFirebaseCmTokenMutation, StoreFirebaseCmTokenMutationVariables>(StoreFirebaseCmTokenDocument, variables, options);
