/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../shared/types/api.gen';

import { useQuery, useSuspenseQuery, UseQueryOptions, UseSuspenseQueryOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type ApplicationQueryVariables = Types.Exact<{
  referenceKey?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ApplicationQuery = { application_by_reference?: { id: number, merchant_id?: number | null, invoice_reference_nr?: string | null, total_paid?: number | null, signed_at?: number | null, requested_amount: number, schedule_type: Types.ApplicationScheduleType, unpaid_principal?: number | null, paid_principal: number, next_payment_amount?: number | null, next_installment_date?: string | null, free_hp_enabled: boolean, can_manually_convert_to_credit_account: boolean, credit_info?: { monthly_payment?: number | null } | null, merchant?: { logo_path?: string | null, name: string, campaign?: { converting_schedule_name?: string | null } | null } | null } | null };

export type ApplicationRemainingAmountQueryVariables = Types.Exact<{
  applicationId: Types.Scalars['Int']['input'];
}>;


export type ApplicationRemainingAmountQuery = { application_remaining_amount: number };



export const ApplicationDocument = `
    query Application($referenceKey: String) {
  application_by_reference(reference_key: $referenceKey) {
    id
    merchant_id
    invoice_reference_nr
    total_paid
    signed_at
    requested_amount
    schedule_type
    unpaid_principal
    paid_principal
    next_payment_amount
    next_installment_date
    free_hp_enabled
    can_manually_convert_to_credit_account
    credit_info {
      monthly_payment
    }
    merchant {
      logo_path
      name
      campaign {
        converting_schedule_name
      }
    }
  }
}
    `;

export const useApplicationQuery = <
      TData = ApplicationQuery,
      TError = unknown
    >(
      variables?: ApplicationQueryVariables,
      options?: Omit<UseQueryOptions<ApplicationQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<ApplicationQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<ApplicationQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['Application'] : ['Application', variables],
    queryFn: fetcher<ApplicationQuery, ApplicationQueryVariables>(ApplicationDocument, variables),
    ...options
  }
    )};

useApplicationQuery.getKey = (variables?: ApplicationQueryVariables) => variables === undefined ? ['Application'] : ['Application', variables];

export const useSuspenseApplicationQuery = <
      TData = ApplicationQuery,
      TError = unknown
    >(
      variables?: ApplicationQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<ApplicationQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<ApplicationQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<ApplicationQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['ApplicationSuspense'] : ['ApplicationSuspense', variables],
    queryFn: fetcher<ApplicationQuery, ApplicationQueryVariables>(ApplicationDocument, variables),
    ...options
  }
    )};

useSuspenseApplicationQuery.getKey = (variables?: ApplicationQueryVariables) => variables === undefined ? ['ApplicationSuspense'] : ['ApplicationSuspense', variables];


useApplicationQuery.fetcher = (variables?: ApplicationQueryVariables, options?: RequestInit['headers']) => fetcher<ApplicationQuery, ApplicationQueryVariables>(ApplicationDocument, variables, options);

export const ApplicationRemainingAmountDocument = `
    query ApplicationRemainingAmount($applicationId: Int!) {
  application_remaining_amount(application_id: $applicationId)
}
    `;

export const useApplicationRemainingAmountQuery = <
      TData = ApplicationRemainingAmountQuery,
      TError = unknown
    >(
      variables: ApplicationRemainingAmountQueryVariables,
      options?: Omit<UseQueryOptions<ApplicationRemainingAmountQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<ApplicationRemainingAmountQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<ApplicationRemainingAmountQuery, TError, TData>(
      {
    queryKey: ['ApplicationRemainingAmount', variables],
    queryFn: fetcher<ApplicationRemainingAmountQuery, ApplicationRemainingAmountQueryVariables>(ApplicationRemainingAmountDocument, variables),
    ...options
  }
    )};

useApplicationRemainingAmountQuery.getKey = (variables: ApplicationRemainingAmountQueryVariables) => ['ApplicationRemainingAmount', variables];

export const useSuspenseApplicationRemainingAmountQuery = <
      TData = ApplicationRemainingAmountQuery,
      TError = unknown
    >(
      variables: ApplicationRemainingAmountQueryVariables,
      options?: Omit<UseSuspenseQueryOptions<ApplicationRemainingAmountQuery, TError, TData>, 'queryKey'> & { queryKey?: UseSuspenseQueryOptions<ApplicationRemainingAmountQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useSuspenseQuery<ApplicationRemainingAmountQuery, TError, TData>(
      {
    queryKey: ['ApplicationRemainingAmountSuspense', variables],
    queryFn: fetcher<ApplicationRemainingAmountQuery, ApplicationRemainingAmountQueryVariables>(ApplicationRemainingAmountDocument, variables),
    ...options
  }
    )};

useSuspenseApplicationRemainingAmountQuery.getKey = (variables: ApplicationRemainingAmountQueryVariables) => ['ApplicationRemainingAmountSuspense', variables];


useApplicationRemainingAmountQuery.fetcher = (variables: ApplicationRemainingAmountQueryVariables, options?: RequestInit['headers']) => fetcher<ApplicationRemainingAmountQuery, ApplicationRemainingAmountQueryVariables>(ApplicationRemainingAmountDocument, variables, options);
