export const LOCIZE_CREDIT_ACCOUNT_LIMIT_INCREASE_KEYS = {
	creditAccountLimitIncreasePageTitle:
		'credit-account-limit-increase.page-title',
	creditAccountLimitIncreaseButtonLabel:
		'credit-account-limit-increase.button-label',
	creditAccountLimitIncreaseActionText:
		'credit-account-limit-increase.action-text',
	creditAccountLimitIncreaseTitle: 'credit-account-limit-increase.title',
	creditAccountLimitIncreaseAmount:
		'credit-account-limit-increase.increase-amount',
	creditAccountLimitIncreaseDescription:
		'credit-account-limit-increase.description',
	creditAccountLimitIncreaseDescription2:
		'credit-account-limit-increase.description-2',
	creditAccountLimitIncreaseContractLink:
		'credit-account-limit-increase.contract-link',
	creditAccountLimitIncreaseFailureTitle:
		'credit-account-limit-increase.failure-title',
	creditAccountLimitIncreaseFailureDescription:
		'credit-account-limit-increase.failure-description',
	creditAccountLimitIncreaseTryAgain: 'credit-account-limit-increase.try-again',
};
