export const LOCIZE_DEALS_KEYS = {
	kuldan: 'kuldan',
	siniseValguse: 'sinise-valguse',
	sportlik: 'sportlik',
	upgreat: 'upgreat',
	fitlap: 'fitlap',
	mokykline: 'mokykline',
	wolt: 'wolt',
	mlily: 'mlily',
	given: 'given',
	goldenLombard: 'golden-lombard',
	madratsipood: 'madratsipood',
	airsoftgo: 'airsoftgo',
	robotimaailm: 'robotimaailm',
	msl: 'msl',
	rokokoLV: 'rokoko-lv',
	ekoplanet: 'ekoplanet',
	ekoplanetLV: 'ekoplanet-lv',
	ekoplanetLT: 'ekoplanet-lv',
	manguarvutid: 'manguarvutid',
	elixHealth: 'elix-health',
	streetStrider: 'street-strider',
	bilance: 'bilance',
	alexanto: 'alexanto',
	merevarustus: 'merevarustus',
	inpuit: 'inpuit',
	anixShop: 'anix-shop',
	probike: 'probike',
	topcik: 'topcik',
	smartpc: 'smartpc',
	lightConcept: 'light-concept',
	bilanceDiscount: 'bilance.discount',
	dialogDiscountDescriptionRobotimaailm:
		'dialog.discount-description.robotimaailm',
	dialogDiscountDescriptionManguarvutid:
		'dialog.discount-description.manguarvutid',
	dialogDiscountDescriptionTopcik: 'dialog.discount-description.topcik',
	dialogDescription: 'dialog.description',
	dialogDescription2: 'dialog.description-2',
	dialogCopyDisclaimer: 'dialog.copy-disclaimer',
	dialogCopyButton: 'dialog.copy-button.default',
	dialogCopyButtonSuccess: 'dialog.copy-button.success',
	dialogRedirectButton: 'dialog.redirect-button',
} as const;
