import { Helmet } from '@components/Helmet';
import { LateSuspense } from '@components/LateSuspense';
import { LoadingSpinner } from '@components/LoadingSpinner';
import { Typography } from '@components/typography';
import { Button } from '@components/ui/button';
import {
	PREPARE_MOBILE_ID_SIGNATURE,
	PREPARE_SMART_ID_SIGNATURE,
} from '@config/cachedKeys';
import { LOCIZE_NAMESPACES } from '@config/locize';
import { LOCIZE_GRACE_PERIOD_KEYS } from '@config/locize/grace-period';
import { ROUTE_NAMES } from '@config/routes';
import { useUserId } from '@entities/user';
import {
	GracePeriodPeriodSelect,
	type GracePeriodPeriodSelectProps,
} from '@features/grace-period-period-select';
import { useIsMobileView } from '@hooks/system';
import { useGetCachedData } from '@hooks/system/useGetCachedData';
import { gracePeriodPageApi } from '@pages/grace-period/api';
import { GracePeriodFormView } from '@pages/grace-period/config';
import { getRouteApi } from '@tanstack/react-router';
import { DualPanelLayout } from '@widgets/layouts/dual-panel';
import { type FC, lazy } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './GracePeriodPage.module.css';

const GracePeriodInfo = lazy(() =>
	import('./grace-period-info').then((module) => ({
		default: module.GracePeriodInfo,
	})),
);
const GracePeriodSigning = lazy(() =>
	import('./grace-period-signing').then((module) => ({
		default: module.GracePeriodSigning,
	})),
);

const routerApi = getRouteApi('/_protected/grace-period/');

export const GracePeriodPage: FC = () => {
	const { t } = useTranslation(LOCIZE_NAMESPACES.gracePeriod);

	const isMobileView = useIsMobileView();

	return (
		<>
			<Helmet title={t(LOCIZE_GRACE_PERIOD_KEYS.pageTitle)} />
			<DualPanelLayout
				left={isMobileView ? null : <GracePeriodInfo />}
				right={<GracePeriodRightPanel />}
				fromPage={ROUTE_NAMES.invoices}
			/>
		</>
	);
};

function GracePeriodRightPanel() {
	const { t } = useTranslation(LOCIZE_NAMESPACES.gracePeriod);

	const mobileIdData = useGetCachedData(PREPARE_MOBILE_ID_SIGNATURE);
	const smartIdData = useGetCachedData(PREPARE_SMART_ID_SIGNATURE);

	const { data: userId } = useUserId();
	const { data: gracePeriodId } =
		gracePeriodPageApi.useSuspensePendingGracePeriodQuery(undefined, {
			select: (data) => {
				if (!data?.me?.pending_payment_leave?.id)
					throw new Error('Grace period id is missing');

				return data.me.pending_payment_leave.id;
			},
		});

	const isMobileView = useIsMobileView();
	const {
		view = GracePeriodFormView.OVERVIEW,
		period,
		payseraSigningResult,
	} = routerApi.useSearch();
	const navigate = routerApi.useNavigate();

	const isPolling = mobileIdData || smartIdData || !!payseraSigningResult;

	const handlePeriodSelectChange: GracePeriodPeriodSelectProps['onChange'] = (
		period,
	) => {
		navigate({
			search: (prev) => ({
				...prev,
				period,
			}),
		});
	};

	return (
		<div className={styles.rightPanel}>
			<Typography variant="s" tag="h1">
				{t(LOCIZE_GRACE_PERIOD_KEYS.pageHeading)}
			</Typography>
			<div className={styles.rightPanelContent}>
				<LateSuspense fallback={<LoadingSpinner className="mx-auto" />}>
					{isMobileView ? (
						view === GracePeriodFormView.OVERVIEW ? (
							<>
								<GracePeriodInfo />
								<Button
									className={styles.continueButton}
									onClick={() =>
										navigate({
											search: (prev) => ({
												...prev,
												view: GracePeriodFormView.SIGNING,
											}),
										})
									}
								>
									{t(LOCIZE_GRACE_PERIOD_KEYS.continueButton)}
								</Button>
							</>
						) : (
							<>
								{!isPolling && (
									<GracePeriodPeriodSelect
										value={period}
										userId={userId}
										paymentLeaveId={gracePeriodId}
										onChange={handlePeriodSelectChange}
									/>
								)}
								<GracePeriodSigning />
							</>
						)
					) : (
						<>
							{!isPolling && (
								<GracePeriodPeriodSelect
									value={period}
									userId={userId}
									paymentLeaveId={gracePeriodId}
									onChange={handlePeriodSelectChange}
								/>
							)}
							<GracePeriodSigning />
						</>
					)}
				</LateSuspense>
			</div>
		</div>
	);
}
