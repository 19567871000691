import { SimpleEligibilityStatus } from '@/shared/types';
import { CreditAccountWithdrawalEligibilityState } from '@/shared/types/credit-account-withdrawal';
import { APP_CONFIG } from '@config/app';
import { MIN_USER_DISPOSABLE_INCOME } from '@config/credit-account-withdrawal';
import {
	OLD_APP_ROUTE_NAME,
	PURCHASE_FLOW_ROUTE_NAME,
	ROUTE_NAMES,
} from '@config/routes';
import { userApi } from '@entities/user';
import { CreditAccountConversionStatuses } from '@features/credit-account-conversion/config';
import { RecalculationStatuses } from '@features/credit-account-modification/config';
import { serverFetch } from '@lib/serverFetcher';
import { useUserCreditAccountWithdrawalSuccessInfoQuery } from '@pages/credit-account-withdrawal/success/api';
import { queryOptions } from '@tanstack/react-query';
import { createFileRoute, redirect } from '@tanstack/react-router';
import * as z from 'zod';

const CreditAccountWithdrawalSearch = z.object({
	fromPathname: z.string().optional().catch(undefined),
	hash: z.string().optional().catch(undefined),
	error: z.boolean().optional(),
	recalculation: z
		.nativeEnum(RecalculationStatuses)
		.optional()
		.catch(undefined),
	conversion: z
		.nativeEnum(CreditAccountConversionStatuses)
		.optional()
		.catch(undefined),
});

export const Route = createFileRoute('/_protected/credit-account-withdrawal')({
	validateSearch: CreditAccountWithdrawalSearch,
	loaderDeps: ({ search: { hash, fromPathname } }) => ({ hash, fromPathname }),
	beforeLoad: async ({
		location,
		context: { queryClient },
		search: { hash, fromPathname },
	}) => {
		const creditAccount = await queryClient.ensureQueryData(
			queryOptions({
				queryKey: userApi.useSuspenseUserCreditAccountQuery.getKey(),
				queryFn: userApi.useUserCreditAccountQuery.fetcher(),
			}),
		);

		if (!hash) {
			if (location.pathname !== ROUTE_NAMES.creditAccountWithdrawalForm) {
				throw redirect({
					replace: true,
					to: ROUTE_NAMES.creditAccountWithdrawalForm,
					search: {
						fromPathname,
					},
				});
			}

			return {
				type: undefined,
			};
		}

		const data = await serverFetch(
			useUserCreditAccountWithdrawalSuccessInfoQuery,
			{ variables: { hash } },
		);

		const { eligibility_state, type, simple_eligibility_status } =
			data.current_credit_account_withdrawal ?? {};

		if (typeof eligibility_state === 'undefined') {
			throw redirect({
				replace: true,
				to: ROUTE_NAMES.creditAccountWithdrawalForm,
				search: {
					fromPathname,
				},
			});
		}

		if (
			eligibility_state === CreditAccountWithdrawalEligibilityState.NEGATIVE
		) {
			const disposableIncomeWithoutCa =
				creditAccount?.me?.disposable_income_without_ca;
			if (
				APP_CONFIG.isRejectedCAWRedirectionToCamEnabled &&
				simple_eligibility_status ===
					SimpleEligibilityStatus.DSTI_DSCR_INSUFFICIENT &&
				!!disposableIncomeWithoutCa &&
				disposableIncomeWithoutCa > MIN_USER_DISPOSABLE_INCOME
			) {
				window.open(OLD_APP_ROUTE_NAME.creditAccountModification, '_self');
				return {
					type,
				};
			}
			if (location.pathname !== ROUTE_NAMES.creditAccountWithdrawalReject) {
				throw redirect({
					replace: true,
					to: ROUTE_NAMES.creditAccountWithdrawalReject,
					search: {
						fromPathname,
						hash,
					},
				});
			}
		}

		if (eligibility_state === CreditAccountWithdrawalEligibilityState.SCORING) {
			window.open(
				PURCHASE_FLOW_ROUTE_NAME.creditLineWithdrawal.replace('$hash', hash),
				'_self',
			);
		}

		if (
			eligibility_state === CreditAccountWithdrawalEligibilityState.SUCCESS &&
			location.pathname !== ROUTE_NAMES.creditAccountWithdrawalSuccess
		) {
			throw redirect({
				replace: true,
				to: ROUTE_NAMES.creditAccountWithdrawalSuccess,
				search: {
					fromPathname,
					hash,
				},
			});
		}

		return {
			type,
		};
	},
});
