import { APP_CONFIG } from '@config/app';
import { useIsFirstCreditAccountWithdrawal } from '@entities/credit-account-withdrawal';
import { useUserCreditAccount } from '@entities/user';
import { CreditAccountLimitIncreaseButton } from '@features/credit-account-limit-increase/ui/CreditAccountLimitIncreaseButton';
import { CreditAccountModificationButton } from '@features/credit-account-modification/ui/CreditAccountModificationButton';
import { type FC, type ReactElement, lazy } from 'react';
import { useCreditLineBalanceState } from '../hooks';
import { CreditLineBalanceState } from '../types';

const CreditLineBalanceNoSigned = lazy(() =>
	import('./credit-line-no-signed').then((module) => ({
		default: module.CreditLineBalanceNoSigned,
	})),
);
const CreditLineBalanceNoSignedInterestFree = lazy(() =>
	import('./credit-line-no-signed-interest-free').then((module) => ({
		default: module.CreditLineBalanceNoSignedInterestFree,
	})),
);
const CreditLineBalanceSigned = lazy(() =>
	import('./CreditLineBalanceSigned').then((module) => ({
		default: module.CreditLineBalanceSigned,
	})),
);
const CreditLineBalanceLateInvoice = lazy(() =>
	import('./CreditLineBalanceLateInvoice').then((module) => ({
		default: module.CreditLineBalanceLateInvoice,
	})),
);
const CreditLineBalanceNoLimit = lazy(() =>
	import('./CreditLineBalanceNoLimit').then((module) => ({
		default: module.CreditLineBalanceNoLimit,
	})),
);

const renderCreditLineBalance: Record<
	Exclude<CreditLineBalanceState, CreditLineBalanceState.NO_SIGNED>,
	(data: {
		isFirstWithdrawal: boolean;
		creditLimit: number;
		unpaidPrincipal: number;
		ModificationCtaButton?: React.ReactNode;
		LimitIncreaseCtaButton?: React.ReactNode;
	}) => ReactElement
> = {
	[CreditLineBalanceState.SIGNED]: (data) => (
		<CreditLineBalanceSigned {...data} />
	),
	[CreditLineBalanceState.LATE_INVOICE]: ({
		creditLimit,
		unpaidPrincipal,
		LimitIncreaseCtaButton,
		ModificationCtaButton,
	}) => (
		<CreditLineBalanceLateInvoice
			creditLimit={creditLimit}
			unpaidPrincipal={unpaidPrincipal}
			LimitIncreaseCtaButton={LimitIncreaseCtaButton}
			ModificationCtaButton={ModificationCtaButton}
		/>
	),
	[CreditLineBalanceState.NO_CREDIT_LEFT]: ({
		creditLimit,
		LimitIncreaseCtaButton,
		ModificationCtaButton,
	}) => (
		<CreditLineBalanceNoLimit
			creditLimit={creditLimit}
			LimitIncreaseCtaButton={LimitIncreaseCtaButton}
			ModificationCtaButton={ModificationCtaButton}
		/>
	),
};

type CreditLineBalanceProps = {
	isCreditLinePage?: boolean;
};

export const CreditLineBalance: FC<CreditLineBalanceProps> = ({
	isCreditLinePage = false,
}) => {
	const { data: creditAccount } = useUserCreditAccount();
	const { data: isFirstCreditAccountWithdrawal } =
		useIsFirstCreditAccountWithdrawal(creditAccount?.id);

	const creditLineBalanceState = useCreditLineBalanceState();

	const ModificationCtaButton = creditAccount?.shouldBeModified ? (
		<CreditAccountModificationButton />
	) : null;

	const LimitIncreaseCtaButton = creditAccount?.canRequestLimitIncrease ? (
		<CreditAccountLimitIncreaseButton />
	) : null;

	if (!creditLineBalanceState) {
		return null;
	}

	if (
		!creditAccount ||
		creditLineBalanceState === CreditLineBalanceState.NO_SIGNED
	) {
		return APP_CONFIG.creditLine.isCreditAccountInterestFreeBannerEnabled ? (
			<CreditLineBalanceNoSignedInterestFree
				isCreditLinePage={isCreditLinePage}
			/>
		) : (
			<CreditLineBalanceNoSigned isCreditLinePage={isCreditLinePage} />
		);
	}

	return renderCreditLineBalance[creditLineBalanceState]({
		creditLimit: creditAccount.creditLimit,
		isFirstWithdrawal: !!isFirstCreditAccountWithdrawal,
		unpaidPrincipal: creditAccount.unpaidPrincipal,
		ModificationCtaButton: ModificationCtaButton,
		LimitIncreaseCtaButton: LimitIncreaseCtaButton,
	});
};
