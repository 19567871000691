export const PAYSERA_LOGIN_STATUS_SEARCH_NAME = 'payseraLoginStatus';

export enum PayseraLoginStatus {
	SUCCESS = 'success',
	ERROR = 'error',
}

export const RESET_PAYSERA_LOGIN_PARAMS = {
	[PAYSERA_LOGIN_STATUS_SEARCH_NAME]: undefined,
	data: undefined,
	ss1: undefined,
	ss2: undefined,
};
