import { isProd } from '@/shared/config/envs';
import { MarketingSearchParam } from '@config/search-params';
import {
	useGoogleTagManagerAnalytics,
	useHandleAnalyticsCampaignParameters,
	useRudderStackAnalytics,
	useSiftAnalytics,
	useSmartlookAnalytics,
} from '@entities/analytics';
import { useFirebase } from '@entities/firebase';
import { useZendeskWidget } from '@entities/support';
import { userApi } from '@entities/user';
import { type QueryClient, queryOptions } from '@tanstack/react-query';
import {
	Outlet,
	ScrollRestoration,
	createRootRouteWithContext,
	retainSearchParams,
	useRouterState,
} from '@tanstack/react-router';
import { Suspense, lazy, useEffect } from 'react';
import { useEffectOnce, useMount } from 'react-use';
import * as z from 'zod';

const rootValidateSearchSchema = z.object({
	rl_anonymous_id: z.string().optional().catch(undefined),
	[MarketingSearchParam.UTM_NAME]: z.string().optional().catch(undefined),
	[MarketingSearchParam.UTM_SOURCE]: z.string().optional().catch(undefined),
	[MarketingSearchParam.UTM_CAMPAIGN]: z.string().optional().catch(undefined),
	[MarketingSearchParam.UTM_MEDIUM]: z.string().optional().catch(undefined),
	[MarketingSearchParam.UTM_CONTENT]: z.string().optional().catch(undefined),
	[MarketingSearchParam.UTM_TERM]: z.string().optional().catch(undefined),
	[MarketingSearchParam.GCLID]: z.string().optional().catch(undefined),
});

const TanStackRouterDevtools = isProd
	? () => null
	: lazy(() =>
			import('@tanstack/router-devtools').then((res) => ({
				default: res.TanStackRouterDevtools,
			})),
		);

const ErrorPage = lazy(() =>
	import('@pages/error').then((mod) => ({
		default: mod.ErrorPage,
	})),
);

export const Route = createRootRouteWithContext<{
	queryClient: QueryClient;
}>()({
	search: {
		middlewares: [
			retainSearchParams([
				MarketingSearchParam.UTM_NAME,
				MarketingSearchParam.UTM_SOURCE,
				MarketingSearchParam.UTM_CAMPAIGN,
				MarketingSearchParam.UTM_MEDIUM,
				MarketingSearchParam.UTM_CONTENT,
				MarketingSearchParam.UTM_TERM,
				MarketingSearchParam.GCLID,
			]),
		],
	},
	validateSearch: rootValidateSearchSchema,
	beforeLoad: async ({ context: { queryClient } }) => {
		await queryClient.ensureQueryData(
			queryOptions({
				queryKey: userApi.useSuspenseUserQuery.getKey(),
				queryFn: userApi.useUserQuery.fetcher(),
			}),
		);
	},
	component: () => {
		const {
			location: { pathname },
		} = useRouterState();
		const { addParamsFromLocalStorageToUrl, clearParamsFromLocalStorage } =
			useHandleAnalyticsCampaignParameters();

		const { initializeApp } = useFirebase();

		useSiftAnalytics();
		useSmartlookAnalytics();
		useGoogleTagManagerAnalytics();
		useZendeskWidget();

		const { loadZendeskWidget } = useZendeskWidget();
		const { trackPage } = useRudderStackAnalytics();

		useMount(() => {
			initializeApp();
			loadZendeskWidget();
		});

		useEffect(() => {
			trackPage(pathname);
		}, [pathname, trackPage]);

		useEffectOnce(() => {
			clearParamsFromLocalStorage();
			addParamsFromLocalStorageToUrl();
		});

		return (
			<>
				<ScrollRestoration />
				<Outlet />
				<Suspense>
					<TanStackRouterDevtools />
				</Suspense>
			</>
		);
	},
	errorComponent: ErrorPage,
});
