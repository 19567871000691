import { Cta } from '@components/Cta';
import {
	LOCIZE_CREDIT_ACCOUNT_MODIFICATION_KEYS,
	LOCIZE_NAMESPACES,
} from '@config/locize';
import { WITHDRAWAL_PANEL_TEST_KEYS } from '@entities/credit-line/config';
import { useTranslation } from 'react-i18next';
import { useRequestCreditAccountModification } from '../hooks';

export const CreditAccountModificationButton = () => {
	const { t } = useTranslation(LOCIZE_NAMESPACES.creditAccountModification);

	const { requestCreditAccountModification } =
		useRequestCreditAccountModification();

	return (
		<Cta
			text={t(
				LOCIZE_CREDIT_ACCOUNT_MODIFICATION_KEYS.creditAccountModificationButtonLabel,
			)}
			actionText={t(
				LOCIZE_CREDIT_ACCOUNT_MODIFICATION_KEYS.creditAccountModificationActionText,
			)}
			onClick={requestCreditAccountModification}
			dataTestId={WITHDRAWAL_PANEL_TEST_KEYS.caModificationCta}
		/>
	);
};
