import { MarketingSearchParam } from '@config/search-params';
import { useSearch } from '@tanstack/react-router';

export const useGetAnalyticsCampaignParametersFromSearch = () => {
	const {
		[MarketingSearchParam.UTM_NAME]: utm_name = '',
		[MarketingSearchParam.UTM_SOURCE]: utm_source = '',
		[MarketingSearchParam.UTM_CONTENT]: utm_content = '',
		[MarketingSearchParam.UTM_MEDIUM]: utm_medium = '',
		[MarketingSearchParam.UTM_CAMPAIGN]: utm_campaign = '',
		[MarketingSearchParam.UTM_TERM]: utm_term = '',
		[MarketingSearchParam.GCLID]: gclid = '',
	} = useSearch({ strict: false });

	return Object.entries({
		utm_name,
		utm_source,
		utm_content,
		utm_medium,
		utm_campaign,
		utm_term,
		gclid,
	}).reduce(
		(prev, [key, value]) => {
			if (value) {
				prev[key] = value;
			}
			return prev;
		},
		{} as Record<string, string>,
	);
};
