export const LOCIZE_CREDIT_ACCOUNT_MODIFICATION_KEYS = {
	creditAccountModificationButtonLabel:
		'credit-account-modification.button.label',
	creditAccountModificationActionText:
		'credit-account-modification.action.text',
	creditAccountModificationPageTitle: 'credit-account-modification.page.title',
	creditAccountModificationTitle: 'credit-account-modification.title',
	creditAccountModificationDescription:
		'credit-account-modification.description',
	creditAccountModificationDescription2:
		'credit-account-modification.description.2',
	creditAccountModificationRejectedTitle:
		'credit-account-modification.rejected.title',
	creditAccountModificationSuccessTitle:
		'credit-account-modification.success.title',
	creditAccountModificationContractLink:
		'credit-account-modification.contract.link',
	creditAccountModificationIncreaseAmount:
		'credit-account-modification.increase.amount',
	failurePageTitle: 'credit-account-modification.failure.page.title',
	failureTitle: 'credit-account-modification.failure.title',
	failureDescription: 'credit-account-modification.failure.description',
	sign: 'credit-account-modification.sign',
	tryAgain: 'credit-account-modification.try.again',
};
