import { Cta } from '@components/Cta';
import {
	LOCIZE_CREDIT_ACCOUNT_LIMIT_INCREASE_KEYS,
	LOCIZE_NAMESPACES,
} from '@config/locize';
import { WITHDRAWAL_PANEL_TEST_KEYS } from '@entities/credit-line/config';
import { useTranslation } from 'react-i18next';
import { useRequestCreditAccountLimitIncrease } from '../hooks';

export const CreditAccountLimitIncreaseButton = () => {
	const { t } = useTranslation(LOCIZE_NAMESPACES.creditAccountLimitIncrease);

	const { requestCreditAccountLimitIncrease } =
		useRequestCreditAccountLimitIncrease();

	return (
		<Cta
			text={t(
				LOCIZE_CREDIT_ACCOUNT_LIMIT_INCREASE_KEYS.creditAccountLimitIncreaseButtonLabel,
			)}
			actionText={t(
				LOCIZE_CREDIT_ACCOUNT_LIMIT_INCREASE_KEYS.creditAccountLimitIncreaseActionText,
			)}
			onClick={requestCreditAccountLimitIncrease}
			dataTestId={WITHDRAWAL_PANEL_TEST_KEYS.limitIncreaseCta}
		/>
	);
};
