import { routeTree } from '@/routeTree.gen';
import { queryClient } from '@lib/queryClient';
import { RouterProvider, createRouter } from '@tanstack/react-router';

const PendingScreen = () => null; // Simplified loading state

export const router = createRouter({
	routeTree,
	notFoundMode: 'root',
	context: {
		queryClient,
	},
	defaultPendingComponent: PendingScreen,
});

declare module '@tanstack/react-router' {
	interface Register {
		router: typeof router;
	}
}

export const AppRouter = () => <RouterProvider router={router} />;
