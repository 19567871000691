/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../shared/types/api.gen';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type RequestCreditAccountLimitIncreaseMutationVariables = Types.Exact<{
  creditAccountId: Types.Scalars['Int']['input'];
}>;


export type RequestCreditAccountLimitIncreaseMutation = { creditAccountLimitIncrease?: { id: number, hash?: string | null } | null };



export const RequestCreditAccountLimitIncreaseDocument = `
    mutation RequestCreditAccountLimitIncrease($creditAccountId: Int!) {
  creditAccountLimitIncrease: request_credit_limit_increase(
    credit_account_id: $creditAccountId
  ) {
    id
    hash
  }
}
    `;

export const useRequestCreditAccountLimitIncreaseMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RequestCreditAccountLimitIncreaseMutation, TError, RequestCreditAccountLimitIncreaseMutationVariables, TContext>) => {
    
    return useMutation<RequestCreditAccountLimitIncreaseMutation, TError, RequestCreditAccountLimitIncreaseMutationVariables, TContext>(
      {
    mutationKey: ['RequestCreditAccountLimitIncrease'],
    mutationFn: (variables?: RequestCreditAccountLimitIncreaseMutationVariables) => fetcher<RequestCreditAccountLimitIncreaseMutation, RequestCreditAccountLimitIncreaseMutationVariables>(RequestCreditAccountLimitIncreaseDocument, variables)(),
    ...options
  }
    )};


useRequestCreditAccountLimitIncreaseMutation.fetcher = (variables: RequestCreditAccountLimitIncreaseMutationVariables, options?: RequestInit['headers']) => fetcher<RequestCreditAccountLimitIncreaseMutation, RequestCreditAccountLimitIncreaseMutationVariables>(RequestCreditAccountLimitIncreaseDocument, variables, options);
