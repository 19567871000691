import { OLD_APP_ROUTE_NAME, ROUTE_NAMES } from '@config/routes';
import { useUserCreditAccount } from '@entities/user';
import { useFeatureToggles, useToast } from '@hooks/system';
import { useNavigate } from '@tanstack/react-router';
import { creditAccountModificationApi } from '../api';

export const useRequestCreditAccountModification = () => {
	const { creditAccountModificationFeature } = useFeatureToggles();
	const navigate = useNavigate();
	const { showErrorMessage } = useToast();
	const { data: creditAccount } = useUserCreditAccount();

	if (!creditAccount) throw new Error('User credit account is missing');

	const creditAccountModificationMutation =
		creditAccountModificationApi.useRequestCreditAccountModificationMutation({
			onSuccess: ({ creditAccountModification }) => {
				if (!creditAccountModification?.hash) {
					throw new Error('Credit account modification hash is missing');
				}

				navigate({
					to: ROUTE_NAMES.creditAccountModification,
					search: {
						hash: creditAccountModification.hash,
					},
					replace: true,
				});
			},
			onError: () => {
				showErrorMessage('Credit account modification request failed');
			},
		});

	const requestCreditAccountModification = () => {
		if (!creditAccountModificationFeature) {
			window.open(OLD_APP_ROUTE_NAME.creditAccountModification, '_self');
			return;
		}

		creditAccountModificationMutation.mutate({
			creditAccountId: creditAccount.id,
		});
	};

	return {
		...creditAccountModificationMutation,
		requestCreditAccountModification,
	};
};
