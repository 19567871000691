/** @generated THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as Types from '../../../shared/types/api.gen';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetcher } from '@lib/fetcher';
export type RequestCreditAccountModificationMutationVariables = Types.Exact<{
  creditAccountId: Types.Scalars['Int']['input'];
}>;


export type RequestCreditAccountModificationMutation = { creditAccountModification?: { id: number, hash?: string | null } | null };



export const RequestCreditAccountModificationDocument = `
    mutation RequestCreditAccountModification($creditAccountId: Int!) {
  creditAccountModification: request_credit_account_modification(
    credit_account_id: $creditAccountId
  ) {
    id
    hash
  }
}
    `;

export const useRequestCreditAccountModificationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RequestCreditAccountModificationMutation, TError, RequestCreditAccountModificationMutationVariables, TContext>) => {
    
    return useMutation<RequestCreditAccountModificationMutation, TError, RequestCreditAccountModificationMutationVariables, TContext>(
      {
    mutationKey: ['RequestCreditAccountModification'],
    mutationFn: (variables?: RequestCreditAccountModificationMutationVariables) => fetcher<RequestCreditAccountModificationMutation, RequestCreditAccountModificationMutationVariables>(RequestCreditAccountModificationDocument, variables)(),
    ...options
  }
    )};


useRequestCreditAccountModificationMutation.fetcher = (variables: RequestCreditAccountModificationMutationVariables, options?: RequestInit['headers']) => fetcher<RequestCreditAccountModificationMutation, RequestCreditAccountModificationMutationVariables>(RequestCreditAccountModificationDocument, variables, options);
