export const LOCIZE_COMMON_KEYS = {
	logout: 'common.logout',
	anotherAuthMethod: 'another-auth-method',
	invoiceNeutralText: 'common.invoiceNeutralText',
	invoiceWarningText: 'common.invoiceWarningText',
	invoiceOverdueText: 'common.invoiceOverdueText',
	footerText: 'footer.text',
	monthLabel: 'month-label',
	emailPlaceholder: 'email-placeholder',
	leaveWithoutSavingPrompt: 'leave-without-saving-prompt',
	applyButton: 'apply-button',
	close: 'close',
} as const;
