import type { PropsWithChildren } from 'react';
import { Helmet as HelmetComponent } from 'react-helmet-async';
import type { HelmetProps } from 'react-helmet-async';

type HelmetComponentProps = PropsWithChildren<HelmetProps> & {
	isIndexable?: boolean;
};

export const Helmet = ({
	title,
	meta = [],
	isIndexable = false,
	children,
	...props
}: HelmetComponentProps) => {
	return (
		<HelmetComponent
			title={title}
			meta={[
				{
					name: 'robots',
					content: isIndexable ? 'index, nofollow' : 'noindex, nofollow',
				},
				...meta,
			]}
			{...props}
		>
			{children}
		</HelmetComponent>
	);
};
