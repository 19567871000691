import { queryClient } from '@lib/queryClient';
import { useQuery } from '@tanstack/react-query';

export const useGetCachedData = (
	key: string,
): ReturnType<typeof queryClient.getQueryData> => {
	const { data } = useQuery({
		queryKey: [key],
		initialData: () => queryClient.getQueryData([key]),
		enabled: false,
	});

	return data;
};
