import { invoicesApi } from '@entities/invoices/api';
import { getRouteApi } from '@tanstack/react-router';

const routeApi = getRouteApi('/payment/invoice/');

export const useUserRecentInvoiceByReference = () => {
	const { referenceKey } = routeApi.useSearch();

	if (!referenceKey) {
		throw new Error(
			'Reference key is required while fetching user payment data',
		);
	}

	return invoicesApi.useSuspenseUserPaymentDataQuery(
		{ referenceKey },
		{
			select: (data) => {
				if (!data?.user_payment_data) {
					return null;
				}

				return {
					userId: data.user_payment_data.user_id,
					url: data.user_payment_data?.recent_invoice?.url,
					nextPaymentDate: data.user_payment_data?.recent_invoice?.due_at,
					totalAmount: data.user_payment_data?.amount,
					referenceNr: data.user_payment_data?.invoice_reference_nr,
				};
			},
		},
	);
};
