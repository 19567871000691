import { APP_CONFIG } from '@config/app';
import { ROUTE_NAMES } from '@config/routes';
import { userApi } from '@entities/user';
import { queryOptions } from '@tanstack/react-query';
import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_protected')({
	beforeLoad: async ({ context: { queryClient } }) => {
		const data = await queryClient.ensureQueryData(
			queryOptions({
				queryKey: userApi.useSuspenseUserQuery.getKey(),
				queryFn: userApi.useUserQuery.fetcher(),
			}),
		);

		if (!data.me) {
			throw redirect({
				to: ROUTE_NAMES.auth,
				search: {
					authMethod: APP_CONFIG.authMethods[0],
					redirectUrl: location.pathname,
				},
				replace: true,
			});
		}
	},
});
