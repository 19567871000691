import { OLD_APP_ROUTE_NAME, ROUTE_NAMES } from '@config/routes';
import { useUserCreditAccount } from '@entities/user';
import { useFeatureToggles, useToast } from '@hooks/system';
import { useNavigate } from '@tanstack/react-router';
import { creditAccountLimitIncreaseApi } from '../api';

export const useRequestCreditAccountLimitIncrease = () => {
	const { creditAccountLimitIncreaseFeature } = useFeatureToggles();
	const navigate = useNavigate();
	const { showErrorMessage } = useToast();
	const { data: creditAccount } = useUserCreditAccount();

	if (!creditAccount) throw new Error('User credit account is missing');

	const creditAccountLimitIncreaseMutation =
		creditAccountLimitIncreaseApi.useRequestCreditAccountLimitIncreaseMutation({
			onSuccess: ({ creditAccountLimitIncrease }) => {
				if (!creditAccountLimitIncrease?.hash) {
					throw new Error('Credit account limit increase hash is missing');
				}

				navigate({
					to: ROUTE_NAMES.creditAccountLimitIncrease,
					search: {
						hash: creditAccountLimitIncrease.hash,
					},
					replace: true,
				});
			},
			onError: () => {
				showErrorMessage('Credit account limit increase request failed');
			},
		});

	const requestCreditAccountLimitIncrease = () => {
		if (!creditAccountLimitIncreaseFeature) {
			window.open(OLD_APP_ROUTE_NAME.creditAccountLimitIncrease, '_self');
			return;
		}

		creditAccountLimitIncreaseMutation.mutate({
			creditAccountId: creditAccount.id,
		});
	};

	return {
		...creditAccountLimitIncreaseMutation,
		requestCreditAccountLimitIncrease,
	};
};
