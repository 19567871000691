import { APP_CONFIG } from '@config/app';
import { ROUTE_NAMES } from '@config/routes';
import { creditAccountLimitRecalculationApi } from '@entities/credit-account-limit-recalculation/api';
import { CreditAccountLimitRecalculationRequestStatuses } from '@entities/credit-account-limit-recalculation/config';

import {
	CreditModificationView,
	RecalculationStatuses,
} from '@features/credit-account-modification/config';
import { ErrorPage } from '@pages/error';
import { createFileRoute, redirect } from '@tanstack/react-router';
import * as z from 'zod';

const creditAccountModificationSearchSchema = z.object({
	fromPathname: z.string().optional().catch(undefined),
	hash: z.string().optional().catch(undefined),
	view: z.nativeEnum(CreditModificationView).optional().catch(undefined),
	status: z
		.nativeEnum(CreditAccountLimitRecalculationRequestStatuses)
		.optional()
		.catch(undefined),
});

export const Route = createFileRoute('/_protected/credit-account-modification')(
	{
		validateSearch: creditAccountModificationSearchSchema,
		beforeLoad: async ({ search: { hash }, context: { queryClient } }) => {
			if (hash) {
				if (
					location.pathname === ROUTE_NAMES.creditAccountModificationFailure
				) {
					return;
				}
				const { creditAccountLimitRecalculation } =
					await queryClient.fetchQuery({
						queryKey:
							creditAccountLimitRecalculationApi.useGetCreditAccountLimitRecalculationQuery.getKey(
								{
									hash,
								},
							),
						queryFn:
							creditAccountLimitRecalculationApi.useGetCreditAccountLimitRecalculationQuery.fetcher(
								{
									hash,
								},
							),
					});

				switch (creditAccountLimitRecalculation?.status) {
					case CreditAccountLimitRecalculationRequestStatuses.PENDING: {
						const { purchaseFlowUrl } = APP_CONFIG;

						window.open(
							`${purchaseFlowUrl}/credit-limit-recalculation?hash=${hash}`,
							'_self',
						);
						return new Promise(() => {});
					}
					case CreditAccountLimitRecalculationRequestStatuses.ACCEPTED: {
						throw redirect({
							replace: true,
							to: ROUTE_NAMES.creditAccountWithdrawalForm,
							search: {
								recalculation: RecalculationStatuses.SUCCESS,
							},
						});
					}
					case CreditAccountLimitRecalculationRequestStatuses.REJECTED: {
						throw redirect({
							replace: true,
							to: ROUTE_NAMES.creditAccountWithdrawalForm,
							search: {
								recalculation: RecalculationStatuses.REJECTED,
							},
						});
					}
					case CreditAccountLimitRecalculationRequestStatuses.APPROVED: {
						const { current_credit_limit, new_credit_limit } =
							creditAccountLimitRecalculation;

						const validNewLimit = new_credit_limit ?? 0;
						const validCurrentLimit = current_credit_limit ?? 0;

						const view =
							validCurrentLimit < validNewLimit
								? CreditModificationView.HIGHER_LIMIT
								: CreditModificationView.LOWER_OR_SAME_LIMIT;

						if (
							location.pathname !== ROUTE_NAMES.creditAccountModificationSigning
						) {
							throw redirect({
								replace: true,
								to: ROUTE_NAMES.creditAccountModificationSigning,
								search: {
									hash: hash,
									view: view,
								},
							});
						}
					}
				}
			} else {
				throw new Error('There is no hash');
			}
		},
		errorComponent: ErrorPage,
	},
);
