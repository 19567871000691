import CancelIcon from '@/shared/assets/icons/close.svg?react';
import { Helmet } from '@components/Helmet';
import { Typography } from '@components/typography';
import { Button } from '@components/ui/button';
import { APP_CONFIG } from '@config/app';
import { LOCIZE_ERROR_SCREEN_KEYS, LOCIZE_NAMESPACES } from '@config/locize';
import { ROUTE_NAMES } from '@config/routes';
import { useIsUserAuthorized } from '@entities/user';
import { useAppConfig } from '@hooks/system';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export const ErrorPage = ({ error }: { error: Error }) => {
	console.log({ error });
	const { t } = useTranslation(LOCIZE_NAMESPACES.errorScreen);

	const { data: isAuthorized } = useIsUserAuthorized();
	const { supportUrl } = useAppConfig();

	return (
		<>
			<Helmet title={t(LOCIZE_ERROR_SCREEN_KEYS.title)} />
			<div className="flex size-full flex-col px-8 pt-28 pb-20 text-center">
				<div className="mx-auto flex w-full max-w-[25rem] flex-col items-center">
					<CancelIcon className="mb-9" />
					<Typography variant="m" className="mb-8">
						{t(LOCIZE_ERROR_SCREEN_KEYS.title)}
					</Typography>
					<Typography variant="text-l" className="mb-14">
						{t(LOCIZE_ERROR_SCREEN_KEYS.errorDescription)}
					</Typography>
					<div className="grid w-full gap-4">
						<Button fullWidth asChild>
							<Button fullWidth asChild>
								{isAuthorized ? (
									<Link to={ROUTE_NAMES.dashboard} replace>
										{t(LOCIZE_ERROR_SCREEN_KEYS.goHomeButton)}
									</Link>
								) : (
									<Link
										to={ROUTE_NAMES.auth}
										replace
										search={{
											authMethod: APP_CONFIG.authMethods[0],
										}}
									>
										{t(LOCIZE_ERROR_SCREEN_KEYS.goHomeButton)}
									</Link>
								)}
							</Button>
						</Button>
						<Button fullWidth asChild variant="grey">
							<a href={supportUrl}>
								{t(LOCIZE_ERROR_SCREEN_KEYS.supportButtonText)}
							</a>
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};
