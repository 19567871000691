import { createFileRoute } from '@tanstack/react-router';
import * as z from 'zod';

const CreditAccountConversionApplySearchSchema = z.object({
	referenceKey: z.string().optional().catch(undefined),
	fromPathname: z.string().optional().catch(undefined),
});

export const Route = createFileRoute(
	'/_protected/credit-account-conversion/apply',
)({
	validateSearch: CreditAccountConversionApplySearchSchema,
});
